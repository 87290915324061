import React, { useState } from 'react';
import axios from 'axios';
import "./Payment.scss";
const PaymentComponent = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'fullName') {
      setFullName(value);
    } else if (name === 'email') {
      setEmail(value);
    }
  };

  const paymentHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const BASE_URL = process.env.REACT_APP_PAYMENT_INTEGRATION_URL;
    const IMAGE_URL = process.env.REACT_APP_PAYMENT_IMAGE;
    try {
      const response = await fetch(
        `${BASE_URL}/orders/payment`,
        {
          method: 'POST',
          body: JSON.stringify({
            amount: 10000* 100,
            currency: 'INR',
            user_id: 1,
            program_registration_id: 1,
            billing_name: 'greesh',
            billing_address: '123 Main Street, City, Country',
            pan: 'ABCDE1234F',
            tan: 'TAN12345',
            tax_amount: 10000,
            created_by: 'admin',
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const order = await response.json();
      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: 'karishye',
        description: 'Karishye Transaction',
        image: IMAGE_URL,
        order_id: order.id,
        handler: async function (response) {
          setShowOverlay(true);
          setIsLoading(false);
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
      rzp1.open();
    } catch (error) {
      console.error('Payment initiation error:', error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={paymentHandler}>
        <div>
          <label>Full Name</label>
          <input type="text" name="fullName" value={fullName} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Email</label>
          <input type="email" name="email" value={email} onChange={handleInputChange} required />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Pay Now'}
        </button>
      </form>
      {showOverlay && <div>Payment Successful</div>}
    </div>
  );
};

export default PaymentComponent;
