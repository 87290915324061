import React, { useEffect, useState } from "react";
import "./Booking.scss";
import Footer from "../../Component/Footer/Footer";
import Navbar from "../../Component/Navbar/navbar";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../environments/config";
import LoginModal from "../../Component/Login/Login";
import { COUNTRIES } from "../../Constants/countries"; 

export default function Booking() {
  const [viewDetail, setViewDetail] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [pujaMode, setPujaMode] = useState("at_my_home");
  const [whomPuja, setWhomPuja] = useState("self");
  const [puja, setPuja] = useState(null);
  const [gst, setGst] = useState(0);
  const [curUser, setCurUser] = useState("");
  const [noOfPujaris, setNoOfPujaris] = useState(0);
  const [signupFormData, setSignupFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    gender: "",
    country: "",
    state: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    pincode: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [pujariCost, setPujariCost] = useState(0);
  const [samagriCost, setSamagriCost] = useState(0);
  const [samagriList, setSamagriList] = useState([]);
  const [formValid, setIsFormValid] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    country: "INDIA",
    addressLine1: "",
    addressLine2: "",
    city: "Hyderabad",
    pincode: "",
    states: "Telangana",
    muhurtam: "",
    muhurtam_fixed:"no"
  });
  const location = useLocation();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const frombooking = true;

  const changePujaMode = (mode) => {
    setPujaMode(mode);
  };

  const changeMode = (puja) => {
    setWhomPuja(puja);
  };

  useEffect(() => {
    document.title = "Karishye - New Booking";
  }, []);

  const hideLogin = () => {
    setLoginModal(!loginModal);
    setPhoneNumber("+91");
  };
  const createBookingData = () => {
    const user = localStorage.getItem("userId");
    return {
      data: {
        userId: user,
        eventType: pujaMode,
        whomPuja: whomPuja,
        state: "active",
        samagriStatus: "samagri_under_review",
        serviceFee: puja?.pujamodels?.[0]?.serviceFee,
        bookingSellingPrice: totalCost,
        gst: gst,
        pujaId: puja?.id,
        bookingStatus: "submitted",
        model: puja?.pujamodels?.[0]?.id,
        noOfPujaris: puja?.pujamodels?.[0]?.noOfPujaris,
        pujariCost: pujariCost,
        ...formData,
      },
      samagri: samagriList,
      procedures: puja?.pujamodels?.[0]?.procedures.map(
        (puja) => puja.procedureId
      ),
    };
  };

  useEffect(() => {
    const {
      firstName,
      lastName,
      gender,
      addressLine1,
      addressLine2,
      city,
      pincode,
      states,
    } = formData;
    setIsFormValid(
      firstName &&
        lastName &&
        gender &&
        addressLine1 &&
        addressLine2 &&
        city &&
        pincode &&
        states
    );
  }, [formData]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users`);
        const users = response.data.data;
        const userdetails = Object.values(users).find((u) => u.name === user);
       
        setCurUser(userdetails.id);
        if (userdetails) {
          const savedNotes = localStorage.getItem(`notes_${puja?.id}`) || "";
          const savedMuhurtam = localStorage.getItem(`muhurtam_${puja?.id}`) || "";
          
          setFormData({
            firstName: userdetails.name || "",
            lastName: userdetails.surname || "",
            gender: userdetails.gender || "",
            country: userdetails.country || "INDIA",
            states: userdetails.state || "Telangana",
            addressLine1: userdetails.addressLine1 || "",
            addressLine2: userdetails.addressLine2 || "",
            city: userdetails.city || "Hyderabad",
            pincode: userdetails.pincode || "",
            customerNotes: savedNotes,
            muhurtam: savedMuhurtam,
            muhurtam_fixed: savedMuhurtam ? "yes" : "no",
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    if (user && puja?.id) {
      fetchUser();
    }
  }, [user, puja?.id]);
 
  useEffect(() => {
    if(localStorage.getItem(`notes_${puja?.id}`)!==formData.customerNotes){
    localStorage.setItem(`notes_${puja?.id}`, formData.customerNotes);
    }
    if(localStorage.getItem(`muhurtam_${puja?.id}`)!==formData.muhurtam){
      localStorage.setItem(`muhurtam_${puja?.id}`, formData.muhurtam);
   
    }
  }, [formData.customerNotes, formData.muhurtam]);
  
  useEffect(() => {
    setSignupFormData({
      name: formData.firstName,
      surname: formData.lastName,
      gender: formData.gender,
      country: formData.country,
      state: formData.states,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      city: formData.city,
      pincode: formData.pincode,
      phoneNumber: "",
    });
  }, [formData]);

  useEffect(() => {
    const fetchPujaDetails = async (pujaId) => {
      try {
        const response = await axios.get(`${BASE_URL}/pujas?id=${pujaId}`);
        setPuja(response.data.data[0]);
      } catch (err) {
        console.log("Error fetching puja details:", err);
      }
    };

    const pujaId = localStorage.getItem("pujaId");
    if (pujaId) {
      fetchPujaDetails(pujaId);
    } else {
      console.log("No puja_id found in query parameters");
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = localStorage.getItem("userId");
    const data = createBookingData();
  
    try {
      const response = await fetch(
        `${BASE_URL}/booking/puja-booking?userId=${user}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      localStorage.removeItem(`notes_${puja.id}`);
      localStorage.removeItem(`muhurtam_${puja.id}`);
      localStorage.removeItem(`userSamagri_${puja.id}`);
      localStorage.removeItem(`samagricost_${puja.id}`);
      localStorage.removeItem(`totalcost_${puja.id}`);
      localStorage.removeItem(`pujaricost_${puja.id}`);
      localStorage.removeItem(`toggleSamagri_${puja.id}`);
      localStorage.removeItem(`gst_${puja.id}`);
  
    } catch (error) {
      console.error("Error:", error);
    }
    navigate("/booking-success");
  };

  useEffect(() => {
    if (puja?.id) {
      setNoOfPujaris(localStorage.getItem(`noOfPujaris_${puja?.id}`));
      const totalCost = localStorage.getItem(`totalcost_${puja?.id}`);
      const pujariCost = localStorage.getItem(`pujaricost_${puja?.id}`);
      const samagriCost = localStorage.getItem(`samagricost_${puja?.id}`);
      const fromBooking = localStorage.getItem("ToBooking");
      setGst(localStorage.getItem(`gst_${puja?.id}`));
      const totalSamagri = JSON.parse(
        localStorage.getItem(`toggleSamagri_${puja?.id}`)
      );

      if(!fromBooking){
      setTotalCost(totalCost ? parseFloat(totalCost) : 0);
      setPujariCost(pujariCost ? parseFloat(pujariCost) : 0);
      setSamagriCost(samagriCost ? parseFloat(samagriCost) : 0);
      }
      else{
        setTotalCost(puja?.pujamodels?.[0]?.pujariCost + puja?.pujamodels?.[0]?.samagriCost + puja?.pujamodels?.[0]?.serviceFee);
        setPujariCost(puja?.pujamodels?.[0]?.pujariCost);
        setSamagriCost(puja?.pujamodels?.[0]?.samagriCost);
      }

      const fetchSamagriList = async (pujaId) => {
        try {
          const response = await axios.get(
            `${BASE_URL}/puja-samagri/${pujaId}`
          );
          setSamagriList(response.data.data);
        } catch (err) {
          console.log("err", err);
        }
      };

      if (totalSamagri != null) {
        setSamagriList(totalSamagri);
      } else {
        fetchSamagriList(puja.id);
      }
    }
  }, [puja?.id]);
  const data = createBookingData();
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="BookingSection">
          <div class="top-form-header">
            <div
              class="back-details"
              onClick={() => {window.history.back()}}
            >
              <img src="../../assets/images/back-arrow.png" alt="" />
              Back
            </div>
            <h2 class="contact-button">Book a Puja</h2>
            <span class="field-mandatory">
              <img
                class="outline-info"
                src="../../assets/images/info-outline.png"
                alt=""
              />{" "}
              All fields are mandatory
            </span>
          </div>

          <div className="accordion-item price-detail-sec">
            <div className="form-puja-name-price-sec">
              <h2 className="form-puja-heading">{puja?.name}</h2>
              <div className="form-price-sec">
                <p className="form-p">Total payable</p>
                <p className="form-price">{totalCost}</p>
              </div>
            </div>
            <div className="border-div">
              <img src="../../assets/images/gold-star-with-dots.png" alt="" />
              <hr />
              <img src="../../assets/images/gold-star-with-dots.png" alt="" />
            </div>
            <div className="selected-puja-bottom-sec">
              <div className="form-puja-modes">
                <div
                  className="available-box online-mode-icon"
                  style={{ display: "flex" }}
                >
                  <img src="../../assets/images/mode1.png" alt="" />
                  <p>Online</p>
                </div>
                <div
                  className="available-box in-person-mode-icon"
                  style={{ display: "flex" }}
                >
                  <img src="../../assets/images/mode2.png" alt="" />
                  <p>In Person</p>
                </div>
                <div
                  className="available-box assisted-mode-icon"
                  style={{ display: "flex" }}
                >
                  <img src="../../assets/images/mode3.png" alt="" />
                  <p>Assisted</p>
                </div>
              </div>
              <div className="form-samagri-count">
                <img
                  style={{ width: 34, height: 20 }}
                  src="../../assets/images/leaf.png"
                  alt=""
                />
                <p className="form-p">
                  <span className="samagri-count">{samagriList.length}</span> samagri selected
                </p>
              </div>
              <div
                className="form-view-details"
                onClick={() => setViewDetail(!viewDetail)}
                style={{ display: viewDetail ? "none" : "flex" }}
              >
                <p className="viewmoreDeatil">View more details</p>
                <img
                  className="arrow-collapse-icon"
                  src="../../assets/images/down-icon.png"
                  alt=""
                  style={{
                    transform: viewDetail ? "rotate(3600deg)" : "rotate(0deg)",
                    marginBottom: "5px",
                  }}
                />
              </div>
              <div
                className="form-hide-details"
                id="form-hide-details"
                onClick={() => setViewDetail(!viewDetail)}
                style={{ display: viewDetail ? "flex" : "none" }}
              >
                <p>Hide details</p>
                <img
                  className="arrow-collapse-icon"
                  style={{ transform: "rotate(180deg)", marginBottom: "5px" }}
                  src="../../assets/images/down-icon.png"
                  alt=""
                />
              </div>
            </div>
            <div
              className="form-samagri-and-price-breakup"
              style={{ display: viewDetail ? "flex" : "none" }}
            >
              <div className="form-samagri-sec">
                <h3 className="samagri-heading">
                  Samagri List
                  <img
                    className="gold-star-line"
                    src="../../assets/images/gold-star-with-line.png"
                    alt=""
                  />
                  <img
                    className="gold-star-dots"
                    src="../../assets/images/gold-star-with-dots.png"
                    alt=""
                  />
                </h3>
                <div className="samagri-list-sec">
                  <ul className="list-of-samagri">
                    <li>
                      <p className="form-price-header">Items</p>
                      <p className="form-price-header">Quantity</p>
                      <p className="form-price-header">Price</p>
                    </li>
                    {samagriList &&
                      samagriList.map((samagri) => (
                        <li>
                          <p className="samagri-list-name">{samagri?.name}</p>
                          <p className="samagri-list-qty">
                            {Number(samagri?.standardQty) * samagri?.qty}
                            {samagri?.units}
                          </p>
                          <p className="samagri-list-price">
                            ₹ {samagri?.customerMrp * samagri?.qty}
                          </p>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="form-price-breakup">
                <h3 className="samagri-heading">
                  Price Breakup
                  <img
                    className="gold-star-line"
                    src="../../assets/images/gold-star-with-line.png"
                    alt=""
                  />
                  <img
                    className="gold-star-dots"
                    src="../../images/gold-star-with-dots.png"
                    alt=""
                  />
                </h3>
                <div className="price-box-invoice">
                  <div>
                    <p className="form-price-header">Invoice</p>
                    <p className="form-price-header">Price</p>
                  </div>
                </div>
                <div className="price-box-invoice">
                  <div>
                    <div>
                      <p>Pujari</p>
                      <p className="numberOfPujaries">
                        ({noOfPujaris})
                      </p>
                    </div>
                    <p className="pujari-cost">₹ {pujariCost}</p>
                  </div>
                </div>
                <div className="price-box-invoice">
                  <div>
                    <p>Samagri</p>
                    <p className="samagri-cost">₹ {samagriCost}</p>
                  </div>
                </div>
                <div className="price-box-invoice service-charges">
                  <div>
                    <p>Karishye charges</p>
                    <p className="service-charge-price">
                      {puja?.pujamodels?.[0]?.serviceFee}
                    </p>
                  </div>
                </div>

                <div className="price-box-invoice">
                  <div>
                    <div className="total-incl-tax">
                      <p className="total">Total</p>
                      <p className="totalSubDetail">(Incl. of all Taxes) </p>
                    </div>
                    <p className="price">₹{totalCost}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="accordion-item form-spl-notes">
              <h2 className="accordion-header ">Special Notes</h2>
              <textarea
                placeholder="Mention here if you need any specific requirements such as: need pujari from a particular sampradayam or vedam, or if you are looking to perform the puja in a particular aacharam etc"
                type="text"
                name="customerNotes"
                className="form-control int-fill spl-note-text"
                value={formData.customerNotes}
                onChange={handleChange}
              />
            </div>

            <div className="accordion-item  accordingSection form-puja-mode-selection">
              <h2 className="accordion-header "> Mode of Puja </h2>
              <div className="accordion-collapse">
                <div className="accordion-body">
                  <div className="d-flex justify-content-start align-items-center flex-wrap">
                    <ul className="checkinfl">
                      <li
                        className={
                          pujaMode === "at_my_home" ? "active" : "inactive"
                        }
                        id="li-at-home"
                        onClick={() => changePujaMode("at_my_home")}
                        style={{ display: "inline-block" }}
                      >
                        <img
                          src="../../assets/images/at-home.png"
                          alt="Icon"
                          className="img-fluid"
                        />
                        <span>
                          <input
                            type="radio"
                            name="puja_mode"
                            className="class_puja_mode hiddenclass"
                            value="at_my_home"
                            defaultChecked=""
                          />
                          At my home
                        </span>
                        <div className="over_content">
                          <p>
                            The pujari and material are sent to your home and
                            puja happens at your place
                          </p>
                        </div>
                      </li>

                      <li
                        className={
                          pujaMode === "remote" ? "active" : "inactive"
                        }
                        id="li-online"
                        onClick={() => changePujaMode("remote")}
                        style={{ display: "inline-block" }}
                      >
                        <img
                          src="../../assets/images/virtual.png"
                          alt="Icon"
                          className="img-fluid"
                        />
                        <span>
                          <input
                            type="radio"
                            name="puja_mode"
                            className="class_puja_mode hiddenclass"
                            value="remote"
                          />
                          Virtual
                        </span>
                        <div className="over_content">
                          <p>
                            You want our pujari to join on a zoom call and
                            conduct the puja for you. Typically opted by NRIs
                          </p>
                        </div>
                      </li>
                      <li
                        className={
                          pujaMode === "on_behalf" ? "active" : "inactive"
                        }
                        id="li-on-behalf"
                        onClick={() => changePujaMode("on_behalf")}
                        style={{ display: "inline-block" }}
                      >
                        <img
                          src="../../assets/images/headquater.png"
                          alt="Icon"
                          className="img-fluid"
                        />
                        <span>
                          <input
                            type="radio"
                            name="puja_mode"
                            className="class_puja_mode hiddenclass"
                            value="on_behalf"
                          />
                          On your behalf
                        </span>
                        <div className="over_content">
                          <p>
                            You are out of station or out of country, however
                            you wish for Karishye pujaris and team to perform
                            the puja on your behalf and send you the videos and
                            photographs
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div id="change-order-btns" className="btn-box">
                    <div className="notetag">
                      * We currently support "At my home" only in Telangana
                      state
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item booking-customer-fields">
              <h2 className="accordion-header ">
                For whom are you booking this puja
              </h2>
              <div className="accordion-collapse">
                <div className="accordion-body">
                  <ul className="checkself-flild checkinfl  member-form-btn-mobile">
                    <li
                      className={whomPuja === "self" ? "active" : "inactive"}
                      onClick={() => changeMode("self")}
                    >
                      <span>
                        <input
                          type="radio"
                          name="whom_puja"
                          className="class_puja_mode hiddenclass selfoption"
                          defaultValue="Self"
                          defaultChecked=""
                        />
                        Self
                      </span>
                    </li>
                    <li
                      className={whomPuja === "other" ? "active" : "inactive"}
                      onClick={() => changeMode("other")}
                    >
                      <span>
                        <input
                          type="radio"
                          name="whom_puja"
                          className="class_puja_mode hiddenclass selfoption"
                          defaultValue="Other"
                        />
                        Other
                      </span>
                    </li>
                  </ul>
                  <div className="form-group mb-3 max-width-l">
                    <label
                      className="labeltitle"
                      htmlFor="booking_customer[0][customer_name]"
                    >
                      First Name <span className="erroricon">*</span>
                      <span className="error" />
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control int-fill-input"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                      aria-required="true"
                    />
                  </div>
                  <div className="form-group mb-3 max-width-r">
                    <label
                      className="labeltitle"
                      htmlFor="booking_customer[0][last_name]"
                    >
                      Last Name <span className="erroricon">*</span>
                      <span className="error" />
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control int-fill-input
"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                      aria-required="true"
                    />
                  </div>
                  <div className="form-group mb-3 max-width-l">
                    <label className="labeltitle" htmlFor="gender">
                      Gender <span className="erroricon">*</span>
                      <span className="error" />
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      className="form-select int-fill-input"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                      aria-required="true"
                    >
                      <option value="">Please Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>

                  <div className="form-group mb-3 muhurtam-check-and-date">
                    <div className="muhurtam-check-div mb-3">
                      <label className="muhurtam-label ">
                        Do you already have a muhurtam fixed for this puja?
                      </label>
                      <div>
                        <p>
                          <input
                            type="radio"
                            className="radio-yes"
                            name="muhurtam_fixed"
                            onClick={() =>
                              setFormData((prevData) => ({
                                ...prevData,
                                muhurtam_fixed: "yes",
                              }))
                            }
                          />
                          Yes
                        </p>
                        <p>
                          <input
                            type="radio"
                            className="radio-no"
                            name="muhurtam_fixed"
                            onClick={() =>
                              setFormData((prevData) => ({
                                ...prevData,
                                muhurtam_fixed: "no",
                                muhurtam: "",
                              }))
                            }
                            defaultChecked
                          />
                          No
                        </p>
                      </div>
                    </div>
                     {formData.muhurtam_fixed === "yes" && (
                      <div className="muhurtam-date-div">
                        <label className="labeltitle" htmlFor="muhurtam">
                          Please enter the muhurtam date and time{" "}
                          <span className="erroricon">*</span>
                          <span className="error" />
                        </label>
                        <input
                          type="datetime-local"
                          name="muhurtam"
                          min={new Date().toISOString().slice(0, 16)}
                          className="form-control int-fill-input"
                          value={formData.muhurtam}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="accordion-item booking-customer-fields"
              style={{
                marginBottom: "5rem",
              }}
            >
              <h2 className="accordion-header ">Address Details</h2>
              <div className="accordion-collapse">
                <div className="accordion-body puja-card-body">
                  <div className="form-group mb-3">
                    <label className="labeltitle" htmlFor="address1">
                      Address 1
                    </label>
                    <textarea
                      name="addressLine1"
                      onChange={handleChange}
                      className="form-control int-fill"
                      value={formData.addressLine1}
                    ></textarea>
                  </div>
                  <div className="form-group mb-3">
                    <label className="labeltitle" htmlFor="address2">
                      Address 2
                    </label>
                    <textarea
                      name="addressLine2"
                      onChange={handleChange}
                      className="form-control int-fill"
                      value={formData.addressLine2}
                    ></textarea>
                  </div>
                  <div className="form-group mb-3 max-width-l dropdown">
                    <label className="labeltitle" htmlFor="country">
                      Country <span className="erroricon">*</span>
                      <span className="error" />
                    </label>
                    <select
                      name="country"
                      id="country"
                      className="form-select int-fill-input valid"
                      value={formData.country}
                      onChange={handleChange}
                      required
                      aria-required="true"
                      aria-invalid="false"
                    >
                      {COUNTRIES.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3 max-width-r">
                    <div className="state-outer">
                      <label className="labeltitle" htmlFor="other_state">
                        State <span className="erroricon">*</span>
                        <span className="stateerror error" />
                      </label>
                      <input
                        type="text"
                        name="states"
                        className="form-control int-fill-input"
                        value={formData.states}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 max-width-l">
                    <div className="city-outer">
                      <label className="labeltitle" htmlFor="other_city">
                        City <span className="erroricon">*</span>
                        <span className="cityerror error" />
                      </label>
                      <input
                        type="text"
                        name="city"
                        className="form-control int-fill-input"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 max-width-r">
                    <label className="labeltitle" htmlFor="pincode">
                      Pin / Zip Code<span className="erroricon">*</span>
                      <span className="error" />
                    </label>
                    <input
                      type="number"
                      name="pincode"
                      className="form-control int-fill-input"
                      value={formData.pincode}
                      onChange={handleChange}
                      required
                      aria-required="true"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      className="labeltitle full-label"
                      htmlFor="privacy_policy"
                    />
                    <div className="privacy-policy-wrapper">
                      <input
                        type="checkbox"
                        required=""
                        name="privacy_policy"
                        defaultValue={1}
                        id="agree"
                        aria-required="true"
                      />
                      <label className="labeltitle flexlabel ">
                        I agree with the{" "}
                        <a className="linkdetail" href="/terms-conditions">
                          <u>Terms &amp; Conditions</u>
                        </a>{" "}
                        and{" "}
                        <a className="linkdetail" href="/privacy-policy">
                          <u>Privacy Policy</u>
                        </a>{" "}
                        of Karishye
                      </label>
                    </div>
                  </div>
                  {user && user ? (
                    <div className="btn-box">
                      <button
                        type="submit"
                        id="submitformdata"
                        className="bookingsubmitbut"
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    <div className="btn-box loginsubmit">
                      <button
                        id="submitformdata"
                        type="button"
                        className="bookingsubmitbut"
                        href="#login-form-model"
                        onClick={() => {
                          if (formValid) {
                            hideLogin();
                          } else {
                            setError("Please fill all required fields");
                          }
                        }}
                      >
                        Submit
                      </button>
                      <span className="bookingerrormessage">{error}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <LoginModal
        formData={formData}
        data={data}
        signupFormData={signupFormData}
        frombooking={frombooking}
        setLoginModal={setLoginModal}
        loginModal={loginModal}
        style={{ display: loginModal ? "flex" : "none" }}
      />

      <Footer />
      <Whatsapp />
    </>
  );
}
