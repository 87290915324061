import React, { useEffect, useState, useRef } from "react";
import "./PujaDetailPage.scss";
import Navbar from "../../Component/Navbar/navbar";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import axios from "axios";
import jsonData from "../../images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BASE_URL } from "../../environments/config";
import Modal from "../../Component/Modal/Modal";
export default function PujaDetailPage() {
  const [pujaDetails, setPujaDetails] = useState({});
  const [samagriList, setSamagriList] = useState([]);
  const [showSamagri, setShowSamagri] = useState(false);
  const [activeFaq, setActiveFaq] = useState(null);
  const [pujaList, setPujaList] = useState([]);
  const [activeDiya, setActiveDiya] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState(false);
  const [isPujariAdded, setIsPujariAdded] = useState(true);
  const [isSamagriAdded, setIsSamagriAdded] = useState(true);
  const [pujariCost, setPujariCost] = useState(0);
  const [samagriCost, setSamagriCost] = useState(0);
  const [defaultSamagri, setDefaultSamagri] = useState([]);
  const [addonSamagri, setAddonSamagri] = useState([]);
  const [tempSamagri, settempSamagri] = useState([]);
  const [history, setHistory] = useState([]);
  const [totalSamagri, setTotalSamagri] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [videos, setVideos] = useState([]);
  const [userSamagri, setUserSamagri] = useState([]);
  const [toggleSamagri, setToggleSamagri] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const modalRef = useRef();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // variableWidth: false,
    // slidesToShow:3,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1346,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    document.title = `${pujaDetails?.name}-Karishye`;
  }, [pujaDetails]);
  const toggleFaq = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };

  const showDiya = (index) => {
    setActiveDiya(index);
  };

  const hideSamagri = () => {
    setShowSamagri(!showSamagri);
  };

  const calculateGST = (amount) => {
    return Math.round(amount * 1.18);
  };

  const togglePujariCost = () => {
    const pujariCostWithGST = calculateGST(
      pujaDetails?.pujamodels[0].pujariCost
    );
    if (isPujariAdded) {
      setTotalCost(Math.round(totalCost - pujariCostWithGST));
    } else {
      setTotalCost(Math.round(totalCost + pujariCostWithGST));
    }
    setIsPujariAdded(!isPujariAdded);
  };

  const toggleSamagriCost = () => {
    const samagriCostWithGST = calculateGST(
      pujaDetails?.pujamodels[0].samagriCost
    );
    if (isSamagriAdded) {
      settempSamagri(totalSamagri);
      setTotalSamagri([]);
      setTotalCost(Math.round(totalCost - samagriCostWithGST));
    } else {
      setTotalSamagri(tempSamagri);
      setTotalCost(Math.round(totalCost + samagriCostWithGST));
    }
    setIsSamagriAdded(!isSamagriAdded);
  };

  const addSamagri = (puja) => {
    setSamagriCost(samagriCost + puja.customerMrp * puja?.qty);
    setTotalSamagri([...totalSamagri, puja]);
    setToggleSamagri({ ...toggleSamagri, [puja.id]: true });
  };

  const removeSamagri = (puja) => {
    const filteredSamagri = totalSamagri.filter((item) => item.id !== puja.id);
    setTotalSamagri(filteredSamagri);
    setToggleSamagri({ ...toggleSamagri, [puja.id]: false });
    setSamagriCost(samagriCost - puja.customerMrp * puja?.qty);
  };

  useEffect(() => {
    const fetchPujaDetails = async (pujaId) => {
      try {
        const response = await axios.get(`${BASE_URL}/pujas?id=${pujaId}`);
        setPujaDetails(response.data.data[0]);
      } catch (err) {
        console.log("err", err);
      }
    };

    const fetchSamagriList = async (pujaId) => {
      try {
        const response = await axios.get(`${BASE_URL}/puja-samagri/${pujaId}`);
        setSamagriList(response.data.data);
      } catch (err) {
        console.log("err", err);
      }
    };

    const pujaId = localStorage.getItem("pujaId");
    if (pujaId) {
      fetchPujaDetails(pujaId);
      fetchSamagriList(pujaId);
    }
  }, []);

  const handleOpenModal = (videoId) => {
    setSelectedVideo(videoId);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedVideo(null);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (modalVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalVisible]);

  useEffect(() => {
    if (pujaDetails?.pujamodels?.[0]) {
      const serviceFee = pujaDetails.pujamodels[0].serviceFee || 0;
      const serviceFeeWithGST = calculateGST(serviceFee);
      let initialTotalCost = serviceFeeWithGST;

      if (isPujariAdded) {
        initialTotalCost += calculateGST(pujaDetails.pujamodels[0].pujariCost);
      }

      if (isSamagriAdded) {
        initialTotalCost += calculateGST(samagriCost);
      }

      setTotalCost(Math.round(initialTotalCost));
    }
  }, [isPujariAdded, isSamagriAdded, pujaDetails, samagriCost, pujariCost]);

  useEffect(() => {
    const fetchPujas = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/pujas?categoryId=1`);
        const threeCards = response.data.data.slice(0, 3);
        setPujaList(threeCards);
        return threeCards;
      } catch (err) {
        console.log("err", err);
      }
    };

    fetchPujas();
    const handleScroll = () => {
      const sections = ["puja-procedure", "faq", "history", "how-it-works"];

      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        if (!section) return;
        const rect = section.getBoundingClientRect();
        const viewportHeight = document.documentElement.clientHeight;
        if (
          (rect.top >= 0 && rect.top < viewportHeight) ||
          (rect.bottom > 0 && rect.bottom <= viewportHeight)
        ) {
          switch (sectionId) {
            case "puja-procedure":
              showDiya(0);
              break;
            case "faq":
              showDiya(3);
              break;
            case "history":
              showDiya(1);
              break;
            case "how-it-works":
              showDiya(2);
              break;
            default:
              break;
          }
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchDataAndVideos = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/puja-history`);
        const fetchedHistory = response.data.data;
        setHistory(fetchedHistory);

        if (pujaDetails) {
          const historyDat =
            fetchedHistory.find((puja) => puja?.name === pujaDetails?.name) ||
            [];

          if (historyDat) {
            setHistoryData(historyDat?.pujaHistory);

            try {
              const videos = JSON.parse(historyDat?.videos || "[]");
              setVideos(videos);
            } catch (error) {
              console.error("Error parsing videos:", error);
              setVideos([]);
            }
          }
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchDataAndVideos();
  }, [pujaDetails]);
  useEffect(() => {
    if (isPujariAdded) {
      setPujariCost(pujaDetails?.pujamodels?.[0]?.pujariCost || 0);
    } else {
      setPujariCost(0);
    }
  }, [isPujariAdded, pujaDetails]);

  useEffect(() => {
    if (isSamagriAdded) {
      const calculatedCost = defaultSamagri.reduce((total, item) => {
        return total + item.customerMrp * item.qty;
      }, 0);
      setSamagriCost(calculatedCost);
    } else {
      setSamagriCost(0);
    }
  }, [isSamagriAdded, pujaDetails, defaultSamagri]);

  useEffect(() => {
    if (pujaDetails?.id) {
      localStorage.setItem(`totalcost_${pujaDetails?.id}`, totalCost);
      localStorage.setItem(`pujaricost_${pujaDetails?.id}`, pujariCost);
      localStorage.setItem(`samagricost_${pujaDetails?.id}`, samagriCost);
      localStorage.setItem(
        `toggleSamagri_${pujaDetails?.id}`,
        JSON.stringify(totalSamagri)
      );
      localStorage.setItem(
        `userSamagri_${pujaDetails?.id}`,
        JSON.stringify(userSamagri)
      );
      localStorage.setItem(
        `gst_${pujaDetails?.id}`,
        calculateGST(totalCost) - totalCost
      );
    }
  }, [
    totalCost,
    pujariCost,
    samagriCost,
    pujaDetails,
    totalSamagri,
    userSamagri,
  ]);
  useEffect(() => {
    const { defaultItems, addonItems, userItems } = samagriList.reduce(
      (acc, item) => {
        if (item?.isProvidedBy === "user") {
          acc.userItems.push(item);
        }
        if (item?.samagriType === "default") {
          acc.defaultItems.push(item);
        } else if (item?.samagriType === "addon") {
          acc.addonItems.push(item);
        }
        return acc;
      },
      { defaultItems: [], addonItems: [], userItems: [] }
    );

    setDefaultSamagri(defaultItems);
    setTotalSamagri(defaultItems);
    setAddonSamagri(addonItems);
    setUserSamagri(userItems);
  }, [samagriList]);
  const pujaName = pujaDetails?.name;

  const redirection = (item) => {
    localStorage.setItem("pujaId", item.id);
    if (
      (item.pujacategorymappings && item.pujacategorymappings.length > 0) ||
      item?.pujas?.pujamodels?.length > 0
    ) {
      let navigateTo =
        window.location.origin + "/poojas/telugu/" + item?.url_name;
      window.open(navigateTo, "_self");
    } else {
      localStorage.setItem("ToBooking", "true");
      let navigateTo =
        window.location.origin + "/booking/telugu/" + item?.url_name;
      window.open(navigateTo, "_self");
    }
  };

  return (
    <>
      <Navbar />
      <div className="PoojaDeatailpage">
        <div className="container" id="Pooja-detail">
          <div class="details-top-img">
            <div class="puja-container">
              <div class="image-slideshow-container">
                <div class="img-slide" style={{ display: " block" }}>
                  {jsonData[pujaDetails?.name] ? (
                    <img
                      class="puja-image-full"
                      src={jsonData[pujaName]}
                      alt=""
                    />
                  ) : (
                    <img
                      class="puja-image-full"
                      src="../../assets/images/default-no-puja-image.jpg"
                      alt=""
                    />
                  )}
                </div>
              </div>
              <br />
              <div class="img-dot-container" style={{ textAlign: "center" }}>
                <span
                  class="change-img-dot active"
                  onclick="currentImgSlide(1)"
                ></span>
              </div>
            </div>
          </div>

          <div class="details-head-sec">
            <div class="details-head-lft">
              <div class="details-heading align-items-center">
                <div class="heading-wrapper">
                  <h2 class="puja-name-heading">{pujaDetails?.name}</h2>
                </div>
                <div class="available-modes align-items-center pb-lg-4 pb-md-3">
                  <div class="available-box d-flex align-items-center">
                    <img src="../../assets/images/mode1.png" alt="" />
                    <p>Online</p>
                  </div>
                  <div class="available-box d-flex align-items-center">
                    <img src="../../assets/images/mode2.png" alt="" />
                    <p>In Person</p>
                  </div>
                  <div class="available-box d-flex align-items-center">
                    <img src="../../assets/images/mode3.png" alt="" />
                    <p>Assisted</p>
                  </div>
                </div>
                <div class="border-line">
                  <p></p>
                </div>
                <div class="puja-features">
                  <div>
                    <img
                      src="../../assets/images/verified-pujaris.png"
                      alt=""
                    />
                    <p className="text-breakdown">Verified Pujaris</p>
                  </div>
                  <div>
                    <img
                      src="../../assets/images/pure-organic-samagri.png"
                      alt=""
                    />
                    <p className="text-breakdown">Pure Organic Samagri</p>
                  </div>
                  <div>
                    <img
                      src="../../assets/images/free-rescheduling.png"
                      alt=""
                    />
                    <p className="text-breakdown">Free rescheduling</p>
                  </div>
                  <div>
                    <img src="../../assets/images/chat-support.png" alt="" />
                    <p className="text-breakdown">Chat support</p>
                  </div>
                  <div>
                    <img
                      src="../../assets/images/no-cancellation-charges.png"
                      alt=""
                    />
                    <p className="text-breakdown">
                      No cancellation charges (until last 48hrs)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="details-head-rht">
              <div class="price-box">
                <div>
                  <p class="price-header">Invoice</p>
                </div>
              </div>
              <div class="price-box">
                <div>
                  <div>
                    <p>Pujari</p>
                    {isPujariAdded ? (
                      <>
                        <p class="numberOfPujaries">
                          {" "}
                          ( {pujaDetails?.pujamodels?.[0]?.noOfPujaris} )
                        </p>
                        <div
                          className="samagri-additional-btn pujari-cost-remove-btn margin-button"
                          onClick={() => togglePujariCost()}
                        >
                          <img
                            src="../../assets/images/delete-icon.svg"
                            alt=""
                          />
                          Remove
                        </div>
                      </>
                    ) : (
                      <>
                        <p class="numberOfPujaries"> ( {0} )</p>
                        <button
                          className="samagri-additional-btn samagri-cost-add-btn margin-button"
                          onClick={() => togglePujariCost()}
                        >
                          Add
                        </button>
                      </>
                    )}
                  </div>
                  <p class="pujari-cost">₹ {pujariCost}</p>
                </div>
              </div>
              <div class="price-box">
                <div>
                  <div className="price-box-samagri">
                    <p class="samagri-text">
                      Puja Samagri Set
                      {isSamagriAdded ? (
                        <div
                          className="samagri-additional-btn pujari-cost-remove-btn"
                          onClick={() => toggleSamagriCost()}
                        >
                          <img
                            src="../../assets/images/delete-icon.svg"
                            alt=""
                          />
                          Remove
                        </div>
                      ) : (
                        <button
                          class="samagri-additional-btn samagri-cost-add-btn"
                          onClick={() => toggleSamagriCost()}
                        >
                          Add
                        </button>
                      )}
                      <a
                        className="view-btn"
                        href="#samagri-header"
                        onClick={() => hideSamagri()}
                      >
                        View
                      </a>
                    </p>
                  </div>
                  <p class="samagri-cost">₹ {samagriCost}</p>
                </div>
              </div>
              <div class="price-box service-charges">
                <div>
                  <div>
                    <p>Karishye charges</p>
                  </div>
                  <p class="service-charge-price">
                    ₹ {pujaDetails?.pujamodels?.[0]?.serviceFee ?? 0}
                  </p>
                </div>
              </div>
              <div class="edit-service-charges">
                <input class="service-charge-input" type="number" />
                <button class="apply-charge-btn">Apply</button>
              </div>
              <div class="price-box">
                <div>
                  <div>
                    <div class="total-amount">
                      <div class="total-amount-container">
                        <p class="total">Total payable</p>
                        <div class="total-contribute">
                          <img
                            class="info-icon"
                            src="../../assets/images/info-outline.png"
                            alt=""
                          />
                          <div class="show-total-tooltip tooltip-right">
                            <span>
                              By paying a little bit of taxes on your puja, you
                              are indirectly doing a great service to the
                              country and our pujari family. We thank you 🙏
                            </span>
                          </div>
                        </div>
                      </div>
                      <p class="totalSubDetail">(Incl. of all Taxes) </p>
                    </div>
                  </div>
                  <p class="price">₹ {totalCost}</p>
                </div>

                <div class="details-book-now-mobile">
                  <a
                    onClick={() => {
                      localStorage.setItem("pujaId", pujaDetails?.id);

                      let navigateTo =
                        window.location.origin +
                        "/booking/telugu/" +
                        pujaDetails?.url_name;
                      window.open(navigateTo, "_self");
                    }}
                    className="bookbut"
                  >
                    Proceed
                  </a>
                </div>
              </div>

              <div
                class="details-book-now"
                onclick="calculatePujaPrice('Gruhapravesham')"
              >
                <a
                  onClick={() => {
                    localStorage.setItem("pujaId", pujaDetails?.id);

                    let navigateTo =
                      window.location.origin +
                      "/booking/telugu/" +
                      pujaDetails?.url_name;
                    window.open(navigateTo, "_self");
                  }}
                  className="bookbut"
                >
                  Proceed
                </a>
              </div>
            </div>
          </div>

          <div className="details-bottom-sec">
            <div className="right-side-floater">
              <div
                className="price-book-floater"
                style={{ display: invoiceDetails ? "flex" : "none" }}
              >
                <div className="hide-total-amount">
                  <div className="total-amount">
                    <div className="total-amount-container">
                      <p className="total">Total payable</p>
                      <div className="total-contribute">
                        <img
                          className="info-icon"
                          src="../../assets/images/info-outline.png"
                          alt=""
                        />
                        <div className="show-total-tooltip tooltip-right">
                          <span>
                            By paying a little bit of taxes on your puja, you
                            are indirectly doing a great service to the country
                            and our pujari family. We thank you 🙏
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="totalSubDetail"> (Incl. of all Taxes) </p>
                  </div>
                  <p className="floater-price">₹{totalCost}</p>
                </div>
                <div className="details-book-now" style={{ width: "100%" }}>
                  <a
                    onClick={() => {
                      localStorage.setItem("pujaId", pujaDetails?.id);

                      let navigateTo =
                        window.location.origin +
                        "/booking/telugu/" +
                        pujaDetails?.url_name;
                      window.open(navigateTo, "_self");
                    }}
                    className="bookbut"
                  >
                    Proceed
                  </a>
                </div>
              </div>

              <div
                style={{ display: invoiceDetails ? "flex" : "none" }}
                className="details-show-display"
                onClick={() => setInvoiceDetails(!invoiceDetails)}
              >
                <p className="show-details">Show details</p>
              </div>

              <div
                className="invoice-and-samagri"
                style={{ display: invoiceDetails ? "none" : "flex" }}
              >
                <div className="price-box">
                  <div>
                    <p className="price-header">Invoice</p>
                  </div>
                </div>
                <div className="price-box">
                  <div>
                    <div>
                      <p>Pujari</p>
                      {isPujariAdded ? (
                        <>
                          <p className="numberOfPujaries">
                            ( {pujaDetails?.pujamodels?.[0]?.noOfPujaris} )
                          </p>
                          <div
                            className="samagri-additional-btn pujari-cost-remove-btn"
                            onClick={() => {
                              togglePujariCost();
                              localStorage.setItem(
                                `noOfPujaris_${pujaDetails?.id}`,
                                0
                              );
                            }}
                          >
                            <img
                              src="../../assets/images/delete-icon.svg"
                              alt=""
                            />
                            Remove
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="numberOfPujaries">( {0} )</p>
                          <button
                            className="samagri-additional-btn samagri-cost-add-btn"
                            onClick={() => {
                              togglePujariCost();
                              localStorage.setItem(
                                `noOfPujaris_${pujaDetails?.id}`,
                                pujaDetails?.pujamodels?.[0]?.noOfPujaris
                              );
                            }}
                          >
                            Add
                          </button>
                        </>
                      )}
                    </div>
                    <p className="pujari-cost">₹ {pujariCost}</p>
                  </div>
                </div>
                <div className="price-box">
                  <div className="price-box-samagri">
                    <div>
                      <p className="samagri-text">Puja Samagri Set</p>
                    </div>

                    {isSamagriAdded ? (
                      <div
                        className="samagri-additional-btn pujari-cost-remove-btn"
                        onClick={() => toggleSamagriCost()}
                      >
                        <img src="../../assets/images/delete-icon.svg" alt="" />
                        Remove
                      </div>
                    ) : (
                      <button
                        class="samagri-additional-btn samagri-cost-add-btn"
                        onClick={() => toggleSamagriCost()}
                      >
                        Add
                      </button>
                    )}
                    <a
                      className="view-btn"
                      href="#samagri-header"
                      onClick={() => hideSamagri()}
                    >
                      View
                    </a>
                    <p className="samagri-cost">₹ {samagriCost}</p>
                  </div>
                </div>
                <div className="price-box service-charges-floater">
                  <div>
                    <div>
                      <p>Karishye charges</p>
                    </div>
                    <p className="service-charge-price-floater">
                      ₹ {pujaDetails?.pujamodels?.[0]?.serviceFee ?? 0}
                    </p>
                  </div>
                </div>
                <div className="edit-service-charges-floater">
                  <input
                    className="service-charge-input-floater"
                    type="number"
                    defaultValue={totalCost}
                  />
                  <button className="apply-charge-btn">Apply</button>
                </div>
                <div className="price-box">
                  <div>
                    <div className="total-amount">
                      <div className="total-amount-container">
                        <p className="total">Total payable</p>
                        <div className="total-contribute">
                          <img
                            className="info-icon"
                            src="../../assets/images/info-outline.png"
                            alt=""
                          />
                          <div className="show-total-tooltip tooltip-right">
                            <span>
                              By paying a little bit of taxes on your puja, you
                              are indirectly doing a great service to the
                              country and our pujari family. We thank you 🙏
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="totalSubDetail">(Incl. of all Taxes) </p>
                    </div>
                    <p className="floater-detail-price">₹ {totalCost}</p>
                  </div>
                  <div
                    className="details-book-now-mobile"
                    onclick="calculatePujaPrice('Saraswathi Homam')"
                  >
                    <a
                      onClick={() => {
                        localStorage.setItem("pujaId", pujaDetails?.id);

                        let navigateTo =
                          window.location.origin +
                          "/booking/telugu/" +
                          pujaDetails?.url_name;
                        window.open(navigateTo, "_self");
                      }}
                      className="bookbut"
                    >
                      Proceed
                    </a>
                  </div>
                </div>
                <div
                  className="details-book-now"
                  style={{ width: "100%" }}
                  onclick="calculatePujaPrice('Saraswathi Homam')"
                >
                  <a
                    onClick={() => {
                      localStorage.setItem("pujaId", pujaDetails?.id);

                      let navigateTo =
                        window.location.origin +
                        "/booking/telugu/" +
                        pujaDetails?.url_name;
                      window.open(navigateTo, "_self");
                    }}
                    className="bookbut"
                  >
                    Proceed
                  </a>
                </div>
              </div>

              {!invoiceDetails && (
                <div className="details-hide-display" onclick="hideInvoice()">
                  <p
                    className="hide-details"
                    onClick={() => setInvoiceDetails(!invoiceDetails)}
                  >
                    Hide details
                  </p>
                </div>
              )}
              <div className="link-container">
                <div className="link-container-links">
                  <div>
                    <a href="#puja-summary" onClick={() => showDiya(0)}>
                      Puja Procedure
                    </a>
                    <img
                      className={`diya-nav-img ${
                        activeDiya === 0 ? "diya-active" : ""
                      }`}
                      src="../../assets/images/diya-navigator.png"
                      alt=""
                    />
                  </div>
                  <div>
                    <a href="#history" onClick={() => showDiya(1)}>
                      History
                    </a>
                    <img
                      className={`diya-nav-img ${
                        activeDiya === 1 ? "diya-active" : ""
                      }`}
                      src="../../assets/images/diya-navigator.png"
                      alt=""
                    />
                  </div>
                  <div>
                    <a href="#how-it-works" onClick={() => showDiya(2)}>
                      How it works
                    </a>
                    <img
                      className={`diya-nav-img ${
                        activeDiya === 2 ? "diya-active" : ""
                      }`}
                      src="../../assets/images/diya-navigator.png"
                      alt=""
                    />
                  </div>
                  <div>
                    <a href="#faq" onClick={() => showDiya(3)}>
                      FAQ
                    </a>
                    <img
                      className={`diya-nav-img ${
                        activeDiya === 3 ? "diya-active" : ""
                      }`}
                      src="../../assets/images/diya-navigator.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="link-container-navigate-stick">
                  <img src="../../assets/images/navigator-stick.png" alt="" />
                </div>
              </div>
            </div>
            <div className="details-summary-and-link-container">
              <div className="details-availability" id="puja-summary">
                <div className="summaryhistory">
                  <div className="availability-head summary-container">
                    <h2 className="summary-heading">Summary</h2>
                    <p className="summary-text">{pujaDetails?.description}</p>
                    <img
                      className="summary-underline-img"
                      src="../../assets/images/summary-underline.jpg"
                      alt=""
                    />
                  </div>
                  <h2
                    className="section-heading puja-details-sec puja-procedure-heading"
                    id="puja-procedure"
                  >
                    Puja Procedure
                  </h2>
                  <div className="puja-details-benefits">
                    {pujaDetails?.pujamodels?.[0]?.procedures?.map(
                      (procedure) => {
                        const { title, description, specialNotes } =
                          procedure?.procedure;
                        return (
                          <>
                            <div className="puja-details-benefits-head">
                              <h4 className="procedure-title"> {title} </h4>
                              <img
                                className="gold-star-dots-procedure"
                                src="../../assets/images/gold-star-with-dots.png"
                                alt=""
                              />
                            </div>

                            <div className="procedure-content">
                              <p className="procedure-description">
                                {description}
                              </p>
                              {specialNotes && (
                                <p className="procedure-special-notes">
                                  <span>NOTE:</span> {specialNotes}
                                </p>
                              )}
                            </div>
                          </>
                        );
                      }
                    )}

                    <div className="procedure-note-container"></div>
                    <div className="puja-details-benefits-head">
                      <div className="procedure-note-container"></div>
                      <div className="procedure-bottom-image-div">
                        <img
                          className="procedure-bottom-image"
                          src="../../assets/images/procedure-bottom-image.png"
                          alt=""
                        />
                      </div>
                      <div className="special-notes-sec" id="special-notes">
                        <h2 className="section-heading puja-details-sec">
                          Special Notes
                        </h2>
                        <p>
                          Any specific asks / family traditions / specific needs
                          we need to know
                        </p>
                        <textarea
                          name
                          className="notes-input"
                          placeholder="Please write here"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="history-container" id="history">
                  <h2 className="section-heading">History</h2>
                  <div className="history-inner-container">
                    {videos.length > 0 && (
                      <div>
                        <div class="history-inner-sub-box">
                          <h3 class="history-sub-title">Insight Videos</h3>
                          <img
                            src="../../assets/images/gold-star-with-dots.png"
                            alt=""
                          />
                        </div>
                        <Slider {...settings}>
                          {videos &&
                            videos.map((video, index) => {
                              const videoId = video.split("/").pop();

                              return (
                                <div
                                  key={index}
                                  onClick={() => handleOpenModal(videoId)}
                                >
                                  <div className="insight-card">
                                    <img
                                      className="video-thumb"
                                      src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                                      alt={`Video thumbnail ${index}`}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </Slider>

                        {modalVisible && (
                          <div
                            className="modal show"
                            tabindex="-1"
                            role="dialog"
                            style={{ display: "block" }}
                            aria-modal="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content" ref={modalRef}>
                                <Modal
                                  show={modalVisible}
                                  onClose={handleCloseModal}
                                  videoId={selectedVideo}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {historyData &&
                      historyData.map((item) => (
                        <div className="history-inner-sub-box">
                          <h3 className="history-sub-title">{item.title}</h3>
                          <img
                            src="../../assets/images/gold-star-with-dots.png"
                            alt="Gold Star with Dots"
                          />
                          <div>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      ))}
                    <div className="history-image-div">
                      <img src="../../assets/images/temple-2.png" alt="" />
                    </div>
                  </div>
                </div>

                <div className="history-container-mobile">
                  <div className="close-icon-div">
                    <h2>History</h2>
                    <img
                      src="../../assets/images/close.png"
                      alt=""
                      onclick="closeHistory()"
                    />
                  </div>
                  <div className="temple-img-container">
                    <img src="../../assets/images/temple-2.png" alt="" />
                  </div>
                  <div className="history-content-mobile">
                    <div className="history-inner-sub-box">
                      <h3 className="history-sub-title">Benefits</h3>
                      <img
                        src="../../assets/images/gold-star-with-dots.png"
                        alt=""
                      />
                    </div>
                    <p>
                      The shakthi swaroopa that governs everything related to
                      absorption of energy is Saraswathi Devi. Hence this homam
                      has been regularly recommended and utilized for students
                      and children. Some of the common benefits that devotees
                      seek and stand to gain from this homam are: 1. Sharpens
                      skill sets, concentration, memory power, focus and our
                      ability to understand complex things 2. Smooth sailing in
                      all literary pursuits 3. Instill confidence in the
                      individual to achieve great success 4. Attain high
                      intellectual and analytical skills and empower one with
                      high self-esteem thereby helping in attaining positive
                      results. It is also recommended at times by vedic scholars
                      for anyone who has speech/communication-related problems.
                      It also helps in curing defect like stammering by the
                      devi's grace.
                    </p>
                  </div>
                </div>
                <div className="how-it-works" id="how-it-works">
                  <h2 className="section-heading">How it works</h2>
                  <div className="zig-zag-container">
                    <div className="card">
                      <img
                        src="../../assets/images/card-fire.png"
                        className="card-img"
                        alt=""
                      />
                      <img
                        src="../../assets/images/gold-star.png"
                        className="gold-star-point"
                        style={{ top: "-35px" }}
                        alt=""
                      />
                      <p className="card-text">
                        Once you confirm the booking, our pujari will get in
                        touch to guide you further and finalise the puja plan.
                      </p>
                    </div>
                    <div className="arrow-card-div">
                      <img
                        src="../../assets/images/left-curve-arrow.png"
                        className="arrow"
                        alt=""
                      />
                      <div className="card">
                        <img
                          src="../../assets/images/gold-star.png"
                          className="gold-star-point"
                          style={{ top: "-64px" }}
                          alt=""
                        />
                        <p className="card-text">
                          The final plan will be shared with you which includes
                          final cost, muhurtam, samagri, what you need to keep
                          ready at home based on your discussion with the
                          pujari.
                        </p>
                        <img
                          src="../../assets/images/card-pray.png"
                          className="card-img"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="arrow-card-div">
                      <div className="card">
                        <img
                          src="../../assets/images/card-puja.png"
                          className="card-img"
                          alt=""
                        />
                        <img
                          src="../../assets/images/gold-star.png"
                          className="gold-star-point"
                          style={{ top: "-50px" }}
                          alt=""
                        />
                        <p className="card-text">
                          You can pay 10% advance (or) total amount. Once
                          payment is received, the booking is confirmed and
                          pujari time is blocked.
                        </p>
                      </div>
                      <img
                        src="../../assets/images/right-curve-arrow.png"
                        className="arrow"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="faq" id="faq">
                  <h2 className="section-heading">FAQ</h2>
                  <div className="faq-container">
                    <div className="faq-container-inner">
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(1)}
                          >
                            What happens once I submit the booking?{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 1
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="../../assets/images/down-icon.png"
                              alt=""
                            />{" "}
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 1 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              Once you submit the booking, it is assigned to a
                              Karishye pujari who will contact you. He will
                              understand the exact requirements and make the
                              detailed puja plan along with all the steps,
                              samagri and muhurtam (as required). Once you are
                              satisfied with the plan and make the payment the
                              puja is confirmed and the pujari time is blocked
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(2)}
                          >
                            Do I need to pay in advance or only after the puja
                            is completed?{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 2
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="../../assets/images/down-icon.png"
                              alt=""
                            />{" "}
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 2 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              We ask for a partial advance payment. This is
                              mainly for us to arrange for samagri or to ensure
                              that we are blocking the pujari time. Once the
                              puja is completed the remaining balance can be
                              paid You also have an option to make the full
                              payment in advance as well. Rest assured, if you
                              cancel in advance we will return all the amount.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(3)}
                          >
                            What if I want to cancel my booking?{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 3
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="../../assets/images/down-icon.png"
                              alt=""
                            />{" "}
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 3 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              Please contact us on our whatsapp number
                              +91-8121007481 . We will cancel your booking from
                              our backend and arrange for the refund. Please
                              note: Cancellations made 3 days or more in advance
                              of the puja start date will receive a 100% refund.
                              Cancellations made between 3 days to 1 day before
                              puja will receive a 50% refund. Cancellations made
                              within 24 hours will not receive a refund because
                              we would have already made the required
                              arrangements and our pujari would have not
                              accepted any other pujas during that time.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(4)}
                          >
                            How to reschedule my puja? My plans have changed due
                            to unforeseen circumstances.{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 4
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="../../assets/images/down-icon.png"
                              alt=""
                            />{" "}
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 4 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              Please contact us on our whatsapp number
                              +91-8121007481 . We will reschedule your booking
                              from our backend (generally at no extra cost).
                              Please note, when we reschedule we will have to
                              check with the pujari's availability and
                              accordingly provide the best possible date
                              options. Please support and bear with us.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(5)}
                          >
                            Important: Pujari changes in the last minute{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 5
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="../../assets/images/down-icon.png"
                              alt=""
                            />{" "}
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 5 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              Generally at Karishye, we ensure that the same
                              pujari who has been assigned to you in the plan
                              comes for the puja. However in cases where we
                              cannot avoid : such as pujari falling sick etc. we
                              would have to re assign the puja to a new pujari.
                              This will be communicated to you by our team as
                              soon as we come to know about it.. However, rest
                              assured that any pujari we send will perform the
                              procedure properly.{" "}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="faq-container-mobile">
                    <div className="faq-container-mobile-inner">
                      <div className="close-icon-div">
                        <h2>FAQ</h2>
                        <img
                          src="../../assets/images/close.png"
                          alt=""
                          onclick="closeFaq()"
                        />
                      </div>
                      <hr />
                      <div className="faq-container-inner">
                        <ul className="faq-list">
                          <li>
                            <button className="accordion">
                              What happens once I submit the booking?
                              <img
                                className="faq-arrow-icon"
                                src="../../assets/images/down-icon.png"
                                alt=""
                              />{" "}
                            </button>
                            <div className="panel">
                              <p>
                                Once you submit the booking, it is assigned to a
                                Karishye pujari who will contact you. He will
                                understand the exact requirements and make the
                                detailed puja plan along with all the steps,
                                samagri and muhurtam (as required). Once you are
                                satisfied with the plan and make the payment the
                                puja is confirmed and the pujari time is blocked
                              </p>
                            </div>
                          </li>
                          <li>
                            <button className="accordion">
                              Do I need to pay in advance or only after the puja
                              is completed?{" "}
                              <img
                                className="faq-arrow-icon"
                                src="../../assets/images/down-icon.png"
                                alt=""
                              />{" "}
                            </button>
                            <div className="panel">
                              <p>
                                We ask for a partial advance payment. This is
                                mainly for us to arrange for samagri or to
                                ensure that we are blocking the pujari time.
                                Once the puja is completed the remaining balance
                                can be paid You also have an option to make the
                                full payment in advance as well. Rest assured,
                                if you cancel in advance we will return all the
                                amount.
                              </p>
                            </div>
                          </li>
                          <li>
                            <button className="accordion">
                              What if I want to cancel my booking?{" "}
                              <img
                                className="faq-arrow-icon"
                                src="../../assets/images/down-icon.png"
                                alt=""
                              />{" "}
                            </button>
                            <div className="panel">
                              <p>
                                Please contact us on our whatsapp number
                                +91-8121007481 . We will cancel your booking
                                from our backend and arrange for the refund.
                                Please note: Cancellations made 3 days or more
                                in advance of the puja start date will receive a
                                100% refund. Cancellations made between 3 days
                                to 1 day before puja will receive a 50% refund.
                                Cancellations made within 24 hours will not
                                receive a refund because we would have already
                                made the required arrangements and our pujari
                                would have not accepted any other pujas during
                                that time.
                              </p>
                            </div>
                          </li>
                          <li>
                            <button className="accordion">
                              How to reschedule my puja? My plans have changed
                              due to unforeseen circumstances.{" "}
                              <img
                                className="faq-arrow-icon"
                                src="../../assets/images/down-icon.png"
                                alt=""
                              />{" "}
                            </button>
                            <div className="panel">
                              <p>
                                Please contact us on our whatsapp number
                                +91-8121007481 . We will reschedule your booking
                                from our backend (generally at no extra cost).
                                Please note, when we reschedule we will have to
                                check with the pujari's availability and
                                accordingly provide the best possible date
                                options. Please support and bear with us.
                              </p>
                            </div>
                          </li>
                          <li>
                            <button className="accordion">
                              Important: Pujari changes in the last minute{" "}
                              <img
                                className="faq-arrow-icon"
                                src="../../assets/images/down-icon.png"
                                alt=""
                              />{" "}
                            </button>
                            <div className="panel">
                              <p>
                                Generally at Karishye, we ensure that the same
                                pujari who has been assigned to you in the plan
                                comes for the puja. However in cases where we
                                cannot avoid : such as pujari falling sick etc.
                                we would have to re assign the puja to a new
                                pujari. This will be communicated to you by our
                                team as soon as we come to know about it..
                                However, rest assured that any pujari we send
                                will perform the procedure properly.{" "}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="note-sec">
                  <p>
                    <span className="note-bold">Disclaimer: </span>Karishye
                    presents its view on puja and procedure as per it's research
                    and understanding. We do not claim that our views are the
                    only correct ones.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="something-else-divider"></hr>
          <div className="details-bottom-sec">
            <div className="something-else-container">
              <h2>Looking for something else ?</h2>
              <div className="carousel-container">
                {pujaList?.map((puja) => (
                  <div
                    className="puja-card"
                    onClick={() => {
                      redirection(puja);
                    }}
                  >
                    <a className="puja-card-line" previewlistener="true">
                      <img
                        className="puja-image"
                        src="../../assets/images/default-no-puja-image.png"
                        alt
                      />
                      <h4 className="puja-title"> {puja.name}</h4>
                      <p className="puja-desc">{puja.description}</p>
                      <img
                        className="gold-line"
                        src="../../assets/images/gold-line.png"
                        alt
                      />
                    </a>
                    <div>
                      <a previewlistener="true"></a>
                      <button className="puja-book-button">
                        <a previewlistener="true"></a>
                        <a className="offerings-pujas" previewlistener="true">
                          Know More
                        </a>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="samagri-outer-container"
        style={{ display: showSamagri ? "flex" : "none" }}
      >
        <div class="samagri-container">
          <div class="samagri-box">
            <div class="samagri-header" id="samagri-header">
              <h2 class="section-heading">Samagri</h2>
              <img
                src="../../assets/images/close.png"
                alt=""
                onClick={() => hideSamagri()}
              />
            </div>
            <div class="samagri-box-inner">
              <div class="samagri-items-container">
                <p class="samagri-heading">
                  Samagri provided by Karishye (Already included in package)
                </p>
                <img
                  class="gold-star-line"
                  src="../../assets/images/gold-star-with-line.png"
                  alt=""
                />
                <img
                  class="gold-star-dots"
                  src="../../assets/images/gold-star-with-dots.png"
                  alt=""
                />

                <div class="samagri-items">
                  <ul>
                    {defaultSamagri.map((puja, index) => (
                      <li>
                        {" "}
                        <span class="samagri-item-name"> {puja.name}</span>{" "}
                        <span class="samagri-item-quantity">
                          {puja.qty} {puja.units}{" "}
                        </span>{" "}
                      </li>
                    ))}
                  </ul>
                  <div class="separte_line"></div>
                </div>
              </div>

              <div class="samagri-you-can-also-buy" id="additional-samagri">
                <div class="additional-samagri-heading-container">
                  <p class="samagri-heading">Add-ons</p>
                  <img
                    class="gold-star-line"
                    src="../../assets/images/gold-star-with-line.png"
                    alt=""
                  />
                  <img
                    class="gold-star-dots"
                    src="../../assets/images/gold-star-with-dots.png"
                    alt=""
                  />
                  <ul className="you-can-buy-list">
                    <li>
                      <p>Items</p>
                      <p>Quantity</p>
                      <p>Price</p>
                      <p>Add</p>
                    </li>
                  </ul>
                  <ul className="you-can-buy-list">
                    {addonSamagri?.map((puja, index) => (
                      <li key={index}>
                        <p className="samagri-item-name-col">
                          {puja?.name}
                          <span className="samagri-desc-span">
                            {puja?.description}
                          </span>
                        </p>
                        <p>
                          {puja?.standardQty * puja?.qty} {puja?.units}
                        </p>
                        <p className="buy-item-price">
                          ₹ {puja?.customerMrp * puja?.qty}
                        </p>
                        {toggleSamagri[puja.id] ? (
                          <button
                            className="remove-button"
                            onClick={() => removeSamagri(puja)}
                          >
                            Remove
                          </button>
                        ) : (
                          <button
                            className="add-button"
                            onClick={() => addSamagri(puja)}
                          >
                            Add
                          </button>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div class="samagri-what-you-need">
                <p class="samagri-heading">Items to keep ready at home</p>
                <img
                  class="gold-star-line"
                  src="../../assets/images/gold-star-with-line.png"
                  alt=""
                />
                <img
                  class="gold-star-dots"
                  src="../../assets/images/gold-star-with-dots.png"
                  alt=""
                />

                <div class="samagri-what-you-need-items">
                  <ul>
                    {userSamagri.map((puja, index) => (
                      <li>
                        <img src="../../assets/images/gold-star.png" />{" "}
                        <span class="samagri-item-name"> {puja.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div class="all-items-checkbox">
                <div
                  class="samagri-done"
                  onclick="calculatePujaPriceDone('Aksharabhyasam')"
                >
                  <p class="donebtn" onClick={() => hideSamagri()}>
                    Done
                  </p>
                </div>
              </div>
            </div>

            <div class="samagri-bottom-image-div">
              <img
                src="../../assets/images/special-notes-icon.png"
                class="special-notes-img"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <Whatsapp />
    </>
  );
}
