import React,{useEffect} from "react";
import "./Thankyou.scss";
import Footer from "../../Component/Footer/Footer";
import Navbar from "../../Component/Navbar/navbar";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import { useNavigate } from "react-router-dom";

const Thankyou = () => {
  const navigate = useNavigate();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
      <Navbar />
      <section class="karishye-thank-form">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 inner-wrapper">
              <div class="congratulation-img">
                <img src="../assets/images/congratulation.png" />
              </div>
              <h1>Thank you</h1>
              <p>
                We appreciate you contacting Karishye. Our Support team will get
                back in
                <br />
                touch with you soon! Have a great day!
              </p>

              <div class="continue-btn">
                <div class="bookbut" onClick={() => navigate("/mybookings")}>
                  Continue
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Whatsapp />
    </>
  );
};

export default Thankyou;
