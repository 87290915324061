import React from 'react'
import "./Whatsapp.scss";
function Whatsup() {
  return (
    <a href="https://wa.me/+918121007481" target='_blank' className='contacticon' previewlistener="true">
    <img className="whatsup" src='../../assets/images/whatappbusinessfooter.png' alt='whatsapp-business'/>
</a>
  )
}

export default Whatsup
