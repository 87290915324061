import React from "react";
import "./App.css";

import { Route, Routes } from "react-router-dom";


import About from "./Page/AboutUs/AboutUs";
import Booking from "./Page/BookingPage/Booking";
import HomePage from "./Page/LandingPage";
import Libary from "./Page/LibraryPage/Libary";
import Offering from "./Page/Offerings/Offerings";
import Pooja from "./Page/PujaDetailPage/PujaDetailPage";
import WhoIsPooja from "./Page/WhoIsPooja/WhoIsPooja";
import Contact from "./Page/ContactUs/Contact";
import Refund from "./Page/Refund/Refund";
import Privacy from "./Page/PrivacyPolicy/PrivacyPolicy";
import TermsAndContions from "./Page/TermsAndConditions/TermsAndConditions";
import Menu from "./Component/Menu/Menu";
import Profile from "./Page/MyProfile/MyProfile";
import PaymentComponent from "./Component/Payment/Payment";
import MyBooking from "./Page/MyBooking/MyBooking";
import Thankyou from "./Page/Thankyou/Thankyou";
function App() {


  return (
    <>
      <div className="" >
        
      <Routes>
          {/* <Route exact path="/library" element={ <Libary />} /> */}
          <Route exact path="/payment" element={ <PaymentComponent />} />
          <Route exact path="/offerings" element={ <Offering />} />
          <Route exact path="/refund-cancellation-policy" element={ <Refund />} />
          <Route exact path="/privacy-policy" element={ <Privacy />} />
          <Route exact path="/terms-conditions" element={ <TermsAndContions />} />
          <Route exact path="/contact" element={ <Contact />} />
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/poojas/telugu/:pujaName" element={ <Pooja />} />
          <Route exact path="/booking/telugu/:pujaName" element={ <Booking />} />
          <Route exact path="/pujaris" element={ <WhoIsPooja />} />
          <Route exact path="/about" element={ <About />} />
          <Route exact path="/menu" element={ <Menu />} />
          <Route exact path="/profile" element={ <Profile />} />
          <Route exact path="/mybookings" element={ <MyBooking />} />
          <Route exact path="/booking-success" element={ <Thankyou />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
