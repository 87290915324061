import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../Page/firebase";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../environments/config";
import SignupModal from "../Signup/Signup";
import "./Login.scss";

const LoginModal = ({
  data,
  frombooking,
  loginModal,
  setLoginModal,
  signupFormData,
  setSignupFormData,
  formData
}) => {
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [hideOtp, setHideOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [loginInputModal, setLoginInputModal] = useState(false);
  const [phNum, setPhNum] = useState("+91");
  const [isUser, setIsUser] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [finalOtp, setFinalOtp] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const navigate = useNavigate();

  const hideLogin = () => {
    setLoginModal(!loginModal);
    setPhNum("+91");
    setOtpSent(false);
    setOtpError("");
    setHideOtp(false);
  };

  const hideLoginInput = () => {
    setLoginInputModal(!loginInputModal);
    setOtpSent(false);
    setOtpError("");
    setHideOtp(false);
  };
  console.log(loginInputModal, "loginInputModal");

  const sendOTP = async () => {
    setHideOtp(true);
    try {
      if (!phNum || phNum.length < 10) {
        setPhoneNumberError("Please enter a valid phone number.");
        return;
      }
      if (!auth) {
        console.error("Firebase auth not initialized");
        return;
      }
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      signInWithPhoneNumber(auth, phNum, recaptcha).then(
        (confirmationResult) => {
          setConfirmationResult(confirmationResult);
          setOtpSent(true);
          setPhoneNumberError("");
          localStorage.setItem("phoneNumber", phNum);
        }
      );
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.length < 3) return phoneNumber;
    const maskedPart = phoneNumber.slice(3, -3).replace(/./g, "x");
    const visiblePart = phoneNumber.slice(-3);
    return maskedPart + visiblePart;
  };

  const user = localStorage.getItem("phoneNumber");
  useEffect(() => {
    if (otpVerified) {
      if (frombooking) {
        handleCombinedSubmit();
      } else if (isUser) {
        setLoginInputModal(false);
        navigate("/");
      } 
      else {
        setLoginInputModal(true);
      }
    }
    setOtpVerified(false);
  }, [otpVerified]);
  
  const verifyOTP = async (pin) => {
    try {
      const result = await confirmationResult.confirm(pin);
  
      const response = await axios.get(`${BASE_URL}/users`);
      const users = response.data.data;
  
      const userDetails = Object.values(users).find(
        (u) => u?.phoneNumber === user.toString()
      );
      setCurrentUser(userDetails);
      if (userDetails) {
        setIsUser(true);
        localStorage.setItem("user", userDetails.name);
        localStorage.setItem("userId", userDetails.id);
      } else {
        setIsUser(false);
      }
      setOtpVerified(true);
      setHideOtp(false);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpError("Invalid OTP. Please try again.");
    }
  };

  

  const handleCombinedSubmit = async () => {
    await handleSignupSubmit();
    handleSubmit();
  };
  const handleSignupSubmit = async () => {
    if (loading) return;
    setLoading(true);

    const userId= localStorage.getItem("userId");
    signupFormData.phoneNumber = user;
    if (currentUser && currentUser.id) {
      signupFormData.id = currentUser.id;
    }
    const payload = { data: signupFormData };
    try {
      const response = await axios.post(`${BASE_URL}/users/create-new`, payload);
      if (response.status!==201) {
        throw new Error("user registration failed");
      }
      const data = response.data;
      if (data.data.userId) {
        localStorage.setItem("userId", data.data.userId);
      }
      localStorage.setItem("user", signupFormData.name);
    } catch (error) {
      console.error("Error in submitting form:", error);
    }finally{
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const user = localStorage.getItem("userId");
    data.data.userId = user;
    try {
      const response = await fetch(
        `${BASE_URL}/booking/puja-booking?userId=${user}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
    navigate("/booking-success");
  };

  return (
    <div
      className="modal fade signup-form show"
      role="dialog"
      style={{ display: loginModal ? "flex" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content padding">
          <div className="modal-header">
            {!otpSent ? (
              <h5 className="modal-title" id="sendopt">
                Enter your mobile number
              </h5>
            ) : (
              <h5 className="modal-title">
                Enter the OTP sent to {maskPhoneNumber(phNum)}
              </h5>
            )}
            <button
              type="button"
              className="btn-close close-btn-loginregister"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={hideLogin}
            ></button>
          </div>
          <div className="modal-body">
            <div className="login-form-massage"></div>
            <form name="login-form" className="login-form">
              <div className="form-group mb-3 login-contact">
                {!otpSent && (
                  <>
                    <PhoneInput
                      country={"in"}
                      required={true}
                      value={phNum}
                      onChange={(phone) => setPhNum("+" + phone)}
                      className="phoneInput"
                    />
                    <div className="error-message">{phoneNumberError}</div>
                  </>
                )}
              </div>
              {otpSent && (
                <label className="otptitle">
                  Enter the OTP:
                  <input
                    onChange={(e) => setPin(e.target.value)}
                    className="otp-input"
                  ></input>
                  <div className="error-message">{otpError}</div>
                </label>
              )}
              <div className="modal-footer justify-content-center">
                {otpSent ? (
                  <button
                    type="button"
                    className="otp_button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => verifyOTP(pin)}
                  >
                    Verify OTP
                  </button>
                ) : (
                  <>
                    <div id="recaptcha"></div>
                    <button
                      type="button"
                      className="otp_button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={sendOTP}
                      style={{ display: hideOtp ? "none" : "block" }}
                    >
                      Get OTP
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <SignupModal
        loginInputModal={loginInputModal}
        setLoginInputModal={setLoginInputModal}
        hideLoginInput={hideLoginInput}
        hideLogin={hideLogin}
        setSignupFormData={setSignupFormData}
        signupFormData={signupFormData}
        style={{ display: loginInputModal ? "flex" : "none" }}
      />
    </div>
  );
};

export default LoginModal;
