import React from 'react';
const Modal = ({ show, onClose, videoId }) => {
  if (!show) {
    return null;
  }

  return (
          <div className="modal-body">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              style={{ width: '100%', height: '315px' }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
  );
};

export default Modal;
