import React, { useState } from "react";
import "./Contact.scss";
import Footer from "../../Component/Footer/Footer";
import NavBar from "../../Component/Navbar/navbar";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import { BASE_URL } from "../../environments/config";
export default function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    reason: "Select Reason",
    message: ""
  });
  const [res,setRes]=useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit =(e) => {
    e.preventDefault();
    const { firstName, lastName, email, phoneNumber, message } = formData;
      fetch(`${BASE_URL}/users/contact-karishye`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
        body: JSON.stringify({
        firstName,
        lastName,
        email,
        phoneNumber,
        message
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Error occured during post call');
      }
      return response.json();
    })
    .then(data => {
      setRes(data?.message)
    })
    .catch(error => {
      console.error('There was a problem with your fetch operation:', error);
    });
    

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      reason: "Select Reason",
      message: ""
    }
    )

  };

  return (
    <>
      <NavBar />
      <div className="contactUsSection">
        <div className="cntpgfstcontsec">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="cntpgsndcontsec">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="contacttext">
                  Just Say <strong className="contacttext">HELLO!</strong>
                </h2>
                <div className="row">
                  <div className="col-log-6 col-md-6">
                    <div className="labelSection">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-3">
                          <img src="assets/images/contactpgwhatsapp.png" alt="Whatsapp" />
                        </div>
                        <div className="col-lg-9 col-md-9 col-9">
                          <h3>Chat</h3>
                          <p>
                            <a href="https://wa.me/+918121007481">
                              +918121007481
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-log-6 col-md-6">
                    <div className="labelSection">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-3">
                          <img src="assets/images/mailicon.png" alt="Mail" />
                        </div>
                        <div className="col-lg-9 col-md-9 col-9">
                          <h3>Email</h3>
                          <p>
                            <a href="mailto:hello@karishye.co">
                              hello@karishye.co
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-log-6 col-md-6">
                    <div className="labelSection">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-3">
                          <img
                            className="phone-icon"
                            src="assets/images/phoneicon.png"
                            alt="Phone"
                          />
                        </div>
                        <div className="col-lg-9 col-md-9 col-9">
                          <h3>Call</h3>
                          <p>8121007481</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cntpgtrdcontsec">
                  <h2>
                    Send <strong className="contacttext">Message</strong>
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={formData.firstName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          name="phoneNumber"
                          placeholder="Phone"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <select name="reason" value={formData.reason} onChange={handleChange} required>
                          <option value="Select Reason">Select Reason</option>
                          <option value="Astrology/Horoscope Help">
                            Astrology/Horoscope Help
                          </option>
                          <option value="For Bulk Puja/Corporate Booking">
                            For Bulk Puja/Corporate Booking
                          </option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <input
                          className="submit"
                          type="submit"
                          value="submit"
                        />
                      </div>
                    </div>
                  </form>
                  {res&&(<div className="response">{res}</div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Whatsapp />
    </>
  );
}
