const jsonData = {
    "Annaprasana Puja": process.env.REACT_APP_IMAGE_URL + "/annaprasana_puja/image_1.png",
    "Aksharabhyasam": process.env.REACT_APP_IMAGE_URL + "/aksharabhyasam/image_1.png",
    "Arogya Homam": process.env.REACT_APP_IMAGE_URL + "/arogya_homa/image_1.jpg",
    "Ayusha Homam":process.env.REACT_APP_IMAGE_URL + "/ayushya_homam/image_1.jpg",
    "Abdhikam One Bhokta Puja":process.env.REACT_APP_IMAGE_URL + "/abdhikam_one_bhokta/image_1.jpg",
    "Abdhikam Three Bhokta Puja":process.env.REACT_APP_IMAGE_URL + "/abdhikam_three_bhokta/image_1.png",
    "Bhoomi Puja":process.env.REACT_APP_IMAGE_URL + "/bhoomi_puja/image_1.jpg",
    "Chandi Homam":process.env.REACT_APP_IMAGE_URL + "/chandi_homam/image_1.png",
    "Default Puja Image":process.env.REACT_APP_IMAGE_URL + "/default_puja_image.jpg",
    "Ganapati Homam":process.env.REACT_APP_IMAGE_URL + "/ganapati_homam/image_1.jpg",
    "Gruhapravesham":process.env.REACT_APP_IMAGE_URL + "/gruhapravesham/image_1.jpeg",
    "Masikam":process.env.REACT_APP_IMAGE_URL + "/masikam/image_1.png",
    "Rudrabhishekam":process.env.REACT_APP_IMAGE_URL + "/rudrabhishekam/image_1.png",
    "Samvatsarikam":process.env.REACT_APP_IMAGE_URL + "/samvatsrikam/image_1.png",
    "Saraswati Homam":process.env.REACT_APP_IMAGE_URL + "/saraswati_homam/image_1.png",
    "Satyanarayana Vratam":process.env.REACT_APP_IMAGE_URL + "/satyanarayana_vratam/image_1.jpg",
    "Sudarshana Homam":process.env.REACT_APP_IMAGE_URL + "/sudarshana_homam/image_1.jpg"
  }

  export default jsonData;

  // cat -1
//   {
//     "status": "SUCCESS",
//     "data": [
//       {
//         "id": 154,
//         "name": "Shop/ Office/ Company Opening",
//         "description": "Puja performed before officially launching or opening a new business / shop / corporate. ",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": null,
//         "mode3": null,
//         "mode4": null,
//         "videos": "undefined",
//         "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//         "updatedAt": "2023-07-24T01:35:35.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-07-08T05:44:38.000Z",
//         "createdById": "47",
//         "updatedById": "44",
//         "pujacategorymappings": [],
//         "pujamodels": [
//           {
//             "id": 102,
//             "pujaId": 154,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 10500,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 5000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-20T13:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T06:21:43.000Z",
//             "createdById": null,
//             "updatedById": "44",
//             "serviceFee": 1247,
//             "samagriCost": null,
//             "procedures": []
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "f9f1cfc0-c15b-4466-8330-43d5ba4b1703",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "f77b57ef-4e28-49c1-9048-4d65259063f7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "efffe0ea-e671-412c-afcc-15638726fbb5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "e632cfbc-fad7-4c95-bb61-06724ea92fef",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "e197c13c-e978-49e1-b828-0a79e1de33df",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "ddacccdb-4cd0-4f56-ab1f-f8a1c97c8e1e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "d7b041de-a5ff-4fb1-af8c-9a58bcbb1bf7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "3be47417-fac4-4cc9-bd82-e3ba00efc6b4",
//             "samagri": {
//               "id": "3be47417-fac4-4cc9-bd82-e3ba00efc6b4",
//               "name": "బూడిద గుమ్మడి కాయ/Ash gourd",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 150,
//               "pujariSellingPrice": 160,
//               "customerMrp": 160,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:48:31.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:17:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 190
//             }
//           },
//           {
//             "id": "d0b84a77-1c05-4e7d-b863-fd727e64318f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "c8b3ea15-753a-4c73-84bc-4a5f7c4e7d20",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "c6c546c7-7abb-4913-9d90-ea8f2bd147fc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "c6bf5594-9f23-48a5-b103-03c7a81a9c87",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "b41b875a-9367-4669-9c86-c050f16abd0b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "a4d25313-c162-440a-9a4d-49056efa9782",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "9c18f790-113d-4949-8f2f-dd5b7e736b43",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "644a01ca-da37-415f-8d5b-8b1d4c6c17a0",
//             "samagri": {
//               "id": "644a01ca-da37-415f-8d5b-8b1d4c6c17a0",
//               "name": "ఉట్టి / Utti",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 40,
//               "customerMrp": 40,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:37:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 38
//             }
//           },
//           {
//             "id": "9ab3df46-9b92-460b-bec5-fb75a7f6b56b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           },
//           {
//             "id": "90a6d4a8-4fee-4ff2-b67d-aa977895c79b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "samagri": {
//               "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//               "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 230,
//               "pujariSellingPrice": 270,
//               "customerMrp": 270,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:31:17.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 23
//             }
//           },
//           {
//             "id": "8d7ad3d0-889e-4d53-aa9c-b0a935ce2bdb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "85f7ef22-e109-4d93-89f9-a5d8aaa9bfb2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "7f54e33a-ea71-4b8c-b775-de45a6578820",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "7866c1d6-28d3-4ead-be7c-bbde82b452f8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "776e20ab-9ef0-455a-b16b-9609cabf61bb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "58c0588d-75a0-4d12-a477-00fe1a96a8e0",
//             "samagri": {
//               "id": "58c0588d-75a0-4d12-a477-00fe1a96a8e0",
//               "name": "నోట్బుక్ పెన్/ NOTEBOOK/PEN  ",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 50,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-05T05:31:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T04:53:07.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 153
//             }
//           },
//           {
//             "id": "73c7e02c-85b0-440d-9820-b33460c2c53a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "b2c2e469-39b3-4c2b-9349-e93f26dc3269",
//             "samagri": {
//               "id": "b2c2e469-39b3-4c2b-9349-e93f26dc3269",
//               "name": "తినే సున్నం /  Edible lime",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 20,
//               "pujariSellingPrice": 26,
//               "customerMrp": 26,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:14:38.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 90
//             }
//           },
//           {
//             "id": "72682c27-8ddf-4f9e-9e1f-a7c0c7b2eebe",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "6eed7a09-42fe-4018-a281-02f003885ba4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "6b072f0e-42a5-4c0c-a547-4a88143ed16b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "68fa8199-323e-4cd3-afbf-272d22cca5f6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "6389e8bf-4031-43ee-a591-80072925f99d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "604991d2-c5c3-4ffc-bf9d-84bddcb7d894",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "addon",
//             "samagriId": "8670332d-d8a9-45c7-ab81-5fda3f46806a",
//             "samagri": {
//               "id": "8670332d-d8a9-45c7-ab81-5fda3f46806a",
//               "name": "Fruits & Flowers ( Shop Opening) (Premium)",
//               "description": "వీడి పూలు  1/4 kg , పూల మాలలు   3,   అరటి పండ్లు    1 Dozen,  ఆపిల్స్   3        ఆరంజ్   3   దానిమ్మ   3    ",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 750,
//               "pujariSellingPrice": 900,
//               "customerMrp": 900,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-15T02:40:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-14T23:49:14.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 263
//             }
//           },
//           {
//             "id": "4a9e08eb-9928-482e-91bc-5b08bedb57d1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "4479e4ec-9ffa-44be-ad63-a617213e5855",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "3ed60ce2-fa37-4bee-8a02-8a6d74777747",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "3bef35a7-d47a-45c6-9a83-dbd12d47bed6",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "3be6e1aa-d3ed-402a-94d5-5edc0df9bdc2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//             "samagri": {
//               "id": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//               "name": "రాగికళశాలు (1/2 ltr)/Copper kalasam (Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 183,
//               "pujariSellingPrice": 300,
//               "customerMrp": 300,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:25:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 16
//             }
//           },
//           {
//             "id": "35b50d99-b285-4fe3-8217-6df98f4ff132",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "356ccd95-8490-4511-9e93-8e0237f52715",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "31739058-df58-461c-af27-b351bad3861e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "2d51ae96-7ea0-451e-8f3d-f6da592b70a3",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "2826b8ed-598e-47a8-9d0c-9c4995a75e95",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "d87297fb-4fd9-4014-b4d8-12642e1975a7",
//             "samagri": {
//               "id": "d87297fb-4fd9-4014-b4d8-12642e1975a7",
//               "name": "నిమ్మకాయలు/LEMON",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 5,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:45:11.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-01T04:00:03.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 173
//             }
//           },
//           {
//             "id": "1be42796-8db0-4014-897c-7703514c7c2a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "1b047f80-c0f2-4e0d-8522-5fda4d264c06",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "1ae493ea-8234-40c4-9704-e13e45834f70",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "addon",
//             "samagriId": "5493b88d-4f54-4009-a6db-1f23dcfa23f1",
//             "samagri": {
//               "id": "5493b88d-4f54-4009-a6db-1f23dcfa23f1",
//               "name": "Fruits & Flowers ( Shop Opening) (Medium Quality)",
//               "description": "వీడి పూలు  1/4 kg , పూల మాలలు   3,   అరటి పండ్లు    1 Dozen,  ఆపిల్స్   3        ఆరంజ్   3   దానిమ్మ   3    ",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 630,
//               "pujariSellingPrice": 720,
//               "customerMrp": 720,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-15T02:40:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T06:01:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 219
//             }
//           },
//           {
//             "id": "1acb3b15-34d3-4022-a495-dd8169b89b93",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "91b85c96-cfd7-4775-9759-e6154c8a7cd5",
//             "samagri": {
//               "id": "91b85c96-cfd7-4775-9759-e6154c8a7cd5",
//               "name": "దేవుడి పటములు ( లక్ష్మి, వినాయకుడు, సరస్వతి)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 200,
//               "pujariSellingPrice": 230,
//               "customerMrp": 230,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:30:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T06:03:24.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 229
//             }
//           },
//           {
//             "id": "19b5c1f4-87b1-441b-a65e-67b2e0e7cf06",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "125b09a9-64af-439f-b194-46b3d7da082c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "11d29fc7-c2c8-4b8e-91e1-4e1b6e9ce0f9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//             "samagri": {
//               "id": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//               "name": "గుమ్మకాయడి/ PUMPKIN",
//               "description": "1",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 140,
//               "pujariSellingPrice": 150,
//               "customerMrp": 150,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:50:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:14:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 191
//             }
//           },
//           {
//             "id": "0cf30f0c-ca27-478b-8819-d2ea8c5dd244",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 154,
//             "samagriType": "default",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           }
//         ]
//       },
//       {
//         "id": 146,
//         "name": "Saraswathi Homam",
//         "description": "Saraswathi homam is performed for the Goddess Saraswati. She is the deity of Wisdom, Knowledge, and Intelligence. This homam is uniquely done for the students to invoke the blessing of Goddess Saraswati to improve their academic ranking and to boost their career life",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": "remote",
//         "mode3": "on_behalf",
//         "mode4": null,
//         "videos": "undefined",
//         "images": "[\"/uploads/2023/07/Saraswathi-Homam-Resized.png\"]",
//         "updatedAt": "2023-07-24T01:34:52.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-06-16T00:44:44.000Z",
//         "createdById": null,
//         "updatedById": "44",
//         "pujacategorymappings": [
//           {
//             "id": 18,
//             "pujaId": 146,
//             "categoryId": 2,
//             "subCategoryId": 7
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 87,
//             "pujaId": 146,
//             "durationHrs": 3,
//             "noOfPujaris": 2,
//             "modelSellingPrice": 17999,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 10000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-20T12:14:06.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-25T22:19:11.000Z",
//             "createdById": null,
//             "updatedById": "44",
//             "serviceFee": 1256,
//             "samagriCost": null,
//             "procedures": []
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "ff09c0b0-8f55-4329-a716-36aabd048fce",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "f95aa32d-91c7-4e60-9120-4db90517de50",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "f559274a-2b4c-4ac1-918b-c7071d96acd2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "f078c76f-9c05-4bf9-9b5b-13d4d9b28b8a",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "e76be4e0-30ed-470b-a2b3-d91b961d9b55",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "e4e5f180-e356-41e8-bc87-b4541fd82239",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "e196f558-bfb7-435d-92b6-b033afbae554",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "499f3b07-536e-4138-8123-a78958cb2c3a",
//             "samagri": {
//               "id": "499f3b07-536e-4138-8123-a78958cb2c3a",
//               "name": "పట్టికబెల్లం చిప్స్ /Misri Chips",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:54:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 106
//             }
//           },
//           {
//             "id": "c3d520f4-a67b-428c-b344-da3b08663cd3",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "b55c775c-1d74-408c-8fb2-6b117de941bf",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "ad4a3b7d-4295-4a5e-8b4f-1469640b7e39",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "aa66bdd2-535c-489b-b6fd-858291eaddce",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "a75cca03-b821-4b82-95ad-4fd541054856",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "9c0dd7b1-e20b-4ab4-9d0f-6687ac3f8053",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "9abb4ad9-5940-4510-8ec9-2525253dd328",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "samagri": {
//               "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//               "name": "బెల్లం / Jaggery",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:23:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T01:22:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 143
//             }
//           },
//           {
//             "id": "98da4c6b-cba8-499a-872a-6e4f67ef9fb6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "samagri": {
//               "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//               "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:38:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 115
//             }
//           },
//           {
//             "id": "9672081e-c1b2-4d41-986a-a53ac99f5b35",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "91964db3-71dd-471e-84c3-8b831b8cc2d0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "9011f264-26ba-44a7-adf6-d9ed709e964e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "82c82a0b-220e-4585-a458-94422e419ddc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "samagri": {
//               "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//               "name": "అరటి పండ్లు / Banana",
//               "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//               "units": "no",
//               "standardQty": "12",
//               "costPrice": 60,
//               "pujariSellingPrice": 70,
//               "customerMrp": 70,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-19T22:36:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:26:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 181
//             }
//           },
//           {
//             "id": "80ff2170-348d-45ed-8afb-283ca86ef93a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "7d015870-3502-45f5-a483-2580ecd49916",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "75621309-d6dd-48e2-b374-db88129029e9",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "74ab3e28-7c18-43a3-9aa6-5f4aa15a79b0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 11,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "aeb7b0f9-511c-4107-a966-e5be4ea389bd",
//             "samagri": {
//               "id": "aeb7b0f9-511c-4107-a966-e5be4ea389bd",
//               "name": "తెల్ల కమలం పువ్వులు/ White Lotus Flowers",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 20,
//               "pujariSellingPrice": 30,
//               "customerMrp": 30,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:05:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-10T02:11:30.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 196
//             }
//           },
//           {
//             "id": "743be43a-aff7-4e9d-970c-5122b1cca713",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "74382e0b-2493-4199-ac90-8b4931529135",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "648d9747-16c5-44ab-a390-1f7f2a74e29e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "samagri": {
//               "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//               "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 110,
//               "pujariSellingPrice": 150,
//               "customerMrp": 150,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:29:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 25
//             }
//           },
//           {
//             "id": "5cf99756-7ddb-454d-bab2-0d20286c458e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "5a44965b-5347-4e0b-9739-f388698e027b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 75,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "4f50cd56-e62d-44ae-a814-8e21666e97ef",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "4e0356c5-57f1-46d6-ae14-012a8de21eb7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "4d374ed7-7e5e-4218-a7dc-d9c425b6d3d2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "4b768fa8-ecf6-4878-becd-37b62fcc6ced",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "48262add-ccf0-4dd6-a3be-1fb67d276e6a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "3f40895a-19b5-4f80-950a-8244b420f679",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "02689db4-c39b-4cd0-8fcc-8f62d667b871",
//             "samagri": {
//               "id": "02689db4-c39b-4cd0-8fcc-8f62d667b871",
//               "name": "తెల్ల విడి పూలు / White Loose Flowers",
//               "description": null,
//               "units": "gms",
//               "standardQty": "500",
//               "costPrice": 200,
//               "pujariSellingPrice": 220,
//               "customerMrp": 220,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:08:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-10T02:10:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 193
//             }
//           },
//           {
//             "id": "38bfec48-73f5-47f8-8514-972f9c683390",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 7,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "361e870c-4d31-4008-86a4-66e95a91692e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "304377b1-2270-4ea2-94ef-d20c9aede77c",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           },
//           {
//             "id": "2a211b35-f46d-4574-9d05-7bc7e48e5541",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "samagri": {
//               "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//               "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 110,
//               "pujariSellingPrice": 150,
//               "customerMrp": 150,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:29:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 25
//             }
//           },
//           {
//             "id": "229eec8b-e876-4979-880c-a424c4933c39",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "samagri": {
//               "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//               "name": "వరిపెళాలు/Rice Fields",
//               "description": null,
//               "units": "gms",
//               "standardQty": "50",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:33:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 58
//             }
//           },
//           {
//             "id": "206a466d-1dcc-4e33-8210-dc92a45b1b20",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "1de3f82f-4af6-4f9f-a893-2e7a19d19337",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "15546c32-d271-4cad-8122-28ed91fa87d0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "17f5dfe8-ebdb-40ed-b278-b73e8cc35ae4",
//             "samagri": {
//               "id": "17f5dfe8-ebdb-40ed-b278-b73e8cc35ae4",
//               "name": "5 types of fruits (Each 2)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 350,
//               "pujariSellingPrice": 400,
//               "customerMrp": 400,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T04:11:15.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-16T05:21:24.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 155
//             }
//           },
//           {
//             "id": "115ec25a-68e6-4292-81e6-3c3ec1093961",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "0cfdf4e9-9e36-4935-b9c6-6c4281dd2f0b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "07822445-0345-4f9c-bc85-8942316e9e20",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 146,
//             "samagriType": "default",
//             "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "samagri": {
//               "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//               "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 210,
//               "pujariSellingPrice": 500,
//               "customerMrp": 500,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:26:10.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 17
//             }
//           }
//         ]
//       },
//       {
//         "id": 148,
//         "name": "Maha Mryutyunjaya Homam ",
//         "description": "Maha Mrityunjaya Homam is performed by devotees to prevent premature deaths. The homam is dedicated to Lord Shiva in the form of mrityunjaya who blesses the devotee with long life. The significance of this Maha Mrityunjaya Homam is that it has been passed on to us via work done by Markandeya maharshi and several others, giving an opportunity for humans to win over untimely or unripe death or any physical illness. \nThe purpose of extending one's life or to get blessed with health is to further one's spiritual pursuit while also fulfilling worldy duties.  When people with such an inner intention perform the puja, Shiva overwrites any karmic balance to grant the devotee with the extra life span.  \nChildren or shishyas also perform these pujas for their elders or guri, since they wish them to be alive in the current upadhi and continue to guide them",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": "remote",
//         "mode3": "on_behalf",
//         "mode4": null,
//         "videos": "undefined",
//         "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//         "updatedAt": "2023-07-24T01:32:45.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-06-16T00:54:46.000Z",
//         "createdById": null,
//         "updatedById": "44",
//         "pujacategorymappings": [
//           {
//             "id": 22,
//             "pujaId": 148,
//             "categoryId": 2,
//             "subCategoryId": 7
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 85,
//             "pujaId": 148,
//             "durationHrs": 3,
//             "noOfPujaris": 2,
//             "modelSellingPrice": 17999,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 8000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-20T12:39:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-23T11:58:31.000Z",
//             "createdById": null,
//             "updatedById": "44",
//             "serviceFee": 1919,
//             "samagriCost": null,
//             "procedures": []
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "fa217b7e-342f-4c1a-8b9b-acf16c290e2e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "f6ca971b-d401-439a-aeae-273685fd0125",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "f6930127-14ac-44a5-bbad-b2f21c8e15b9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "f6311dc8-2dd2-4983-b041-593510a5e6d5",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "f2b79bdf-059f-4bb8-be15-82fcf22f2d12",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//             "samagri": {
//               "id": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//               "name": "యాలకులు / Cardamom",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 10,
//               "pujariSellingPrice": 24,
//               "customerMrp": 24,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:07:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 87
//             }
//           },
//           {
//             "id": "efc48a54-7dc3-4d1b-a625-d7435d9eee6f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 35,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "samagri": {
//               "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//               "name": "ఇటుకలు/ BRICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 8,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:39:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:14:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 150
//             }
//           },
//           {
//             "id": "e7bf3683-174d-4e1a-8508-ecc7ff607b6a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "samagri": {
//               "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//               "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:38:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 115
//             }
//           },
//           {
//             "id": "e09bf7bc-1851-4bd4-960f-cecb9139eed4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//             "samagri": {
//               "id": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//               "name": "జాజి కయ/Nutmeg",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 22,
//               "pujariSellingPrice": 30,
//               "customerMrp": 30,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:03:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 50
//             }
//           },
//           {
//             "id": "d83b7b14-7f29-4657-a91e-62613e8e8372",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "d6fcd61d-d3f7-4aea-b729-a76e42a1a32c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//             "samagri": {
//               "id": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//               "name": "పచ్చ కర్పూరం /  GREEN CAMPHOR",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 10,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:22:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 99
//             }
//           },
//           {
//             "id": "d4a4568a-61c8-4ee9-b89a-7876c4c336d2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "d4347408-2352-4612-a179-9760106c8b88",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "d4063733-d0b6-4a7a-9441-db19181daea3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "d0b0a1e6-1dd9-4f87-b7b3-da0cddcadfb8",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           },
//           {
//             "id": "d031ded0-f6ae-4c41-91bd-09fa67188dfb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//             "samagri": {
//               "id": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//               "name": "గోమయం/Cow Dung",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-13T06:33:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:27:00.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 230
//             }
//           },
//           {
//             "id": "c94f12af-3792-4770-a7e1-24f036fdd07a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "499f3b07-536e-4138-8123-a78958cb2c3a",
//             "samagri": {
//               "id": "499f3b07-536e-4138-8123-a78958cb2c3a",
//               "name": "పట్టికబెల్లం చిప్స్ /Misri Chips",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:54:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 106
//             }
//           },
//           {
//             "id": "c8bd039e-afe3-4620-8a6c-228d53e92b89",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "bb94bff7-c855-4559-916d-166d4237cf46",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "0031beaf-9312-467e-853a-b2243ce677f5",
//             "samagri": {
//               "id": "0031beaf-9312-467e-853a-b2243ce677f5",
//               "name": "గోమూత్రం/Cow Urine",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:17:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:26:29.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 231
//             }
//           },
//           {
//             "id": "ba599e6d-8b38-4e31-8e7c-57f352c96eaa",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "b91cdf68-0ea1-4390-86b8-f99e7984a37e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "ab88547d-0167-4e87-9210-e38a8ce89e48",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "a540bc12-167e-4140-bfe5-4058a0f589d9",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "a0e5e64d-8cc9-4c07-988d-0d3c75811f59",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "9976239d-c2bc-4290-b622-f36a1aa3a483",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "samagri": {
//               "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//               "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 8,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:40:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 33
//             }
//           },
//           {
//             "id": "9893c9b9-f8b6-4529-b2e0-6d4bc877b4db",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "97717971-a0b4-4d75-bc44-77f85d546252",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "samagri": {
//               "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//               "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 18,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:01:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 48
//             }
//           },
//           {
//             "id": "964707d5-ce25-4f58-a8b4-1dbc8f6530e8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "samagri": {
//               "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//               "name": "గరిక/  Grass",
//               "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 20,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:31:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:11:34.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 183
//             }
//           },
//           {
//             "id": "95c6c364-4d64-4e56-ac0d-11e23475c38e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "d2267b70-2283-43d2-b6a3-ea9e2c00c60a",
//             "samagri": {
//               "id": "d2267b70-2283-43d2-b6a3-ea9e2c00c60a",
//               "name": "Fruits & Flowers / పూలు మరియు పండ్లు  ( Maha Mruthyunjaya Homam)",
//               "description": "వీడి పూలు - 1/2 kg , పూల దండలు -3, అరటి పండ్లు - 1 dozen,  5 టైప్స్  అఫ్ ఫ్రూప్ట్స్  ఈచ్ - 2,  మరేడు  దళాలు - 1/2 kg",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 420,
//               "pujariSellingPrice": 504,
//               "customerMrp": 504,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-17T01:44:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-17T01:44:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 267
//             }
//           },
//           {
//             "id": "8f0de026-81ef-4df8-ae2b-e8048073138c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "8f0613cc-2f92-4766-bb5f-cd222f9a9c76",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "samagri": {
//               "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//               "name": "దర్భ కట్టలు/Kusha Grass",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T06:06:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 53
//             }
//           },
//           {
//             "id": "8d8ef146-6eeb-4fbb-8bba-f3adfa944459",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "8938029a-d3bd-4a5b-a72e-2ee9ff073189",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "87269f4b-3746-4992-8608-87d9ffde9617",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "83075cba-1c92-48e5-9b88-2736cb92295e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "addon",
//             "samagriId": "f777870d-f64a-46ba-8817-210a3f31a8da",
//             "samagri": {
//               "id": "f777870d-f64a-46ba-8817-210a3f31a8da",
//               "name": " రావి కొమ్మ/ Peepal Branch",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T02:56:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:46:31.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 223
//             }
//           },
//           {
//             "id": "7d64b2b7-044b-40c1-94d5-731d476297c4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "75c54993-415b-4c9c-b109-22c643a7f56b",
//             "samagri": {
//               "id": "75c54993-415b-4c9c-b109-22c643a7f56b",
//               "name": "గోధుమలు / Wheat",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 67
//             }
//           },
//           {
//             "id": "781c805e-919d-42c7-a303-bf22509580dc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "samagri": {
//               "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//               "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 210,
//               "pujariSellingPrice": 500,
//               "customerMrp": 500,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:26:10.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 17
//             }
//           },
//           {
//             "id": "7652d487-37d0-43f6-ba58-116e4d96b710",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "3ed7a4c6-bde0-45ce-a96b-30c381e0092b",
//             "samagri": {
//               "id": "3ed7a4c6-bde0-45ce-a96b-30c381e0092b",
//               "name": "రోజ్ వాటర్/ Rose water",
//               "description": null,
//               "units": "ml",
//               "standardQty": "100",
//               "costPrice": 14,
//               "pujariSellingPrice": 40,
//               "customerMrp": 40,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T01:37:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 76
//             }
//           },
//           {
//             "id": "75eb979a-a645-412d-9a53-310989470311",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "addon",
//             "samagriId": "31ef9c71-5019-488c-a9e8-311d0c264d71",
//             "samagri": {
//               "id": "31ef9c71-5019-488c-a9e8-311d0c264d71",
//               "name": "జువ్వి కొమ్మ/Juvvi branch",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 70,
//               "pujariSellingPrice": 85,
//               "customerMrp": 85,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T02:55:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:48:48.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 221
//             }
//           },
//           {
//             "id": "71b1fd4f-cc04-4f69-8f1e-c3aecf12e018",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "f3809443-befe-4c75-a784-4f1cf7527d51",
//             "samagri": {
//               "id": "f3809443-befe-4c75-a784-4f1cf7527d51",
//               "name": "యజ్ఞోపవేతం వడికిన /Sacred thread",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 45,
//               "customerMrp": 45,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:25:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 52
//             }
//           },
//           {
//             "id": "6f07843c-da7a-4a11-b616-49d44f2807ed",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "6c39bb37-efcc-446f-960a-af68ef6c2080",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "674a477c-782c-41cf-b51e-98f60e633f30",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "66ccec83-5f05-43c2-936c-7520ab7ff1ae",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "64b7308c-fc64-453c-832d-2aa4cff36d35",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "samagri": {
//               "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//               "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 500,
//               "pujariSellingPrice": 600,
//               "customerMrp": 600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:43:18.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 114
//             }
//           },
//           {
//             "id": "5cbadb55-c008-41b5-b171-1683d1efadfa",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//             "samagri": {
//               "id": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//               "name": "లవంగాలు / cloves",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:08:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 88
//             }
//           },
//           {
//             "id": "5ca44499-0647-4c04-968f-edb108186f5d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "5a1aa697-726c-446f-9558-978b8946f93b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "56535763-562b-4cab-a49c-d98b40be0249",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "51d2f750-96c8-41a3-a845-9da1cf7f01fd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "ecaa05b8-2b35-43fc-b0c5-a7effff2676a",
//             "samagri": {
//               "id": "ecaa05b8-2b35-43fc-b0c5-a7effff2676a",
//               "name": "మినపప్పు/Urad Dal",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 4,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T05:01:50.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 30
//             }
//           },
//           {
//             "id": "4f5c6b51-3d26-4d2c-84cc-f42124d2ee86",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "4dba48cc-3e9c-4169-b765-993f020ef97e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "4c6d1c82-9066-4509-a1b1-2a438dd4b9b2",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "4b08de98-a770-4cf2-ae82-d24e4135ba6d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "46d13094-1288-42cc-97a8-12eb06a21e05",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "42f8f04b-d874-488e-8beb-e3632eaa4d5e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "417e5eca-f57d-468d-adc5-d59d2cb3323e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "3e29e39c-61f7-4754-aa14-20e0d02a340f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//             "samagri": {
//               "id": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//               "name": "జాపత్రి/Nutmeg Mace",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 54,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:04:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 51
//             }
//           },
//           {
//             "id": "3db5220d-c3c7-496d-ad8f-af56fbb9613d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "3d9360a6-c412-4856-b3e2-a891c642863f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "samagri": {
//               "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//               "name": "ఇసుక / SAND",
//               "description": "As Gampallu",
//               "units": "other",
//               "standardQty": "1",
//               "costPrice": 100,
//               "pujariSellingPrice": 125,
//               "customerMrp": 125,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:28:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:16:43.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 151
//             }
//           },
//           {
//             "id": "3199fc70-0aaa-4263-a283-23527e50f3b5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "bc62b6a8-24f4-4b8a-bc91-0c9ef8436c19",
//             "samagri": {
//               "id": "bc62b6a8-24f4-4b8a-bc91-0c9ef8436c19",
//               "name": "శేనగ పప్పు/Chana Dal",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T05:04:57.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 32
//             }
//           },
//           {
//             "id": "313f6948-66a3-4792-b79e-dfb8511092d4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "addon",
//             "samagriId": "7526e2c0-07c4-4a31-8482-85153766f5b4",
//             "samagri": {
//               "id": "7526e2c0-07c4-4a31-8482-85153766f5b4",
//               "name": "మర్రి కొమ్మ/ Banyan branch",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 75,
//               "pujariSellingPrice": 85,
//               "customerMrp": 85,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T02:57:11.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:42:23.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 224
//             }
//           },
//           {
//             "id": "30229700-bc1e-4efe-9db2-8a340ac78b1c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "4fc25bac-40ce-42c1-9ada-5ea068ea3553",
//             "samagri": {
//               "id": "4fc25bac-40ce-42c1-9ada-5ea068ea3553",
//               "name": "అత్తరు / Perfume",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 35,
//               "pujariSellingPrice": 43,
//               "customerMrp": 43,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:44:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:59:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 121
//             }
//           },
//           {
//             "id": "2fbbf980-a7d4-47b6-a2e5-cd0c9723be9a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "2ee5b6d4-c423-4e2e-bd8b-a08a6f99f4ba",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "addon",
//             "samagriId": "a6466242-2a96-4098-97e9-022996693e06",
//             "samagri": {
//               "id": "a6466242-2a96-4098-97e9-022996693e06",
//               "name": "మేడి కొమ్మ / Medi Branch ",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 80,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-18T02:55:46.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:45:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 222
//             }
//           },
//           {
//             "id": "2b245487-7322-4e45-b976-002be8d1bd88",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "2ae4ab81-8d9e-48e9-babf-4c3480147217",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "26e78c60-7b92-497a-9097-4d9ec3e67e2c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "1f8c4bbf-d2e8-46d6-ad41-79c010b92b8b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "1f01558e-a7db-405c-aa12-46def6574cf7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "57903f59-a61a-400f-b0f9-68e6355039f2",
//             "samagri": {
//               "id": "57903f59-a61a-400f-b0f9-68e6355039f2",
//               "name": "పత్తి వస్త్రం Big / Cotton Garland  (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 52,
//               "customerMrp": 52,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:19:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 96
//             }
//           },
//           {
//             "id": "1cbc1f1f-994b-4713-87a9-25ec86b43be1",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "06f63547-7e67-4081-af22-ddee788e43fa",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "052d3601-6fcd-4077-976c-d9b170516299",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "1a564751-0ae5-42de-821a-e1e3afe41741",
//             "samagri": {
//               "id": "1a564751-0ae5-42de-821a-e1e3afe41741",
//               "name": "కత్తెర / Scissor",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 150,
//               "pujariSellingPrice": 170,
//               "customerMrp": 170,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T06:13:28.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-21T06:13:28.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 167
//             }
//           },
//           {
//             "id": "05038269-8ca1-4e30-ae8e-9cc0cfbbd28f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 148,
//             "samagriType": "default",
//             "samagriId": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//             "samagri": {
//               "id": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//               "name": "తెల్ల నువ్వులు/White Sesame",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T02:09:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 43
//             }
//           }
//         ]
//       },
//       {
//         "id": 150,
//         "name": "Sudarshana Homam",
//         "description": "The fire ritual ceremony of Sudarshana Homam is performed to seek the benevolence of Lord Vishnu through his possession of Sudarshana, in the form of deity with 16 hands. “Su” means the right. “Darshana: means vision. When Lord Sudarshan is invoked with prayers, it sets out to fulfil the Ishtakaamya of the devotee. This Homam holds the power to remove malefic effects of evil spirits, troubles caused by enemies and witchcraft. It needs to be performed with shraddha and under the guidance of an able pujari who has strong vishnu upasana.  This puja is at times,  performed for protection from evil eyes, dark malefic forces, and for success in business, work and life.",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": "on_behalf",
//         "mode3": "remote",
//         "mode4": null,
//         "videos": "undefined",
//         "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//         "updatedAt": "2023-07-20T13:35:30.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-06-16T00:58:06.000Z",
//         "createdById": null,
//         "updatedById": "44",
//         "pujacategorymappings": [
//           {
//             "id": 26,
//             "pujaId": 150,
//             "categoryId": 2,
//             "subCategoryId": 5
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 83,
//             "pujaId": 150,
//             "durationHrs": 4,
//             "noOfPujaris": 2,
//             "modelSellingPrice": 29999,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 15000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-20T13:37:24.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-23T11:48:48.000Z",
//             "createdById": null,
//             "updatedById": "44",
//             "serviceFee": 1869,
//             "samagriCost": null,
//             "procedures": []
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "74def7d4-1e47-4d57-9867-785e00c6fba7",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "70796169-debf-452d-966d-fd65bd1aad1e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "6e64f991-c51b-4b37-90b9-23cd3d2949a1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//             "samagri": {
//               "id": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//               "name": "జాపత్రి/Nutmeg Mace",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 54,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:04:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 51
//             }
//           },
//           {
//             "id": "6df31827-9fd6-4044-a80f-62bcec3499e3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "1e4cc208-8f75-4c51-a062-e8782e728d9e",
//             "samagri": {
//               "id": "1e4cc208-8f75-4c51-a062-e8782e728d9e",
//               "name": "ఇత్తడి కళశాలు (1 Ltr)/Brass Kalasam (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 290,
//               "pujariSellingPrice": 500,
//               "customerMrp": 500,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:28:05.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 20
//             }
//           },
//           {
//             "id": "6d754979-fa35-4e68-b7ba-7a0e362fe871",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "6c21a8c9-76e8-498e-8267-9f7f90d80900",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "samagri": {
//               "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//               "name": "వరిపెళాలు/Rice Fields",
//               "description": null,
//               "units": "gms",
//               "standardQty": "50",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:33:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 58
//             }
//           },
//           {
//             "id": "6293587e-17cc-4fae-80e2-1d32061b260b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//             "samagri": {
//               "id": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//               "name": "జాజి కయ/Nutmeg",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 22,
//               "pujariSellingPrice": 30,
//               "customerMrp": 30,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:03:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 50
//             }
//           },
//           {
//             "id": "6080ffbd-55c9-455c-b08a-bb0c070773f9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 51,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "5f5a5b67-3d0c-4ff0-9079-aebbb5b6e701",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "5eca6414-b46b-46ae-a84e-ed8377bd2bda",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "2e4abf8f-5fe9-4add-aca5-da8dc7d7b98f",
//             "samagri": {
//               "id": "2e4abf8f-5fe9-4add-aca5-da8dc7d7b98f",
//               "name": "తెల్ల ఆవాలు / White Mustard",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 13,
//               "pujariSellingPrice": 17,
//               "customerMrp": 17,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:12:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T01:04:20.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 86
//             }
//           },
//           {
//             "id": "5acbd341-ed57-49c1-b55e-edd9fcee4f20",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "573c5a93-93f5-4d51-a6c7-33d7d6eaee95",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "53fe27eb-07c3-40f6-88d7-81b268e3b840",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "535de3c8-416b-4a38-a726-f77fecb50ccb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "51ffc44c-c081-4e07-97df-16b6b132435b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "samagri": {
//               "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//               "name": "ఇసుక / SAND",
//               "description": "As Gampallu",
//               "units": "other",
//               "standardQty": "1",
//               "costPrice": 100,
//               "pujariSellingPrice": 125,
//               "customerMrp": 125,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:28:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:16:43.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 151
//             }
//           },
//           {
//             "id": "4f37deb4-7ad7-4237-bb90-13c5eb872978",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "47892dd1-3eb5-4c6e-9cc8-55c2f8b5be9d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//             "samagri": {
//               "id": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//               "name": "అటుకులు / Poha",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:28:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 101
//             }
//           },
//           {
//             "id": "475d7950-f912-40f0-8cfa-37e46504e1a5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "413d47d6-c46e-4d65-b7e2-c91b26a31af2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "3dfe797b-3b4e-4938-9ccd-894434bceea8",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "3d683149-b1d9-49a6-8d07-681e55894106",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "1b23748e-bf83-4c3b-b84c-bdb96d280750",
//             "samagri": {
//               "id": "1b23748e-bf83-4c3b-b84c-bdb96d280750",
//               "name": "మట్టి కంచుళ్ళు /  Clay pebbles (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 50,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:58:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T03:44:02.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 135
//             }
//           },
//           {
//             "id": "3ccbcb49-5b99-4350-be37-dfce792b737d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "37d14a5d-83f8-42c4-8907-809a440c39f4",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "378cdc84-ac97-415a-823d-4b9bd89cba13",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 25,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "f45a00cd-55ce-4107-ad58-32050ceda4ae",
//             "samagri": {
//               "id": "f45a00cd-55ce-4107-ad58-32050ceda4ae",
//               "name": "తామర గింజలు / Lotus seeds",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 6,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:19:40.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-09T06:09:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 194
//             }
//           },
//           {
//             "id": "36882852-d4ff-4b21-b814-f9329804e9e7",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "samagri": {
//               "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//               "name": "చెంబులు/Kalasam",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:26.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:39:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 253
//             }
//           },
//           {
//             "id": "33cf6fa3-3a9f-4df6-97eb-e21f61fff78b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "29b6af6e-0168-478b-8535-0d7587849e02",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "2902f4e1-3a61-491f-a30c-67fca42196ee",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "1a3a115b-97c4-43ac-8c42-04aa79abf12a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "1944d66e-8633-4278-8af7-d8f768142f24",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//             "samagri": {
//               "id": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//               "name": "పచ్చ కర్పూరం /  GREEN CAMPHOR",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 10,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:22:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 99
//             }
//           },
//           {
//             "id": "16a1cb2a-18d6-4f12-9eb5-3afa27405fad",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "117c02aa-2fbd-4442-ba21-5bed7c03dde1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "111eaa31-5af9-47b0-8144-3ec2f53b901a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           },
//           {
//             "id": "0bffd6c3-944e-4360-aa7e-cb105690f2f8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//             "samagri": {
//               "id": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//               "name": "తెల్ల నువ్వులు/White Sesame",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T02:09:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 43
//             }
//           },
//           {
//             "id": "06c76b99-e556-4161-8196-4a23d7480ace",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "fa49dd85-1709-4b55-b3b2-957c5abb5c29",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 100,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "f69670e3-a096-4123-ae8c-e11a309b82c4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "2dcb00f8-6ed3-4736-877b-544a38fed1ab",
//             "samagri": {
//               "id": "2dcb00f8-6ed3-4736-877b-544a38fed1ab",
//               "name": "Fruits and Flowers ( Sudarshana Homam)",
//               "description": "వీడి పూలు - 1/4 kg, పూల మూరలు -5,  తామరపువ్వులు -3,  బత్తాయి పళ్ళు -3, సపోటాలు-3,  ఆపిల్స్ -3, కమలాలు -3  చేరుకుగడ ముక్కలు - 1/4 kg\n",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 570,
//               "pujariSellingPrice": 600,
//               "customerMrp": 600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T01:33:05.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-09T07:23:53.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 217
//             }
//           },
//           {
//             "id": "ef576259-34ba-4e92-9e39-6f4d833a939f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "ee5e2808-f031-407e-9549-ddfb059c3108",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "e6991869-4cac-4aa9-959b-a14b2aa3478e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "samagri": {
//               "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//               "name": "బెల్లం / Jaggery",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:23:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T01:22:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 143
//             }
//           },
//           {
//             "id": "e320875b-00c5-49cc-9823-6e73dc4fcc3a",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "e02e9a58-82a4-4f79-a2a0-f76b9a75c5ca",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "dc989022-956d-4097-9825-943a0b018db9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "d82f91c0-0eb9-415d-a195-c3415d89df96",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//             "samagri": {
//               "id": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//               "name": "నీళ్ల బిందెలు / Water Pots",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:33:20.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 248
//             }
//           },
//           {
//             "id": "d76a2f80-fc2d-4820-9c58-2fd1d6a65c0c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "d6c96ae9-7a56-486b-98c0-06de8f75a7be",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "ecaa05b8-2b35-43fc-b0c5-a7effff2676a",
//             "samagri": {
//               "id": "ecaa05b8-2b35-43fc-b0c5-a7effff2676a",
//               "name": "మినపప్పు/Urad Dal",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 4,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T05:01:50.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 30
//             }
//           },
//           {
//             "id": "d6c087fc-b7eb-4296-8d4e-63301161402c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "d37a04dc-9509-4d82-8715-e887fe49ec79",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 100,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "samagri": {
//               "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//               "name": "ఇటుకలు/ BRICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 8,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:39:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:14:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 150
//             }
//           },
//           {
//             "id": "cb3278c3-49f0-4683-a1a1-55ff8417d575",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "c7188900-6bb3-455c-a21f-6e082c26ef51",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "75c54993-415b-4c9c-b109-22c643a7f56b",
//             "samagri": {
//               "id": "75c54993-415b-4c9c-b109-22c643a7f56b",
//               "name": "గోధుమలు / Wheat",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 67
//             }
//           },
//           {
//             "id": "c174a5b4-a2cb-4b03-909d-2a06607577b3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "c085fac8-d2df-4c4a-91ec-7fb83d7d5f13",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           },
//           {
//             "id": "bf86fed3-81f3-4a3f-b098-99aa579afa74",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "samagri": {
//               "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//               "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 500,
//               "pujariSellingPrice": 600,
//               "customerMrp": 600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:43:18.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 114
//             }
//           },
//           {
//             "id": "abe6dd1b-1cce-4042-adc5-54759d3f96ca",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "0f1d9325-8831-460a-9060-bc780de080a4",
//             "samagri": {
//               "id": "0f1d9325-8831-460a-9060-bc780de080a4",
//               "name": "ఉత్తరేణీ   పుల్లలు / Uttareni sours",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 50,
//               "pujariSellingPrice": 55,
//               "customerMrp": 55,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:32:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-09T06:07:29.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 220
//             }
//           },
//           {
//             "id": "a7d8e90a-245f-4880-b759-faafa843a511",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "9969d251-aded-4a89-8fe5-799df6963a55",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "80f2e43f-0de9-49a0-b310-9267ab16b4fc",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "79cca446-4200-4792-b73f-1766af6e6736",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "764b1f6c-c8fd-4df2-9691-d65c4d9a6435",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 150,
//             "samagriType": "default",
//             "samagriId": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//             "samagri": {
//               "id": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//               "name": "లవంగాలు / cloves",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:08:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 88
//             }
//           }
//         ]
//       },
//       {
//         "id": 16,
//         "name": "Ganapati Homam",
//         "description": "A certain amsha / energy of Ganapati is worshipped through this homam.  Ganapati combines the energy of Shiva and Parvati. Through the peacefulness of Shiva and the creative power of Parvati, and with his enormous intelligence removes all the obstacles from our path.",
//         "language": "telugu",
//         "region": "Hyderabad",
//         "mode1": "at_my_home",
//         "mode2": "on_behalf",
//         "mode3": "remote",
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/SsRsg2jC-ec\",\"https://www.youtube.com/embed/CkuMjlP8w9A\",\"https://www.youtube.com/embed/qrdxFxKIYNY\",\"https://www.youtube.com/embed/tQJc70v5O2o\"]",
//         "images": "[\"/uploads/2023/07/Homam-2-scaled.jpg\"]",
//         "updatedAt": "2023-07-24T01:33:38.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": null,
//         "createdById": null,
//         "updatedById": "44",
//         "pujacategorymappings": [
//           {
//             "id": 17,
//             "pujaId": 16,
//             "categoryId": 2,
//             "subCategoryId": 4
//           },
//           {
//             "id": 16,
//             "pujaId": 16,
//             "categoryId": 2,
//             "subCategoryId": 7
//           },
//           {
//             "id": 4,
//             "pujaId": 16,
//             "categoryId": 1,
//             "subCategoryId": 1
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 80,
//             "pujaId": 16,
//             "durationHrs": 4,
//             "noOfPujaris": 2,
//             "modelSellingPrice": 15000,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 7000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-20T12:11:15.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-01T15:20:41.000Z",
//             "createdById": null,
//             "updatedById": "44",
//             "serviceFee": 1011,
//             "samagriCost": 6300,
//             "procedures": []
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "f6f40e4a-45c6-4fdb-9ec3-f8d36fb90128",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "samagri": {
//               "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//               "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 500,
//               "pujariSellingPrice": 600,
//               "customerMrp": 600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:43:18.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 114
//             }
//           },
//           {
//             "id": "f2bf891b-03fd-4a1f-8db3-1d92f856ebc6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "499f3b07-536e-4138-8123-a78958cb2c3a",
//             "samagri": {
//               "id": "499f3b07-536e-4138-8123-a78958cb2c3a",
//               "name": "పట్టికబెల్లం చిప్స్ /Misri Chips",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:54:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 106
//             }
//           },
//           {
//             "id": "f2569b3a-348a-482e-9a74-f89aa84b30eb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "samagri": {
//               "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//               "name": "బెల్లం / Jaggery",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:23:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T01:22:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 143
//             }
//           },
//           {
//             "id": "e5a2039f-cb12-4f2e-90c7-f9acc05f9c77",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "96414c68-fdd7-4a85-9fc1-4bedd5c27b60",
//             "samagri": {
//               "id": "96414c68-fdd7-4a85-9fc1-4bedd5c27b60",
//               "name": "జీడిపప్పు/Cashew",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:59:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 12
//             }
//           },
//           {
//             "id": "dd9ef38a-ce3c-4abf-a5dc-e8136cc4499f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "d85bb33f-95da-49ab-8978-ac7340d5199f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "c457817e-7b7e-42fd-836c-c0723e0a06f2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "bbdb70b7-1300-4e8f-bf6b-2bbc7103530b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//             "samagri": {
//               "id": "92da20c4-a10e-4013-9364-f5114438d904",
//               "name": "వీడి పూలు / Loose Flowers",
//               "description": null,
//               "units": "gms",
//               "standardQty": "250",
//               "costPrice": 110,
//               "pujariSellingPrice": 120,
//               "customerMrp": 120,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:16:53.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:44:42.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 176
//             }
//           },
//           {
//             "id": "b646afc5-9f94-452e-9450-d369895cc5a5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "ad2ba7d6-04ab-4903-9226-62859fbadb0a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 11,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "ca478478-dede-4f37-bb5a-1e2c2a023696",
//             "samagri": {
//               "id": "ca478478-dede-4f37-bb5a-1e2c2a023696",
//               "name": "చెరుకు ముక్కలు / Pieces of Sugarcane",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 12,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:36:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T05:03:23.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 186
//             }
//           },
//           {
//             "id": "a03db37a-ac9d-4cf3-9677-2e47d845b8a2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "00416a9a-4864-4758-a471-f915ab3c63e7",
//             "samagri": {
//               "id": "00416a9a-4864-4758-a471-f915ab3c63e7",
//               "name": "ముద్దకర్పూరం / Big Chunk Camphor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 38,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:40:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 113
//             }
//           },
//           {
//             "id": "9ea9703b-f66d-4ff3-9625-57bb3b68c51e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "samagri": {
//               "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//               "name": "అరటి పండ్లు / Banana",
//               "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//               "units": "no",
//               "standardQty": "12",
//               "costPrice": 60,
//               "pujariSellingPrice": 70,
//               "customerMrp": 70,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-19T22:36:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:26:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 181
//             }
//           },
//           {
//             "id": "9c9ce3e4-a7b3-4c0b-8695-ea57f819ab24",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           },
//           {
//             "id": "99e3c30b-80ab-4b52-bde4-48d4b735236c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "97d82f32-333d-41f5-ab58-c7a86ec49374",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "94114175-495b-42d7-b39e-31321d4c1010",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//             "samagri": {
//               "id": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//               "name": "తెల్ల నువ్వులు/White Sesame",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T02:09:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 43
//             }
//           },
//           {
//             "id": "92c33e8b-8bcd-4238-98e8-5eab3c61b2d3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "samagri": {
//               "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//               "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:38:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 115
//             }
//           },
//           {
//             "id": "8d7667ee-f603-415a-a4a4-65d9d659f0b9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "8973b4bb-b3f8-4968-b25b-25949007ecb2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "samagri": {
//               "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//               "name": "ఇటుకలు/ BRICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 8,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:39:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:14:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 150
//             }
//           },
//           {
//             "id": "6e86ef2a-6606-4e65-8f85-165125fc4e76",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "a3d9cd9c-7e5e-4161-97b8-8ea71843eae4",
//             "samagri": {
//               "id": "a3d9cd9c-7e5e-4161-97b8-8ea71843eae4",
//               "name": "అగ్గి పెట్టిలు (Homelite) /Match Box (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:57:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 40
//             }
//           },
//           {
//             "id": "6aa966ef-3cc5-46f6-933c-597457dc1785",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "64e2c83c-bbaf-4fcd-adc2-3813914b3dde",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "62b03864-ba92-42e7-a93a-3aac91198b0c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "samagri": {
//               "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//               "name": "దర్భ కట్టలు/Kusha Grass",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T06:06:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 53
//             }
//           },
//           {
//             "id": "5f09c330-59f0-4c21-b308-aa241c7c59ad",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "samagri": {
//               "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//               "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 230,
//               "pujariSellingPrice": 270,
//               "customerMrp": 270,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:31:17.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 23
//             }
//           },
//           {
//             "id": "5d909619-db03-4ac9-9587-cb29c8329a7f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "5907fd01-8937-4b29-88e0-c32e4ec93f62",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "555e8376-034e-4474-9dc1-af299126c983",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 100,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "4d0fd598-e513-4ffa-9770-dc18205cec5c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 75,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "4d0829cd-ba06-4a75-84cb-8c9c5dd78432",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "samagri": {
//               "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//               "name": "ఇసుక / SAND",
//               "description": "As Gampallu",
//               "units": "other",
//               "standardQty": "1",
//               "costPrice": 100,
//               "pujariSellingPrice": 125,
//               "customerMrp": 125,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:28:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:16:43.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 151
//             }
//           },
//           {
//             "id": "4cfe7439-b8c2-4d6f-9a9d-42d232769e5e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "samagri": {
//               "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//               "name": "గరిక/  Grass",
//               "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 20,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:31:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:11:34.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 183
//             }
//           },
//           {
//             "id": "44e2f7ce-b875-4ba6-bf67-a9efc0e23bfb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//             "samagri": {
//               "id": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//               "name": "అటుకులు / Poha",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:28:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 101
//             }
//           },
//           {
//             "id": "3aa0252e-4ebe-4e7c-b73e-ed6725a040df",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "37bb68b9-a712-41a4-8105-e56ecf1f0779",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "d7ec440e-56ba-4c10-bac4-2ad60700526d",
//             "samagri": {
//               "id": "d7ec440e-56ba-4c10-bac4-2ad60700526d",
//               "name": "బాదంపప్పు/Badam",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 9,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:11:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 13
//             }
//           },
//           {
//             "id": "349937b7-660a-4e0d-9751-a7a5a42d62c1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "samagri": {
//               "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//               "name": "వరిపెళాలు/Rice Fields",
//               "description": null,
//               "units": "gms",
//               "standardQty": "50",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:33:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 58
//             }
//           },
//           {
//             "id": "3291a823-2769-48ae-a0cf-bda92a485af3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "2e1e9d1e-2a71-41a2-ac1e-ecb2e723722b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "2686a4c1-fa56-419a-aa62-42327e507a41",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "389822bd-ca89-469c-bc0a-82694a7c8062",
//             "samagri": {
//               "id": "389822bd-ca89-469c-bc0a-82694a7c8062",
//               "name": "అష్ట గంధం/Ashtagandham",
//               "description": null,
//               "units": "gms",
//               "standardQty": "5",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:28:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 47
//             }
//           },
//           {
//             "id": "24cec99e-2609-4d37-89ae-ac0ec3e3c42d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "22fef19b-8d26-41c2-9cc1-78c20cad8ffc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "1733ae70-2bc5-4f5e-a07f-ac787c77d21d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "14621871-914d-455a-9531-173eb129d705",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "80189106-e98e-41b8-8435-beef3b4cf163",
//             "samagri": {
//               "id": "80189106-e98e-41b8-8435-beef3b4cf163",
//               "name": "ఎండుద్రాక్ష/Raisins ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:11:50.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 14
//             }
//           },
//           {
//             "id": "103a9fab-e387-4352-b1a0-80dd76965d98",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "0fb46a14-a0f4-4b00-8888-2985512f8ca0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "006db73d-9c35-481c-8281-9f119f9da334",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "0034e504-a972-46cd-b67a-0f3eafed8dc5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 16,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           }
//         ]
//       },
//       {
//         "id": 132,
//         "name": "Rudrabhishekam (Eka rudram)",
//         "description": "Rudras are the energies that have originated from Lord Shiva and control several aspects within our body.  There are a total of eleven prominent Rudras that govern the pranas and upapranas which are worshipped in Rudrabhishekam\n\nThe puja procedure given below is for Eka rudram (one recitation of namakam and chamakam) with regular offerings to the Shiva lingam such as panchamrutham.  \n\nDepending on the purpose and wish, the repetitions can vary.  Our pujari will accordingly make changes based on your requirement, after we receive your booking submission",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": "on_behalf",
//         "mode3": null,
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/DJpFNFqfngU\",\"https://www.youtube.com/embed/egsORT-tkqk\",\"https://www.youtube.com/embed/ku6Op_DJgDo\",\"https://www.youtube.com/embed/pArLQEQyECA\",\"https://www.youtube.com/embed/Yf5vE75JAUg\"]",
//         "images": "[\"/uploads/2023/07/Rudrabhishekam-Right-Size.png\"]",
//         "updatedAt": "2023-07-24T01:34:37.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-06-02T05:15:35.000Z",
//         "createdById": null,
//         "updatedById": "44",
//         "pujacategorymappings": [
//           {
//             "id": 19,
//             "pujaId": 132,
//             "categoryId": 2,
//             "subCategoryId": 4
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 82,
//             "pujaId": 132,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 6999,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 3000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-20T12:04:06.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-02T05:18:06.000Z",
//             "createdById": null,
//             "updatedById": "44",
//             "serviceFee": 677,
//             "samagriCost": 1521,
//             "procedures": []
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "fe9f9e70-e1c7-40cc-b99d-0f8fcb030d76",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "f79d1977-c38e-4813-a9cc-c05d1d621bfb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "f6b56cf2-f256-41ad-8388-1cd4c4a97d07",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "f11a07d4-c021-46b5-94cf-375244c3b7e6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "ec0606ac-9760-4732-8778-7f6b6d9c797e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "df52dbdb-2d21-4675-9a8f-d6b035a3bcc7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "ddb8efc5-1e65-4d42-95c7-d1fa2b450c4d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "db4f7fb2-ca06-4702-9df5-5b7e040f2f0e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//             "samagri": {
//               "id": "92da20c4-a10e-4013-9364-f5114438d904",
//               "name": "వీడి పూలు / Loose Flowers",
//               "description": null,
//               "units": "gms",
//               "standardQty": "250",
//               "costPrice": 110,
//               "pujariSellingPrice": 120,
//               "customerMrp": 120,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:16:53.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:44:42.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 176
//             }
//           },
//           {
//             "id": "d9d04849-a371-42d8-8475-48cb0c92f3d7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "d57b983b-44d2-4514-87d6-e924ccef244b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 2,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "d0f66847-b34e-47a0-b7a2-75177181f1d8",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           },
//           {
//             "id": "ceb29804-08b0-4348-85b0-9b184a76d658",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "cd9003dc-e302-4c82-aa82-41a2974b663f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "ca88f049-692a-4db6-93ba-08e83c8d713a",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "b6fb3968-5e9f-4eae-a75e-b1f772abc1a1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 16,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "b3074ddb-c626-4a1a-a56a-9d8a9f5d8659",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "aca46391-8fce-41e3-84f2-84d0a483e2e1",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "a5e63f73-b9a2-41c5-8b2d-709f4346cf35",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "a0240e8c-1685-4eb6-b4d1-2ca468ae7478",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "99651137-966b-4e45-8d43-8d911a1a723b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           },
//           {
//             "id": "951e91a8-c832-430b-b69e-9482d12d672a",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "91fb9278-0495-4c58-8a76-70bd748d285e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "8ca8a391-e884-49d7-a2fc-2a3a6ce2a3bb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "7a639953-efe4-464b-b120-8501fc264dc9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "6e82f2bf-7881-47f3-a009-017461eee5df",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "66643472-61bd-462c-b760-e1a1e436d335",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//             "samagri": {
//               "id": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//               "name": "నీళ్ల బిందెలు / Water Pots",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:33:20.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 248
//             }
//           },
//           {
//             "id": "6650f68a-b61c-42e1-9527-c8230db47413",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "samagri": {
//               "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//               "name": "అరటి పండ్లు / Banana",
//               "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//               "units": "no",
//               "standardQty": "12",
//               "costPrice": 60,
//               "pujariSellingPrice": 70,
//               "customerMrp": 70,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-19T22:36:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:26:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 181
//             }
//           },
//           {
//             "id": "62d46984-6c62-48a6-a362-a846a183af10",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "5f951510-eb7a-4504-8cbe-ca972775c92d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "samagri": {
//               "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//               "name": "గరిక/  Grass",
//               "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 20,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:31:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:11:34.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 183
//             }
//           },
//           {
//             "id": "5eca02b8-cb0f-4289-b429-1a71da1462ed",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "71cf7fc1-7b23-44d9-a265-c673cc1d5f8b",
//             "samagri": {
//               "id": "71cf7fc1-7b23-44d9-a265-c673cc1d5f8b",
//               "name": "Fruits and Flowers ( Rudrabhishekam)",
//               "description": "మారేడు  దళాలు - 30 దళాలు, కొబ్బరి   నీళ్లు - 1 లీటర్ ,   5 రకాలు ఈచ్  2,  వీడి పూలు -1/4 kg\n,పూల మూరలు -2",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 750,
//               "pujariSellingPrice": 775,
//               "customerMrp": 775,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:13:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-09T05:18:53.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 218
//             }
//           },
//           {
//             "id": "5356c1dc-004a-4ee4-bc9a-e7665828a94e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "1a564751-0ae5-42de-821a-e1e3afe41741",
//             "samagri": {
//               "id": "1a564751-0ae5-42de-821a-e1e3afe41741",
//               "name": "కత్తెర / Scissor",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 150,
//               "pujariSellingPrice": 170,
//               "customerMrp": 170,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T06:13:28.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-21T06:13:28.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 167
//             }
//           },
//           {
//             "id": "4e5aff47-72d7-42c7-b11c-94fe83574b8b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "4c7c9bbb-aba1-4d57-8e82-eabba77ff1f6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "4c23254d-2243-4bd2-aa3d-41cf3860a730",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "39a878a5-315f-4f8d-8c2f-e995d33dd1ab",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "64d18991-1089-470e-adff-d0567d3e8b1e",
//             "samagri": {
//               "id": "64d18991-1089-470e-adff-d0567d3e8b1e",
//               "name": "వట్టివేళ్ళు / Khas Khas grass",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:32:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 104
//             }
//           },
//           {
//             "id": "2f0ba153-8b02-47a1-b3d7-5fea71a3026a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "2c01bf2b-26d1-4fa7-b700-51b8c6b5b3d6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "26229c15-1c00-43c7-b7ae-fe113c7aa3f5",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "14b09ec7-0688-427d-9aef-57e215744c6e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "12eb0433-1f04-47d1-98fc-bebd19aa4b7f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "0c2859b9-2af0-4075-a59c-a97e526a7625",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "samagri": {
//               "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//               "name": "చెంబులు/Kalasam",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:26.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:39:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 253
//             }
//           },
//           {
//             "id": "06ce2ed9-8e13-45f4-ac95-457b0248c2f0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "samagri": {
//               "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//               "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 8,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:40:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 33
//             }
//           },
//           {
//             "id": "050cdde2-6691-4d1b-86b2-ee18941d9d76",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 132,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           }
//         ]
//       },
//       {
//         "id": 149,
//         "name": "Ayushya Homam ",
//         "description": "Ayushya means lifespan in Sanskrit. Ayush homam is the worship of Ayur Devatha (God of life). By performing this ayush homam, one is blessed with good health and long life. As mentioned in Bodhayana sutras, if a child is falling ill often, this homam could be performed regularly until the child gets better",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": "on_behalf",
//         "mode3": "remote",
//         "mode4": null,
//         "videos": "undefined",
//         "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//         "updatedAt": "2023-08-11T04:27:40.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-06-16T00:56:13.000Z",
//         "createdById": null,
//         "updatedById": "9",
//         "pujacategorymappings": [
//           {
//             "id": 27,
//             "pujaId": 149,
//             "categoryId": 2,
//             "subCategoryId": 7
//           },
//           {
//             "id": 23,
//             "pujaId": 149,
//             "categoryId": 2,
//             "subCategoryId": 4
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 84,
//             "pujaId": 149,
//             "durationHrs": 5,
//             "noOfPujaris": 2,
//             "modelSellingPrice": 19999,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 10500,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-28T03:15:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-23T11:56:39.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 1715,
//             "samagriCost": 4733,
//             "procedures": [
//               {
//                 "id": "cbf771d1-c80c-488c-a797-04f9a0ba0def",
//                 "modelId": 84,
//                 "procedureId": 46,
//                 "procedure": {
//                   "id": 46,
//                   "language": "telugu",
//                   "title": "Ayushya Homam",
//                   "description": "The pradhana ayur devatha is offered havissu along with the other devatas that were invited during the kalasa sthapana and mandaparadhana.   Ayusha suktham and ayur devatha mantram are utilised while performing the homam.  Maha mrutyunjaya, saptha chiranjeevulu, dhanvantari are also considered as prominent during the procedure.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "9f6392bf-5526-4b42-9ee7-ae191bed78ce",
//                 "modelId": 84,
//                 "procedureId": 39,
//                 "procedure": {
//                   "id": 39,
//                   "language": "telugu",
//                   "title": "Plate of rice with gold ring wrapped with darbha",
//                   "description": "As Darbham grass absorbs radiation from the atmosphere, it is also used to cover all food items during eclipse time, to protect them. Darbham is also an effective conductor of sound vibrations, which makes it suitable to absorb and transfer Vedic chanting vibrations.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "a5446ff6-f7cf-4fcc-88ba-d0bea9424a0c",
//                 "modelId": 84,
//                 "procedureId": 37,
//                 "procedure": {
//                   "id": 37,
//                   "language": "telugu",
//                   "title": "Ash Gourd break / Gummadikaya kottadam",
//                   "description": "As the vegetable called Ash gourd is very high in life energy, it is broken in front of the door.\nAnother ash gourd (a full one, not damaged) is sometimes hung at the entrance after puja and replaced regularly. This wards off any Nara drishti doshas and keeps positive energies flowing into the house.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "f96a2ef0-5629-4ead-ac3a-fea70190e30c",
//                 "modelId": 84,
//                 "procedureId": 31,
//                 "procedure": {
//                   "id": 31,
//                   "language": "telugu",
//                   "title": "Govu puja / Govu pravesham",
//                   "description": "The reason for making a cow enter a new house before the family members is to resolve any vastu doshas, if present in the house. Since, it is believed that, every aspect of a cow is associated with a deity, the cow helps bring peace and prosperity in the house.",
//                   "specialNotes": "If you can accommodate and wish to have an actual cow enter your space, please mention the same in special notes or to our representative.  We will verify if it is safe and convenient to gomatha and arrange for the same."
//                 }
//               },
//               {
//                 "id": "136cf03c-331b-40d3-9fa4-0cfdaf26171a",
//                 "modelId": 84,
//                 "procedureId": 22,
//                 "procedure": {
//                   "id": 22,
//                   "language": "telugu",
//                   "title": "Abhishekam (Panchamrutalu)",
//                   "description": "Abhishekam can be seen as watering the roots of a tree. Only if the roots of a tree are nourished, it can produce fruits. During an Abhishekam, the results that we desire to see in this world are like the fruits of a tree, and the divine beings who influence these results are like roots. Since, everything in this universe is interconnected, we perform Abhishekam to see specific results in the world.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "d5102486-feb6-4ab8-9384-43dd48ddb50b",
//                 "modelId": 84,
//                 "procedureId": 17,
//                 "procedure": {
//                   "id": 17,
//                   "language": "telugu",
//                   "title": "Mandapam",
//                   "description": "A devotee can worship his/ her Ishta devata, to help them achieve their wishes and to eventually move onto Moksha. Since, It is not easy to concentrate on the formless, we invite the devata to descend into a form. A devata is pure consciousness, who does not have a form. Just like water, the devata can be invited to take the form of the vessel, known as an Upadhi. It is like freezing water into an ice-block of desired shape.  Mandapam is a suitable place that is prepared prior to starting the puja where devatas can be invoked and concecrated until the puja ends. ",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "f49abdbb-433e-4093-a6ca-1eb14899d83b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "samagri": {
//               "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//               "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 500,
//               "pujariSellingPrice": 600,
//               "customerMrp": 600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:43:18.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 114
//             }
//           },
//           {
//             "id": "f38ea173-42d1-4bad-8559-4bf0ad2bd07c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 40,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//             "samagri": {
//               "id": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//               "name": "నల్ల నువ్వులు/Black Sesame",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T02:08:36.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 42
//             }
//           },
//           {
//             "id": "ef531cb3-f4aa-4ed2-ab12-faa5868df008",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "ede0168e-15c6-4d6e-bfb6-7cba3cf8abe5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 35,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "samagri": {
//               "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//               "name": "ఇటుకలు/ BRICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 8,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:39:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:14:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 150
//             }
//           },
//           {
//             "id": "edcad959-ac33-41ad-a7cd-e6dcbbbb0bab",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           },
//           {
//             "id": "e5a3e593-5bf2-4fed-afc8-d88c44a00570",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "0031beaf-9312-467e-853a-b2243ce677f5",
//             "samagri": {
//               "id": "0031beaf-9312-467e-853a-b2243ce677f5",
//               "name": "గోమూత్రం/Cow Urine",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:17:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:26:29.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 231
//             }
//           },
//           {
//             "id": "e1f5c912-bb4d-489a-985b-936e6ee5d785",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "de24904d-8678-4b7d-afba-ed5593fa6aa5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//             "samagri": {
//               "id": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//               "name": "లవంగాలు / cloves",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:08:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 88
//             }
//           },
//           {
//             "id": "dd4d6214-b4cb-42df-be9b-f37f6da9fa7c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//             "samagri": {
//               "id": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//               "name": "పచ్చ కర్పూరం /  GREEN CAMPHOR",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 10,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:22:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 99
//             }
//           },
//           {
//             "id": "d8c5c632-f555-42a1-8832-6b2e6e7ec5b6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "samagri": {
//               "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//               "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:38:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 115
//             }
//           },
//           {
//             "id": "d7b407d7-edfd-48c5-b89c-9070921ea312",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "d6a260a4-4eaf-4de1-b514-3eb46adb7a27",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "d4b37797-bbd0-4dbd-ae5b-3fb2ce671815",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "d3e7783c-8ea9-4324-ab28-bb14f509c1c4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "d3da642a-5963-489e-9ed3-91d1475e0a77",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "d13f1fbd-813c-49b9-8875-4c3ba428487c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "d0304bb4-679a-4d71-b1f0-f187fbd7105a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "c26e726b-a69a-46f1-ba97-873ec775ddbe",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "samagri": {
//               "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//               "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 8,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:40:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 33
//             }
//           },
//           {
//             "id": "be81a535-c933-4651-8d66-8faa49447510",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "samagri": {
//               "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//               "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 18,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:01:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 48
//             }
//           },
//           {
//             "id": "b5ee5b38-541c-49d7-b51c-5801728e5f7d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "aba90d1f-8174-4d98-8264-82cae743b497",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "a94a4c39-53b7-4b15-bd71-36f704467359",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "a7b0fc14-9c5f-4d2e-abc4-08e724121b90",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 15,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "a76e0fce-23da-48ad-89a5-f67b4ad87fda",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "samagri": {
//               "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//               "name": "ఇసుక / SAND",
//               "description": "As Gampallu",
//               "units": "other",
//               "standardQty": "1",
//               "costPrice": 100,
//               "pujariSellingPrice": 125,
//               "customerMrp": 125,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:28:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:16:43.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 151
//             }
//           },
//           {
//             "id": "a1594a5b-33fa-45cf-82b2-dd1cab71af80",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "samagri": {
//               "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//               "name": "దర్భ కట్టలు/Kusha Grass",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T06:06:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 53
//             }
//           },
//           {
//             "id": "9ee8508c-f632-4ad6-8094-d46bbf5c781b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "9b45e8ba-f580-4848-ac43-e93a8da7b2e1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "9586933f-bf85-4e54-bef5-9f07bdc5066a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "f3809443-befe-4c75-a784-4f1cf7527d51",
//             "samagri": {
//               "id": "f3809443-befe-4c75-a784-4f1cf7527d51",
//               "name": "యజ్ఞోపవేతం వడికిన /Sacred thread",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 45,
//               "customerMrp": 45,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:25:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 52
//             }
//           },
//           {
//             "id": "9499587e-130f-4b01-83b9-8900dde47911",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "8ad357a6-83b0-43e0-acf5-b7a6c7a522cd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "8a00ddbb-fd74-43bc-8933-13baad1d599e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "8841250a-604f-479b-b5b2-7fd79b24b1e7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//             "samagri": {
//               "id": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//               "name": "జాజి కయ/Nutmeg",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 22,
//               "pujariSellingPrice": 30,
//               "customerMrp": 30,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:03:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 50
//             }
//           },
//           {
//             "id": "84bcc252-6e73-4352-95fd-d56d8058ab0e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 11,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "828ed7ee-dc57-4c2c-bd5b-cdf42eee0d41",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "samagri": {
//               "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//               "name": "గరిక/  Grass",
//               "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 20,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:31:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:11:34.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 183
//             }
//           },
//           {
//             "id": "7d58bc9d-4c39-4f9b-8741-caca5802b674",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "7b92df8f-d80a-4da1-864d-cd30a216e2ef",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "58f64511-aebf-4e90-b1ca-0c398343d723",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "5765cc8a-9020-4056-93f9-8bff4bf1ed52",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "54e72aaf-614f-4f2e-a149-c1365198250d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "50af142e-3442-48a7-898a-b17b787181bf",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "42552b53-47d7-494b-9212-b2f7f92f2daa",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "39fc0e0b-4089-4198-8782-7a051d969ce4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//             "samagri": {
//               "id": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//               "name": "యాలకులు / Cardamom",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 10,
//               "pujariSellingPrice": 24,
//               "customerMrp": 24,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:07:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 87
//             }
//           },
//           {
//             "id": "2f751660-799a-48e0-8341-fd1e4083cb64",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "2f556b37-f8a9-48a4-88ce-6613c7bb431d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "2dfcdbad-5b3e-490c-9cbd-3a041a232645",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "2dd1b7a7-6957-4677-b610-f3d5a9fdbab8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//             "samagri": {
//               "id": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//               "name": "గోమయం/Cow Dung",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-13T06:33:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:27:00.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 230
//             }
//           },
//           {
//             "id": "1f874d0a-4652-4c54-9b84-df12a12ba39e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "1c9121e1-87e5-4a3d-9ea6-a988591c36e9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "samagri": {
//               "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//               "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 230,
//               "pujariSellingPrice": 270,
//               "customerMrp": 270,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:31:17.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 23
//             }
//           },
//           {
//             "id": "19a5b6d4-cfbd-45fb-a143-d357422cc379",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "14730a63-5f68-4e15-bd5c-2728ac9ac5a1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//             "samagri": {
//               "id": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//               "name": "జాపత్రి/Nutmeg Mace",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 54,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:04:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 51
//             }
//           },
//           {
//             "id": "12943569-d426-4b4c-88ba-df9ac45ea1a0",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "0bb93dc3-1159-4f63-8eae-b9376e84154f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "c0dfeb27-c9b4-4965-9cbe-9175cbff8e5c",
//             "samagri": {
//               "id": "c0dfeb27-c9b4-4965-9cbe-9175cbff8e5c",
//               "name": "Fruits & Flowers / పూలు మరియు పండ్లు  ( Ayushya Homam)",
//               "description": "విడి పూలు - 1/4 kg,  పూల మూరలు - 3, మారేడు దళాలు - 20 g, అరటి పండ్లు - 1 dozen, 4types of fruits each 2\n ",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 560,
//               "pujariSellingPrice": 672,
//               "customerMrp": 672,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-25T06:59:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-17T08:08:30.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 268
//             }
//           },
//           {
//             "id": "075e3a7b-40e0-4b52-8d35-ea3afd9e3cf4",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "06f450db-6405-4948-930d-b642ccba3468",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "02d6a08c-81bc-4828-b229-38f4058b477a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "0106ba7b-d79f-46bc-b78b-92835b72fe4d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 149,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           }
//         ]
//       },
//       {
//         "id": 135,
//         "name": "Navagraha Homam",
//         "description": "The term Navagraha denotes the nine celestial bodies that form the base for astrological calculations. Nava means 'Nine' and Graha means 'energy', 'force' or 'controller' so Navagraha Pooja is a prayer (ritual) performed to energies according to the nine forces.\n\nThese nine devatas govern important aspects in our life that make us experience joy or sadness based on our karma.  The real goal of any navagraha puja or astrology related pujas is to come out of problems that are really bothering us and to ultimately start our journey towards eeshwara so that we won't need such pujas in the future\n\nIf you are advised to perform this homam or any other astrological related shanti, japa etc. and if you are not sure whether you need to, do speak to us.  Our pujaris will analyse and advice you if you really need this puja OR if you can solve your issue in other ways, which only require your investment of time and shraddha.",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": "on_behalf",
//         "mode3": "remote",
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/YT3nFDlrnuw\",\"https://www.youtube.com/embed/r6YyE-Wj9kU\"]",
//         "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//         "updatedAt": "2023-07-24T01:32:24.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-06-07T05:45:46.000Z",
//         "createdById": null,
//         "updatedById": "44",
//         "pujacategorymappings": [
//           {
//             "id": 24,
//             "pujaId": 135,
//             "categoryId": 2,
//             "subCategoryId": 3
//           },
//           {
//             "id": 25,
//             "pujaId": 135,
//             "categoryId": 2,
//             "subCategoryId": 6
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 98,
//             "pujaId": 135,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 23999,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 11000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-20T12:58:36.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-26T05:19:46.000Z",
//             "createdById": null,
//             "updatedById": "44",
//             "serviceFee": 2753,
//             "samagriCost": null,
//             "procedures": [
//               {
//                 "id": "5c3b4ea4-4140-4954-bcd3-61fa54c59c51",
//                 "modelId": 98,
//                 "procedureId": 8,
//                 "procedure": {
//                   "id": 8,
//                   "language": "telugu",
//                   "title": "Mandaparadhana",
//                   "description": "Aavahanam of navagraha, ashtadikpalaka, panchalokapalaka, trimurthulu, ishta devata, kula devata, and nakshatra devatalu.",
//                   "specialNotes": "testing 8888"
//                 }
//               },
//               {
//                 "id": "a82ec0a1-901d-4400-bec6-a962d6896ab4",
//                 "modelId": 98,
//                 "procedureId": 17,
//                 "procedure": {
//                   "id": 17,
//                   "language": "telugu",
//                   "title": "Mandapam",
//                   "description": "A devotee can worship his/ her Ishta devata, to help them achieve their wishes and to eventually move onto Moksha. Since, It is not easy to concentrate on the formless, we invite the devata to descend into a form. A devata is pure consciousness, who does not have a form. Just like water, the devata can be invited to take the form of the vessel, known as an Upadhi. It is like freezing water into an ice-block of desired shape.  Mandapam is a suitable place that is prepared prior to starting the puja where devatas can be invoked and concecrated until the puja ends. ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "716d5e56-83ef-4ed8-80e5-e4f1f74170fc",
//                 "modelId": 98,
//                 "procedureId": 23,
//                 "procedure": {
//                   "id": 23,
//                   "language": "telugu",
//                   "title": "Archana ",
//                   "description": "During Archana, we offer flowers to a deity while chanting mantras or names of the deity. The flowers that we offer have a fragrance. Another name for fragrance is vasana(tendency). Therefore, offering flowers to a deity represents offering all our vasanas to the deity. And, while we let go of our vasanas, we chant the names of the deity, to connect with the divine.\n\nSometimes archana is performed with other dhravya like leaves that are most suitable for that particular devata. For e.g. tulsi, bilva etc. ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "687db513-54c8-4e41-a347-9b06e066f27a",
//                 "modelId": 98,
//                 "procedureId": 14,
//                 "procedure": {
//                   "id": 14,
//                   "language": "telugu",
//                   "title": "Aashirvachanam",
//                   "description": "The guru aashirvachanam is the biggest blessing for the devotee or the family performing the puja.  Guru is eeshwara's form in front of us. His tapasya and veda parayana, nithya gayatri japa etc. mean that his blessings can lead to god's grace.",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "2840b60a-de77-4886-89a4-e97d21fcc4f7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "2689e211-baad-4073-87ff-993e5f349815",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 100,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "26873da3-1482-4847-8d47-f327db01d3e6",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "232bd700-0534-4a1b-8fcc-416d21abe2d7",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//             "samagri": {
//               "id": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//               "name": "నీళ్ల బిందెలు / Water Pots",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:33:20.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 248
//             }
//           },
//           {
//             "id": "1d560177-11e9-4b53-a3c2-61451e02495c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "672d4bf0-7711-41d2-9f23-1c72d79d80fc",
//             "samagri": {
//               "id": "672d4bf0-7711-41d2-9f23-1c72d79d80fc",
//               "name": "సెనగలు /  Chick pea",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:44:36.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 68
//             }
//           },
//           {
//             "id": "1c85d47a-62f9-498a-9aec-60035dbceb1d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "130e0a5e-4866-42ba-93e9-153c38556ead",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "addon",
//             "samagriId": "7526e2c0-07c4-4a31-8482-85153766f5b4",
//             "samagri": {
//               "id": "7526e2c0-07c4-4a31-8482-85153766f5b4",
//               "name": "మర్రి కొమ్మ/ Banyan branch",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 75,
//               "pujariSellingPrice": 85,
//               "customerMrp": 85,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T02:57:11.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:42:23.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 224
//             }
//           },
//           {
//             "id": "0bf415db-e567-40ac-94bd-e15b0647ef96",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "samagri": {
//               "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//               "name": "చెంబులు/Kalasam",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:26.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:39:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 253
//             }
//           },
//           {
//             "id": "054ff985-8a38-4bcc-8c7e-012685de1520",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "addon",
//             "samagriId": "a6466242-2a96-4098-97e9-022996693e06",
//             "samagri": {
//               "id": "a6466242-2a96-4098-97e9-022996693e06",
//               "name": "మేడి కొమ్మ / Medi Branch ",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 80,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-18T02:55:46.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:45:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 222
//             }
//           },
//           {
//             "id": "053ed3cc-2bfd-4cae-b100-ea9691b09bae",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "samagri": {
//               "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//               "name": "వరిపెళాలు/Rice Fields",
//               "description": null,
//               "units": "gms",
//               "standardQty": "50",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:33:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 58
//             }
//           },
//           {
//             "id": "0308945e-2f92-4bfe-9e34-84115f4910cb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "01185ccc-d74a-44d7-852d-08f95167cba9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "489060d1-f4de-4888-9b68-d3831c57179e",
//             "samagri": {
//               "id": "489060d1-f4de-4888-9b68-d3831c57179e",
//               "name": "వడ్లు / Paddy Seeds",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:50:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 71
//             }
//           },
//           {
//             "id": "002a1430-5578-4d6f-8b87-9a58fd2c2e6a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "18916846-5b89-49d2-a9f3-e0359cbbe70c",
//             "samagri": {
//               "id": "18916846-5b89-49d2-a9f3-e0359cbbe70c",
//               "name": "మూకుళ్ళు / Mukudu",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 30,
//               "customerMrp": 30,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:13:20.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 93
//             }
//           },
//           {
//             "id": "fe2c5c71-8992-43dc-8083-8d80c9b8768d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "26b085af-563e-4118-bcd3-933b1ef6856b",
//             "samagri": {
//               "id": "26b085af-563e-4118-bcd3-933b1ef6856b",
//               "name": "ఉలవలు / Horsegram",
//               "description": null,
//               "units": "gms",
//               "standardQty": "250",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:59:36.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 74
//             }
//           },
//           {
//             "id": "fa570831-eae3-45d3-88a6-18525497d962",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "73525e4a-856a-4fe1-9a8b-ca899577013a",
//             "samagri": {
//               "id": "73525e4a-856a-4fe1-9a8b-ca899577013a",
//               "name": "నీలం రంగు బ్లౌజ్ ముక్క/Blue Color Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:01:05.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:23:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 236
//             }
//           },
//           {
//             "id": "f18c6858-43f3-4b6f-a497-6cd50a225386",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "efb93055-b04e-4b4b-9e7b-5693322cc6f6",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "e65b8c6b-c65c-4e2f-899b-5374ad04dc4e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 40,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "samagri": {
//               "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//               "name": "ఇటుకలు/ BRICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 8,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:39:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:14:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 150
//             }
//           },
//           {
//             "id": "e6002e9a-8cb8-4eff-baf2-776e728806f7",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "e2525210-d347-4124-89a1-cab5968bf1dd",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "dcf8e548-59bd-42c2-85c2-c5f0afc31640",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "dcf6798e-1d3c-437d-b617-6a727439199e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "dcb8c481-c70e-4247-8f5a-d2035ed53ed2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "cb64c2ba-1f34-461e-b0c1-0d719d7701a5",
//             "samagri": {
//               "id": "cb64c2ba-1f34-461e-b0c1-0d719d7701a5",
//               "name": "తెలుపు రంగు బ్లౌజ్ ముక్క/White Color Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T00:54:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:18:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 233
//             }
//           },
//           {
//             "id": "dc311da2-a513-4813-9e66-680c9d290030",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "d651b315-4932-45e7-a475-4bad2ecdfbd3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "cf22917f-899d-4f20-b1e1-5af557d43de9",
//             "samagri": {
//               "id": "cf22917f-899d-4f20-b1e1-5af557d43de9",
//               "name": "పెసలు / Moong Bean",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:45:31.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 69
//             }
//           },
//           {
//             "id": "ce73386a-b83d-4135-8bc0-e94c950bb163",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "cd872324-3f88-4b13-9165-9fc751aafc78",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "samagri": {
//               "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//               "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 500,
//               "pujariSellingPrice": 600,
//               "customerMrp": 600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:43:18.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 114
//             }
//           },
//           {
//             "id": "cbdc615c-143d-4485-8a55-014e7467a1c4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "ca20d952-ea92-4742-b644-0c1f59ebc95c",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "c985a4d4-5da3-457f-89b6-a7ba7585f5b9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "addon",
//             "samagriId": "31ef9c71-5019-488c-a9e8-311d0c264d71",
//             "samagri": {
//               "id": "31ef9c71-5019-488c-a9e8-311d0c264d71",
//               "name": "జువ్వి కొమ్మ/Juvvi branch",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 70,
//               "pujariSellingPrice": 85,
//               "customerMrp": 85,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T02:55:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:48:48.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 221
//             }
//           },
//           {
//             "id": "c788fab5-d7bf-430a-9e1d-f6060274b92c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//             "samagri": {
//               "id": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//               "name": "గుమ్మకాయడి/ PUMPKIN",
//               "description": "1",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 140,
//               "pujariSellingPrice": 150,
//               "customerMrp": 150,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:50:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:14:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 191
//             }
//           },
//           {
//             "id": "c779f6f1-7366-4daa-8be9-927793b0ad53",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "d3376577-7d65-41e5-aa45-aede75fc6d32",
//             "samagri": {
//               "id": "d3376577-7d65-41e5-aa45-aede75fc6d32",
//               "name": "డిజైన్ క్లాత్/Design Cloth",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 100,
//               "pujariSellingPrice": 125,
//               "customerMrp": 125,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:26:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:25:07.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 239
//             }
//           },
//           {
//             "id": "c256eb62-6805-423a-8ebf-6460735abc5a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "c00c1de1-64b9-43f2-a1f5-b99941c2b155",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "acdf7c21-6c25-4a96-87e8-272d55b63eca",
//             "samagri": {
//               "id": "acdf7c21-6c25-4a96-87e8-272d55b63eca",
//               "name": "కందులు / Toor dal",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 4,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:46:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 70
//             }
//           },
//           {
//             "id": "bf87a278-d1c2-445b-b965-b66852be7526",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "bf3cf46c-81a5-4f1c-a58b-5d8645401a4c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "b208a753-a8d5-407e-9c70-16ef70bbd925",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "aa57f753-2696-4547-b861-469bae0fd11d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "samagri": {
//               "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//               "name": "గరిక/  Grass",
//               "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 20,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:31:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:11:34.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 183
//             }
//           },
//           {
//             "id": "a8ab33f2-190f-4097-b877-311f31ef0352",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "1f329789-c301-4476-ae69-60822c13f389",
//             "samagri": {
//               "id": "1f329789-c301-4476-ae69-60822c13f389",
//               "name": "బూడిద రంగు బ్లౌజ్ ముక్క/Ash Color Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:03:11.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:23:50.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 238
//             }
//           },
//           {
//             "id": "9b0a04a2-bbbd-4040-8577-be99d204e0b3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "addon",
//             "samagriId": "f777870d-f64a-46ba-8817-210a3f31a8da",
//             "samagri": {
//               "id": "f777870d-f64a-46ba-8817-210a3f31a8da",
//               "name": " రావి కొమ్మ/ Peepal Branch",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T02:56:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:46:31.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 223
//             }
//           },
//           {
//             "id": "99901753-bf8e-4e2f-b191-2a8976d20ef0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "21c610ea-d3bb-48ed-b983-d94ddbf3c9b9",
//             "samagri": {
//               "id": "21c610ea-d3bb-48ed-b983-d94ddbf3c9b9",
//               "name": "పసుపచ్చ రంగు  బ్లౌజ్ ముక్క/Yellow Color Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T00:59:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:21:03.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 234
//             }
//           },
//           {
//             "id": "9550ec5c-270e-4988-82ec-f712efd6d4a8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "addon",
//             "samagriId": "2de30e9f-ed41-44a1-a9fe-242718388946",
//             "samagri": {
//               "id": "2de30e9f-ed41-44a1-a9fe-242718388946",
//               "name": "Fruits & Flowers ( Navagraha Homam)",
//               "description": "వీడి పూలు( 2 Types Mix) - 1 kg ,        పూల మూరలు-(2)  ,   ఆరటి పండ్లు -1Dozen ,      ఫ్రూప్ట్స్ 5 types  5 Each\n\n\n",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 1550,
//               "pujariSellingPrice": 1600,
//               "customerMrp": 1600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T04:43:54.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-09T02:32:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 148
//             }
//           },
//           {
//             "id": "94dd2f18-5097-49a1-b288-9c524ee33d85",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "00416a9a-4864-4758-a471-f915ab3c63e7",
//             "samagri": {
//               "id": "00416a9a-4864-4758-a471-f915ab3c63e7",
//               "name": "ముద్దకర్పూరం / Big Chunk Camphor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 38,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:40:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 113
//             }
//           },
//           {
//             "id": "9040f0c0-05a3-4680-8716-de6f58b7d796",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "8cc84d97-5ab3-4729-9701-3825d5c2e4af",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "8a2bf08f-7176-492d-b9bd-cfcf453cfe23",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "eadf2e11-db74-410e-9f2b-fe2c0abea8dd",
//             "samagri": {
//               "id": "eadf2e11-db74-410e-9f2b-fe2c0abea8dd",
//               "name": "ఆకుపచ్చ రంగు బ్లౌజ్ ముక్క/Green Color Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T00:54:31.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:20:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 232
//             }
//           },
//           {
//             "id": "89afe3b8-e60e-4af0-8a6c-5ac98c6b4aae",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "samagri": {
//               "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//               "name": "ఇసుక / SAND",
//               "description": "As Gampallu",
//               "units": "other",
//               "standardQty": "1",
//               "costPrice": 100,
//               "pujariSellingPrice": 125,
//               "customerMrp": 125,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:28:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:16:43.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 151
//             }
//           },
//           {
//             "id": "89214bd5-bc95-4267-b65b-ffd52c2bb9f5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "85c42b25-5839-455e-8838-5a78a3564385",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "846f0813-aebe-46cf-ab56-0d50be96fb40",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "7043905d-685a-4121-bee3-4c81d4101cfe",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "6ffa3cc7-0af9-4115-b909-2dbaccef75a4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "6ba0efe5-e568-4931-a3e8-b55f66fdd977",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "b76071d0-2635-472c-a09a-3862729f2885",
//             "samagri": {
//               "id": "b76071d0-2635-472c-a09a-3862729f2885",
//               "name": "ఎరుపు రంగు బ్లౌజ్ ముక్క/ Red Color Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T00:59:28.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:17:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 235
//             }
//           },
//           {
//             "id": "6a8bf682-7452-479a-923e-b1c0caaa458e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "66dea59f-7442-4b61-b8d3-721f03071207",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 100,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "63304fdc-ce9a-4766-b1b7-259550f52c38",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "samagri": {
//               "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//               "name": "దర్భ కట్టలు/Kusha Grass",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T06:06:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 53
//             }
//           },
//           {
//             "id": "5e03b443-3992-42f4-b9aa-d21735e21964",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "5cb2536d-72c9-49ec-ab9c-c5cf665301af",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "5b289f0a-ca79-45ed-903c-ff5cca29a40a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "4836d741-21a8-4974-adb2-48db7a4b0bc6",
//             "samagri": {
//               "id": "4836d741-21a8-4974-adb2-48db7a4b0bc6",
//               "name": "బొబ్బర్లు / Black eyed beans",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:57:28.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 72
//             }
//           },
//           {
//             "id": "59d71eeb-8ccf-40b6-84f6-c94d11e8bea6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "fc843717-569b-46f3-8a8c-952669a13bf0",
//             "samagri": {
//               "id": "fc843717-569b-46f3-8a8c-952669a13bf0",
//               "name": "మార్వాడి దారం / Marwadi Thread",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 40,
//               "customerMrp": 40,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 79
//             }
//           },
//           {
//             "id": "561f0677-6f2e-44ca-a3e6-8b897c03ac25",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "52972363-0cd1-4d2d-a656-0f61a794b46b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "4d313536-399c-49f7-bfa4-45bcfb201b65",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "samagri": {
//               "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//               "name": "అరటి పండ్లు / Banana",
//               "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//               "units": "no",
//               "standardQty": "12",
//               "costPrice": 60,
//               "pujariSellingPrice": 70,
//               "customerMrp": 70,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-19T22:36:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:26:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 181
//             }
//           },
//           {
//             "id": "488c3c76-5fa9-4bc4-80e9-cccd01a48568",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//             "samagri": {
//               "id": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//               "name": "నల్ల నువ్వులు/Black Sesame",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T02:08:36.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 42
//             }
//           },
//           {
//             "id": "4183e516-10c1-4160-8ea4-f28d5dac01cd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "samagri": {
//               "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//               "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:38:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 115
//             }
//           },
//           {
//             "id": "3f7aec45-9741-44dd-9ff8-7e2e1e94ec59",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "3d894fa6-1a0b-4b7b-9e09-af5e22fbca77",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           },
//           {
//             "id": "373fefff-3789-4313-b128-2a57bc1c5239",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "363e301e-8ed4-4a20-bb0a-516ecb004129",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "0aa14bf1-02bc-4b1b-9d2f-6f54a2dc077d",
//             "samagri": {
//               "id": "0aa14bf1-02bc-4b1b-9d2f-6f54a2dc077d",
//               "name": "మినుములు / Black Gram",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:58:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 73
//             }
//           },
//           {
//             "id": "317ca902-ab22-4e2c-ba60-7462a510e7bd",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "2ff70cd2-5b97-4811-b471-980db89f491c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "2f23a767-1b63-4846-97f5-9bf55baa5143",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "2c19bd83-dc66-4c06-9d21-b84033ca883d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "8f20fa74-8221-479d-871e-3d989c54c446",
//             "samagri": {
//               "id": "8f20fa74-8221-479d-871e-3d989c54c446",
//               "name": "కాషాయం రంగు బ్లౌజ్ ముక్క/Amber Color Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:01:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:19:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 237
//             }
//           },
//           {
//             "id": "2acdfa82-47a7-4773-93b1-c40b8615a6ec",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "75c54993-415b-4c9c-b109-22c643a7f56b",
//             "samagri": {
//               "id": "75c54993-415b-4c9c-b109-22c643a7f56b",
//               "name": "గోధుమలు / Wheat",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-09T04:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 67
//             }
//           },
//           {
//             "id": "292ae1ac-2229-460b-8c6e-f48dd22a8051",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 135,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           }
//         ]
//       },
//       {
//         "id": 155,
//         "name": "Punyahavachanam",
//         "description": "Punyaham means auspicious, and vachanam means to recite. In this puja, specific mantras are recited which purify us and our surroundings and bring forth the auspiciousness required before performing a vedic action or any auspicious events. The mantra shakti is embedded into the kalasha jalam and leads to prosperity of the doer and their family tree",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": null,
//         "mode3": null,
//         "mode4": null,
//         "videos": "undefined",
//         "images": "[\"/uploads/2023/07/DSC01273-2.jpg\"]",
//         "updatedAt": "2023-07-16T02:26:27.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-07-08T06:36:56.000Z",
//         "createdById": "47",
//         "updatedById": "47",
//         "pujacategorymappings": [],
//         "pujamodels": [
//           {
//             "id": 103,
//             "pujaId": 155,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 6368,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 3000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-28T03:20:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T06:37:35.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 514,
//             "samagriCost": 1883,
//             "procedures": [
//               {
//                 "id": "f2347762-95e5-41fe-9795-187a7b2561bb",
//                 "modelId": 103,
//                 "procedureId": 46,
//                 "procedure": {
//                   "id": 46,
//                   "language": "telugu",
//                   "title": "Ayushya Homam",
//                   "description": "The pradhana ayur devatha is offered havissu along with the other devatas that were invited during the kalasa sthapana and mandaparadhana.   Ayusha suktham and ayur devatha mantram are utilised while performing the homam.  Maha mrutyunjaya, saptha chiranjeevulu, dhanvantari are also considered as prominent during the procedure.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "295042a5-ebcb-4fc9-afa0-d45947e81a20",
//                 "modelId": 103,
//                 "procedureId": 38,
//                 "procedure": {
//                   "id": 38,
//                   "language": "telugu",
//                   "title": "Breaking Coconut",
//                   "description": "By offering a coconut to the devata, we offer ourselves in the form of a coconut. First, the outer layer of a coconut called coir, is removed, because it symbolizes all our desires, likes, and dislikes. Next, only the hard shell remains between us and the devata. This hard shell represents our EGO, which we break at the feet of the devata. The remaining thing is our inner self symbolized by coconut water which spills at the devata's feet. On the coconut's hard shell, there are three hole-like marks. These three holes represent all that is three. This includes the body, mind, and intellect, the three Gunas, the three Kaalas, and the three states of consciousness. Therefore, breaking a coconut symbolizes not just breaking our Ego, but also transcending all the things in the category of three.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "d81f10a5-af77-4543-83e4-81a42a5bdc27",
//                 "modelId": 103,
//                 "procedureId": 36,
//                 "procedure": {
//                   "id": 36,
//                   "language": "telugu",
//                   "title": "Aada bidda - Gadapa Kadagadam",
//                   "description": "Most of the negative energy in the house, if any, is deposited on the frame of the main entrance door. Due to this, before entering the house, we apply yellow turmeric paste, red kumkum dots, and white lines to the door frame. White represents Saraswati, yellow represents Lakshmi, and red represents Gowri. The three mothers protect the family. At the entrance of the house, the lady of the house draws rangolis (geometric shapes conducive to positive energy), inviting goddess Lakshmi in her thoughts and emotion to visit the house.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "65c84968-1e46-4b80-b3a7-2b5578096326",
//                 "modelId": 103,
//                 "procedureId": 50,
//                 "procedure": {
//                   "id": 50,
//                   "language": "telugu",
//                   "title": "Satyanarayana Vratam",
//                   "description": "Full procedure of satyanarayana vratam once again reminding us the power of truth and need of honesty and ethics, in order to make this occasion successful",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "edc782ce-49ef-4703-8aa5-ebc1fd3c3051",
//                 "modelId": 103,
//                 "procedureId": 16,
//                 "procedure": {
//                   "id": 16,
//                   "language": "telugu",
//                   "title": "Ganapati Puja",
//                   "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "f69ab779-e61c-40e7-b99e-ec1727565614",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "f49ce39d-3013-440e-8406-9d9180a4e730",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "samagri": {
//               "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//               "name": "చెంబులు/Kalasam",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:26.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:39:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 253
//             }
//           },
//           {
//             "id": "f469e4cb-0bfd-45f3-b241-23912c89f478",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "389822bd-ca89-469c-bc0a-82694a7c8062",
//             "samagri": {
//               "id": "389822bd-ca89-469c-bc0a-82694a7c8062",
//               "name": "అష్ట గంధం/Ashtagandham",
//               "description": null,
//               "units": "gms",
//               "standardQty": "5",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:28:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 47
//             }
//           },
//           {
//             "id": "edf1d7dc-5805-4fbe-bc8d-665102cfed39",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "ea144d0f-a966-4312-a5ba-a19168ef36f2",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "e7c9de72-3179-4f95-ae32-7a55f3f09975",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 11,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "e68f8982-6ac6-43c8-9990-487fcf94668b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "e2923ed5-4e22-455c-9a96-d52d1d73604b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "e12f675a-b5c3-484b-ba99-a2922d6166c2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "db536a44-d1d3-4cf5-a50c-9ab8cc2bb98a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "samagri": {
//               "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//               "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 8,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:40:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 33
//             }
//           },
//           {
//             "id": "d3ff54b1-996b-44a2-9f0a-6dac74d464ed",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "cc250fbe-edab-44b2-bcba-ded0d3f0d1ba",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//             "samagri": {
//               "id": "92da20c4-a10e-4013-9364-f5114438d904",
//               "name": "వీడి పూలు / Loose Flowers",
//               "description": null,
//               "units": "gms",
//               "standardQty": "250",
//               "costPrice": 110,
//               "pujariSellingPrice": 120,
//               "customerMrp": 120,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:16:53.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:44:42.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 176
//             }
//           },
//           {
//             "id": "cab57ebf-ba2a-4dec-a73c-483a59e5e5e5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "c2dc3f3d-6000-40e1-a27d-d4b9b43afcfa",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "bb18054e-14ba-47cd-8596-0444a7a8ee4d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "b7dc2e40-6ff8-40aa-be9f-ee37a46551e8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "b3ba4ae3-6867-4758-b130-8880b720b27e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "b28e55e9-df79-463f-8bf9-bde8212d97e5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "b222e90d-82ea-4894-bc03-df5de9a599c4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "samagri": {
//               "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//               "name": "బెల్లం / Jaggery",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:23:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T01:22:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 143
//             }
//           },
//           {
//             "id": "a12e4f9e-4ab4-4cc8-8596-d2e3e1d3ab42",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "9fdb2aa5-dea6-4154-ae08-3b9bc938fbd6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "9d578285-b0eb-466b-8226-f93a149a5e1b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "9a55950a-b132-4ebe-990a-04bf31594783",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "98938340-44a7-4856-bd5d-ef0b5865030e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "9699e07f-63ad-492d-a639-47ba82c6b36f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "91de6a3c-4e64-43c1-afc8-a224be0394c9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "a844072d-3461-43c9-ae33-96c0668987af",
//             "samagri": {
//               "id": "a844072d-3461-43c9-ae33-96c0668987af",
//               "name": "ఇత్తడి గ్లాసు / Brass Glass (250ml)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 50,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:26:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 18
//             }
//           },
//           {
//             "id": "874f9244-2d18-46b7-ac4e-e0e23bca7fc8",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "7ee8ce8f-7c93-4d16-b334-052d33b166c4",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "7e2d96b9-a758-4647-a18a-46b8306c42e0",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "7c92f312-781b-4ffc-bec7-45dd605429fd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "726cf263-fafc-4b2c-b04e-38dc5d926a0c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "6e42068f-ce91-4c6b-b443-a32692c0be7c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "6d1d80bd-ed84-4138-b83e-e7a147b0eddc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "6a9fac8e-746f-4d87-a868-309f2a88ebdc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "65950ed9-7431-4ea8-98f9-115fa3649a27",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//             "samagri": {
//               "id": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//               "name": "గోమయం/Cow Dung",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-13T06:33:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:27:00.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 230
//             }
//           },
//           {
//             "id": "58597e49-01d2-40ba-8a16-9cc4a733a9d7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "55b44cb9-84df-4b7b-a683-76ca9b052d7e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "4e408bed-3051-4bfe-935e-5b72ac286e9c",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "4cebd13d-85f8-4a76-bdd5-db24ed961ea5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "f1e08e9d-b717-4135-bc74-db8376126649",
//             "samagri": {
//               "id": "f1e08e9d-b717-4135-bc74-db8376126649",
//               "name": "ఎండు కొబ్బరి ముక్కలు (Premium) / Dry coconut flakes (Premium)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 29,
//               "pujariSellingPrice": 38,
//               "customerMrp": 38,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:45:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:53:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 117
//             }
//           },
//           {
//             "id": "458ca3de-661b-4c59-a874-e177fe8cb2cd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "44cf0ae5-1cf1-4cc6-82f1-261672b5266c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//             "samagri": {
//               "id": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//               "name": "పూల దండ/ Flower Garland",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 200,
//               "pujariSellingPrice": 220,
//               "customerMrp": 220,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:31:38.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T06:47:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 228
//             }
//           },
//           {
//             "id": "44bb3afe-00e7-4843-bc0a-d7281dbcd21a",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "41752fac-f1db-43a3-a1c2-84d885346154",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "3b5a54ed-68d5-46b6-814d-e1c1a3ce1e43",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "3a370672-30f5-4874-9f72-965622ce9970",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "0031beaf-9312-467e-853a-b2243ce677f5",
//             "samagri": {
//               "id": "0031beaf-9312-467e-853a-b2243ce677f5",
//               "name": "గోమూత్రం/Cow Urine",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:17:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:26:29.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 231
//             }
//           },
//           {
//             "id": "3431473e-ebbf-41d0-a300-861660e681b9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "332dbc4f-b89f-492d-89d7-e643bde8eace",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "330a3db6-e066-4947-a588-b56a111f1ee5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "315dfcca-332e-49cb-ad4b-ed15da78af5c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "2f5f2f3b-09ce-4dff-abf9-1af88a4d19cf",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "1c0c719f-35c5-4876-addf-091e84ee50fe",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "addon",
//             "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "samagri": {
//               "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//               "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 210,
//               "pujariSellingPrice": 500,
//               "customerMrp": 500,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:26:10.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 17
//             }
//           },
//           {
//             "id": "1b7476f9-1f3c-4bf3-871f-362c7bb5c70f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "1b57450c-c0dd-44f2-9dd8-b69dae00c21c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "132361b8-8a0c-4aa9-9a4d-576e9c9f7448",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "0c1f9e4a-719f-4e65-9982-583fbe53ebe9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "088cbe60-b156-477a-ac74-8e62f110a689",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "01816e07-e00f-4bba-8b00-8945e757a0ad",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 155,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           }
//         ]
//       },
//       {
//         "id": 167,
//         "name": "Ganesha - For Navaratri Pandals (Corporate Offices / Community / Colony) - EcoFriendly Mud/Clay ",
//         "description": "Step 1: Select the Ganesha Idol of your choice by checking the photos . Remember the IDOL NUMBER\n\nStep 2:  Go to Add Ons in Samagri, and select the Ganesha size that you wish for\n\nStep 3: Customize other samagri items as per the quantities you require\n\nStep 4:  Click on Proceed.  In Special Notes, mention IDOL NUMBER. \n\nIf you are also looking for Pujari for your pandal, specify if you require pujari for 1 day/ 3 days/ 5 days / 9 days  \n\nPujari charges are 1000rs per visit\n\n1 Day Only morning / Only evening - 1000 Rs\n1 Day Morning and Evening - 2000Rs\n\n3 days Only morning / Only evening - 3000Rs\n3 days Morning and Evening - 6000Rs\n\n5 days Only morning / Only evening - 5000Rs\n5 days Morning and Evening - 10000Rs\n\n9 days Only morning / Only evening - 9000Rs\n9 days Morning and Evening - 18000Rs\n\nPlace the booking with your details and our representative will contact you and finalize the booking\n",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": null,
//         "mode3": null,
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/ET38mTYA3rg\",\"https://www.youtube.com/embed/mNX2gbk7hlw\",\"https://www.youtube.com/embed/arrvt-fQZ2s\"]",
//         "images": "[\"/uploads/2023/07/Ganesha-20-inch.png\",\"/uploads/2023/07/Ganesh-2023-Big-Rate-Card.png\",\"/uploads/2023/07/Ganesha-2ft-1.png\",\"/uploads/2023/07/Ganesha-2ft-2.png\",\"/uploads/2023/07/Ganesha-2ft-3.png\",\"/uploads/2023/07/Ganesha-2.5ft.png\",\"/uploads/2023/07/Ganesha-3ft.png\",\"/uploads/2023/07/Ganesha-4-ft.png\",\"/uploads/2023/07/Ganesha-5ft.png\",\"/uploads/2023/07/Ganesha-5.8ft.png\"]",
//         "updatedAt": "2023-07-25T12:47:27.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-07-21T05:59:32.000Z",
//         "createdById": "47",
//         "updatedById": "44",
//         "pujacategorymappings": [],
//         "pujamodels": [
//           {
//             "id": 116,
//             "pujaId": 167,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 1116,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": null,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-28T03:17:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-21T06:43:25.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 946,
//             "samagriCost": null,
//             "procedures": [
//               {
//                 "id": "e42c2cd5-1fc5-463f-966b-3bf6b93a62cf",
//                 "modelId": 116,
//                 "procedureId": 43,
//                 "procedure": {
//                   "id": 43,
//                   "language": "telugu",
//                   "title": "Thora Grandhi Puja",
//                   "description": "Thoranam (raksha) puja",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "8c24d4de-774d-4af5-8f4f-e3603ccea44c",
//                 "modelId": 116,
//                 "procedureId": 38,
//                 "procedure": {
//                   "id": 38,
//                   "language": "telugu",
//                   "title": "Breaking Coconut",
//                   "description": "By offering a coconut to the devata, we offer ourselves in the form of a coconut. First, the outer layer of a coconut called coir, is removed, because it symbolizes all our desires, likes, and dislikes. Next, only the hard shell remains between us and the devata. This hard shell represents our EGO, which we break at the feet of the devata. The remaining thing is our inner self symbolized by coconut water which spills at the devata's feet. On the coconut's hard shell, there are three hole-like marks. These three holes represent all that is three. This includes the body, mind, and intellect, the three Gunas, the three Kaalas, and the three states of consciousness. Therefore, breaking a coconut symbolizes not just breaking our Ego, but also transcending all the things in the category of three.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "c615b4d4-9b85-43b2-8726-6e84281a2904",
//                 "modelId": 116,
//                 "procedureId": 33,
//                 "procedure": {
//                   "id": 33,
//                   "language": "telugu",
//                   "title": "Aksharabhyasam",
//                   "description": "Aksharabhyasam",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "f586b9c9-3f8c-4da2-aa04-c21b23b84090",
//                 "modelId": 116,
//                 "procedureId": 24,
//                 "procedure": {
//                   "id": 24,
//                   "language": "telugu",
//                   "title": "Vrata Kadhalu",
//                   "description": "Vrata Kadhalu remind us and re -nforce the main promise (vrata) that we are undertaking as part of the puja.  The stories are designed with characters from the puranas whose smarana (rememberance) has a positive intent and impact on us. Hence the stories are not changed. ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "83b9ad4c-376b-4f2d-9f96-0386b5723200",
//                 "modelId": 116,
//                 "procedureId": 22,
//                 "procedure": {
//                   "id": 22,
//                   "language": "telugu",
//                   "title": "Abhishekam (Panchamrutalu)",
//                   "description": "Abhishekam can be seen as watering the roots of a tree. Only if the roots of a tree are nourished, it can produce fruits. During an Abhishekam, the results that we desire to see in this world are like the fruits of a tree, and the divine beings who influence these results are like roots. Since, everything in this universe is interconnected, we perform Abhishekam to see specific results in the world.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "b16434a6-acd9-428d-bd80-9bb793e20ff4",
//                 "modelId": 116,
//                 "procedureId": 19,
//                 "procedure": {
//                   "id": 19,
//                   "language": "telugu",
//                   "title": "Pancha loka palaka puja",
//                   "description": "In Sanatana Dharma, different worlds exist together like a multiverse, called lokas.\nFor Example: When we refer to the Vaikuntha Loka, ‘Vai’ means ‘No’ and ‘Kuntha’ means ‘anxiety’. So, for the people living in Vaikuntha there is ‘No-anxiety’. Similarly, Kailasha is called the loka of Lord Shiva, where his devotees reside. \nIn Pancha Loka Palaka Puja, we bow down to the rulers of five lokas: Ganapati Loka, Brahma Loka, Vishnu Loka, Rudra Loka, and Gouri Loka.",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "0484cada-3f0a-42ca-88c0-4324647bdb0e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "f8a796b8-6a86-4e44-9c91-290dd48830b1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "f7c4c8ab-e6e8-4ba5-a467-bb976cf373f8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "f2a35249-faeb-4261-a18d-7a9b1fa5f2dd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "f773cae7-c30d-4b6d-893c-5a336e820c13",
//             "samagri": {
//               "id": "f773cae7-c30d-4b6d-893c-5a336e820c13",
//               "name": "5 types of fruits (Each 3)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 650,
//               "pujariSellingPrice": 700,
//               "customerMrp": 700,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T04:14:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-16T05:20:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 157
//             }
//           },
//           {
//             "id": "f0e054e3-a241-4dc8-92b1-a4744d7183fd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "9ca7a9ba-14d1-48cf-bdb3-b0700ba8783f",
//             "samagri": {
//               "id": "9ca7a9ba-14d1-48cf-bdb3-b0700ba8783f",
//               "name": "అఖండ జ్యోతి వొత్తులు / Akanda Jyothi Wicks",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 40,
//               "customerMrp": 40,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:02:57.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 78
//             }
//           },
//           {
//             "id": "e9512dc0-8b96-48d8-afef-dc38fb7c2511",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "738a029e-4db1-4a3a-8728-af89a6f95fe3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "a3d9cd9c-7e5e-4161-97b8-8ea71843eae4",
//             "samagri": {
//               "id": "a3d9cd9c-7e5e-4161-97b8-8ea71843eae4",
//               "name": "అగ్గి పెట్టిలు (Homelite) /Match Box (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:57:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 40
//             }
//           },
//           {
//             "id": "725c55b4-659e-465c-97cb-9d114f8d509a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "cde9a6ed-e84e-488d-a9f8-d438053592e8",
//             "samagri": {
//               "id": "cde9a6ed-e84e-488d-a9f8-d438053592e8",
//               "name": "5.8 / 5.9 Feet - గణేష్ విగ్రహం / Ganesha  (Pure Clay / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 12500,
//               "pujariSellingPrice": 16000,
//               "customerMrp": 16000,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:40:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-21T07:19:55.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "6e85b332-d172-463e-b094-e335a0a25eb0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "814c760a-3fb4-4f25-b90e-eba9bd0c9a8a",
//             "samagri": {
//               "id": "814c760a-3fb4-4f25-b90e-eba9bd0c9a8a",
//               "name": "3ft / 3.2ft - గణేష్ విగ్రహం / Ganesha (Pure Clay / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 4000,
//               "pujariSellingPrice": 5200,
//               "customerMrp": 5200,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:42:20.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-23T13:19:47.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "6dc157af-7dfe-402a-a061-82afc241c343",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "57903f59-a61a-400f-b0f9-68e6355039f2",
//             "samagri": {
//               "id": "57903f59-a61a-400f-b0f9-68e6355039f2",
//               "name": "పత్తి వస్త్రం Big / Cotton Garland  (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 52,
//               "customerMrp": 52,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:19:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 96
//             }
//           },
//           {
//             "id": "67383d61-11f4-4861-a973-cba9fcf5e9fa",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "94405a53-4cd7-447c-9058-20b0db6e82f1",
//             "samagri": {
//               "id": "94405a53-4cd7-447c-9058-20b0db6e82f1",
//               "name": "2.3 ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2000,
//               "pujariSellingPrice": 2800,
//               "customerMrp": 2800,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:41:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-23T13:21:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "66c37c21-415e-4fcd-abab-1cea29f1a5f6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "samagri": {
//               "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//               "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 210,
//               "pujariSellingPrice": 500,
//               "customerMrp": 500,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:26:10.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 17
//             }
//           },
//           {
//             "id": "5e579e77-0c33-4f08-aad8-f940cddd132f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "52a0aae3-3997-441f-8369-7bf3bda103ed",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "4b339936-ead9-46b6-a6d6-ba9f4afe8a9b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "f21392b0-2848-47be-a081-1a9f4733cb48",
//             "samagri": {
//               "id": "f21392b0-2848-47be-a081-1a9f4733cb48",
//               "name": "2.5 ft to 2.8ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2600,
//               "pujariSellingPrice": 4500,
//               "customerMrp": 4500,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:42:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-23T13:21:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "4a7362b1-b003-483a-9062-d194bc9112e8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "fc843717-569b-46f3-8a8c-952669a13bf0",
//             "samagri": {
//               "id": "fc843717-569b-46f3-8a8c-952669a13bf0",
//               "name": "మార్వాడి దారం / Marwadi Thread",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 40,
//               "customerMrp": 40,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 79
//             }
//           },
//           {
//             "id": "3ca9d4f3-faa7-4a3b-b467-2103c5bc30e0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "1f195f94-bc18-4aa1-b1e7-b450ccc995f3",
//             "samagri": {
//               "id": "1f195f94-bc18-4aa1-b1e7-b450ccc995f3",
//               "name": "2.2 ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1800,
//               "pujariSellingPrice": 2500,
//               "customerMrp": 2500,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:41:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-23T13:22:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "3420e564-aeb9-4314-8c03-d0e4f88e247d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "2eac7941-1725-4cfd-893e-5eb39d43639a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "271592ce-6710-4262-a7c4-358a5df794ed",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "bd3cee3c-c039-4347-8660-5102c4981e0b",
//             "samagri": {
//               "id": "bd3cee3c-c039-4347-8660-5102c4981e0b",
//               "name": "5 ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 9500,
//               "pujariSellingPrice": 13000,
//               "customerMrp": 13000,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-25T12:41:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-21T07:10:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "22fb12af-429f-4930-8a68-378fec784ac9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 25,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "173fc7a1-7439-4579-8ac3-efec07942e2d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           },
//           {
//             "id": "1639cc87-796b-4267-ba8d-7f46e1ef586e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "0ead9a46-5322-4a69-bb90-9c1591723c0e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//             "samagri": {
//               "id": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//               "name": "పూల దండ/ Flower Garland",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 200,
//               "pujariSellingPrice": 220,
//               "customerMrp": 220,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:31:38.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T06:47:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 228
//             }
//           },
//           {
//             "id": "0adad9ab-87e1-4ba6-973b-1d886f9139f2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "e37b8f17-d7bb-46ac-b2c9-a040e1c4eeb7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "ec517b0b-d4ab-4538-a612-ee302755ab1b",
//             "samagri": {
//               "id": "ec517b0b-d4ab-4538-a612-ee302755ab1b",
//               "name": "4.5 Feet - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 7000,
//               "pujariSellingPrice": 10000,
//               "customerMrp": 10000,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:41:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-21T07:02:42.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "da2f7a9c-2ded-4058-90f3-70429c7a5788",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "c987ebf9-d1c4-4148-b5e5-367aedcc6aeb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "3b26947e-94f8-46e4-b00b-657c841d2ec5",
//             "samagri": {
//               "id": "3b26947e-94f8-46e4-b00b-657c841d2ec5",
//               "name": "ఇత్తడి కళశాలు (1/2 ltr) / Brass Kalasam (Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 170,
//               "pujariSellingPrice": 300,
//               "customerMrp": 300,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:27:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 19
//             }
//           },
//           {
//             "id": "c3a75a09-0157-467a-a7f1-54311f0ab68b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "b537ccac-7d58-4d90-ad0e-b832ef39296a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "4d7e2a56-3966-4fce-8480-11596641d205",
//             "samagri": {
//               "id": "4d7e2a56-3966-4fce-8480-11596641d205",
//               "name": "20 inch - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 900,
//               "pujariSellingPrice": 1500,
//               "customerMrp": 1500,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:40:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-23T13:23:31.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "b305dded-1f4d-49a0-a6e8-e90b99aaa03f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 116,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "b2cf860a-ac80-4785-8eab-1d191fb45fce",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "2db77651-291a-4841-bc75-e4e6451701ff",
//             "samagri": {
//               "id": "2db77651-291a-4841-bc75-e4e6451701ff",
//               "name": "2 ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1300,
//               "pujariSellingPrice": 2000,
//               "customerMrp": 2000,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:41:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-23T13:23:00.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "b0b746e7-17b9-4e80-9376-bb9f7c44cb20",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "b01be69e-5160-438d-8a4c-e2ea4c24a3a1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "afa5d558-8f8a-4b3f-a699-3c05f4b16122",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "ae703abc-26be-4de6-9c34-09985e56625f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "18916846-5b89-49d2-a9f3-e0359cbbe70c",
//             "samagri": {
//               "id": "18916846-5b89-49d2-a9f3-e0359cbbe70c",
//               "name": "మూకుళ్ళు / Mukudu",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 30,
//               "customerMrp": 30,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:13:20.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 93
//             }
//           },
//           {
//             "id": "a8fcc55b-11fe-4549-a3f0-92d251e0f4ed",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 25,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "9f54e581-21da-48d3-a669-cfa101a05394",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "8e6ed4b8-c46f-43ee-95eb-49f66d414313",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "0e9012a1-a5f7-445d-829f-69ef9ac5bf71",
//             "samagri": {
//               "id": "0e9012a1-a5f7-445d-829f-69ef9ac5bf71",
//               "name": "4.2 Feet - గణేష్ విగ్రహం / Ganesha  (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 5800,
//               "pujariSellingPrice": 7500,
//               "customerMrp": 7500,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:41:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-21T07:00:59.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "879ad6be-ecbf-4097-ab6c-bdfe62f4cd7d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "83b1d67b-15be-450b-8f40-14464274ffee",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "f1e08e9d-b717-4135-bc74-db8376126649",
//             "samagri": {
//               "id": "f1e08e9d-b717-4135-bc74-db8376126649",
//               "name": "ఎండు కొబ్బరి ముక్కలు (Premium) / Dry coconut flakes (Premium)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 29,
//               "pujariSellingPrice": 38,
//               "customerMrp": 38,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:45:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:53:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 117
//             }
//           },
//           {
//             "id": "7ae5994c-7f71-47b6-893b-746b1f573ec9",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "7a0caf44-1df5-40a7-b680-b2a0da61e1ea",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 40,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "samagri": {
//               "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//               "name": "బెల్లం / Jaggery",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:23:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T01:22:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 143
//             }
//           },
//           {
//             "id": "7780d07d-9478-46b4-a638-6f6c6c51d28c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 167,
//             "samagriType": "addon",
//             "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "samagri": {
//               "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//               "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 230,
//               "pujariSellingPrice": 270,
//               "customerMrp": 270,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:31:17.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 23
//             }
//           }
//         ]
//       },
//       {
//         "id": 168,
//         "name": "Ganesha Chaturdhi Kit - River Ganesha 10 inch & 21 Patri (Vinayaka Vratam Kit)",
//         "description": "10 inch River Clay Ganesha\n21 Ayurvedic Patri   (Select from Addons.  All proceedings will be towards NGO helping in tribal girl education)\n\n\n",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": null,
//         "mode3": null,
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/ET38mTYA3rg\",\"https://www.youtube.com/embed/mNX2gbk7hlw\",\"https://www.youtube.com/embed/arrvt-fQZ2s\"]",
//         "images": "[\"/uploads/2023/07/Ganesha-Home-2023.png\"]",
//         "updatedAt": "2023-07-25T22:04:21.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-07-25T12:15:35.000Z",
//         "createdById": "44",
//         "updatedById": "44",
//         "pujacategorymappings": [],
//         "pujamodels": [
//           {
//             "id": 117,
//             "pujaId": 168,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 768,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": null,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-28T03:16:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-25T12:17:18.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 132,
//             "samagriCost": 519,
//             "procedures": [
//               {
//                 "id": "578b3cf1-54ce-4020-a7ec-ba90796cb7f1",
//                 "modelId": 117,
//                 "procedureId": 44,
//                 "procedure": {
//                   "id": 44,
//                   "language": "telugu",
//                   "title": "Mantra Pushpam",
//                   "description": "A reminder that the divine force that we seek, pray and worship actually exists within us, and if we are able to follow Sanatana Dharma (the way of life), we can awaken this force and remain at eternal bliss with this presence.  A reminder that the divine and us are 'abhedam' (inseparable) and we are yet to experience this.\n\nThose who experience this enlightnment need not perform pujas anymore and that is our rishis' wish.  They want us to perform pujas, only for us to rise beyond them towards ultimate gyana and moksha",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "f9eac6b5-7220-45ea-98c2-af22de360a1e",
//                 "modelId": 117,
//                 "procedureId": 36,
//                 "procedure": {
//                   "id": 36,
//                   "language": "telugu",
//                   "title": "Aada bidda - Gadapa Kadagadam",
//                   "description": "Most of the negative energy in the house, if any, is deposited on the frame of the main entrance door. Due to this, before entering the house, we apply yellow turmeric paste, red kumkum dots, and white lines to the door frame. White represents Saraswati, yellow represents Lakshmi, and red represents Gowri. The three mothers protect the family. At the entrance of the house, the lady of the house draws rangolis (geometric shapes conducive to positive energy), inviting goddess Lakshmi in her thoughts and emotion to visit the house.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "201070f1-2049-4264-93a5-561855d7aeab",
//                 "modelId": 117,
//                 "procedureId": 27,
//                 "procedure": {
//                   "id": 27,
//                   "language": "telugu",
//                   "title": "Chandi Parayanam",
//                   "description": "Brahmin who has initiated into chandi will do parayanam and japam and give theertham to yajamani\n1 time, 3, 5, 7, 9 times parayanams repeats\n1.5 to 2 hrs for 1st parayanam + 1 hr for every next iteration\nAfter parayanam, prasad, generally fruits is given ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "bf9d25d4-75b7-4977-8cc7-7cdbff6550e6",
//                 "modelId": 117,
//                 "procedureId": 21,
//                 "procedure": {
//                   "id": 21,
//                   "language": "telugu",
//                   "title": "Satyanarayana Puja",
//                   "description": "Satyanarayana Puja",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "514bad60-e63e-44ea-8aa6-9f72711d5f56",
//                 "modelId": 117,
//                 "procedureId": 16,
//                 "procedure": {
//                   "id": 16,
//                   "language": "telugu",
//                   "title": "Ganapati Puja",
//                   "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "112283f9-109a-41e3-9773-ce386106c0a8",
//                 "modelId": 117,
//                 "procedureId": 19,
//                 "procedure": {
//                   "id": 19,
//                   "language": "telugu",
//                   "title": "Pancha loka palaka puja",
//                   "description": "In Sanatana Dharma, different worlds exist together like a multiverse, called lokas.\nFor Example: When we refer to the Vaikuntha Loka, ‘Vai’ means ‘No’ and ‘Kuntha’ means ‘anxiety’. So, for the people living in Vaikuntha there is ‘No-anxiety’. Similarly, Kailasha is called the loka of Lord Shiva, where his devotees reside. \nIn Pancha Loka Palaka Puja, we bow down to the rulers of five lokas: Ganapati Loka, Brahma Loka, Vishnu Loka, Rudra Loka, and Gouri Loka.",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "f7f037e9-7c89-41dd-a5d4-d00b0488c7de",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "d43f3f12-c5c1-400e-badf-a390c43e7261",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "afaf1cae-ce7b-406b-a9f3-2a1a2e36a2d0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "c0785734-ce6b-4868-9e57-e34bd7b32f44",
//             "samagri": {
//               "id": "c0785734-ce6b-4868-9e57-e34bd7b32f44",
//               "name": "Karishye Cotton Bag (Reusable)",
//               "description": "Sturdy Cotton Bag . Good Quality",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 50,
//               "pujariSellingPrice": 80,
//               "customerMrp": 80,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-25T12:18:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-25T12:18:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "a7b78052-067f-44c8-b053-254b85e48f9a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "a6ae86c0-05b9-4a71-bd8a-5e0a9ba83a88",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 2,
//             "pujaId": 168,
//             "samagriType": "addon",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "9bc75de5-e681-4acd-af94-939130cb7031",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "9bb62db2-9404-43ec-8e1f-566e05cdfa88",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "98d3efc6-29ae-4297-b841-abe560f7044e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "78db3814-7504-4ddf-8fde-0bd5282951ed",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "7713dd59-2dc2-4595-addb-ac2065fb8299",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "addon",
//             "samagriId": "7113792b-a69f-4932-b4b0-86f17a794727",
//             "samagri": {
//               "id": "7113792b-a69f-4932-b4b0-86f17a794727",
//               "name": "Ganesha Pooja - 21 Patri",
//               "description": "21 Ayurvedic Patri for Ganesha Pooja.  All proceedings are directed towards NGOs helping girl education in tribal areas",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 280,
//               "pujariSellingPrice": 300,
//               "customerMrp": 300,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-25T12:20:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-25T12:20:37.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "6973c0ff-9305-47aa-9cfb-4d41c49e0cc5",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "66ccc432-3d28-41b8-ad87-92a17c2fc293",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "5959b553-8b22-49aa-9198-231af5be333c",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "41136472-9763-4201-90cd-67b7017671ab",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "401e6eb8-03f3-4435-8f09-feeaa7c7ebe2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "3d3a87f4-3712-4124-b3a5-3bce19f78fbe",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "375b9443-96cc-4f60-8922-8108392e58de",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "23d2add6-547b-468b-845e-c523d8daf648",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "234484af-9d2d-4f83-aa56-dce94587e865",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "e91f55b6-95ae-45d3-a141-bbf37f4f6f19",
//             "samagri": {
//               "id": "e91f55b6-95ae-45d3-a141-bbf37f4f6f19",
//               "name": "10 inch - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//               "description": "10 inch Ganesha, carefully and artistically designed\nDhyaana Aasanam, Abhaya Hastham, Kireetham, Mushikam.  \nOm Shree Ganeshaya Namaha\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 155,
//               "pujariSellingPrice": 250,
//               "customerMrp": 250,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-25T12:07:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-25T12:07:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "1cadd727-c7ae-4527-a4be-51d2b64c1cfb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "18845304-c0dc-4bf7-ad1c-edad56e865ee",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 168,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           }
//         ]
//       },
//       {
//         "id": 156,
//         "name": "Samvathsarikam (యజుర్వేదులు/Yajurvedulu)",
//         "description": "This puja procedure is performed exactly 1 year after the person departs and only performed during the 1st year.  From 2nd year onwards Abdhikam is performed.\nThe ritual lasts for 3 days in total. ",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": null,
//         "mode3": null,
//         "mode4": null,
//         "videos": "undefined",
//         "images": "[\"/uploads/2023/07/Samvatsrikam-2.png\"]",
//         "updatedAt": "2023-07-24T01:35:10.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-07-10T00:13:50.000Z",
//         "createdById": "47",
//         "updatedById": "44",
//         "pujacategorymappings": [],
//         "pujamodels": [
//           {
//             "id": 104,
//             "pujaId": 156,
//             "durationHrs": 2,
//             "noOfPujaris": 3,
//             "modelSellingPrice": 98884,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 70000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-28T03:20:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-10T00:37:12.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 1500,
//             "samagriCost": 12300,
//             "procedures": [
//               {
//                 "id": "26a06f94-679a-43bb-b0f0-80a2f9f1bc09",
//                 "modelId": 104,
//                 "procedureId": 46,
//                 "procedure": {
//                   "id": 46,
//                   "language": "telugu",
//                   "title": "Ayushya Homam",
//                   "description": "The pradhana ayur devatha is offered havissu along with the other devatas that were invited during the kalasa sthapana and mandaparadhana.   Ayusha suktham and ayur devatha mantram are utilised while performing the homam.  Maha mrutyunjaya, saptha chiranjeevulu, dhanvantari are also considered as prominent during the procedure.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "9fd2fa33-cd61-4110-a374-ce6c9142453c",
//                 "modelId": 104,
//                 "procedureId": 39,
//                 "procedure": {
//                   "id": 39,
//                   "language": "telugu",
//                   "title": "Plate of rice with gold ring wrapped with darbha",
//                   "description": "As Darbham grass absorbs radiation from the atmosphere, it is also used to cover all food items during eclipse time, to protect them. Darbham is also an effective conductor of sound vibrations, which makes it suitable to absorb and transfer Vedic chanting vibrations.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "b9af8b9e-763a-426c-97d0-164be33ebd54",
//                 "modelId": 104,
//                 "procedureId": 37,
//                 "procedure": {
//                   "id": 37,
//                   "language": "telugu",
//                   "title": "Ash Gourd break / Gummadikaya kottadam",
//                   "description": "As the vegetable called Ash gourd is very high in life energy, it is broken in front of the door.\nAnother ash gourd (a full one, not damaged) is sometimes hung at the entrance after puja and replaced regularly. This wards off any Nara drishti doshas and keeps positive energies flowing into the house.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "035d9b3f-51c5-42c1-a0c1-f3d6ce697272",
//                 "modelId": 104,
//                 "procedureId": 33,
//                 "procedure": {
//                   "id": 33,
//                   "language": "telugu",
//                   "title": "Aksharabhyasam",
//                   "description": "Aksharabhyasam",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "92656822-3425-4204-ace0-46194255da3d",
//                 "modelId": 104,
//                 "procedureId": 30,
//                 "procedure": {
//                   "id": 30,
//                   "language": "telugu",
//                   "title": "Saraswati Homam",
//                   "description": "Ganapati, navagraha devatas and all other deities such as ashtadikpalakas, panchalokapalas, trimurthulu which are generally invited during mandaparadhana are given havissu (offering in the homam via swahakara and agni devata).\nFinally, along with the recititation of saraswati suktham as well as108 repetitions of saraswati moola mantram, the devi is offered havissu.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "4443e2a6-d7ec-4cf6-9804-837eea974bff",
//                 "modelId": 104,
//                 "procedureId": 23,
//                 "procedure": {
//                   "id": 23,
//                   "language": "telugu",
//                   "title": "Archana ",
//                   "description": "During Archana, we offer flowers to a deity while chanting mantras or names of the deity. The flowers that we offer have a fragrance. Another name for fragrance is vasana(tendency). Therefore, offering flowers to a deity represents offering all our vasanas to the deity. And, while we let go of our vasanas, we chant the names of the deity, to connect with the divine.\n\nSometimes archana is performed with other dhravya like leaves that are most suitable for that particular devata. For e.g. tulsi, bilva etc. ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "82fc1184-43c1-4760-b00f-4e7b146059d7",
//                 "modelId": 104,
//                 "procedureId": 21,
//                 "procedure": {
//                   "id": 21,
//                   "language": "telugu",
//                   "title": "Satyanarayana Puja",
//                   "description": "Satyanarayana Puja",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "d80f7e14-2802-43ed-b747-63f741bdb83f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "f3809443-befe-4c75-a784-4f1cf7527d51",
//             "samagri": {
//               "id": "f3809443-befe-4c75-a784-4f1cf7527d51",
//               "name": "యజ్ఞోపవేతం వడికిన /Sacred thread",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 45,
//               "customerMrp": 45,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:25:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 52
//             }
//           },
//           {
//             "id": "d6797563-8f36-4f52-8192-23126d1031e8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "d3a101c3-134e-48d4-9230-22a748f3445a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 12,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//             "samagri": {
//               "id": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//               "name": "నల్ల నువ్వులు/Black Sesame",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T02:08:36.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 42
//             }
//           },
//           {
//             "id": "d1d86592-3e50-44e6-9a44-e4550ba4d20c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "ce6d7242-1c4b-4092-88c1-44d9572c6007",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//             "samagri": {
//               "id": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//               "name": "పూల దండ/ Flower Garland",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 200,
//               "pujariSellingPrice": 220,
//               "customerMrp": 220,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:31:38.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T06:47:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 228
//             }
//           },
//           {
//             "id": "b84a9df8-e9e3-4f3a-8651-36099f375472",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "samagri": {
//               "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//               "name": "ఇసుక / SAND",
//               "description": "As Gampallu",
//               "units": "other",
//               "standardQty": "1",
//               "costPrice": 100,
//               "pujariSellingPrice": 125,
//               "customerMrp": 125,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:28:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:16:43.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 151
//             }
//           },
//           {
//             "id": "a7b0acf4-2bb0-48a6-ab7d-b0ab85378c91",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 3,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "a2c510e8-05b5-4b86-933e-c2ba01d2452f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "8cf7c5d6-34d0-4993-ae76-457889e55ab7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "87ee1670-b891-4ac6-b3b2-6a3a41c59f00",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           },
//           {
//             "id": "855c8332-9bf2-43d5-8548-579ff3893378",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "84b053cd-1706-4f7c-b6b2-90976656853e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "samagri": {
//               "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//               "name": "అరటి పండ్లు / Banana",
//               "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//               "units": "no",
//               "standardQty": "12",
//               "costPrice": 60,
//               "pujariSellingPrice": 70,
//               "customerMrp": 70,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-19T22:36:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:26:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 181
//             }
//           },
//           {
//             "id": "83721f8e-4f3c-4806-9270-a4031f74922e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "samagri": {
//               "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//               "name": "దర్భ కట్టలు/Kusha Grass",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T06:06:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 53
//             }
//           },
//           {
//             "id": "7596f4ea-6743-4434-b033-4de453e1f628",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "samagri": {
//               "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//               "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 230,
//               "pujariSellingPrice": 270,
//               "customerMrp": 270,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:31:17.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 23
//             }
//           },
//           {
//             "id": "6bb277f1-2873-4154-875b-dde20f6f097d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "6896b720-0dec-48ac-ba5e-67309a0bc300",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 12,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "98f97689-5cc6-46c6-a53f-f1f759c11f3c",
//             "samagri": {
//               "id": "98f97689-5cc6-46c6-a53f-f1f759c11f3c",
//               "name": "యవలు / Broad Beans",
//               "description": "Yam is a grass plant. It is more commonly known by the English word Barley. It is a major cereal crop grown in temperate climates worldwide. It was one of the first grains to be cultivated by humans, especially in Eurasia as early as 10,000 years ago.[1] Barley is used as animal feed, as a source of fermentable material for distilled beverages such as beer, and as a component of various health foods. It is used in soups and barley bread",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 4,
//               "pujariSellingPrice": 5,
//               "customerMrp": 5,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:52:18.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-13T01:56:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 123
//             }
//           },
//           {
//             "id": "654316c8-80f4-48f8-816c-eca935e49324",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "samagri": {
//               "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//               "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 110,
//               "pujariSellingPrice": 150,
//               "customerMrp": 150,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:29:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 25
//             }
//           },
//           {
//             "id": "57df5346-c5cd-4adc-bd15-a8454e127dd8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "d55da975-09bc-4577-868a-b94571edef0d",
//             "samagri": {
//               "id": "d55da975-09bc-4577-868a-b94571edef0d",
//               "name": "9×5 మంచి పంచలు (Poojarigariki)/Dhoti",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 550,
//               "pujariSellingPrice": 715,
//               "customerMrp": 715,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:25:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 22
//             }
//           },
//           {
//             "id": "4fb77da0-7184-4050-8004-c2848fa161be",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 30,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "8afdda99-a5e0-4478-9894-a40f7eb9235c",
//             "samagri": {
//               "id": "8afdda99-a5e0-4478-9894-a40f7eb9235c",
//               "name": " అరటి ఆకులూ / Banana leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 4,
//               "pujariSellingPrice": 5,
//               "customerMrp": 5,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:15:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-10T00:31:06.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 200
//             }
//           },
//           {
//             "id": "46ed0e89-d4a2-437b-bc3d-59912e71e335",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 101,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "421b6bb2-2bfd-4869-b404-fd37569ceed6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "35bc5c78-48ee-44de-a498-2bd1931ec6e4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "2b6e8738-a38b-40e3-8249-863480faf9fc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "23145841-6fb0-45f0-8446-6e4b1c7e712f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "2f6a7019-6552-443d-a4be-6eba4e92d213",
//             "samagri": {
//               "id": "2f6a7019-6552-443d-a4be-6eba4e92d213",
//               "name": "దీపం కంచుళ్ళు / Lampshades (Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-19T22:31:17.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T03:48:47.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 138
//             }
//           },
//           {
//             "id": "17eea05c-a342-4396-94bc-ae472134016b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 75,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "10ec7cb0-6c0e-4967-b9b4-31e05595bb79",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 9,
//             "pujaId": 156,
//             "samagriType": "addon",
//             "samagriId": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//             "samagri": {
//               "id": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//               "name": "రాగికళశాలు (1/2 ltr)/Copper kalasam (Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 183,
//               "pujariSellingPrice": 300,
//               "customerMrp": 300,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:25:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 16
//             }
//           },
//           {
//             "id": "0e8453e4-d65d-484d-9344-8da2d58cb2f5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "0e454b93-d20c-46ae-b8f9-b6cf17e5c3c5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 7,
//             "pujaId": 156,
//             "samagriType": "addon",
//             "samagriId": "bdf78f17-db12-4634-9792-4a9f5320856b",
//             "samagri": {
//               "id": "bdf78f17-db12-4634-9792-4a9f5320856b",
//               "name": "రాగి గ్లాసులు/Copper Glass - 250ml ",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 49,
//               "pujariSellingPrice": 80,
//               "customerMrp": 80,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:28:57.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 21
//             }
//           },
//           {
//             "id": "092ca2f9-6dc3-40ab-acba-6d1e8aff22e8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 60,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "samagri": {
//               "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//               "name": "బెల్లం / Jaggery",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:23:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T01:22:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 143
//             }
//           },
//           {
//             "id": "013ba1db-7ff4-4f08-a4f1-07528e5e5ce4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 150,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "fd76109d-7b82-4a9d-8ddc-504f2eb97328",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "fa9c3c18-d484-4a58-99cd-0640c1ad796d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "samagri": {
//               "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//               "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 8,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:40:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 33
//             }
//           },
//           {
//             "id": "f9c0c641-3134-4eef-b14c-c8a0e0c871c6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "88283fdf-e374-4209-9f32-271942f838e9",
//             "samagri": {
//               "id": "88283fdf-e374-4209-9f32-271942f838e9",
//               "name": "అగ్నిహోత్రం సామాగ్రి/Agnihotram ",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T03:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-10T00:33:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 198
//             }
//           },
//           {
//             "id": "e831ab71-fd10-4371-ba10-9756022436f0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "b36854ff-7621-42fe-9f2e-7f0128cdcd99",
//             "samagri": {
//               "id": "b36854ff-7621-42fe-9f2e-7f0128cdcd99",
//               "name": "విస్తరాకులు/ Leaf Plate",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 5,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-04T02:21:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-04T02:19:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 192
//             }
//           },
//           {
//             "id": "e1b9aae6-43ad-40c3-b8b1-fea18cf57e8d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "dc8f4bf7-085e-4d6b-8578-09628b08cf08",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 156,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           }
//         ]
//       },
//       {
//         "id": 147,
//         "name": "Lakshmi Kubera Homam",
//         "description": "The Lakshmi Kubera Homam is usually performed by the devotees to get blessed with luxuries, financial growth, and success with the benevolence of Goddess Lakshmi and lord of wealth Kubera. This homam impacts us to improve and overcome financial hindrances which block the flow of wealth.",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": "remote",
//         "mode3": "on_behalf",
//         "mode4": null,
//         "videos": "undefined",
//         "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//         "updatedAt": "2023-07-24T01:33:01.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-06-16T00:47:03.000Z",
//         "createdById": null,
//         "updatedById": "44",
//         "pujacategorymappings": [
//           {
//             "id": 21,
//             "pujaId": 147,
//             "categoryId": 2,
//             "subCategoryId": 6
//           },
//           {
//             "id": 20,
//             "pujaId": 147,
//             "categoryId": 2,
//             "subCategoryId": 7
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 86,
//             "pujaId": 147,
//             "durationHrs": 3,
//             "noOfPujaris": 2,
//             "modelSellingPrice": 19500,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 10000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-28T03:20:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-25T22:17:19.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 1709,
//             "samagriCost": 4816,
//             "procedures": [
//               {
//                 "id": "bf6ed962-33e9-49b5-84f9-36e8576c712e",
//                 "modelId": 86,
//                 "procedureId": 46,
//                 "procedure": {
//                   "id": 46,
//                   "language": "telugu",
//                   "title": "Ayushya Homam",
//                   "description": "The pradhana ayur devatha is offered havissu along with the other devatas that were invited during the kalasa sthapana and mandaparadhana.   Ayusha suktham and ayur devatha mantram are utilised while performing the homam.  Maha mrutyunjaya, saptha chiranjeevulu, dhanvantari are also considered as prominent during the procedure.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "72e3a11d-4d99-475d-bff3-a05af94eb7d2",
//                 "modelId": 86,
//                 "procedureId": 50,
//                 "procedure": {
//                   "id": 50,
//                   "language": "telugu",
//                   "title": "Satyanarayana Vratam",
//                   "description": "Full procedure of satyanarayana vratam once again reminding us the power of truth and need of honesty and ethics, in order to make this occasion successful",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "1c67f184-3129-4014-9c56-bc059ade2134",
//                 "modelId": 86,
//                 "procedureId": 53,
//                 "procedure": {
//                   "id": 53,
//                   "language": "telugu",
//                   "title": "Naamakarana",
//                   "description": "A plate of rice is taken. With a gold ring wrapped with darbha, 3 lines are drawn and three different names are written by the father.\n1st line: Maasa namam, 2nd line: Nakshatra namam: 3rd : Vyavaharika namam  . Once this is done, the child is placed in cradle Three names are recited in child's ear along with mantra by pujari. Followed by the pujari, the mother, the father and the grandparents repeat the same and bless the child.\n\nDepending ojn the family traditions, culture of the region etc. the sequence or the steps would vary.  The pujari would accordingly guide the event. \n",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "015d896c-9ab1-47bb-8112-bddad4a0366a",
//                 "modelId": 86,
//                 "procedureId": 43,
//                 "procedure": {
//                   "id": 43,
//                   "language": "telugu",
//                   "title": "Thora Grandhi Puja",
//                   "description": "Thoranam (raksha) puja",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "338f38d3-118f-4b64-9893-1cec0c54fcd8",
//                 "modelId": 86,
//                 "procedureId": 24,
//                 "procedure": {
//                   "id": 24,
//                   "language": "telugu",
//                   "title": "Vrata Kadhalu",
//                   "description": "Vrata Kadhalu remind us and re -nforce the main promise (vrata) that we are undertaking as part of the puja.  The stories are designed with characters from the puranas whose smarana (rememberance) has a positive intent and impact on us. Hence the stories are not changed. ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "bf2f8f9e-31fb-43f7-b58b-ba22d7b9e966",
//                 "modelId": 86,
//                 "procedureId": 37,
//                 "procedure": {
//                   "id": 37,
//                   "language": "telugu",
//                   "title": "Ash Gourd break / Gummadikaya kottadam",
//                   "description": "As the vegetable called Ash gourd is very high in life energy, it is broken in front of the door.\nAnother ash gourd (a full one, not damaged) is sometimes hung at the entrance after puja and replaced regularly. This wards off any Nara drishti doshas and keeps positive energies flowing into the house.",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "fdb17a48-3ddc-40be-bb97-0a3b9bd6c16c",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "fca88882-74a1-41d3-b7a6-6315ace3b69a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "f79a5f77-389a-464a-88e7-99631a53f9e0",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "f2ab6b6f-5257-4487-843e-668a6b46d08f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "efca0718-95af-415c-bf25-6b3949ae2d08",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           },
//           {
//             "id": "e914ed24-f870-4d61-bd1a-351514592c27",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "e6844145-9baa-4997-ab33-60a547c26724",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "e028ec19-5f00-4a4e-907d-0fc866f3c0cd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "d1fff849-8a72-4531-b7b1-1198094b0315",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "d0837f8e-c15e-4b10-be0b-a0792c69d50d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "samagri": {
//               "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//               "name": "బెల్లం / Jaggery",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:23:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T01:22:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 143
//             }
//           },
//           {
//             "id": "cc9f8d1e-e7c3-4165-84e7-db24dd6b158f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//             "samagri": {
//               "id": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//               "name": "యాలకులు / Cardamom",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 10,
//               "pujariSellingPrice": 24,
//               "customerMrp": 24,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:07:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 87
//             }
//           },
//           {
//             "id": "cb831381-ed13-4125-8e02-340f9fac3687",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 11,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "9241ed1f-9ee0-4800-a3ae-78b1269b524e",
//             "samagri": {
//               "id": "9241ed1f-9ee0-4800-a3ae-78b1269b524e",
//               "name": "కమలం పువ్వులు / Lotus flowers",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T03:14:11.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-10T01:32:10.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 197
//             }
//           },
//           {
//             "id": "c134d189-fbca-40ed-a021-ad28344ac74e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "6fab8ce0-d092-4ed6-bdd5-c7b060e4c37a",
//             "samagri": {
//               "id": "6fab8ce0-d092-4ed6-bdd5-c7b060e4c37a",
//               "name": "పట్టు వస్త్రం / Silk cloth",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 150,
//               "pujariSellingPrice": 200,
//               "customerMrp": 200,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:37:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 112
//             }
//           },
//           {
//             "id": "bd49d877-481a-479c-ac8c-5096363b3030",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 9,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "191c838a-45d3-436f-807e-d7fc0cd0eddb",
//             "samagri": {
//               "id": "191c838a-45d3-436f-807e-d7fc0cd0eddb",
//               "name": "కమల గింజలు/Lotus seeds",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:09:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-10T01:33:11.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 195
//             }
//           },
//           {
//             "id": "bb119b64-61f7-4bdb-9369-e7030b67a604",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "b88c79fd-e06e-4a62-87d2-e8d8e9777061",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//             "samagri": {
//               "id": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//               "name": "లవంగాలు / cloves",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:08:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 88
//             }
//           },
//           {
//             "id": "b6a044c0-4d09-48fa-a2a5-053fcf2c3825",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "addon",
//             "samagriId": "695a3876-5245-431c-992d-9ba25f398e5c",
//             "samagri": {
//               "id": "695a3876-5245-431c-992d-9ba25f398e5c",
//               "name": "దర్భ చాపలు / Darba Mat",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 50,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T01:34:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 75
//             }
//           },
//           {
//             "id": "b2d2f5a2-24bb-479e-8ac5-3b5627486f7f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "80189106-e98e-41b8-8435-beef3b4cf163",
//             "samagri": {
//               "id": "80189106-e98e-41b8-8435-beef3b4cf163",
//               "name": "ఎండుద్రాక్ష/Raisins ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:11:50.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 14
//             }
//           },
//           {
//             "id": "ae93bdd7-2bc7-495a-a516-415646549683",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "a4a51c9a-9c98-4c2d-8831-6bef0a057102",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "samagri": {
//               "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//               "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:38:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 115
//             }
//           },
//           {
//             "id": "a215a0a3-68e6-47b3-9a42-868cfccb9689",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "4423f1cb-e2eb-459c-bba3-9964b0fb3c6b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "499f3b07-536e-4138-8123-a78958cb2c3a",
//             "samagri": {
//               "id": "499f3b07-536e-4138-8123-a78958cb2c3a",
//               "name": "పట్టికబెల్లం చిప్స్ /Misri Chips",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:54:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 106
//             }
//           },
//           {
//             "id": "40358bcd-295c-42e2-9b85-6fbc56488145",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "30975d1e-7d91-4fd8-a1c2-7d731672e7c3",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "2fd73d1e-37b5-4430-b140-0b02d528395d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//             "samagri": {
//               "id": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//               "name": "తెల్ల నువ్వులు/White Sesame",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T02:09:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 43
//             }
//           },
//           {
//             "id": "2be05a38-0fe1-4a46-8538-6dd1a197862f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "samagri": {
//               "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//               "name": "చెంబులు/Kalasam",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:26.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:39:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 253
//             }
//           },
//           {
//             "id": "24eb12df-1557-4ff5-94e3-2bcb3067712c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "23ebf5f3-68a2-4081-97f4-a72fa4951298",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "ca478478-dede-4f37-bb5a-1e2c2a023696",
//             "samagri": {
//               "id": "ca478478-dede-4f37-bb5a-1e2c2a023696",
//               "name": "చెరుకు ముక్కలు / Pieces of Sugarcane",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 12,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:36:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T05:03:23.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 186
//             }
//           },
//           {
//             "id": "1f68f05e-8631-4ada-a6b4-78c207e834cb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//             "samagri": {
//               "id": "92da20c4-a10e-4013-9364-f5114438d904",
//               "name": "వీడి పూలు / Loose Flowers",
//               "description": null,
//               "units": "gms",
//               "standardQty": "250",
//               "costPrice": 110,
//               "pujariSellingPrice": 120,
//               "customerMrp": 120,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:16:53.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:44:42.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 176
//             }
//           },
//           {
//             "id": "1f44b436-305a-4b78-9670-af87970204d6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//             "samagri": {
//               "id": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//               "name": "అటుకులు / Poha",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:28:02.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 101
//             }
//           },
//           {
//             "id": "151930d1-64bc-4a8e-9d9d-17b4a5874186",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "96414c68-fdd7-4a85-9fc1-4bedd5c27b60",
//             "samagri": {
//               "id": "96414c68-fdd7-4a85-9fc1-4bedd5c27b60",
//               "name": "జీడిపప్పు/Cashew",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:59:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 12
//             }
//           },
//           {
//             "id": "141e0d36-dbb2-480e-a10f-ab061f50b891",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "13350cf6-3546-4a0a-8fa4-af8024008bea",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "9372c01d-6536-4327-ad2d-bd9cc6d53e1f",
//             "samagri": {
//               "id": "9372c01d-6536-4327-ad2d-bd9cc6d53e1f",
//               "name": "గంగా జలం / Ganga Water",
//               "description": null,
//               "units": "ml",
//               "standardQty": "50",
//               "costPrice": 6,
//               "pujariSellingPrice": 30,
//               "customerMrp": 30,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:11:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 91
//             }
//           },
//           {
//             "id": "12b82113-b2f3-4e07-9653-3af5ec69d495",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "d5fae081-6678-4cd1-865e-89097def5d16",
//             "samagri": {
//               "id": "d5fae081-6678-4cd1-865e-89097def5d16",
//               "name": "పూల మూరలు/flower buds",
//               "description": "1 మూర",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 35,
//               "pujariSellingPrice": 45,
//               "customerMrp": 45,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T03:13:28.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T06:48:27.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 227
//             }
//           },
//           {
//             "id": "11911396-70b4-4f55-9ac0-ceeeb9625a4a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//             "samagri": {
//               "id": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//               "name": "పచ్చ కర్పూరం /  GREEN CAMPHOR",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 10,
//               "pujariSellingPrice": 12,
//               "customerMrp": 12,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:22:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 99
//             }
//           },
//           {
//             "id": "0493c7aa-8c44-4b65-8d8b-b0457f9fee1f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "98d0dda3-394a-4399-96dd-663bb9e0e1ad",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "9869b490-f93d-4c5a-b4b4-6c69e99f8658",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "addon",
//             "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "samagri": {
//               "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//               "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 210,
//               "pujariSellingPrice": 500,
//               "customerMrp": 500,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:26:10.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 17
//             }
//           },
//           {
//             "id": "96fe5f0c-79d8-4c9e-b784-d39331993b90",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 75,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "95ace273-502c-45fc-8368-615e55b03779",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "17f5dfe8-ebdb-40ed-b278-b73e8cc35ae4",
//             "samagri": {
//               "id": "17f5dfe8-ebdb-40ed-b278-b73e8cc35ae4",
//               "name": "5 types of fruits (Each 2)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 350,
//               "pujariSellingPrice": 400,
//               "customerMrp": 400,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T04:11:15.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-16T05:21:24.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 155
//             }
//           },
//           {
//             "id": "9363087d-832b-4ead-a881-b3daf4703dd7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "88619606-231a-45d1-b3b4-c098538603c3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "88527666-3cc3-4a1d-8c34-254a77a2e375",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "7c2cd2ea-47d7-4dd6-8ba9-a2f28b0c15eb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 50,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "792e93f3-1bf2-4411-b658-4756aeb8ca44",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "samagri": {
//               "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//               "name": "వరిపెళాలు/Rice Fields",
//               "description": null,
//               "units": "gms",
//               "standardQty": "50",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:33:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 58
//             }
//           },
//           {
//             "id": "7767587c-121e-405e-ba96-812daaf6c50e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "76b35c65-66ce-4fda-8afe-57ccfe4e6dbb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "712d340e-f2be-4fc5-a878-ee28047b27c2",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "6ef2aa06-9a7e-4942-ab51-292129a81367",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "6eedc844-fca7-4c1d-8165-5f993787b57c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "6ca2f3a6-0ed2-4c96-aea6-5320a7217a07",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "samagri": {
//               "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//               "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 500,
//               "pujariSellingPrice": 600,
//               "customerMrp": 600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:43:18.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 114
//             }
//           },
//           {
//             "id": "69dbe52e-91e9-43e8-be7a-960498a6d497",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "samagri": {
//               "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//               "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 110,
//               "pujariSellingPrice": 150,
//               "customerMrp": 150,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:29:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 25
//             }
//           },
//           {
//             "id": "64e2ef61-e228-4192-a64d-d2131c7612a8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "62e1623e-6bc8-47d2-adae-451a57b309b2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "6145183b-9cf5-4e18-93ee-08060a27f724",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "546d5154-1cef-44c7-9bdf-e2267c4cbebc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "4fdf6aa9-5e8c-4e46-92f9-531668ab019d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "4d995346-2943-44a8-9a5f-57aa68ae0549",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "4bb692d0-78f0-4082-bc87-666152f1576c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "4b0392d5-174b-40eb-b294-184c61094e1d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 147,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           }
//         ]
//       },
//       {
//         "id": 143,
//         "name": "Shankusthapana  (Bhumi pooja)",
//         "description": "Bhumi pooja is performed to seek the blessings and permission of Goddess Bhoomi or earth and Vastu Purush (the deity of directions), before beginning the construction of a new house / place of business etc. The five elements of nature are also worshipped during the bhumi puja",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": null,
//         "mode3": null,
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/4ijX9N6xEBo\"]",
//         "images": "[\"/uploads/2023/07/Bhoomi-pujas-2.jpg\",\"/uploads/2023/07/Bhoomi-Puja-scaled.jpeg\"]",
//         "updatedAt": "2023-07-20T13:29:45.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-06-15T03:08:11.000Z",
//         "createdById": null,
//         "updatedById": "44",
//         "pujacategorymappings": [
//           {
//             "id": 11,
//             "pujaId": 143,
//             "categoryId": 1,
//             "subCategoryId": 1
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 90,
//             "pujaId": 143,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 6899,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 3000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-28T03:01:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-25T22:27:42.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 914,
//             "samagriCost": 1933,
//             "procedures": [
//               {
//                 "id": "e1cb6f1b-d73d-4936-b658-0f1848eae97c",
//                 "modelId": 90,
//                 "procedureId": 20,
//                 "procedure": {
//                   "id": 20,
//                   "language": "telugu",
//                   "title": "Ashta dikpalaka puja",
//                   "description": "Ashtadikpalakas denote that God is in every direction, and that there is a specific energy and deity in each direction. Each energy has specific qualities and is suitable for different activities. We perform this puja to balance the five elements in these directions.\n\nThe Ashtadikpalakas of various directions are:\nKubera for North,\nYama for South,\nIndra for East,\nVaruna for West,\nIsana for Northeast,\nAgni for Southeast,\nVayu for Northwest,\nNirrti for Southwest.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "881ae0ac-4b2a-43d6-a69c-2b729204d49e",
//                 "modelId": 90,
//                 "procedureId": 24,
//                 "procedure": {
//                   "id": 24,
//                   "language": "telugu",
//                   "title": "Vrata Kadhalu",
//                   "description": "Vrata Kadhalu remind us and re -nforce the main promise (vrata) that we are undertaking as part of the puja.  The stories are designed with characters from the puranas whose smarana (rememberance) has a positive intent and impact on us. Hence the stories are not changed. ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "75d77e1b-7a85-4297-931c-0598fb4c1f28",
//                 "modelId": 90,
//                 "procedureId": 44,
//                 "procedure": {
//                   "id": 44,
//                   "language": "telugu",
//                   "title": "Mantra Pushpam",
//                   "description": "A reminder that the divine force that we seek, pray and worship actually exists within us, and if we are able to follow Sanatana Dharma (the way of life), we can awaken this force and remain at eternal bliss with this presence.  A reminder that the divine and us are 'abhedam' (inseparable) and we are yet to experience this.\n\nThose who experience this enlightnment need not perform pujas anymore and that is our rishis' wish.  They want us to perform pujas, only for us to rise beyond them towards ultimate gyana and moksha",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "ffdb86f3-31fd-4703-8926-81d2b4b2864e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "f3030fa9-274c-4628-a689-f1a1a338e097",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "eb4093e1-206d-4417-8acf-198f547c1ba2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "eb0363f0-b4e4-4edb-a9ec-afb5ca7de7a3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "e080adea-427c-4dda-a25b-9302798a951d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "d8ed8480-c2f3-45c7-be4f-d87fd4d92884",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "d69be70c-59c8-42e1-bb97-0acd7653ea59",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "d536b6af-4172-4fa6-994e-02973c1c3f74",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "samagri": {
//               "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//               "name": "అరటి పండ్లు / Banana",
//               "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//               "units": "no",
//               "standardQty": "12",
//               "costPrice": 60,
//               "pujariSellingPrice": 70,
//               "customerMrp": 70,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-19T22:36:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:26:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 181
//             }
//           },
//           {
//             "id": "d465000a-e29c-44db-ade2-affbc4f3d5ba",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "c5e0500f-8f20-4eff-83ef-253e1e1038b8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "c5d056c3-1e92-415b-ac5a-2f042dda3576",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "b8db253f-acdc-44ce-8f5a-29cd67f3544a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "b2f3f694-a80a-4961-b2c7-3011d924b4b4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "af0ab536-15dc-4d8c-bc8c-e653c9b50d0f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "a2193541-6d45-415a-a873-dfa78ce173f4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "a13c6f75-8312-4f3c-8069-a2b1337cbb5a",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "9da2a3f6-aff6-4085-a7d3-92a04bc29a5d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "946f1915-4fe5-4848-a070-cc2ee7eb5023",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "9349f144-444e-4297-bf51-de4d6986bad4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "8ec0e050-14d2-4349-8b17-691b9f7c8732",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "samagri": {
//               "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//               "name": "దోపళ్ళు / Doppa",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 18,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:58:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 44
//             }
//           },
//           {
//             "id": "8c8c83fc-4286-4947-b1fb-30050c0251cc",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "samagri": {
//               "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//               "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 18,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:01:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 48
//             }
//           },
//           {
//             "id": "819f1bd8-e7cb-4e8b-b96a-9ecd89424866",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "samagri": {
//               "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//               "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 230,
//               "pujariSellingPrice": 270,
//               "customerMrp": 270,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:31:17.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 23
//             }
//           },
//           {
//             "id": "7ecfd689-70c7-4abe-9bf7-0c7736759a53",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "776ea4dc-4a4b-494f-8e0e-2b7f4e3ab060",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "6d85b324-1926-42e3-a111-e8207461658d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "5ddea222-90ea-4fe4-8661-a0c64bd5d181",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "5484201d-d09c-440d-a938-4c814dfd5517",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "53947e0c-ee08-4548-a46c-81fc550bfd95",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "addon",
//             "samagriId": "07dd931d-1a74-40fe-8f3e-b4def95054e6",
//             "samagri": {
//               "id": "07dd931d-1a74-40fe-8f3e-b4def95054e6",
//               "name": "నవరత్నాలు / Navratnas ( Best Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 3650,
//               "pujariSellingPrice": 3950,
//               "customerMrp": 3950,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:56:40.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T03:54:07.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 132
//             }
//           },
//           {
//             "id": "4eef5347-ff88-4cf8-ba7f-e5eab3619d84",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "49cf6827-6473-428f-9761-1c652416040e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "41b26e98-2423-4445-8a56-3dcf880452f7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "350a3c49-e86e-4214-a75f-fe1af85bbf3b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "3462a924-21d4-4b6e-8abb-3e619fceb114",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "341988cb-f421-4d5d-b770-00c496f69e2d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "9372c01d-6536-4327-ad2d-bd9cc6d53e1f",
//             "samagri": {
//               "id": "9372c01d-6536-4327-ad2d-bd9cc6d53e1f",
//               "name": "గంగా జలం / Ganga Water",
//               "description": null,
//               "units": "ml",
//               "standardQty": "50",
//               "costPrice": 6,
//               "pujariSellingPrice": 30,
//               "customerMrp": 30,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:11:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 91
//             }
//           },
//           {
//             "id": "31728f8f-8ed5-45fe-a504-efbf31b5b1e2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "30aa6ab3-2392-454b-8f65-d2a93694c79b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "29d47fee-3e59-4a48-9e39-4f72a62bca6f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "292d03b4-103f-4c7b-b1c6-ec01aef6ff81",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "248271be-8081-4cb6-87c8-2aa16a33eb1f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "1cd04d43-0359-42fe-9b25-1408b000be93",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//             "samagri": {
//               "id": "92da20c4-a10e-4013-9364-f5114438d904",
//               "name": "వీడి పూలు / Loose Flowers",
//               "description": null,
//               "units": "gms",
//               "standardQty": "250",
//               "costPrice": 110,
//               "pujariSellingPrice": 120,
//               "customerMrp": 120,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:16:53.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:44:42.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 176
//             }
//           },
//           {
//             "id": "173fadb4-f17d-4085-9f78-00ae0f723087",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "117fbe14-6189-4fb8-a001-4882cd005b82",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "0a1f9116-111a-496e-b61f-e08d4dc7c17d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "default",
//             "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "samagri": {
//               "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//               "name": "చెంబులు/Kalasam",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:26.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:39:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 253
//             }
//           },
//           {
//             "id": "03aca55c-c601-44c6-ba05-e65497aee60a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 143,
//             "samagriType": "addon",
//             "samagriId": "2236e37c-234f-4d44-a9c0-8cfb98f6bf53",
//             "samagri": {
//               "id": "2236e37c-234f-4d44-a9c0-8cfb98f6bf53",
//               "name": "పంచలోహాలు / Five Metals ( Best Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 8500,
//               "pujariSellingPrice": 8900,
//               "customerMrp": 8900,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T07:01:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T03:53:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 133
//             }
//           }
//         ]
//       },
//       {
//         "id": 10,
//         "name": "Gruhapravesham",
//         "description": "Gruhapravesha puja is designed to make a home’s space / environment conducive for the people living in it and to help them move towards their wellbeing. \nThis puja is a culmination of several branches of scriptures/ shastras, and is intended for balancing out any vaastu doshas, and imbalances in the five basic elements and the eight directions.\n",
//         "language": "telugu",
//         "region": "Hyderabad",
//         "mode1": "at_my_home",
//         "mode2": null,
//         "mode3": null,
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/4ijX9N6xEBo\",\"https://www.youtube.com/embed/w_JnwafQQNw\"]",
//         "images": "[\"/uploads/2023/07/Gruhapravesham-2.jpeg\",\"/uploads/2023/07/Gruhapravesham-1-scaled.jpg\"]",
//         "updatedAt": "2023-07-18T03:19:22.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": null,
//         "createdById": null,
//         "updatedById": "47",
//         "pujacategorymappings": [
//           {
//             "id": 3,
//             "pujaId": 10,
//             "categoryId": 1,
//             "subCategoryId": 1
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 71,
//             "pujaId": 10,
//             "durationHrs": 4,
//             "noOfPujaris": 2,
//             "modelSellingPrice": 24822,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 12000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-28T03:09:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-24T03:36:26.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 1159,
//             "samagriCost": 7877,
//             "procedures": [
//               {
//                 "id": "86086fd6-d5ca-4100-be33-69bf4a5472c0",
//                 "modelId": 71,
//                 "procedureId": 16,
//                 "procedure": {
//                   "id": 16,
//                   "language": "telugu",
//                   "title": "Ganapati Puja",
//                   "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "0bc335c5-84a0-4829-a170-1c04567dfd38",
//                 "modelId": 71,
//                 "procedureId": 6,
//                 "procedure": {
//                   "id": 6,
//                   "language": "telugu",
//                   "title": "Punyaha vachanam (Laghu)",
//                   "description": "Punyaham means auspicious, and vachanam means to recite. In this step, mantras are recited which purify us and our surroundings and bring forth the auspiciousness required before performing a vedic action. This step is given in yajurveda and rigveda, and requires kalasham to perform it. It is performed to purify 8 things which are: Gruha, bhu, bhanda, atma, dravya, jala, sthala, shareera, and padartham.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "2640cf81-3fd9-4b22-b0cb-7f2ab2cc8beb",
//                 "modelId": 71,
//                 "procedureId": 14,
//                 "procedure": {
//                   "id": 14,
//                   "language": "telugu",
//                   "title": "Aashirvachanam",
//                   "description": "The guru aashirvachanam is the biggest blessing for the devotee or the family performing the puja.  Guru is eeshwara's form in front of us. His tapasya and veda parayana, nithya gayatri japa etc. mean that his blessings can lead to god's grace.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "aabd56b4-ea56-4fa7-bf59-7d7b41cf4e78",
//                 "modelId": 71,
//                 "procedureId": 7,
//                 "procedure": {
//                   "id": 7,
//                   "language": "telugu",
//                   "title": "Raksha bandanam ",
//                   "description": "A sacred thread is tied to the individuals performing the puja, while chanting mantras. This signifies their determination to begin the ceremony.",
//                   "specialNotes": "testing 32"
//                 }
//               },
//               {
//                 "id": "81138eef-38fe-4fc4-84cc-55c7eaef588f",
//                 "modelId": 71,
//                 "procedureId": 8,
//                 "procedure": {
//                   "id": 8,
//                   "language": "telugu",
//                   "title": "Mandaparadhana",
//                   "description": "Aavahanam of navagraha, ashtadikpalaka, panchalokapalaka, trimurthulu, ishta devata, kula devata, and nakshatra devatalu.",
//                   "specialNotes": "testing 8888"
//                 }
//               },
//               {
//                 "id": "9627b226-9662-4b28-afab-d14509fc6b5d",
//                 "modelId": 71,
//                 "procedureId": 9,
//                 "procedure": {
//                   "id": 9,
//                   "language": "telugu",
//                   "title": "Vaastu Kalasa sthapanam and Puja",
//                   "description": "Vaastu purusha aavahana is performed into the kalasha thus enabling the devotees to pray and seek for the blessings of an amsha of this abstract form that governs the space that we are going to live / work in. Depending on the tradition followed in the yajamani (doer's) house, kalasha prathista is done and sometimes not. ",
//                   "specialNotes": "Few customers opt for very detailed and elaborate vaasthu puja which includes things like: sarvathobhadra mandalam, vaasthu bomma / bimbam etc.. You can discuss with pujari in the call if you are interested in the same."
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "53c6358a-39d0-46e2-a6cd-a760cf522a11",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 100,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "51ecad5b-8fe8-4109-8e75-bca0db8c9689",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 10,
//             "samagriType": "addon",
//             "samagriId": "6c40c524-ce9e-4e76-b9d8-23bb3598b511",
//             "samagri": {
//               "id": "6c40c524-ce9e-4e76-b9d8-23bb3598b511",
//               "name": "కొత్త నీళ్ల బిందేళ్లు/ NEW WATER POTS (Steel)",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 1500,
//               "pujariSellingPrice": 1600,
//               "customerMrp": 1600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:40:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:04:26.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 189
//             }
//           },
//           {
//             "id": "4e00a214-7e8f-442d-b102-4aed26ffd584",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//             "samagri": {
//               "id": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//               "name": "గోమయం/Cow Dung",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-13T06:33:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:27:00.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 230
//             }
//           },
//           {
//             "id": "4dd09820-7b6e-4936-a541-addc97619897",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "samagri": {
//               "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//               "name": "వరిపెళాలు/Rice Fields",
//               "description": null,
//               "units": "gms",
//               "standardQty": "50",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:33:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 58
//             }
//           },
//           {
//             "id": "4cf59da6-6edb-4211-b98f-847406ccfb11",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 15,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "4b996a63-3442-4e10-b9f7-d9c7503305f9",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "samagri": {
//               "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//               "name": "చెంబులు/Kalasam",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:26.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:39:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 253
//             }
//           },
//           {
//             "id": "4b818a6d-f768-4e3f-85f1-d2dfc628616e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "samagri": {
//               "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//               "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 3,
//               "customerMrp": 3,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:27:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 7
//             }
//           },
//           {
//             "id": "4a5d676e-f6c0-405a-a0cc-ac45dc945ebf",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "429f9834-d2bd-4c5f-af9a-02dc1d85696e",
//             "samagri": {
//               "id": "429f9834-d2bd-4c5f-af9a-02dc1d85696e",
//               "name": "Fruits and Flowers (Satyanarayana Vratam) (Premium)",
//               "description": "Bananas 6 No ,  Loose Flowers 1/2 Kg  ,  Poola Morallu(2)   Moora Tulasi Mala (1),   Thulasi dhalam 50g  4 Types of Fruits 2 each",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 815,
//               "pujariSellingPrice": 978,
//               "customerMrp": 978,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-15T02:38:36.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-15T00:00:34.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 265
//             }
//           },
//           {
//             "id": "47762bb2-e84d-468c-ab48-aab82d91e207",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "addon",
//             "samagriId": "1c95dca1-e487-47dc-b510-3c026d51e96f",
//             "samagri": {
//               "id": "1c95dca1-e487-47dc-b510-3c026d51e96f",
//               "name": "పాలు పొంగించే గిన్నె1/2 kg / Doodhwala Patila ",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 550,
//               "pujariSellingPrice": 700,
//               "customerMrp": 700,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T01:34:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-19T04:16:02.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 66
//             }
//           },
//           {
//             "id": "46902cba-1759-40b6-84bb-89bea87a0aff",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "40c13cbe-2b66-413d-ad48-10558011f91b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "3c85c937-91e8-4ec4-ba31-3a55350066f0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "samagri": {
//               "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//               "name": "సమిధలు / Homam Samithu Sticks",
//               "description": null,
//               "units": "bunch",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 60,
//               "customerMrp": 60,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:37:34.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 54
//             }
//           },
//           {
//             "id": "3aef2d97-281d-4fb2-a858-62addf0ae34c",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "3a452a6a-8590-420a-85e2-a597a25eefaf",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "36cdaa47-09ec-4fd2-98a5-b463002cd29d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 35,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "samagri": {
//               "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//               "name": "ఇటుకలు/ BRICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 8,
//               "pujariSellingPrice": 13,
//               "customerMrp": 13,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:39:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:14:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 150
//             }
//           },
//           {
//             "id": "36378b51-a190-4b5c-a4d8-8ec58a097f2a",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "2bf2075b-4905-4e62-9919-d55b4e61a2ce",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "samagri": {
//               "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//               "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 3,
//               "pujariSellingPrice": 4,
//               "customerMrp": 4,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:38:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 115
//             }
//           },
//           {
//             "id": "26b5eb4a-6960-4046-b887-0154250c1d9f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "2559c28b-3507-4c93-a5a3-55098364af9d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "3be47417-fac4-4cc9-bd82-e3ba00efc6b4",
//             "samagri": {
//               "id": "3be47417-fac4-4cc9-bd82-e3ba00efc6b4",
//               "name": "బూడిద గుమ్మడి కాయ/Ash gourd",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 150,
//               "pujariSellingPrice": 160,
//               "customerMrp": 160,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:48:31.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:17:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 190
//             }
//           },
//           {
//             "id": "21191387-5ccb-4520-9d47-22cc86709ba8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "20c30b17-c4fb-42e8-9a9c-e4bfcaa96202",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "1ffefed0-4b46-4a20-b8b0-38b7db4973cb",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 60,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "1a25b1b6-21f1-48fb-a9c1-80f5a4833e41",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "1348ed40-4836-48e5-b726-3249525a01ac",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 16,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "0d934e09-d552-450e-bfc3-92d09ad52ba5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "0031beaf-9312-467e-853a-b2243ce677f5",
//             "samagri": {
//               "id": "0031beaf-9312-467e-853a-b2243ce677f5",
//               "name": "గోమూత్రం/Cow Urine",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:17:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:26:29.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 231
//             }
//           },
//           {
//             "id": "0d76b2f8-8705-44d0-9eca-4bd0ce5b61c2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "644a01ca-da37-415f-8d5b-8b1d4c6c17a0",
//             "samagri": {
//               "id": "644a01ca-da37-415f-8d5b-8b1d4c6c17a0",
//               "name": "ఉట్టి / Utti",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 40,
//               "customerMrp": 40,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:37:09.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 38
//             }
//           },
//           {
//             "id": "0845ff30-911b-41ef-87e5-9ba873035788",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "05bf9806-6c69-46de-bc77-6f1500088926",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "037f772b-d132-4250-bf53-f923c5841b3b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "d392818e-706e-4287-9818-c92b4d24342a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "samagri": {
//               "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//               "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 230,
//               "pujariSellingPrice": 270,
//               "customerMrp": 270,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:31:17.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 23
//             }
//           },
//           {
//             "id": "c83d240c-6ce9-4f1f-abc7-ab73322ea2c2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "a844072d-3461-43c9-ae33-96c0668987af",
//             "samagri": {
//               "id": "a844072d-3461-43c9-ae33-96c0668987af",
//               "name": "ఇత్తడి గ్లాసు / Brass Glass (250ml)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 50,
//               "pujariSellingPrice": 75,
//               "customerMrp": 75,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:26:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 18
//             }
//           },
//           {
//             "id": "c5f1402a-1377-41f1-a122-ccee85701069",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "c583ed1e-7378-4417-9ad1-2fe599568799",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "samagri": {
//               "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//               "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 500,
//               "pujariSellingPrice": 600,
//               "customerMrp": 600,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T03:43:18.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 114
//             }
//           },
//           {
//             "id": "c4a82f87-ca16-48d9-b7d1-dc8271671273",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "c1ff3528-f0ac-4c2f-8393-e7cee2365c34",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           },
//           {
//             "id": "bd2bf9f4-3501-406b-920d-5b61c45d589e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 100,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "b90c15f7-d4da-46a7-9d2a-0b3ff0ae6afc",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "b89db2a2-697f-4f6e-ab49-a58a35979f4d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "b31390e4-0e15-4cea-9d66-ac85cd50159e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "b149a625-aa5e-4f14-91aa-29bda9fb0788",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "samagri": {
//               "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//               "name": "గరిక/  Grass",
//               "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 20,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:31:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:11:34.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 183
//             }
//           },
//           {
//             "id": "ad314276-b5cc-4ed8-a926-8c645a355259",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "b19dc2b1-0b51-4031-91ed-9a61d63985f2",
//             "samagri": {
//               "id": "b19dc2b1-0b51-4031-91ed-9a61d63985f2",
//               "name": "జాజు / Ocher powder/ Geru Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 8,
//               "customerMrp": 8,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:50:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T03:23:59.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 122
//             }
//           },
//           {
//             "id": "a88cafb1-8ad0-4df5-8eee-31360a87d8db",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "a08ed3a7-1088-4263-924f-603f6fce657d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "9a9d1bfb-3417-4fc0-bf3c-1c2f04117a2a",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "8dddc121-4f77-4aab-9c8a-6013158917d7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 8,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "samagri": {
//               "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//               "name": "బెల్లం / Jaggery",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:23:52.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T01:22:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 143
//             }
//           },
//           {
//             "id": "8acf4d80-8c1b-4188-80e0-35e0cd43d2b1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "8648c461-7be4-4726-8643-7f79a7007a70",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "824876d0-2f8e-4829-a4aa-672c3a2576f5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "addon",
//             "samagriId": "48a8178f-db8e-42f4-bcef-9d2dcee46d21",
//             "samagri": {
//               "id": "48a8178f-db8e-42f4-bcef-9d2dcee46d21",
//               "name": "సత్యనారాయణ స్వామి మహా-ప్రసాద్ పదార్థాలు  / Satyanarayana Vrata Prasadam",
//               "description": "Godhuma Ravva 1 1/4 kg ,  Kaju 50g ,  Kismiss 50g ,   Badam 50 g\n\nగోధుమ రవ్వ\t1 1/4 kg,     కాజు\t50g,     కిస్మిస్\t50g,    బాదం\t50 g",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 427,
//               "pujariSellingPrice": 450,
//               "customerMrp": 450,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:36:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:59:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 175
//             }
//           },
//           {
//             "id": "7f64ba07-f66f-4dcc-b916-7190b713c536",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 3,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "samagri": {
//               "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//               "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 18,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:01:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 48
//             }
//           },
//           {
//             "id": "7c1e182c-121f-48c0-b494-3795c7a0dd71",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 24,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "d87297fb-4fd9-4014-b4d8-12642e1975a7",
//             "samagri": {
//               "id": "d87297fb-4fd9-4014-b4d8-12642e1975a7",
//               "name": "నిమ్మకాయలు/LEMON",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 5,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:45:11.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-01T04:00:03.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 173
//             }
//           },
//           {
//             "id": "7b7ac32d-7c6f-445b-a9f9-fe51c07eef70",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "7553eb56-ee70-4b2a-8b06-89760952bee0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "04902608-1beb-43e6-bb11-f579bf3da0f5",
//             "samagri": {
//               "id": "04902608-1beb-43e6-bb11-f579bf3da0f5",
//               "name": "తెల శైలలు / White Cloth",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 110,
//               "pujariSellingPrice": 130,
//               "customerMrp": 130,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T02:13:06.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 46
//             }
//           },
//           {
//             "id": "733e7f98-ba2a-4f69-9ecc-52ba367517c3",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "samagri": {
//               "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//               "name": "దర్భ కట్టలు/Kusha Grass",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 15,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T06:06:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 53
//             }
//           },
//           {
//             "id": "6eb70219-7ed2-4341-9e59-798218823518",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "62b714dd-f071-4c4c-9e07-1f82f37bf0fd",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "1818135a-6d1f-487d-b65d-e920835036c1",
//             "samagri": {
//               "id": "1818135a-6d1f-487d-b65d-e920835036c1",
//               "name": " కొబ్బరి బొండం / Coconut Bondam",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 50,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T03:06:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-01T04:04:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 174
//             }
//           },
//           {
//             "id": "61d31258-91ad-43a3-80f6-047577d99a10",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "5ef43bfa-8d74-4f72-8a13-19bb90dbc658",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "5d6ea2d6-4d8b-46e5-953b-eb2612114616",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "5d2a7054-fe74-4b2b-9c36-b99d16d39a24",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "58894572-5dea-470c-9121-87bd01af66d5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 10,
//             "samagriType": "addon",
//             "samagriId": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//             "samagri": {
//               "id": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//               "name": "రాగికళశాలు (1/2 ltr)/Copper kalasam (Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 183,
//               "pujariSellingPrice": 300,
//               "customerMrp": 300,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:25:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 16
//             }
//           },
//           {
//             "id": "5789ace8-3018-4dd7-aa20-7f367b8fa998",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "addon",
//             "samagriId": "7c45717e-0684-455f-af8a-4dca8140c21b",
//             "samagri": {
//               "id": "7c45717e-0684-455f-af8a-4dca8140c21b",
//               "name": "FRUITS & FLOWERS/ పూలు పండ్లు (GRUHAPRAVESAM) (Medium)",
//               "description": " పువ్వులు\t1/2 KG,     పూల మూరలు 4,         అరటిపండ్లు 1 1/2 Dozen,       పండ్లు\t4రకాలు 5    each",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 900,
//               "pujariSellingPrice": 1080,
//               "customerMrp": 1080,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-15T02:39:40.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T03:54:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 147
//             }
//           },
//           {
//             "id": "55d9a50c-5da6-4317-9334-0620b839f7f8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "samagri": {
//               "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//               "name": "వరిపిండి/Rice Powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 5,
//               "pujariSellingPrice": 7,
//               "customerMrp": 7,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:34:35.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 59
//             }
//           },
//           {
//             "id": "fe5ca8af-db16-4211-a12b-2386ff90ee7e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "samagri": {
//               "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//               "name": "అగ్గి పెట్టిలు (Homelite Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 2,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:47:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-18T02:51:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 116
//             }
//           },
//           {
//             "id": "f843c803-3191-4b73-8556-291f7b21e366",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 10,
//             "samagriType": "addon",
//             "samagriId": "e2ea4998-041f-4027-8c31-50a68f5b885f",
//             "samagri": {
//               "id": "e2ea4998-041f-4027-8c31-50a68f5b885f",
//               "name": "అరటి పిల్లకల్లు/ Banana Plant ",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 40,
//               "pujariSellingPrice": 45,
//               "customerMrp": 45,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-12T01:27:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T05:28:24.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 199
//             }
//           },
//           {
//             "id": "f40cd2ca-5efb-4ff5-ad37-16aff052de26",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "addon",
//             "samagriId": "c4b1db1f-6f75-4669-b942-1e3535e2be53",
//             "samagri": {
//               "id": "c4b1db1f-6f75-4669-b942-1e3535e2be53",
//               "name": "FRUITS & FLOWERS/ పూలు పండ్లు (GRUHAPRAVESAM) (Premium)",
//               "description": "పువ్వులు\t1/2 KG,     పూల మూరలు 4,         అరటిపండ్లు 1 1/2 Dozen,       పండ్లు\t4రకాలు 5    each",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 1370,
//               "pujariSellingPrice": 1644,
//               "customerMrp": 1644,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-15T02:39:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-14T23:50:59.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 264
//             }
//           },
//           {
//             "id": "f0d7b540-8aea-4977-921b-f5c9dc6b834a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "e99698f9-2d61-4f70-b4db-c49bb1dab303",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "ddf6471b-2a08-4e89-9dc8-62935a033e90",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//             "samagri": {
//               "id": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//               "name": "గుమ్మకాయడి/ PUMPKIN",
//               "description": "1",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 140,
//               "pujariSellingPrice": 150,
//               "customerMrp": 150,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-14T02:50:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:14:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 191
//             }
//           },
//           {
//             "id": "d69c1980-2f50-4d91-9adb-ab18204bd218",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           },
//           {
//             "id": "d5235a47-3a3e-46d7-a124-815950a3a1af",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 10,
//             "samagriType": "default",
//             "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "samagri": {
//               "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//               "name": "ఇసుక / SAND",
//               "description": "As Gampallu",
//               "units": "other",
//               "standardQty": "1",
//               "costPrice": 100,
//               "pujariSellingPrice": 125,
//               "customerMrp": 125,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:28:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-31T02:16:43.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 151
//             }
//           }
//         ]
//       },
//       {
//         "id": 4,
//         "name": "Naamakaranam",
//         "description": "A puja to help name the baby most appropriately, in the presence of vedic mantras and a guru's blessing. This is one of the sixteen vedic samskaras in Sanatana Dharma",
//         "language": "telugu",
//         "region": "Hyderabad",
//         "mode1": "at_my_home",
//         "mode2": "remote",
//         "mode3": null,
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/SsRsg2jC-ec\",\"https://www.youtube.com/embed/CkuMjlP8w9A\",\"https://www.youtube.com/embed/qrdxFxKIYNY\",\"https://www.youtube.com/embed/tQJc70v5O2o\"]",
//         "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//         "updatedAt": "2023-07-05T05:19:42.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": null,
//         "createdById": null,
//         "updatedById": "9",
//         "pujacategorymappings": [
//           {
//             "id": 1,
//             "pujaId": 4,
//             "categoryId": 1,
//             "subCategoryId": 1
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 74,
//             "pujaId": 4,
//             "durationHrs": 2,
//             "noOfPujaris": 2,
//             "modelSellingPrice": 5000,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 3000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-07-25T06:02:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-24T04:40:52.000Z",
//             "createdById": null,
//             "updatedById": "47",
//             "serviceFee": 722,
//             "samagriCost": 209,
//             "procedures": [
//               {
//                 "id": "6c5f39b9-f797-4ab7-9c51-631a8f14c7d8",
//                 "modelId": 74,
//                 "procedureId": 15,
//                 "procedure": {
//                   "id": 15,
//                   "language": "telugu",
//                   "title": "Sankalpam",
//                   "description": "Sankalpa is the reason for performing this puja. The puja depends upon the Sankalpa or will of the participants. Each puja is performed for a definite purpose and it is this purpose that the performer and participants must hold in their hearts as they take the Sankalpa. \nThe Sankalpa mantra begins by stating the name of the present year, month, season, phase of the moon, the day, time, and the Nakshatra, as per the Indian Calendar. \nIt then states the name of the deity, whom we are invoking for this puja. And finally, the individual or family who will be performing this puja, and the purpose of the puja.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "fd7d817a-9c7d-457b-9625-1cbc1cc17e20",
//                 "modelId": 74,
//                 "procedureId": 16,
//                 "procedure": {
//                   "id": 16,
//                   "language": "telugu",
//                   "title": "Ganapati Puja",
//                   "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "3ed1a1b3-6cf2-48d3-bcad-17ced758f509",
//                 "modelId": 74,
//                 "procedureId": 6,
//                 "procedure": {
//                   "id": 6,
//                   "language": "telugu",
//                   "title": "Punyaha vachanam (Laghu)",
//                   "description": "Punyaham means auspicious, and vachanam means to recite. In this step, mantras are recited which purify us and our surroundings and bring forth the auspiciousness required before performing a vedic action. This step is given in yajurveda and rigveda, and requires kalasham to perform it. It is performed to purify 8 things which are: Gruha, bhu, bhanda, atma, dravya, jala, sthala, shareera, and padartham.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "95ea2c02-d779-49a3-afe6-34fe6b5713dc",
//                 "modelId": 74,
//                 "procedureId": 14,
//                 "procedure": {
//                   "id": 14,
//                   "language": "telugu",
//                   "title": "Aashirvachanam",
//                   "description": "The guru aashirvachanam is the biggest blessing for the devotee or the family performing the puja.  Guru is eeshwara's form in front of us. His tapasya and veda parayana, nithya gayatri japa etc. mean that his blessings can lead to god's grace.",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "efb6e531-c961-4e68-9dcd-38b9d72b8450",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 4,
//             "samagriType": "addon",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "ec0808a2-a192-4861-80a1-f36d92a16fef",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 4,
//             "samagriType": "addon",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "dcedf57a-2820-4552-9554-d3cca48dac58",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "dc3fa43d-de76-4bef-a58f-229a6d673209",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "d2699100-83d7-45ed-ae65-cc7efcc3e9c6",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 12,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "d0e94381-5e6e-4b57-9d8b-342477135552",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "cde4b702-da1f-424b-88fb-dcbc4180235f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "b59f017a-24e4-4b27-8e9e-6bcc2fcf91e1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "82942519-94e4-4a77-a088-c94c5e9c3cf7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//             "samagri": {
//               "id": "92da20c4-a10e-4013-9364-f5114438d904",
//               "name": "వీడి పూలు / Loose Flowers",
//               "description": null,
//               "units": "gms",
//               "standardQty": "250",
//               "costPrice": 110,
//               "pujariSellingPrice": 120,
//               "customerMrp": 120,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T05:16:53.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-05T04:44:42.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 176
//             }
//           },
//           {
//             "id": "6acf4acf-a97d-4668-8379-cfaa4a6ad024",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "6a36259b-402c-48a0-b0a5-17f334ef90ab",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "5f0a95c7-0f7f-416f-bf3d-941b18ffea5e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "43e4b66e-341e-4406-b596-fe35d01ef217",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "30a43050-3da6-4386-9fde-8f91c204ceab",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "2cb9973b-8b21-40a6-955f-f37947f7955e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "1cf280f0-f049-4c9c-a38b-f3999e0ee20d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "17a0d692-6905-4f84-84c5-82e73c989740",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 4,
//             "samagriType": "addon",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "12f4d2bb-d711-4916-a94e-742ca077850b",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "0879374e-a83d-4b4a-983d-14b04b0cca50",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 4,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           }
//         ]
//       },
//       {
//         "id": 24,
//         "name": "Satyanarayana Vratam",
//         "description": "Sri Satyanarayana Pooja Vrat is performed with the sankalpam (resolve) to Lord Vishnu in the form of Sri Satya Narayana Swamy, seeking his benevolence to remove all the Obstacles and negative Forces and lead to victory or duccess in all the endeavours. Sri Satyanarayana Vrata is performed with puja offerings and satyanarayana katha is recited. This vrata is also observed after important events like gruhapravesha, the beginning of new ventures, marriage, or after the successful completion of major events.",
//         "language": "telugu",
//         "region": "Hyderabad",
//         "mode1": "at_my_home",
//         "mode2": null,
//         "mode3": null,
//         "mode4": null,
//         "videos": "[\"https://www.youtube.com/embed/w_JnwafQQNw\",\"https://www.youtube.com/embed/uZjCFWCu7eM\",\"https://www.youtube.com/embed/4z4DcaIPVAA\"]",
//         "images": "[\"/uploads/2023/07/satyanarayan_vratam.jpg\"]",
//         "updatedAt": "2023-07-13T07:02:37.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": null,
//         "createdById": null,
//         "updatedById": "9",
//         "pujacategorymappings": [
//           {
//             "id": 5,
//             "pujaId": 24,
//             "categoryId": 1,
//             "subCategoryId": 1
//           },
//           {
//             "id": 32,
//             "pujaId": 24,
//             "categoryId": 2,
//             "subCategoryId": 4
//           },
//           {
//             "id": 33,
//             "pujaId": 24,
//             "categoryId": 2,
//             "subCategoryId": 8
//           }
//         ],
//         "pujamodels": [
//           {
//             "id": 70,
//             "pujaId": 24,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 5999,
//             "advanceAmount": null,
//             "isPopularModel": true,
//             "pujariCost": 3000,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-10-05T08:11:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-24T01:46:40.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 690,
//             "samagriCost": 1394,
//             "procedures": [
//               {
//                 "id": "ec627726-5c53-4f40-bb91-f3fe74f06457",
//                 "modelId": 70,
//                 "procedureId": 15,
//                 "procedure": {
//                   "id": 15,
//                   "language": "telugu",
//                   "title": "Sankalpam",
//                   "description": "Sankalpa is the reason for performing this puja. The puja depends upon the Sankalpa or will of the participants. Each puja is performed for a definite purpose and it is this purpose that the performer and participants must hold in their hearts as they take the Sankalpa. \nThe Sankalpa mantra begins by stating the name of the present year, month, season, phase of the moon, the day, time, and the Nakshatra, as per the Indian Calendar. \nIt then states the name of the deity, whom we are invoking for this puja. And finally, the individual or family who will be performing this puja, and the purpose of the puja.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "78b0b7d6-8bb8-48fd-b1d5-ed173488fc96",
//                 "modelId": 70,
//                 "procedureId": 16,
//                 "procedure": {
//                   "id": 16,
//                   "language": "telugu",
//                   "title": "Ganapati Puja",
//                   "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "5f7555c1-b934-461c-86aa-5e18bdbe1051",
//                 "modelId": 70,
//                 "procedureId": 17,
//                 "procedure": {
//                   "id": 17,
//                   "language": "telugu",
//                   "title": "Mandapam",
//                   "description": "A devotee can worship his/ her Ishta devata, to help them achieve their wishes and to eventually move onto Moksha. Since, It is not easy to concentrate on the formless, we invite the devata to descend into a form. A devata is pure consciousness, who does not have a form. Just like water, the devata can be invited to take the form of the vessel, known as an Upadhi. It is like freezing water into an ice-block of desired shape.  Mandapam is a suitable place that is prepared prior to starting the puja where devatas can be invoked and concecrated until the puja ends. ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "e64a3efd-5f18-4b65-a8a8-457c3b3ca851",
//                 "modelId": 70,
//                 "procedureId": 18,
//                 "procedure": {
//                   "id": 18,
//                   "language": "telugu",
//                   "title": "Kalasa aaradhana",
//                   "description": "Here, water in a pot acquires the qualities of holy rivers, through the chanting of mantras. The 'Kalasa' accumulates all the positive vibrations during the puja and the same is transferred to our bodies when its water is sprinkled on our head and food items at the end of the puja.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "c42f6c66-7156-4d32-a323-f81b29dce936",
//                 "modelId": 70,
//                 "procedureId": 19,
//                 "procedure": {
//                   "id": 19,
//                   "language": "telugu",
//                   "title": "Pancha loka palaka puja",
//                   "description": "In Sanatana Dharma, different worlds exist together like a multiverse, called lokas.\nFor Example: When we refer to the Vaikuntha Loka, ‘Vai’ means ‘No’ and ‘Kuntha’ means ‘anxiety’. So, for the people living in Vaikuntha there is ‘No-anxiety’. Similarly, Kailasha is called the loka of Lord Shiva, where his devotees reside. \nIn Pancha Loka Palaka Puja, we bow down to the rulers of five lokas: Ganapati Loka, Brahma Loka, Vishnu Loka, Rudra Loka, and Gouri Loka.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "314cd5ab-ab88-4bc8-af8a-9dd3d694edbb",
//                 "modelId": 70,
//                 "procedureId": 20,
//                 "procedure": {
//                   "id": 20,
//                   "language": "telugu",
//                   "title": "Ashta dikpalaka puja",
//                   "description": "Ashtadikpalakas denote that God is in every direction, and that there is a specific energy and deity in each direction. Each energy has specific qualities and is suitable for different activities. We perform this puja to balance the five elements in these directions.\n\nThe Ashtadikpalakas of various directions are:\nKubera for North,\nYama for South,\nIndra for East,\nVaruna for West,\nIsana for Northeast,\nAgni for Southeast,\nVayu for Northwest,\nNirrti for Southwest.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "4716beae-48a5-4d45-ab97-82ce82275d48",
//                 "modelId": 70,
//                 "procedureId": 21,
//                 "procedure": {
//                   "id": 21,
//                   "language": "telugu",
//                   "title": "Satyanarayana Puja",
//                   "description": "Satyanarayana Puja",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "c6546fb2-f194-4938-829e-23d81bc871be",
//                 "modelId": 70,
//                 "procedureId": 22,
//                 "procedure": {
//                   "id": 22,
//                   "language": "telugu",
//                   "title": "Abhishekam (Panchamrutalu)",
//                   "description": "Abhishekam can be seen as watering the roots of a tree. Only if the roots of a tree are nourished, it can produce fruits. During an Abhishekam, the results that we desire to see in this world are like the fruits of a tree, and the divine beings who influence these results are like roots. Since, everything in this universe is interconnected, we perform Abhishekam to see specific results in the world.",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "8b9968f2-9883-43d0-b080-cab0ec6ff022",
//                 "modelId": 70,
//                 "procedureId": 23,
//                 "procedure": {
//                   "id": 23,
//                   "language": "telugu",
//                   "title": "Archana ",
//                   "description": "During Archana, we offer flowers to a deity while chanting mantras or names of the deity. The flowers that we offer have a fragrance. Another name for fragrance is vasana(tendency). Therefore, offering flowers to a deity represents offering all our vasanas to the deity. And, while we let go of our vasanas, we chant the names of the deity, to connect with the divine.\n\nSometimes archana is performed with other dhravya like leaves that are most suitable for that particular devata. For e.g. tulsi, bilva etc. ",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "143061e3-3127-4a16-96ec-56a7c1f2edc8",
//                 "modelId": 70,
//                 "procedureId": 24,
//                 "procedure": {
//                   "id": 24,
//                   "language": "telugu",
//                   "title": "Vrata Kadhalu",
//                   "description": "Vrata Kadhalu remind us and re -nforce the main promise (vrata) that we are undertaking as part of the puja.  The stories are designed with characters from the puranas whose smarana (rememberance) has a positive intent and impact on us. Hence the stories are not changed. ",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "8dab2712-5702-40d3-9912-d8d6b9967d4f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "samagri": {
//               "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//               "name": "మామిడి కొమ్మలు / Mango Leaves",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 25,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:35:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:17:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 145
//             }
//           },
//           {
//             "id": "8bfae949-82c2-49ac-ae23-ffc25b15703e",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//             "samagri": {
//               "id": "5d041188-68c4-4353-abef-d2b874717a73",
//               "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//               "description": "\n\n",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:37:07.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-27T00:34:32.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 247
//             }
//           },
//           {
//             "id": "829ebfe1-974e-4843-90ab-e906dac6b79c",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "samagri": {
//               "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//               "name": "నువ్వుల నూనె/ Sesame oil",
//               "description": null,
//               "units": "ml",
//               "standardQty": "25",
//               "costPrice": 17,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:55:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 41
//             }
//           },
//           {
//             "id": "82919200-7195-48ea-b641-5cc739c11ae5",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//             "samagri": {
//               "id": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//               "name": "నీళ్ల బిందెలు / Water Pots",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:33:20.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 248
//             }
//           },
//           {
//             "id": "809102d3-c668-4410-9d0a-b02d33426f9f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//             "samagri": {
//               "id": "95a758e3-2601-4657-add4-687e4543e5db",
//               "name": "చెంచాలు/Spoons",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:33.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:52:58.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 245
//             }
//           },
//           {
//             "id": "7df2db48-db3b-42c0-85fc-aa8c4f1984e7",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 4,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "samagri": {
//               "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//               "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 8,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:14:39.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 6
//             }
//           },
//           {
//             "id": "7af026bb-da19-4a55-bbdc-47629b195cfa",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "addon",
//             "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "samagri": {
//               "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//               "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 30,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T05:38:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:38:16.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 178
//             }
//           },
//           {
//             "id": "769c0029-0566-4279-b9d4-1b003e969dc5",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 24,
//             "samagriType": "addon",
//             "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "samagri": {
//               "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//               "name": "తేనె/Honey",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 6,
//               "customerMrp": 6,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:43:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 35
//             }
//           },
//           {
//             "id": "74dd5bab-6faa-4368-a8ef-98c98df2d598",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "samagri": {
//               "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//               "name": "గంట/Bell",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:08.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:24:45.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 254
//             }
//           },
//           {
//             "id": "7200d758-041d-4b45-a313-2ee6ae658843",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "samagri": {
//               "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//               "name": "గిన్నెలు/ Bowls",
//               "description": null,
//               "units": "no",
//               "standardQty": "6",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:47:01.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:37:17.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 250
//             }
//           },
//           {
//             "id": "68ca7505-e88c-4a32-bcf5-afe3126e9369",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "samagri": {
//               "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//               "name": "గరిటలు/ Ladles",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:27.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 243
//             }
//           },
//           {
//             "id": "68bff3bd-88cd-4f94-96f2-ecf3c007c20a",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 7,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "samagri": {
//               "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//               "name": "Coconut / కొబ్బరి కాయ",
//               "description": "Coconut",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 33,
//               "customerMrp": 33,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T02:28:29.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T04:59:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 144
//             }
//           },
//           {
//             "id": "5e3b04b8-6a89-4542-98f4-e1f54c52f06f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "samagri": {
//               "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//               "name": "గంధం/Sandalwood powder",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 36,
//               "pujariSellingPrice": 50,
//               "customerMrp": 50,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:12:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 3
//             }
//           },
//           {
//             "id": "42a1bd78-2dca-4c2b-97f1-6cd979f27271",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "samagri": {
//               "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//               "name": "పీటలు/ Peetalu",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:45.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:08.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 240
//             }
//           },
//           {
//             "id": "40a5aab1-5d12-42c4-9c55-4b7f759916f0",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "samagri": {
//               "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//               "name": "తాంబాళాలు / Basins",
//               "description": null,
//               "units": "no",
//               "standardQty": "3",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:55.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:36:38.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 242
//             }
//           },
//           {
//             "id": "3daae976-243e-4589-8880-29d2331a3910",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 21,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "samagri": {
//               "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//               "name": "చిల్లరపైసలు/Rupee Coins",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:32:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 26
//             }
//           },
//           {
//             "id": "3b8b52e2-7bb1-4015-baf1-888e40f392ce",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "addon",
//             "samagriId": "1b070b44-eed3-483d-bdcc-b0d38935fedd",
//             "samagri": {
//               "id": "1b070b44-eed3-483d-bdcc-b0d38935fedd",
//               "name": "Fruits and Flowers (Satyanarayana Vratam) (Medium)",
//               "description": "Bananas 6 No ,  Loose Flowers 1/2 Kg  ,  Poola Morallu(2)   Moora Tulasi Mala (1),   Thulasi dhalam 50g  4 Types of Fruits 2 each",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 600,
//               "pujariSellingPrice": 700,
//               "customerMrp": 700,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-15T02:38:51.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:56:59.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 146
//             }
//           },
//           {
//             "id": "3ac0c988-298c-4312-b212-fd7ace0c8e01",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 6,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "samagri": {
//               "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//               "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 12,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T06:25:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 5
//             }
//           },
//           {
//             "id": "34e2505e-2bca-4b84-be75-31739ae18b56",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "samagri": {
//               "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//               "name": "చాపలు/ Mats",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:43:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 244
//             }
//           },
//           {
//             "id": "34d0b338-7ba9-496f-a340-8bb1e61a4733",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "samagri": {
//               "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//               "name": "గ్లాసులు/ Glasses",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:49:59.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:38:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 246
//             }
//           },
//           {
//             "id": "30372b87-b2d9-4f40-9f7c-533f971db4a8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "samagri": {
//               "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//               "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 110,
//               "pujariSellingPrice": 150,
//               "customerMrp": 150,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:29:13.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 25
//             }
//           },
//           {
//             "id": "2b4e3efe-f9a8-4322-a8b6-ec1a33b00985",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "addon",
//             "samagriId": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//             "samagri": {
//               "id": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//               "name": "రాగికళశాలు (1/2 ltr)/Copper kalasam (Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 183,
//               "pujariSellingPrice": 300,
//               "customerMrp": 300,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T07:25:25.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 16
//             }
//           },
//           {
//             "id": "27849cd4-a956-4914-8636-999bcf283cb0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 20,
//             "pujaId": 24,
//             "samagriType": "addon",
//             "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "samagri": {
//               "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//               "name": "ఆవు నెయ్యి/Cow Ghee",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 7,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T05:08:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 36
//             }
//           },
//           {
//             "id": "2014431d-db07-4bf4-a97f-8cdaa06cfe4b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "samagri": {
//               "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//               "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//               "description": null,
//               "units": "bundles",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:50:42.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:50:41.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 249
//             }
//           },
//           {
//             "id": "1f05bacc-2e12-457b-a289-f1b43ebddae9",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//             "samagri": {
//               "id": "145d9bf0-e145-46ff-b694-91184f294443",
//               "name": "నేప్కిన్లు/Napkins",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:43.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:49:22.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 251
//             }
//           },
//           {
//             "id": "1d94c7b0-ef66-4ae9-bcd2-5dab4f0d5fa4",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "b8e79765-6a9f-43f7-8c17-48c393ed1c83",
//             "samagri": {
//               "id": "b8e79765-6a9f-43f7-8c17-48c393ed1c83",
//               "name": "పత్తి వస్త్రం చిన్నది / Cotton Garland  ( Small)",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 30,
//               "pujariSellingPrice": 39,
//               "customerMrp": 39,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T01:21:00.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 95
//             }
//           },
//           {
//             "id": "0f666b27-f893-419e-9f0e-e41d6e8f3701",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "samagri": {
//               "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//               "name": "దీపపు కుందులు /Lampshades",
//               "description": null,
//               "units": "no",
//               "standardQty": "2",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-13T04:48:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:23:39.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 256
//             }
//           },
//           {
//             "id": "09a193a4-7177-4597-a268-5c6b2a1b6c2d",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "samagri": {
//               "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//               "name": "కుంకుమ /Sindoor /  Kumkum ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 9,
//               "pujariSellingPrice": 11,
//               "customerMrp": 11,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:10:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 2
//             }
//           },
//           {
//             "id": "0737070d-56d1-4942-9594-f0f1bf8f1d6b",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "samagri": {
//               "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//               "name": "చెంబులు/Kalasam",
//               "description": null,
//               "units": "no",
//               "standardQty": "4",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:42:26.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:39:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 253
//             }
//           },
//           {
//             "id": "eda0f0b1-2a8b-4f95-840f-a674b54c8f5e",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "samagri": {
//               "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//               "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 58,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T02:51:30.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 103
//             }
//           },
//           {
//             "id": "e84782fc-8318-4754-a939-d3396e465df1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "samagri": {
//               "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//               "name": "పసుపు /Turmeric  / Haldi",
//               "description": "This was a completely organic Turmeric",
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 7,
//               "pujariSellingPrice": 9,
//               "customerMrp": 9,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:11:12.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 1
//             }
//           },
//           {
//             "id": "dfe66764-dacc-4d76-990d-2d057ef90533",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "samagri": {
//               "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//               "name": "హారతి కర్పూరం /Camphor",
//               "description": "This was a camphor",
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:04.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 8
//             }
//           },
//           {
//             "id": "d7295d32-6941-4033-a4dc-221a0c72c8b2",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "samagri": {
//               "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//               "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//               "description": null,
//               "units": "gms",
//               "standardQty": "100",
//               "costPrice": 18,
//               "pujariSellingPrice": 22,
//               "customerMrp": 22,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-12T08:01:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 48
//             }
//           },
//           {
//             "id": "cd4676f0-d221-4be1-af01-e66db28368c8",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 10,
//             "pujaId": 24,
//             "samagriType": "addon",
//             "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//             "samagri": {
//               "id": "be08854a-0802-41aa-a71b-703590374e7e",
//               "name": "పంచదార/Sugar",
//               "description": null,
//               "units": "gms",
//               "standardQty": "25",
//               "costPrice": 1,
//               "pujariSellingPrice": 2,
//               "customerMrp": 2,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:47:44.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 37
//             }
//           },
//           {
//             "id": "ca702289-e8db-4b27-8212-87cd5550bd27",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "samagri": {
//               "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//               "name": "ఏక హారతి/Eka Harati",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:39:14.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:26:01.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 259
//             }
//           },
//           {
//             "id": "c3cc8419-f9c6-4fd1-8507-8c2ccdcb534f",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "samagri": {
//               "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//               "name": "ఉద్దరిని/Uddarini",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:34:41.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:52.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 258
//             }
//           },
//           {
//             "id": "bb7eeced-da5f-46f7-b445-c917f3ddf71f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "samagri": {
//               "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//               "name": "అగరుబత్తులు / INCENSE STICKS",
//               "description": null,
//               "units": "no",
//               "standardQty": "10",
//               "costPrice": 4,
//               "pujariSellingPrice": 14,
//               "customerMrp": 14,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-22T06:47:48.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 9
//             }
//           },
//           {
//             "id": "b3a68258-a315-4d3b-afbf-c1ee269534ab",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//             "samagri": {
//               "id": "836ee333-9374-4fbb-9810-815984a91453",
//               "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:35:32.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:51.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 241
//             }
//           },
//           {
//             "id": "b21fec2f-8ab3-46a6-bc69-6544cafc55e6",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "samagri": {
//               "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//               "name": "దారం బంతి/ Thread Ball",
//               "description": null,
//               "units": "packets",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 10,
//               "customerMrp": 10,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-20T01:35:16.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 28
//             }
//           },
//           {
//             "id": "a6c7b358-fb06-44a0-88d6-cf0f832fa4f0",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "addon",
//             "samagriId": "48a8178f-db8e-42f4-bcef-9d2dcee46d21",
//             "samagri": {
//               "id": "48a8178f-db8e-42f4-bcef-9d2dcee46d21",
//               "name": "సత్యనారాయణ స్వామి మహా-ప్రసాద్ పదార్థాలు  / Satyanarayana Vrata Prasadam",
//               "description": "Godhuma Ravva 1 1/4 kg ,  Kaju 50g ,  Kismiss 50g ,   Badam 50 g\n\nగోధుమ రవ్వ\t1 1/4 kg,     కాజు\t50g,     కిస్మిస్\t50g,    బాదం\t50 g",
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 427,
//               "pujariSellingPrice": 450,
//               "customerMrp": 450,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-26T04:36:22.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:59:15.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 175
//             }
//           },
//           {
//             "id": "a407245b-5bc8-4b69-a11f-cf41eea6ec97",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 2,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "samagri": {
//               "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//               "name": "బియ్యం/Rice",
//               "description": null,
//               "units": "kgs",
//               "standardQty": "1",
//               "costPrice": 60,
//               "pujariSellingPrice": 65,
//               "customerMrp": 65,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:23:37.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 27
//             }
//           },
//           {
//             "id": "9d0e2e4a-88f6-4fbd-81b1-554ad6fd2a33",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "samagri": {
//               "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//               "name": "వక్కలు/Betel Nuts",
//               "description": null,
//               "units": "gms",
//               "standardQty": "10",
//               "costPrice": 11,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-26T00:37:23.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 4
//             }
//           },
//           {
//             "id": "9bfbd50f-4cdc-4da8-b4c7-72f60ce69fe5",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "samagri": {
//               "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//               "name": "పంచపాత్ర/Panchapatra",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:36:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:41:05.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 257
//             }
//           },
//           {
//             "id": "96f74929-6f76-4c87-9d15-6a9d166999c8",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "samagri": {
//               "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//               "name": "చాకు/knife",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:38:03.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:45:12.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 252
//             }
//           },
//           {
//             "id": "91be3f45-1ab8-48ce-b616-ce67134eadbf",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 24,
//             "samagriType": "addon",
//             "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "samagri": {
//               "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//               "name": "ఆవు పాలు/ Cow Milk ",
//               "description": null,
//               "units": "ml",
//               "standardQty": "250",
//               "costPrice": 18,
//               "pujariSellingPrice": 25,
//               "customerMrp": 25,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-16T04:08:58.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:37:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 177
//             }
//           },
//           {
//             "id": "8ffd535e-e031-4536-9507-3fcec546a8ab",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "1a564751-0ae5-42de-821a-e1e3afe41741",
//             "samagri": {
//               "id": "1a564751-0ae5-42de-821a-e1e3afe41741",
//               "name": "కత్తెర / Scissor",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 150,
//               "pujariSellingPrice": 170,
//               "customerMrp": 170,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-06-21T06:13:28.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-21T06:13:28.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 167
//             }
//           },
//           {
//             "id": "f1b2ad14-5075-4c58-897c-35e8d3a37c8f",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 5,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "samagri": {
//               "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//               "name": "తమలపాకులు / Betel leaves",
//               "description": "These are leaves user in puja",
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 1,
//               "pujariSellingPrice": 1,
//               "customerMrp": 1,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:30:21.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-05-26T05:08:04.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 169
//             }
//           },
//           {
//             "id": "fd4c211c-8c6a-4441-ab7c-ca0dd90d6468",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "samagri": {
//               "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//               "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//               "description": null,
//               "units": "no",
//               "standardQty": "20",
//               "costPrice": 10,
//               "pujariSellingPrice": 15,
//               "customerMrp": 15,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-13T06:26:47.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": null,
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 45
//             }
//           },
//           {
//             "id": "f5e1cff7-9e84-4af9-aad2-45c66db0f846",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 24,
//             "samagriType": "default",
//             "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "samagri": {
//               "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//               "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 0,
//               "pujariSellingPrice": 0,
//               "customerMrp": 0,
//               "karishyeProvided": "does_not_stock",
//               "updatedAt": "2023-07-12T01:43:19.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-28T00:48:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 255
//             }
//           }
//         ]
//       },
//       {
//         "id": 169,
//         "name": "Birthday function",
//         "description": "To celebrate birthday",
//         "language": "telugu",
//         "region": null,
//         "mode1": "at_my_home",
//         "mode2": "remote",
//         "mode3": "on_behalf",
//         "mode4": null,
//         "videos": "[\"https://youtube.com/embed/B6qgBhMt1T4\",\"https://youtube.com/embed/B6qgBhMt1T4\",\"https://youtube.com/embed/B6qgBhMt1T4\",\"https://youtube.com/embed/B6qgBhMt1T4\"]",
//         "images": "[\"/uploads/2023/07/Satyanarayana-Vratam-Abhishekam-scaled.jpg\",\"/uploads/2023/07/Navagraha-3-scaled.jpg\",\"/uploads/2023/07/Satyanarayana-Vratam-Abhishekam-scaled.jpg\",\"/uploads/2023/07/Navagraha-3-scaled.jpg\",\"/uploads/2023/07/Satyanarayana-Vratam-Abhishekam-scaled.jpg\"]",
//         "updatedAt": "2023-08-28T07:37:37.000Z",
//         "deletedAt": null,
//         "importHash": null,
//         "createdAt": "2023-07-28T01:41:25.000Z",
//         "createdById": "9",
//         "updatedById": "9",
//         "pujacategorymappings": [],
//         "pujamodels": [
//           {
//             "id": 118,
//             "pujaId": 169,
//             "durationHrs": 2,
//             "noOfPujaris": 1,
//             "modelSellingPrice": 673,
//             "advanceAmount": 100,
//             "isPopularModel": true,
//             "pujariCost": null,
//             "indicativeCostPrice": null,
//             "name": null,
//             "updatedAt": "2023-08-10T01:18:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-28T01:46:16.000Z",
//             "createdById": null,
//             "updatedById": "9",
//             "serviceFee": 500,
//             "samagriCost": 70,
//             "procedures": [
//               {
//                 "id": "ab3ef1d7-9325-4c48-ad1c-965b0fd0581d",
//                 "modelId": 118,
//                 "procedureId": 54,
//                 "procedure": {
//                   "id": 54,
//                   "language": "telugu",
//                   "title": "Bhoomi Puja",
//                   "description": "Bhoomi devi ?prudhvi devata aavahana is done into the kalasha.  Bhu devi is worshipped with bhu suktham .  Milk and water is sprinked on the water.  On the ishyanya corner the yajamani digs some mud. The yajamani's wife, takes this mud and dispatches it in the nairuthi corner of the land.  \n\nKalasham with some special ingredients or shanku is placed in the land at that location, covered with bricks and puja is performed. ",
//                   "specialNotes": "test notes"
//                 }
//               },
//               {
//                 "id": "b6aa787b-15b1-4630-b802-9fb911769919",
//                 "modelId": 118,
//                 "procedureId": 55,
//                 "procedure": {
//                   "id": 55,
//                   "language": "telugu",
//                   "title": "Ganesha Navaratri Puja",
//                   "description": "Puja on 1st day or 3 days or 5 days or 9 days as required.\nPuja on morning or evening or both as required.\n\nOur pujari will be present as you opted for and take care of the nithya puja, devotees, aashirvachanam etc.\n\n",
//                   "specialNotes": "Please mention if you need any other pujas like ganapati homam etc. We will accordingly arrange for the same"
//                 }
//               },
//               {
//                 "id": "5ecafe5e-3761-44fb-801a-3897e22c63dc",
//                 "modelId": 118,
//                 "procedureId": 53,
//                 "procedure": {
//                   "id": 53,
//                   "language": "telugu",
//                   "title": "Naamakarana",
//                   "description": "A plate of rice is taken. With a gold ring wrapped with darbha, 3 lines are drawn and three different names are written by the father.\n1st line: Maasa namam, 2nd line: Nakshatra namam: 3rd : Vyavaharika namam  . Once this is done, the child is placed in cradle Three names are recited in child's ear along with mantra by pujari. Followed by the pujari, the mother, the father and the grandparents repeat the same and bless the child.\n\nDepending ojn the family traditions, culture of the region etc. the sequence or the steps would vary.  The pujari would accordingly guide the event. \n",
//                   "specialNotes": null
//                 }
//               },
//               {
//                 "id": "01163d26-badd-4ea3-bfef-065b7c84bc41",
//                 "modelId": 118,
//                 "procedureId": 51,
//                 "procedure": {
//                   "id": 51,
//                   "language": "telugu",
//                   "title": "Navagraha Homam",
//                   "description": " 1/10th of the japam count has to be offered as tarpanam to the graha. For e.g. if 6000 japam was performed, then 600 times tarpanam needs to be offered. Following the same 1/10th of tarpanam count has to be offered to the graha during homam with mantra, dhravyam . For e.g  if 6000 japam was performed, then 600 times tarpanam needs to be offered and 60 times, homam offering needs to be given .\n\nDepending on which graha balance needs to be restored, that particular grahas are offered havissu with a prominent count.   The count depends on the intensity of the problem to be solved and has a mathematical relationship with the tarpanam and japam that was offered to the graha.\n\nSurya\t(6  multiples    / 108)\nChandra  (10 multiples  / 108)\nKujudu\t(7 multiples   / 108)\nBudhudu\t(17 multiples  / 108)\nGuruvu\t(16 multiples   / 108)\nSukrudu\t (20 multiples   / 108)\nShani\t(19 multiples   / 108)\nRahu\t(18 multiples   / 108)\nKetu\t(7 multiples   / 108)",
//                   "specialNotes": "If you have not performed any tarpana or japam and have been advised to perform navagraha homam directly, please mention the same in your call with our pujari.  He will accordingly guide and plan the puja."
//                 }
//               },
//               {
//                 "id": "e87ab40c-d12a-410f-a6b0-9ac45990024b",
//                 "modelId": 118,
//                 "procedureId": 52,
//                 "procedure": {
//                   "id": 52,
//                   "language": "telugu",
//                   "title": "Shop / Business Dwara Pooja",
//                   "description": "Along with lightning lamps usually the consort of yajamani applies turmeric and decorates the threshold. The yajamani bows to gadapa/dwara and pays his obeisance to the threshold which is manifest of mahalakshmi then followed by breaking of pumpkin balipradhana (sacrifice /offering)\nThe spilling of navadhanyas which signifies prosperity is done, finally step into the new space where prospectively business is conducted \n",
//                   "specialNotes": null
//                 }
//               }
//             ]
//           }
//         ],
//         "pujaSamagriMappings": [
//           {
//             "id": "fc6685d2-8118-4012-9180-d3c81e4b81d1",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 169,
//             "samagriType": "addon",
//             "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "samagri": {
//               "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//               "name": "అరటి పండ్లు / Banana",
//               "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//               "units": "no",
//               "standardQty": "12",
//               "costPrice": 60,
//               "pujariSellingPrice": 70,
//               "customerMrp": 70,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-19T22:36:56.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-06-10T03:26:36.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 181
//             }
//           },
//           {
//             "id": "f8d1519f-1240-40c2-92e2-73ac70cbc731",
//             "isProvidedBy": "pujari",
//             "noOfStandardQty": 1,
//             "pujaId": 169,
//             "samagriType": "default",
//             "samagriId": "814c760a-3fb4-4f25-b90e-eba9bd0c9a8a",
//             "samagri": {
//               "id": "814c760a-3fb4-4f25-b90e-eba9bd0c9a8a",
//               "name": "3ft / 3.2ft - గణేష్ విగ్రహం / Ganesha (Pure Clay / మట్టి గణేష )",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 4000,
//               "pujariSellingPrice": 5200,
//               "customerMrp": 5200,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-25T12:42:20.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-23T13:19:47.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 0
//             }
//           },
//           {
//             "id": "d988e43b-25f3-4b1d-bbea-322890c7f129",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 169,
//             "samagriType": "addon",
//             "samagriId": "934c0246-56b9-419e-adf1-f76bbd33f01b",
//             "samagri": {
//               "id": "934c0246-56b9-419e-adf1-f76bbd33f01b",
//               "name": "పసుపు దీక్ష వస్త్రాలు / Pasupu Deeksha Vatralu",
//               "description": null,
//               "units": "set",
//               "standardQty": "1",
//               "costPrice": 850,
//               "pujariSellingPrice": 900,
//               "customerMrp": 900,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-20T09:18:49.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-09T21:35:11.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 207
//             }
//           },
//           {
//             "id": "c707e8fe-4939-447c-9ad5-544ff722641d",
//             "isProvidedBy": "user",
//             "noOfStandardQty": 1,
//             "pujaId": 169,
//             "samagriType": "default",
//             "samagriId": "a6466242-2a96-4098-97e9-022996693e06",
//             "samagri": {
//               "id": "a6466242-2a96-4098-97e9-022996693e06",
//               "name": "మేడి కొమ్మ / Medi Branch ",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 80,
//               "pujariSellingPrice": 100,
//               "customerMrp": 100,
//               "karishyeProvided": "stocks",
//               "updatedAt": "2023-07-18T02:55:46.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-08T04:45:33.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 222
//             }
//           },
//           {
//             "id": "b16111bc-dcb5-4515-b291-6ac6ead07568",
//             "isProvidedBy": "karishye",
//             "noOfStandardQty": 1,
//             "pujaId": 169,
//             "samagriType": "default",
//             "samagriId": "72e2551c-e406-4a7b-bb41-0384d9125819",
//             "samagri": null
//           },
//           {
//             "id": "8b0b8c8a-ea66-4d3f-8f01-ea791ad05779",
//             "isProvidedBy": "pujari",
//             "noOfStandardQty": 1,
//             "pujaId": 169,
//             "samagriType": "default",
//             "samagriId": "9241ed1f-9ee0-4800-a3ae-78b1269b524e",
//             "samagri": {
//               "id": "9241ed1f-9ee0-4800-a3ae-78b1269b524e",
//               "name": "కమలం పువ్వులు / Lotus flowers",
//               "description": null,
//               "units": "no",
//               "standardQty": "1",
//               "costPrice": 10,
//               "pujariSellingPrice": 20,
//               "customerMrp": 20,
//               "karishyeProvided": "does_not_stock_but_can_supply",
//               "updatedAt": "2023-07-18T03:14:11.000Z",
//               "deletedAt": null,
//               "importHash": null,
//               "createdAt": "2023-07-10T01:32:10.000Z",
//               "createdById": null,
//               "updatedById": null,
//               "orderId": 197
//             }
//           }
//         ]
//       }
//     ]
//   }{
//   "status": "SUCCESS",
//   "data": [
//     {
//       "id": 154,
//       "name": "Shop/ Office/ Company Opening",
//       "description": "Puja performed before officially launching or opening a new business / shop / corporate. ",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": null,
//       "mode3": null,
//       "mode4": null,
//       "videos": "undefined",
//       "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//       "updatedAt": "2023-07-24T01:35:35.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-07-08T05:44:38.000Z",
//       "createdById": "47",
//       "updatedById": "44",
//       "pujacategorymappings": [],
//       "pujamodels": [
//         {
//           "id": 102,
//           "pujaId": 154,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 10500,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 5000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-20T13:11:12.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-07-08T06:21:43.000Z",
//           "createdById": null,
//           "updatedById": "44",
//           "serviceFee": 1247,
//           "samagriCost": null,
//           "procedures": []
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "f9f1cfc0-c15b-4466-8330-43d5ba4b1703",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "f77b57ef-4e28-49c1-9048-4d65259063f7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "efffe0ea-e671-412c-afcc-15638726fbb5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "e632cfbc-fad7-4c95-bb61-06724ea92fef",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "e197c13c-e978-49e1-b828-0a79e1de33df",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "ddacccdb-4cd0-4f56-ab1f-f8a1c97c8e1e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "d7b041de-a5ff-4fb1-af8c-9a58bcbb1bf7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "3be47417-fac4-4cc9-bd82-e3ba00efc6b4",
//           "samagri": {
//             "id": "3be47417-fac4-4cc9-bd82-e3ba00efc6b4",
//             "name": "బూడిద గుమ్మడి కాయ/Ash gourd",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 150,
//             "pujariSellingPrice": 160,
//             "customerMrp": 160,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:48:31.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:17:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 190
//           }
//         },
//         {
//           "id": "d0b84a77-1c05-4e7d-b863-fd727e64318f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "c8b3ea15-753a-4c73-84bc-4a5f7c4e7d20",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "c6c546c7-7abb-4913-9d90-ea8f2bd147fc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "c6bf5594-9f23-48a5-b103-03c7a81a9c87",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "b41b875a-9367-4669-9c86-c050f16abd0b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "a4d25313-c162-440a-9a4d-49056efa9782",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "9c18f790-113d-4949-8f2f-dd5b7e736b43",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "644a01ca-da37-415f-8d5b-8b1d4c6c17a0",
//           "samagri": {
//             "id": "644a01ca-da37-415f-8d5b-8b1d4c6c17a0",
//             "name": "ఉట్టి / Utti",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 40,
//             "customerMrp": 40,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:37:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 38
//           }
//         },
//         {
//           "id": "9ab3df46-9b92-460b-bec5-fb75a7f6b56b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         },
//         {
//           "id": "90a6d4a8-4fee-4ff2-b67d-aa977895c79b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//           "samagri": {
//             "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 230,
//             "pujariSellingPrice": 270,
//             "customerMrp": 270,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:31:17.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 23
//           }
//         },
//         {
//           "id": "8d7ad3d0-889e-4d53-aa9c-b0a935ce2bdb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "85f7ef22-e109-4d93-89f9-a5d8aaa9bfb2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "7f54e33a-ea71-4b8c-b775-de45a6578820",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "7866c1d6-28d3-4ead-be7c-bbde82b452f8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "776e20ab-9ef0-455a-b16b-9609cabf61bb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "58c0588d-75a0-4d12-a477-00fe1a96a8e0",
//           "samagri": {
//             "id": "58c0588d-75a0-4d12-a477-00fe1a96a8e0",
//             "name": "నోట్బుక్ పెన్/ NOTEBOOK/PEN  ",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 50,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-05T05:31:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T04:53:07.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 153
//           }
//         },
//         {
//           "id": "73c7e02c-85b0-440d-9820-b33460c2c53a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "b2c2e469-39b3-4c2b-9349-e93f26dc3269",
//           "samagri": {
//             "id": "b2c2e469-39b3-4c2b-9349-e93f26dc3269",
//             "name": "తినే సున్నం /  Edible lime",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 20,
//             "pujariSellingPrice": 26,
//             "customerMrp": 26,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:14:38.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 90
//           }
//         },
//         {
//           "id": "72682c27-8ddf-4f9e-9e1f-a7c0c7b2eebe",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "6eed7a09-42fe-4018-a281-02f003885ba4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "6b072f0e-42a5-4c0c-a547-4a88143ed16b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "68fa8199-323e-4cd3-afbf-272d22cca5f6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "6389e8bf-4031-43ee-a591-80072925f99d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "604991d2-c5c3-4ffc-bf9d-84bddcb7d894",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "addon",
//           "samagriId": "8670332d-d8a9-45c7-ab81-5fda3f46806a",
//           "samagri": {
//             "id": "8670332d-d8a9-45c7-ab81-5fda3f46806a",
//             "name": "Fruits & Flowers ( Shop Opening) (Premium)",
//             "description": "వీడి పూలు  1/4 kg , పూల మాలలు   3,   అరటి పండ్లు    1 Dozen,  ఆపిల్స్   3        ఆరంజ్   3   దానిమ్మ   3    ",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 750,
//             "pujariSellingPrice": 900,
//             "customerMrp": 900,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-15T02:40:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-14T23:49:14.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 263
//           }
//         },
//         {
//           "id": "4a9e08eb-9928-482e-91bc-5b08bedb57d1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "4479e4ec-9ffa-44be-ad63-a617213e5855",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "3ed60ce2-fa37-4bee-8a02-8a6d74777747",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "3bef35a7-d47a-45c6-9a83-dbd12d47bed6",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "3be6e1aa-d3ed-402a-94d5-5edc0df9bdc2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//           "samagri": {
//             "id": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//             "name": "రాగికళశాలు (1/2 ltr)/Copper kalasam (Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 183,
//             "pujariSellingPrice": 300,
//             "customerMrp": 300,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:25:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 16
//           }
//         },
//         {
//           "id": "35b50d99-b285-4fe3-8217-6df98f4ff132",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "356ccd95-8490-4511-9e93-8e0237f52715",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "31739058-df58-461c-af27-b351bad3861e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "2d51ae96-7ea0-451e-8f3d-f6da592b70a3",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "2826b8ed-598e-47a8-9d0c-9c4995a75e95",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "d87297fb-4fd9-4014-b4d8-12642e1975a7",
//           "samagri": {
//             "id": "d87297fb-4fd9-4014-b4d8-12642e1975a7",
//             "name": "నిమ్మకాయలు/LEMON",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 5,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:45:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-01T04:00:03.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 173
//           }
//         },
//         {
//           "id": "1be42796-8db0-4014-897c-7703514c7c2a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "1b047f80-c0f2-4e0d-8522-5fda4d264c06",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "1ae493ea-8234-40c4-9704-e13e45834f70",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "addon",
//           "samagriId": "5493b88d-4f54-4009-a6db-1f23dcfa23f1",
//           "samagri": {
//             "id": "5493b88d-4f54-4009-a6db-1f23dcfa23f1",
//             "name": "Fruits & Flowers ( Shop Opening) (Medium Quality)",
//             "description": "వీడి పూలు  1/4 kg , పూల మాలలు   3,   అరటి పండ్లు    1 Dozen,  ఆపిల్స్   3        ఆరంజ్   3   దానిమ్మ   3    ",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 630,
//             "pujariSellingPrice": 720,
//             "customerMrp": 720,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-15T02:40:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T06:01:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 219
//           }
//         },
//         {
//           "id": "1acb3b15-34d3-4022-a495-dd8169b89b93",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "91b85c96-cfd7-4775-9759-e6154c8a7cd5",
//           "samagri": {
//             "id": "91b85c96-cfd7-4775-9759-e6154c8a7cd5",
//             "name": "దేవుడి పటములు ( లక్ష్మి, వినాయకుడు, సరస్వతి)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 200,
//             "pujariSellingPrice": 230,
//             "customerMrp": 230,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:30:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T06:03:24.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 229
//           }
//         },
//         {
//           "id": "19b5c1f4-87b1-441b-a65e-67b2e0e7cf06",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "125b09a9-64af-439f-b194-46b3d7da082c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "11d29fc7-c2c8-4b8e-91e1-4e1b6e9ce0f9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//           "samagri": {
//             "id": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//             "name": "గుమ్మకాయడి/ PUMPKIN",
//             "description": "1",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 140,
//             "pujariSellingPrice": 150,
//             "customerMrp": 150,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:50:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:14:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 191
//           }
//         },
//         {
//           "id": "0cf30f0c-ca27-478b-8819-d2ea8c5dd244",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 154,
//           "samagriType": "default",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         }
//       ]
//     },
//     {
//       "id": 146,
//       "name": "Saraswathi Homam",
//       "description": "Saraswathi homam is performed for the Goddess Saraswati. She is the deity of Wisdom, Knowledge, and Intelligence. This homam is uniquely done for the students to invoke the blessing of Goddess Saraswati to improve their academic ranking and to boost their career life",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": "remote",
//       "mode3": "on_behalf",
//       "mode4": null,
//       "videos": "undefined",
//       "images": "[\"/uploads/2023/07/Saraswathi-Homam-Resized.png\"]",
//       "updatedAt": "2023-07-24T01:34:52.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-06-16T00:44:44.000Z",
//       "createdById": null,
//       "updatedById": "44",
//       "pujacategorymappings": [
//         {
//           "id": 18,
//           "pujaId": 146,
//           "categoryId": 2,
//           "subCategoryId": 7
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 87,
//           "pujaId": 146,
//           "durationHrs": 3,
//           "noOfPujaris": 2,
//           "modelSellingPrice": 17999,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 10000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-20T12:14:06.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-25T22:19:11.000Z",
//           "createdById": null,
//           "updatedById": "44",
//           "serviceFee": 1256,
//           "samagriCost": null,
//           "procedures": []
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "ff09c0b0-8f55-4329-a716-36aabd048fce",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "f95aa32d-91c7-4e60-9120-4db90517de50",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "f559274a-2b4c-4ac1-918b-c7071d96acd2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "f078c76f-9c05-4bf9-9b5b-13d4d9b28b8a",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "e76be4e0-30ed-470b-a2b3-d91b961d9b55",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "e4e5f180-e356-41e8-bc87-b4541fd82239",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "e196f558-bfb7-435d-92b6-b033afbae554",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "499f3b07-536e-4138-8123-a78958cb2c3a",
//           "samagri": {
//             "id": "499f3b07-536e-4138-8123-a78958cb2c3a",
//             "name": "పట్టికబెల్లం చిప్స్ /Misri Chips",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:54:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 106
//           }
//         },
//         {
//           "id": "c3d520f4-a67b-428c-b344-da3b08663cd3",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "b55c775c-1d74-408c-8fb2-6b117de941bf",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "ad4a3b7d-4295-4a5e-8b4f-1469640b7e39",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "aa66bdd2-535c-489b-b6fd-858291eaddce",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "a75cca03-b821-4b82-95ad-4fd541054856",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "9c0dd7b1-e20b-4ab4-9d0f-6687ac3f8053",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "9abb4ad9-5940-4510-8ec9-2525253dd328",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//           "samagri": {
//             "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "name": "బెల్లం / Jaggery",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:23:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T01:22:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 143
//           }
//         },
//         {
//           "id": "98da4c6b-cba8-499a-872a-6e4f67ef9fb6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//           "samagri": {
//             "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:38:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 115
//           }
//         },
//         {
//           "id": "9672081e-c1b2-4d41-986a-a53ac99f5b35",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "91964db3-71dd-471e-84c3-8b831b8cc2d0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "9011f264-26ba-44a7-adf6-d9ed709e964e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "82c82a0b-220e-4585-a458-94422e419ddc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//           "samagri": {
//             "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "name": "అరటి పండ్లు / Banana",
//             "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//             "units": "no",
//             "standardQty": "12",
//             "costPrice": 60,
//             "pujariSellingPrice": 70,
//             "customerMrp": 70,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-19T22:36:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:26:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 181
//           }
//         },
//         {
//           "id": "80ff2170-348d-45ed-8afb-283ca86ef93a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "7d015870-3502-45f5-a483-2580ecd49916",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "75621309-d6dd-48e2-b374-db88129029e9",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "74ab3e28-7c18-43a3-9aa6-5f4aa15a79b0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 11,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "aeb7b0f9-511c-4107-a966-e5be4ea389bd",
//           "samagri": {
//             "id": "aeb7b0f9-511c-4107-a966-e5be4ea389bd",
//             "name": "తెల్ల కమలం పువ్వులు/ White Lotus Flowers",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 20,
//             "pujariSellingPrice": 30,
//             "customerMrp": 30,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:05:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-10T02:11:30.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 196
//           }
//         },
//         {
//           "id": "743be43a-aff7-4e9d-970c-5122b1cca713",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "74382e0b-2493-4199-ac90-8b4931529135",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "648d9747-16c5-44ab-a390-1f7f2a74e29e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//           "samagri": {
//             "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 110,
//             "pujariSellingPrice": 150,
//             "customerMrp": 150,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:29:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 25
//           }
//         },
//         {
//           "id": "5cf99756-7ddb-454d-bab2-0d20286c458e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "5a44965b-5347-4e0b-9739-f388698e027b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 75,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "4f50cd56-e62d-44ae-a814-8e21666e97ef",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "4e0356c5-57f1-46d6-ae14-012a8de21eb7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "4d374ed7-7e5e-4218-a7dc-d9c425b6d3d2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "4b768fa8-ecf6-4878-becd-37b62fcc6ced",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "48262add-ccf0-4dd6-a3be-1fb67d276e6a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "3f40895a-19b5-4f80-950a-8244b420f679",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "02689db4-c39b-4cd0-8fcc-8f62d667b871",
//           "samagri": {
//             "id": "02689db4-c39b-4cd0-8fcc-8f62d667b871",
//             "name": "తెల్ల విడి పూలు / White Loose Flowers",
//             "description": null,
//             "units": "gms",
//             "standardQty": "500",
//             "costPrice": 200,
//             "pujariSellingPrice": 220,
//             "customerMrp": 220,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:08:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-10T02:10:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 193
//           }
//         },
//         {
//           "id": "38bfec48-73f5-47f8-8514-972f9c683390",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 7,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "361e870c-4d31-4008-86a4-66e95a91692e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "304377b1-2270-4ea2-94ef-d20c9aede77c",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         },
//         {
//           "id": "2a211b35-f46d-4574-9d05-7bc7e48e5541",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//           "samagri": {
//             "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 110,
//             "pujariSellingPrice": 150,
//             "customerMrp": 150,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:29:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 25
//           }
//         },
//         {
//           "id": "229eec8b-e876-4979-880c-a424c4933c39",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//           "samagri": {
//             "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "name": "వరిపెళాలు/Rice Fields",
//             "description": null,
//             "units": "gms",
//             "standardQty": "50",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:33:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 58
//           }
//         },
//         {
//           "id": "206a466d-1dcc-4e33-8210-dc92a45b1b20",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "1de3f82f-4af6-4f9f-a893-2e7a19d19337",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "15546c32-d271-4cad-8122-28ed91fa87d0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "17f5dfe8-ebdb-40ed-b278-b73e8cc35ae4",
//           "samagri": {
//             "id": "17f5dfe8-ebdb-40ed-b278-b73e8cc35ae4",
//             "name": "5 types of fruits (Each 2)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 350,
//             "pujariSellingPrice": 400,
//             "customerMrp": 400,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T04:11:15.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-16T05:21:24.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 155
//           }
//         },
//         {
//           "id": "115ec25a-68e6-4292-81e6-3c3ec1093961",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "0cfdf4e9-9e36-4935-b9c6-6c4281dd2f0b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "07822445-0345-4f9c-bc85-8942316e9e20",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 146,
//           "samagriType": "default",
//           "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//           "samagri": {
//             "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 210,
//             "pujariSellingPrice": 500,
//             "customerMrp": 500,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:26:10.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 17
//           }
//         }
//       ]
//     },
//     {
//       "id": 148,
//       "name": "Maha Mryutyunjaya Homam ",
//       "description": "Maha Mrityunjaya Homam is performed by devotees to prevent premature deaths. The homam is dedicated to Lord Shiva in the form of mrityunjaya who blesses the devotee with long life. The significance of this Maha Mrityunjaya Homam is that it has been passed on to us via work done by Markandeya maharshi and several others, giving an opportunity for humans to win over untimely or unripe death or any physical illness. \nThe purpose of extending one's life or to get blessed with health is to further one's spiritual pursuit while also fulfilling worldy duties.  When people with such an inner intention perform the puja, Shiva overwrites any karmic balance to grant the devotee with the extra life span.  \nChildren or shishyas also perform these pujas for their elders or guri, since they wish them to be alive in the current upadhi and continue to guide them",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": "remote",
//       "mode3": "on_behalf",
//       "mode4": null,
//       "videos": "undefined",
//       "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//       "updatedAt": "2023-07-24T01:32:45.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-06-16T00:54:46.000Z",
//       "createdById": null,
//       "updatedById": "44",
//       "pujacategorymappings": [
//         {
//           "id": 22,
//           "pujaId": 148,
//           "categoryId": 2,
//           "subCategoryId": 7
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 85,
//           "pujaId": 148,
//           "durationHrs": 3,
//           "noOfPujaris": 2,
//           "modelSellingPrice": 17999,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 8000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-20T12:39:11.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-23T11:58:31.000Z",
//           "createdById": null,
//           "updatedById": "44",
//           "serviceFee": 1919,
//           "samagriCost": null,
//           "procedures": []
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "fa217b7e-342f-4c1a-8b9b-acf16c290e2e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "f6ca971b-d401-439a-aeae-273685fd0125",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "f6930127-14ac-44a5-bbad-b2f21c8e15b9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "f6311dc8-2dd2-4983-b041-593510a5e6d5",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "f2b79bdf-059f-4bb8-be15-82fcf22f2d12",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//           "samagri": {
//             "id": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//             "name": "యాలకులు / Cardamom",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 10,
//             "pujariSellingPrice": 24,
//             "customerMrp": 24,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:07:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 87
//           }
//         },
//         {
//           "id": "efc48a54-7dc3-4d1b-a625-d7435d9eee6f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 35,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//           "samagri": {
//             "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "name": "ఇటుకలు/ BRICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 8,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:39:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:14:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 150
//           }
//         },
//         {
//           "id": "e7bf3683-174d-4e1a-8508-ecc7ff607b6a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//           "samagri": {
//             "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:38:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 115
//           }
//         },
//         {
//           "id": "e09bf7bc-1851-4bd4-960f-cecb9139eed4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//           "samagri": {
//             "id": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//             "name": "జాజి కయ/Nutmeg",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 22,
//             "pujariSellingPrice": 30,
//             "customerMrp": 30,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:03:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 50
//           }
//         },
//         {
//           "id": "d83b7b14-7f29-4657-a91e-62613e8e8372",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "d6fcd61d-d3f7-4aea-b729-a76e42a1a32c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//           "samagri": {
//             "id": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//             "name": "పచ్చ కర్పూరం /  GREEN CAMPHOR",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 10,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:22:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 99
//           }
//         },
//         {
//           "id": "d4a4568a-61c8-4ee9-b89a-7876c4c336d2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "d4347408-2352-4612-a179-9760106c8b88",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "d4063733-d0b6-4a7a-9441-db19181daea3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "d0b0a1e6-1dd9-4f87-b7b3-da0cddcadfb8",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         },
//         {
//           "id": "d031ded0-f6ae-4c41-91bd-09fa67188dfb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//           "samagri": {
//             "id": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//             "name": "గోమయం/Cow Dung",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-13T06:33:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:27:00.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 230
//           }
//         },
//         {
//           "id": "c94f12af-3792-4770-a7e1-24f036fdd07a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "499f3b07-536e-4138-8123-a78958cb2c3a",
//           "samagri": {
//             "id": "499f3b07-536e-4138-8123-a78958cb2c3a",
//             "name": "పట్టికబెల్లం చిప్స్ /Misri Chips",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:54:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 106
//           }
//         },
//         {
//           "id": "c8bd039e-afe3-4620-8a6c-228d53e92b89",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "bb94bff7-c855-4559-916d-166d4237cf46",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "0031beaf-9312-467e-853a-b2243ce677f5",
//           "samagri": {
//             "id": "0031beaf-9312-467e-853a-b2243ce677f5",
//             "name": "గోమూత్రం/Cow Urine",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:17:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:26:29.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 231
//           }
//         },
//         {
//           "id": "ba599e6d-8b38-4e31-8e7c-57f352c96eaa",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "b91cdf68-0ea1-4390-86b8-f99e7984a37e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "ab88547d-0167-4e87-9210-e38a8ce89e48",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "a540bc12-167e-4140-bfe5-4058a0f589d9",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "a0e5e64d-8cc9-4c07-988d-0d3c75811f59",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "9976239d-c2bc-4290-b622-f36a1aa3a483",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//           "samagri": {
//             "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 8,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:40:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 33
//           }
//         },
//         {
//           "id": "9893c9b9-f8b6-4529-b2e0-6d4bc877b4db",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "97717971-a0b4-4d75-bc44-77f85d546252",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//           "samagri": {
//             "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 18,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:01:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 48
//           }
//         },
//         {
//           "id": "964707d5-ce25-4f58-a8b4-1dbc8f6530e8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//           "samagri": {
//             "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "name": "గరిక/  Grass",
//             "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 20,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:31:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:11:34.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 183
//           }
//         },
//         {
//           "id": "95c6c364-4d64-4e56-ac0d-11e23475c38e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "d2267b70-2283-43d2-b6a3-ea9e2c00c60a",
//           "samagri": {
//             "id": "d2267b70-2283-43d2-b6a3-ea9e2c00c60a",
//             "name": "Fruits & Flowers / పూలు మరియు పండ్లు  ( Maha Mruthyunjaya Homam)",
//             "description": "వీడి పూలు - 1/2 kg , పూల దండలు -3, అరటి పండ్లు - 1 dozen,  5 టైప్స్  అఫ్ ఫ్రూప్ట్స్  ఈచ్ - 2,  మరేడు  దళాలు - 1/2 kg",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 420,
//             "pujariSellingPrice": 504,
//             "customerMrp": 504,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-17T01:44:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-17T01:44:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 267
//           }
//         },
//         {
//           "id": "8f0de026-81ef-4df8-ae2b-e8048073138c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "8f0613cc-2f92-4766-bb5f-cd222f9a9c76",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//           "samagri": {
//             "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "name": "దర్భ కట్టలు/Kusha Grass",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T06:06:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 53
//           }
//         },
//         {
//           "id": "8d8ef146-6eeb-4fbb-8bba-f3adfa944459",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "8938029a-d3bd-4a5b-a72e-2ee9ff073189",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "87269f4b-3746-4992-8608-87d9ffde9617",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "83075cba-1c92-48e5-9b88-2736cb92295e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "addon",
//           "samagriId": "f777870d-f64a-46ba-8817-210a3f31a8da",
//           "samagri": {
//             "id": "f777870d-f64a-46ba-8817-210a3f31a8da",
//             "name": " రావి కొమ్మ/ Peepal Branch",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T02:56:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:46:31.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 223
//           }
//         },
//         {
//           "id": "7d64b2b7-044b-40c1-94d5-731d476297c4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "75c54993-415b-4c9c-b109-22c643a7f56b",
//           "samagri": {
//             "id": "75c54993-415b-4c9c-b109-22c643a7f56b",
//             "name": "గోధుమలు / Wheat",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 67
//           }
//         },
//         {
//           "id": "781c805e-919d-42c7-a303-bf22509580dc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//           "samagri": {
//             "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 210,
//             "pujariSellingPrice": 500,
//             "customerMrp": 500,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:26:10.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 17
//           }
//         },
//         {
//           "id": "7652d487-37d0-43f6-ba58-116e4d96b710",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "3ed7a4c6-bde0-45ce-a96b-30c381e0092b",
//           "samagri": {
//             "id": "3ed7a4c6-bde0-45ce-a96b-30c381e0092b",
//             "name": "రోజ్ వాటర్/ Rose water",
//             "description": null,
//             "units": "ml",
//             "standardQty": "100",
//             "costPrice": 14,
//             "pujariSellingPrice": 40,
//             "customerMrp": 40,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T01:37:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 76
//           }
//         },
//         {
//           "id": "75eb979a-a645-412d-9a53-310989470311",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "addon",
//           "samagriId": "31ef9c71-5019-488c-a9e8-311d0c264d71",
//           "samagri": {
//             "id": "31ef9c71-5019-488c-a9e8-311d0c264d71",
//             "name": "జువ్వి కొమ్మ/Juvvi branch",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 70,
//             "pujariSellingPrice": 85,
//             "customerMrp": 85,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T02:55:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:48:48.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 221
//           }
//         },
//         {
//           "id": "71b1fd4f-cc04-4f69-8f1e-c3aecf12e018",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "f3809443-befe-4c75-a784-4f1cf7527d51",
//           "samagri": {
//             "id": "f3809443-befe-4c75-a784-4f1cf7527d51",
//             "name": "యజ్ఞోపవేతం వడికిన /Sacred thread",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 45,
//             "customerMrp": 45,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:25:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 52
//           }
//         },
//         {
//           "id": "6f07843c-da7a-4a11-b616-49d44f2807ed",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "6c39bb37-efcc-446f-960a-af68ef6c2080",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "674a477c-782c-41cf-b51e-98f60e633f30",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "66ccec83-5f05-43c2-936c-7520ab7ff1ae",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "64b7308c-fc64-453c-832d-2aa4cff36d35",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//           "samagri": {
//             "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 500,
//             "pujariSellingPrice": 600,
//             "customerMrp": 600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:43:18.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 114
//           }
//         },
//         {
//           "id": "5cbadb55-c008-41b5-b171-1683d1efadfa",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//           "samagri": {
//             "id": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//             "name": "లవంగాలు / cloves",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:08:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 88
//           }
//         },
//         {
//           "id": "5ca44499-0647-4c04-968f-edb108186f5d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "5a1aa697-726c-446f-9558-978b8946f93b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "56535763-562b-4cab-a49c-d98b40be0249",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "51d2f750-96c8-41a3-a845-9da1cf7f01fd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "ecaa05b8-2b35-43fc-b0c5-a7effff2676a",
//           "samagri": {
//             "id": "ecaa05b8-2b35-43fc-b0c5-a7effff2676a",
//             "name": "మినపప్పు/Urad Dal",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 4,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T05:01:50.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 30
//           }
//         },
//         {
//           "id": "4f5c6b51-3d26-4d2c-84cc-f42124d2ee86",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "4dba48cc-3e9c-4169-b765-993f020ef97e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "4c6d1c82-9066-4509-a1b1-2a438dd4b9b2",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "4b08de98-a770-4cf2-ae82-d24e4135ba6d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "46d13094-1288-42cc-97a8-12eb06a21e05",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "42f8f04b-d874-488e-8beb-e3632eaa4d5e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "417e5eca-f57d-468d-adc5-d59d2cb3323e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "3e29e39c-61f7-4754-aa14-20e0d02a340f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//           "samagri": {
//             "id": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//             "name": "జాపత్రి/Nutmeg Mace",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 54,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:04:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 51
//           }
//         },
//         {
//           "id": "3db5220d-c3c7-496d-ad8f-af56fbb9613d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "3d9360a6-c412-4856-b3e2-a891c642863f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//           "samagri": {
//             "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "name": "ఇసుక / SAND",
//             "description": "As Gampallu",
//             "units": "other",
//             "standardQty": "1",
//             "costPrice": 100,
//             "pujariSellingPrice": 125,
//             "customerMrp": 125,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:28:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:16:43.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 151
//           }
//         },
//         {
//           "id": "3199fc70-0aaa-4263-a283-23527e50f3b5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "bc62b6a8-24f4-4b8a-bc91-0c9ef8436c19",
//           "samagri": {
//             "id": "bc62b6a8-24f4-4b8a-bc91-0c9ef8436c19",
//             "name": "శేనగ పప్పు/Chana Dal",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T05:04:57.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 32
//           }
//         },
//         {
//           "id": "313f6948-66a3-4792-b79e-dfb8511092d4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "addon",
//           "samagriId": "7526e2c0-07c4-4a31-8482-85153766f5b4",
//           "samagri": {
//             "id": "7526e2c0-07c4-4a31-8482-85153766f5b4",
//             "name": "మర్రి కొమ్మ/ Banyan branch",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 75,
//             "pujariSellingPrice": 85,
//             "customerMrp": 85,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T02:57:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:42:23.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 224
//           }
//         },
//         {
//           "id": "30229700-bc1e-4efe-9db2-8a340ac78b1c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "4fc25bac-40ce-42c1-9ada-5ea068ea3553",
//           "samagri": {
//             "id": "4fc25bac-40ce-42c1-9ada-5ea068ea3553",
//             "name": "అత్తరు / Perfume",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 35,
//             "pujariSellingPrice": 43,
//             "customerMrp": 43,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:44:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:59:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 121
//           }
//         },
//         {
//           "id": "2fbbf980-a7d4-47b6-a2e5-cd0c9723be9a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "2ee5b6d4-c423-4e2e-bd8b-a08a6f99f4ba",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "addon",
//           "samagriId": "a6466242-2a96-4098-97e9-022996693e06",
//           "samagri": {
//             "id": "a6466242-2a96-4098-97e9-022996693e06",
//             "name": "మేడి కొమ్మ / Medi Branch ",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 80,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-18T02:55:46.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:45:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 222
//           }
//         },
//         {
//           "id": "2b245487-7322-4e45-b976-002be8d1bd88",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "2ae4ab81-8d9e-48e9-babf-4c3480147217",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "26e78c60-7b92-497a-9097-4d9ec3e67e2c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "1f8c4bbf-d2e8-46d6-ad41-79c010b92b8b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "1f01558e-a7db-405c-aa12-46def6574cf7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "57903f59-a61a-400f-b0f9-68e6355039f2",
//           "samagri": {
//             "id": "57903f59-a61a-400f-b0f9-68e6355039f2",
//             "name": "పత్తి వస్త్రం Big / Cotton Garland  (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 52,
//             "customerMrp": 52,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:19:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 96
//           }
//         },
//         {
//           "id": "1cbc1f1f-994b-4713-87a9-25ec86b43be1",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "06f63547-7e67-4081-af22-ddee788e43fa",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "052d3601-6fcd-4077-976c-d9b170516299",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "1a564751-0ae5-42de-821a-e1e3afe41741",
//           "samagri": {
//             "id": "1a564751-0ae5-42de-821a-e1e3afe41741",
//             "name": "కత్తెర / Scissor",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 150,
//             "pujariSellingPrice": 170,
//             "customerMrp": 170,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T06:13:28.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-21T06:13:28.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 167
//           }
//         },
//         {
//           "id": "05038269-8ca1-4e30-ae8e-9cc0cfbbd28f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 148,
//           "samagriType": "default",
//           "samagriId": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//           "samagri": {
//             "id": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//             "name": "తెల్ల నువ్వులు/White Sesame",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T02:09:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 43
//           }
//         }
//       ]
//     },
//     {
//       "id": 150,
//       "name": "Sudarshana Homam",
//       "description": "The fire ritual ceremony of Sudarshana Homam is performed to seek the benevolence of Lord Vishnu through his possession of Sudarshana, in the form of deity with 16 hands. “Su” means the right. “Darshana: means vision. When Lord Sudarshan is invoked with prayers, it sets out to fulfil the Ishtakaamya of the devotee. This Homam holds the power to remove malefic effects of evil spirits, troubles caused by enemies and witchcraft. It needs to be performed with shraddha and under the guidance of an able pujari who has strong vishnu upasana.  This puja is at times,  performed for protection from evil eyes, dark malefic forces, and for success in business, work and life.",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": "on_behalf",
//       "mode3": "remote",
//       "mode4": null,
//       "videos": "undefined",
//       "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//       "updatedAt": "2023-07-20T13:35:30.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-06-16T00:58:06.000Z",
//       "createdById": null,
//       "updatedById": "44",
//       "pujacategorymappings": [
//         {
//           "id": 26,
//           "pujaId": 150,
//           "categoryId": 2,
//           "subCategoryId": 5
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 83,
//           "pujaId": 150,
//           "durationHrs": 4,
//           "noOfPujaris": 2,
//           "modelSellingPrice": 29999,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 15000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-20T13:37:24.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-23T11:48:48.000Z",
//           "createdById": null,
//           "updatedById": "44",
//           "serviceFee": 1869,
//           "samagriCost": null,
//           "procedures": []
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "74def7d4-1e47-4d57-9867-785e00c6fba7",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "70796169-debf-452d-966d-fd65bd1aad1e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "6e64f991-c51b-4b37-90b9-23cd3d2949a1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//           "samagri": {
//             "id": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//             "name": "జాపత్రి/Nutmeg Mace",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 54,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:04:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 51
//           }
//         },
//         {
//           "id": "6df31827-9fd6-4044-a80f-62bcec3499e3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "1e4cc208-8f75-4c51-a062-e8782e728d9e",
//           "samagri": {
//             "id": "1e4cc208-8f75-4c51-a062-e8782e728d9e",
//             "name": "ఇత్తడి కళశాలు (1 Ltr)/Brass Kalasam (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 290,
//             "pujariSellingPrice": 500,
//             "customerMrp": 500,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:28:05.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 20
//           }
//         },
//         {
//           "id": "6d754979-fa35-4e68-b7ba-7a0e362fe871",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "6c21a8c9-76e8-498e-8267-9f7f90d80900",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//           "samagri": {
//             "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "name": "వరిపెళాలు/Rice Fields",
//             "description": null,
//             "units": "gms",
//             "standardQty": "50",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:33:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 58
//           }
//         },
//         {
//           "id": "6293587e-17cc-4fae-80e2-1d32061b260b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//           "samagri": {
//             "id": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//             "name": "జాజి కయ/Nutmeg",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 22,
//             "pujariSellingPrice": 30,
//             "customerMrp": 30,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:03:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 50
//           }
//         },
//         {
//           "id": "6080ffbd-55c9-455c-b08a-bb0c070773f9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 51,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "5f5a5b67-3d0c-4ff0-9079-aebbb5b6e701",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "5eca6414-b46b-46ae-a84e-ed8377bd2bda",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "2e4abf8f-5fe9-4add-aca5-da8dc7d7b98f",
//           "samagri": {
//             "id": "2e4abf8f-5fe9-4add-aca5-da8dc7d7b98f",
//             "name": "తెల్ల ఆవాలు / White Mustard",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 13,
//             "pujariSellingPrice": 17,
//             "customerMrp": 17,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:12:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T01:04:20.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 86
//           }
//         },
//         {
//           "id": "5acbd341-ed57-49c1-b55e-edd9fcee4f20",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "573c5a93-93f5-4d51-a6c7-33d7d6eaee95",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "53fe27eb-07c3-40f6-88d7-81b268e3b840",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "535de3c8-416b-4a38-a726-f77fecb50ccb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "51ffc44c-c081-4e07-97df-16b6b132435b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//           "samagri": {
//             "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "name": "ఇసుక / SAND",
//             "description": "As Gampallu",
//             "units": "other",
//             "standardQty": "1",
//             "costPrice": 100,
//             "pujariSellingPrice": 125,
//             "customerMrp": 125,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:28:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:16:43.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 151
//           }
//         },
//         {
//           "id": "4f37deb4-7ad7-4237-bb90-13c5eb872978",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "47892dd1-3eb5-4c6e-9cc8-55c2f8b5be9d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//           "samagri": {
//             "id": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//             "name": "అటుకులు / Poha",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:28:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 101
//           }
//         },
//         {
//           "id": "475d7950-f912-40f0-8cfa-37e46504e1a5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "413d47d6-c46e-4d65-b7e2-c91b26a31af2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "3dfe797b-3b4e-4938-9ccd-894434bceea8",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "3d683149-b1d9-49a6-8d07-681e55894106",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "1b23748e-bf83-4c3b-b84c-bdb96d280750",
//           "samagri": {
//             "id": "1b23748e-bf83-4c3b-b84c-bdb96d280750",
//             "name": "మట్టి కంచుళ్ళు /  Clay pebbles (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 50,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:58:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T03:44:02.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 135
//           }
//         },
//         {
//           "id": "3ccbcb49-5b99-4350-be37-dfce792b737d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "37d14a5d-83f8-42c4-8907-809a440c39f4",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "378cdc84-ac97-415a-823d-4b9bd89cba13",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 25,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "f45a00cd-55ce-4107-ad58-32050ceda4ae",
//           "samagri": {
//             "id": "f45a00cd-55ce-4107-ad58-32050ceda4ae",
//             "name": "తామర గింజలు / Lotus seeds",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 6,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:19:40.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-09T06:09:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 194
//           }
//         },
//         {
//           "id": "36882852-d4ff-4b21-b814-f9329804e9e7",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//           "samagri": {
//             "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "name": "చెంబులు/Kalasam",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:26.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:39:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 253
//           }
//         },
//         {
//           "id": "33cf6fa3-3a9f-4df6-97eb-e21f61fff78b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "29b6af6e-0168-478b-8535-0d7587849e02",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "2902f4e1-3a61-491f-a30c-67fca42196ee",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "1a3a115b-97c4-43ac-8c42-04aa79abf12a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "1944d66e-8633-4278-8af7-d8f768142f24",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//           "samagri": {
//             "id": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//             "name": "పచ్చ కర్పూరం /  GREEN CAMPHOR",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 10,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:22:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 99
//           }
//         },
//         {
//           "id": "16a1cb2a-18d6-4f12-9eb5-3afa27405fad",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "117c02aa-2fbd-4442-ba21-5bed7c03dde1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "111eaa31-5af9-47b0-8144-3ec2f53b901a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         },
//         {
//           "id": "0bffd6c3-944e-4360-aa7e-cb105690f2f8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//           "samagri": {
//             "id": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//             "name": "తెల్ల నువ్వులు/White Sesame",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T02:09:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 43
//           }
//         },
//         {
//           "id": "06c76b99-e556-4161-8196-4a23d7480ace",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "fa49dd85-1709-4b55-b3b2-957c5abb5c29",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 100,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "f69670e3-a096-4123-ae8c-e11a309b82c4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "2dcb00f8-6ed3-4736-877b-544a38fed1ab",
//           "samagri": {
//             "id": "2dcb00f8-6ed3-4736-877b-544a38fed1ab",
//             "name": "Fruits and Flowers ( Sudarshana Homam)",
//             "description": "వీడి పూలు - 1/4 kg, పూల మూరలు -5,  తామరపువ్వులు -3,  బత్తాయి పళ్ళు -3, సపోటాలు-3,  ఆపిల్స్ -3, కమలాలు -3  చేరుకుగడ ముక్కలు - 1/4 kg\n",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 570,
//             "pujariSellingPrice": 600,
//             "customerMrp": 600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T01:33:05.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-09T07:23:53.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 217
//           }
//         },
//         {
//           "id": "ef576259-34ba-4e92-9e39-6f4d833a939f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "ee5e2808-f031-407e-9549-ddfb059c3108",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "e6991869-4cac-4aa9-959b-a14b2aa3478e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//           "samagri": {
//             "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "name": "బెల్లం / Jaggery",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:23:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T01:22:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 143
//           }
//         },
//         {
//           "id": "e320875b-00c5-49cc-9823-6e73dc4fcc3a",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "e02e9a58-82a4-4f79-a2a0-f76b9a75c5ca",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "dc989022-956d-4097-9825-943a0b018db9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "d82f91c0-0eb9-415d-a195-c3415d89df96",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//           "samagri": {
//             "id": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//             "name": "నీళ్ల బిందెలు / Water Pots",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:33:20.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 248
//           }
//         },
//         {
//           "id": "d76a2f80-fc2d-4820-9c58-2fd1d6a65c0c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "d6c96ae9-7a56-486b-98c0-06de8f75a7be",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "ecaa05b8-2b35-43fc-b0c5-a7effff2676a",
//           "samagri": {
//             "id": "ecaa05b8-2b35-43fc-b0c5-a7effff2676a",
//             "name": "మినపప్పు/Urad Dal",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 4,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T05:01:50.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 30
//           }
//         },
//         {
//           "id": "d6c087fc-b7eb-4296-8d4e-63301161402c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "d37a04dc-9509-4d82-8715-e887fe49ec79",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 100,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//           "samagri": {
//             "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "name": "ఇటుకలు/ BRICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 8,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:39:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:14:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 150
//           }
//         },
//         {
//           "id": "cb3278c3-49f0-4683-a1a1-55ff8417d575",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "c7188900-6bb3-455c-a21f-6e082c26ef51",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "75c54993-415b-4c9c-b109-22c643a7f56b",
//           "samagri": {
//             "id": "75c54993-415b-4c9c-b109-22c643a7f56b",
//             "name": "గోధుమలు / Wheat",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 67
//           }
//         },
//         {
//           "id": "c174a5b4-a2cb-4b03-909d-2a06607577b3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "c085fac8-d2df-4c4a-91ec-7fb83d7d5f13",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         },
//         {
//           "id": "bf86fed3-81f3-4a3f-b098-99aa579afa74",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//           "samagri": {
//             "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 500,
//             "pujariSellingPrice": 600,
//             "customerMrp": 600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:43:18.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 114
//           }
//         },
//         {
//           "id": "abe6dd1b-1cce-4042-adc5-54759d3f96ca",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "0f1d9325-8831-460a-9060-bc780de080a4",
//           "samagri": {
//             "id": "0f1d9325-8831-460a-9060-bc780de080a4",
//             "name": "ఉత్తరేణీ   పుల్లలు / Uttareni sours",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 50,
//             "pujariSellingPrice": 55,
//             "customerMrp": 55,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:32:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-09T06:07:29.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 220
//           }
//         },
//         {
//           "id": "a7d8e90a-245f-4880-b759-faafa843a511",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "9969d251-aded-4a89-8fe5-799df6963a55",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "80f2e43f-0de9-49a0-b310-9267ab16b4fc",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "79cca446-4200-4792-b73f-1766af6e6736",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "764b1f6c-c8fd-4df2-9691-d65c4d9a6435",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 150,
//           "samagriType": "default",
//           "samagriId": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//           "samagri": {
//             "id": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//             "name": "లవంగాలు / cloves",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:08:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 88
//           }
//         }
//       ]
//     },
//     {
//       "id": 16,
//       "name": "Ganapati Homam",
//       "description": "A certain amsha / energy of Ganapati is worshipped through this homam.  Ganapati combines the energy of Shiva and Parvati. Through the peacefulness of Shiva and the creative power of Parvati, and with his enormous intelligence removes all the obstacles from our path.",
//       "language": "telugu",
//       "region": "Hyderabad",
//       "mode1": "at_my_home",
//       "mode2": "on_behalf",
//       "mode3": "remote",
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/SsRsg2jC-ec\",\"https://www.youtube.com/embed/CkuMjlP8w9A\",\"https://www.youtube.com/embed/qrdxFxKIYNY\",\"https://www.youtube.com/embed/tQJc70v5O2o\"]",
//       "images": "[\"/uploads/2023/07/Homam-2-scaled.jpg\"]",
//       "updatedAt": "2023-07-24T01:33:38.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": null,
//       "createdById": null,
//       "updatedById": "44",
//       "pujacategorymappings": [
//         {
//           "id": 17,
//           "pujaId": 16,
//           "categoryId": 2,
//           "subCategoryId": 4
//         },
//         {
//           "id": 16,
//           "pujaId": 16,
//           "categoryId": 2,
//           "subCategoryId": 7
//         },
//         {
//           "id": 4,
//           "pujaId": 16,
//           "categoryId": 1,
//           "subCategoryId": 1
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 80,
//           "pujaId": 16,
//           "durationHrs": 4,
//           "noOfPujaris": 2,
//           "modelSellingPrice": 15000,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 7000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-20T12:11:15.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-01T15:20:41.000Z",
//           "createdById": null,
//           "updatedById": "44",
//           "serviceFee": 1011,
//           "samagriCost": 6300,
//           "procedures": []
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "f6f40e4a-45c6-4fdb-9ec3-f8d36fb90128",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//           "samagri": {
//             "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 500,
//             "pujariSellingPrice": 600,
//             "customerMrp": 600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:43:18.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 114
//           }
//         },
//         {
//           "id": "f2bf891b-03fd-4a1f-8db3-1d92f856ebc6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "499f3b07-536e-4138-8123-a78958cb2c3a",
//           "samagri": {
//             "id": "499f3b07-536e-4138-8123-a78958cb2c3a",
//             "name": "పట్టికబెల్లం చిప్స్ /Misri Chips",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:54:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 106
//           }
//         },
//         {
//           "id": "f2569b3a-348a-482e-9a74-f89aa84b30eb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//           "samagri": {
//             "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "name": "బెల్లం / Jaggery",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:23:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T01:22:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 143
//           }
//         },
//         {
//           "id": "e5a2039f-cb12-4f2e-90c7-f9acc05f9c77",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "96414c68-fdd7-4a85-9fc1-4bedd5c27b60",
//           "samagri": {
//             "id": "96414c68-fdd7-4a85-9fc1-4bedd5c27b60",
//             "name": "జీడిపప్పు/Cashew",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:59:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 12
//           }
//         },
//         {
//           "id": "dd9ef38a-ce3c-4abf-a5dc-e8136cc4499f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "d85bb33f-95da-49ab-8978-ac7340d5199f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "c457817e-7b7e-42fd-836c-c0723e0a06f2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "bbdb70b7-1300-4e8f-bf6b-2bbc7103530b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//           "samagri": {
//             "id": "92da20c4-a10e-4013-9364-f5114438d904",
//             "name": "వీడి పూలు / Loose Flowers",
//             "description": null,
//             "units": "gms",
//             "standardQty": "250",
//             "costPrice": 110,
//             "pujariSellingPrice": 120,
//             "customerMrp": 120,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:16:53.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:44:42.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 176
//           }
//         },
//         {
//           "id": "b646afc5-9f94-452e-9450-d369895cc5a5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "ad2ba7d6-04ab-4903-9226-62859fbadb0a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 11,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "ca478478-dede-4f37-bb5a-1e2c2a023696",
//           "samagri": {
//             "id": "ca478478-dede-4f37-bb5a-1e2c2a023696",
//             "name": "చెరుకు ముక్కలు / Pieces of Sugarcane",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 12,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:36:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T05:03:23.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 186
//           }
//         },
//         {
//           "id": "a03db37a-ac9d-4cf3-9677-2e47d845b8a2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "00416a9a-4864-4758-a471-f915ab3c63e7",
//           "samagri": {
//             "id": "00416a9a-4864-4758-a471-f915ab3c63e7",
//             "name": "ముద్దకర్పూరం / Big Chunk Camphor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 38,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:40:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 113
//           }
//         },
//         {
//           "id": "9ea9703b-f66d-4ff3-9625-57bb3b68c51e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//           "samagri": {
//             "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "name": "అరటి పండ్లు / Banana",
//             "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//             "units": "no",
//             "standardQty": "12",
//             "costPrice": 60,
//             "pujariSellingPrice": 70,
//             "customerMrp": 70,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-19T22:36:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:26:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 181
//           }
//         },
//         {
//           "id": "9c9ce3e4-a7b3-4c0b-8695-ea57f819ab24",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         },
//         {
//           "id": "99e3c30b-80ab-4b52-bde4-48d4b735236c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "97d82f32-333d-41f5-ab58-c7a86ec49374",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "94114175-495b-42d7-b39e-31321d4c1010",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//           "samagri": {
//             "id": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//             "name": "తెల్ల నువ్వులు/White Sesame",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T02:09:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 43
//           }
//         },
//         {
//           "id": "92c33e8b-8bcd-4238-98e8-5eab3c61b2d3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//           "samagri": {
//             "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:38:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 115
//           }
//         },
//         {
//           "id": "8d7667ee-f603-415a-a4a4-65d9d659f0b9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "8973b4bb-b3f8-4968-b25b-25949007ecb2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//           "samagri": {
//             "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "name": "ఇటుకలు/ BRICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 8,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:39:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:14:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 150
//           }
//         },
//         {
//           "id": "6e86ef2a-6606-4e65-8f85-165125fc4e76",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "a3d9cd9c-7e5e-4161-97b8-8ea71843eae4",
//           "samagri": {
//             "id": "a3d9cd9c-7e5e-4161-97b8-8ea71843eae4",
//             "name": "అగ్గి పెట్టిలు (Homelite) /Match Box (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:57:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 40
//           }
//         },
//         {
//           "id": "6aa966ef-3cc5-46f6-933c-597457dc1785",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "64e2c83c-bbaf-4fcd-adc2-3813914b3dde",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "62b03864-ba92-42e7-a93a-3aac91198b0c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//           "samagri": {
//             "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "name": "దర్భ కట్టలు/Kusha Grass",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T06:06:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 53
//           }
//         },
//         {
//           "id": "5f09c330-59f0-4c21-b308-aa241c7c59ad",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//           "samagri": {
//             "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 230,
//             "pujariSellingPrice": 270,
//             "customerMrp": 270,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:31:17.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 23
//           }
//         },
//         {
//           "id": "5d909619-db03-4ac9-9587-cb29c8329a7f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "5907fd01-8937-4b29-88e0-c32e4ec93f62",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "555e8376-034e-4474-9dc1-af299126c983",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 100,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "4d0fd598-e513-4ffa-9770-dc18205cec5c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 75,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "4d0829cd-ba06-4a75-84cb-8c9c5dd78432",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//           "samagri": {
//             "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "name": "ఇసుక / SAND",
//             "description": "As Gampallu",
//             "units": "other",
//             "standardQty": "1",
//             "costPrice": 100,
//             "pujariSellingPrice": 125,
//             "customerMrp": 125,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:28:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:16:43.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 151
//           }
//         },
//         {
//           "id": "4cfe7439-b8c2-4d6f-9a9d-42d232769e5e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//           "samagri": {
//             "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "name": "గరిక/  Grass",
//             "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 20,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:31:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:11:34.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 183
//           }
//         },
//         {
//           "id": "44e2f7ce-b875-4ba6-bf67-a9efc0e23bfb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//           "samagri": {
//             "id": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//             "name": "అటుకులు / Poha",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:28:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 101
//           }
//         },
//         {
//           "id": "3aa0252e-4ebe-4e7c-b73e-ed6725a040df",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "37bb68b9-a712-41a4-8105-e56ecf1f0779",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "d7ec440e-56ba-4c10-bac4-2ad60700526d",
//           "samagri": {
//             "id": "d7ec440e-56ba-4c10-bac4-2ad60700526d",
//             "name": "బాదంపప్పు/Badam",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 9,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:11:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 13
//           }
//         },
//         {
//           "id": "349937b7-660a-4e0d-9751-a7a5a42d62c1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//           "samagri": {
//             "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "name": "వరిపెళాలు/Rice Fields",
//             "description": null,
//             "units": "gms",
//             "standardQty": "50",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:33:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 58
//           }
//         },
//         {
//           "id": "3291a823-2769-48ae-a0cf-bda92a485af3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "2e1e9d1e-2a71-41a2-ac1e-ecb2e723722b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "2686a4c1-fa56-419a-aa62-42327e507a41",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "389822bd-ca89-469c-bc0a-82694a7c8062",
//           "samagri": {
//             "id": "389822bd-ca89-469c-bc0a-82694a7c8062",
//             "name": "అష్ట గంధం/Ashtagandham",
//             "description": null,
//             "units": "gms",
//             "standardQty": "5",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:28:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 47
//           }
//         },
//         {
//           "id": "24cec99e-2609-4d37-89ae-ac0ec3e3c42d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "22fef19b-8d26-41c2-9cc1-78c20cad8ffc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "1733ae70-2bc5-4f5e-a07f-ac787c77d21d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "14621871-914d-455a-9531-173eb129d705",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "80189106-e98e-41b8-8435-beef3b4cf163",
//           "samagri": {
//             "id": "80189106-e98e-41b8-8435-beef3b4cf163",
//             "name": "ఎండుద్రాక్ష/Raisins ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:11:50.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 14
//           }
//         },
//         {
//           "id": "103a9fab-e387-4352-b1a0-80dd76965d98",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "0fb46a14-a0f4-4b00-8888-2985512f8ca0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "006db73d-9c35-481c-8281-9f119f9da334",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "0034e504-a972-46cd-b67a-0f3eafed8dc5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 16,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         }
//       ]
//     },
//     {
//       "id": 132,
//       "name": "Rudrabhishekam (Eka rudram)",
//       "description": "Rudras are the energies that have originated from Lord Shiva and control several aspects within our body.  There are a total of eleven prominent Rudras that govern the pranas and upapranas which are worshipped in Rudrabhishekam\n\nThe puja procedure given below is for Eka rudram (one recitation of namakam and chamakam) with regular offerings to the Shiva lingam such as panchamrutham.  \n\nDepending on the purpose and wish, the repetitions can vary.  Our pujari will accordingly make changes based on your requirement, after we receive your booking submission",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": "on_behalf",
//       "mode3": null,
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/DJpFNFqfngU\",\"https://www.youtube.com/embed/egsORT-tkqk\",\"https://www.youtube.com/embed/ku6Op_DJgDo\",\"https://www.youtube.com/embed/pArLQEQyECA\",\"https://www.youtube.com/embed/Yf5vE75JAUg\"]",
//       "images": "[\"/uploads/2023/07/Rudrabhishekam-Right-Size.png\"]",
//       "updatedAt": "2023-07-24T01:34:37.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-06-02T05:15:35.000Z",
//       "createdById": null,
//       "updatedById": "44",
//       "pujacategorymappings": [
//         {
//           "id": 19,
//           "pujaId": 132,
//           "categoryId": 2,
//           "subCategoryId": 4
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 82,
//           "pujaId": 132,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 6999,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 3000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-20T12:04:06.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-02T05:18:06.000Z",
//           "createdById": null,
//           "updatedById": "44",
//           "serviceFee": 677,
//           "samagriCost": 1521,
//           "procedures": []
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "fe9f9e70-e1c7-40cc-b99d-0f8fcb030d76",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "f79d1977-c38e-4813-a9cc-c05d1d621bfb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "f6b56cf2-f256-41ad-8388-1cd4c4a97d07",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "f11a07d4-c021-46b5-94cf-375244c3b7e6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "ec0606ac-9760-4732-8778-7f6b6d9c797e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "df52dbdb-2d21-4675-9a8f-d6b035a3bcc7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "ddb8efc5-1e65-4d42-95c7-d1fa2b450c4d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "db4f7fb2-ca06-4702-9df5-5b7e040f2f0e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//           "samagri": {
//             "id": "92da20c4-a10e-4013-9364-f5114438d904",
//             "name": "వీడి పూలు / Loose Flowers",
//             "description": null,
//             "units": "gms",
//             "standardQty": "250",
//             "costPrice": 110,
//             "pujariSellingPrice": 120,
//             "customerMrp": 120,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:16:53.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:44:42.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 176
//           }
//         },
//         {
//           "id": "d9d04849-a371-42d8-8475-48cb0c92f3d7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "d57b983b-44d2-4514-87d6-e924ccef244b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 2,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "d0f66847-b34e-47a0-b7a2-75177181f1d8",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         },
//         {
//           "id": "ceb29804-08b0-4348-85b0-9b184a76d658",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "cd9003dc-e302-4c82-aa82-41a2974b663f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "ca88f049-692a-4db6-93ba-08e83c8d713a",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "b6fb3968-5e9f-4eae-a75e-b1f772abc1a1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 16,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "b3074ddb-c626-4a1a-a56a-9d8a9f5d8659",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "aca46391-8fce-41e3-84f2-84d0a483e2e1",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "a5e63f73-b9a2-41c5-8b2d-709f4346cf35",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "a0240e8c-1685-4eb6-b4d1-2ca468ae7478",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "99651137-966b-4e45-8d43-8d911a1a723b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         },
//         {
//           "id": "951e91a8-c832-430b-b69e-9482d12d672a",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "91fb9278-0495-4c58-8a76-70bd748d285e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "8ca8a391-e884-49d7-a2fc-2a3a6ce2a3bb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "7a639953-efe4-464b-b120-8501fc264dc9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "6e82f2bf-7881-47f3-a009-017461eee5df",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "66643472-61bd-462c-b760-e1a1e436d335",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//           "samagri": {
//             "id": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//             "name": "నీళ్ల బిందెలు / Water Pots",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:33:20.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 248
//           }
//         },
//         {
//           "id": "6650f68a-b61c-42e1-9527-c8230db47413",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//           "samagri": {
//             "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "name": "అరటి పండ్లు / Banana",
//             "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//             "units": "no",
//             "standardQty": "12",
//             "costPrice": 60,
//             "pujariSellingPrice": 70,
//             "customerMrp": 70,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-19T22:36:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:26:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 181
//           }
//         },
//         {
//           "id": "62d46984-6c62-48a6-a362-a846a183af10",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "5f951510-eb7a-4504-8cbe-ca972775c92d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//           "samagri": {
//             "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "name": "గరిక/  Grass",
//             "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 20,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:31:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:11:34.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 183
//           }
//         },
//         {
//           "id": "5eca02b8-cb0f-4289-b429-1a71da1462ed",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "71cf7fc1-7b23-44d9-a265-c673cc1d5f8b",
//           "samagri": {
//             "id": "71cf7fc1-7b23-44d9-a265-c673cc1d5f8b",
//             "name": "Fruits and Flowers ( Rudrabhishekam)",
//             "description": "మారేడు  దళాలు - 30 దళాలు, కొబ్బరి   నీళ్లు - 1 లీటర్ ,   5 రకాలు ఈచ్  2,  వీడి పూలు -1/4 kg\n,పూల మూరలు -2",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 750,
//             "pujariSellingPrice": 775,
//             "customerMrp": 775,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:13:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-09T05:18:53.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 218
//           }
//         },
//         {
//           "id": "5356c1dc-004a-4ee4-bc9a-e7665828a94e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "1a564751-0ae5-42de-821a-e1e3afe41741",
//           "samagri": {
//             "id": "1a564751-0ae5-42de-821a-e1e3afe41741",
//             "name": "కత్తెర / Scissor",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 150,
//             "pujariSellingPrice": 170,
//             "customerMrp": 170,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T06:13:28.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-21T06:13:28.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 167
//           }
//         },
//         {
//           "id": "4e5aff47-72d7-42c7-b11c-94fe83574b8b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "4c7c9bbb-aba1-4d57-8e82-eabba77ff1f6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "4c23254d-2243-4bd2-aa3d-41cf3860a730",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "39a878a5-315f-4f8d-8c2f-e995d33dd1ab",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "64d18991-1089-470e-adff-d0567d3e8b1e",
//           "samagri": {
//             "id": "64d18991-1089-470e-adff-d0567d3e8b1e",
//             "name": "వట్టివేళ్ళు / Khas Khas grass",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:32:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 104
//           }
//         },
//         {
//           "id": "2f0ba153-8b02-47a1-b3d7-5fea71a3026a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "2c01bf2b-26d1-4fa7-b700-51b8c6b5b3d6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "26229c15-1c00-43c7-b7ae-fe113c7aa3f5",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "14b09ec7-0688-427d-9aef-57e215744c6e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "12eb0433-1f04-47d1-98fc-bebd19aa4b7f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "0c2859b9-2af0-4075-a59c-a97e526a7625",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//           "samagri": {
//             "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "name": "చెంబులు/Kalasam",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:26.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:39:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 253
//           }
//         },
//         {
//           "id": "06ce2ed9-8e13-45f4-ac95-457b0248c2f0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//           "samagri": {
//             "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 8,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:40:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 33
//           }
//         },
//         {
//           "id": "050cdde2-6691-4d1b-86b2-ee18941d9d76",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 132,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         }
//       ]
//     },
//     {
//       "id": 149,
//       "name": "Ayushya Homam ",
//       "description": "Ayushya means lifespan in Sanskrit. Ayush homam is the worship of Ayur Devatha (God of life). By performing this ayush homam, one is blessed with good health and long life. As mentioned in Bodhayana sutras, if a child is falling ill often, this homam could be performed regularly until the child gets better",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": "on_behalf",
//       "mode3": "remote",
//       "mode4": null,
//       "videos": "undefined",
//       "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//       "updatedAt": "2023-08-11T04:27:40.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-06-16T00:56:13.000Z",
//       "createdById": null,
//       "updatedById": "9",
//       "pujacategorymappings": [
//         {
//           "id": 27,
//           "pujaId": 149,
//           "categoryId": 2,
//           "subCategoryId": 7
//         },
//         {
//           "id": 23,
//           "pujaId": 149,
//           "categoryId": 2,
//           "subCategoryId": 4
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 84,
//           "pujaId": 149,
//           "durationHrs": 5,
//           "noOfPujaris": 2,
//           "modelSellingPrice": 19999,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 10500,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-28T03:15:48.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-23T11:56:39.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 1715,
//           "samagriCost": 4733,
//           "procedures": [
//             {
//               "id": "cbf771d1-c80c-488c-a797-04f9a0ba0def",
//               "modelId": 84,
//               "procedureId": 46,
//               "procedure": {
//                 "id": 46,
//                 "language": "telugu",
//                 "title": "Ayushya Homam",
//                 "description": "The pradhana ayur devatha is offered havissu along with the other devatas that were invited during the kalasa sthapana and mandaparadhana.   Ayusha suktham and ayur devatha mantram are utilised while performing the homam.  Maha mrutyunjaya, saptha chiranjeevulu, dhanvantari are also considered as prominent during the procedure.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "9f6392bf-5526-4b42-9ee7-ae191bed78ce",
//               "modelId": 84,
//               "procedureId": 39,
//               "procedure": {
//                 "id": 39,
//                 "language": "telugu",
//                 "title": "Plate of rice with gold ring wrapped with darbha",
//                 "description": "As Darbham grass absorbs radiation from the atmosphere, it is also used to cover all food items during eclipse time, to protect them. Darbham is also an effective conductor of sound vibrations, which makes it suitable to absorb and transfer Vedic chanting vibrations.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "a5446ff6-f7cf-4fcc-88ba-d0bea9424a0c",
//               "modelId": 84,
//               "procedureId": 37,
//               "procedure": {
//                 "id": 37,
//                 "language": "telugu",
//                 "title": "Ash Gourd break / Gummadikaya kottadam",
//                 "description": "As the vegetable called Ash gourd is very high in life energy, it is broken in front of the door.\nAnother ash gourd (a full one, not damaged) is sometimes hung at the entrance after puja and replaced regularly. This wards off any Nara drishti doshas and keeps positive energies flowing into the house.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "f96a2ef0-5629-4ead-ac3a-fea70190e30c",
//               "modelId": 84,
//               "procedureId": 31,
//               "procedure": {
//                 "id": 31,
//                 "language": "telugu",
//                 "title": "Govu puja / Govu pravesham",
//                 "description": "The reason for making a cow enter a new house before the family members is to resolve any vastu doshas, if present in the house. Since, it is believed that, every aspect of a cow is associated with a deity, the cow helps bring peace and prosperity in the house.",
//                 "specialNotes": "If you can accommodate and wish to have an actual cow enter your space, please mention the same in special notes or to our representative.  We will verify if it is safe and convenient to gomatha and arrange for the same."
//               }
//             },
//             {
//               "id": "136cf03c-331b-40d3-9fa4-0cfdaf26171a",
//               "modelId": 84,
//               "procedureId": 22,
//               "procedure": {
//                 "id": 22,
//                 "language": "telugu",
//                 "title": "Abhishekam (Panchamrutalu)",
//                 "description": "Abhishekam can be seen as watering the roots of a tree. Only if the roots of a tree are nourished, it can produce fruits. During an Abhishekam, the results that we desire to see in this world are like the fruits of a tree, and the divine beings who influence these results are like roots. Since, everything in this universe is interconnected, we perform Abhishekam to see specific results in the world.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "d5102486-feb6-4ab8-9384-43dd48ddb50b",
//               "modelId": 84,
//               "procedureId": 17,
//               "procedure": {
//                 "id": 17,
//                 "language": "telugu",
//                 "title": "Mandapam",
//                 "description": "A devotee can worship his/ her Ishta devata, to help them achieve their wishes and to eventually move onto Moksha. Since, It is not easy to concentrate on the formless, we invite the devata to descend into a form. A devata is pure consciousness, who does not have a form. Just like water, the devata can be invited to take the form of the vessel, known as an Upadhi. It is like freezing water into an ice-block of desired shape.  Mandapam is a suitable place that is prepared prior to starting the puja where devatas can be invoked and concecrated until the puja ends. ",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "f49abdbb-433e-4093-a6ca-1eb14899d83b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//           "samagri": {
//             "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 500,
//             "pujariSellingPrice": 600,
//             "customerMrp": 600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:43:18.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 114
//           }
//         },
//         {
//           "id": "f38ea173-42d1-4bad-8559-4bf0ad2bd07c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 40,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//           "samagri": {
//             "id": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//             "name": "నల్ల నువ్వులు/Black Sesame",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T02:08:36.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 42
//           }
//         },
//         {
//           "id": "ef531cb3-f4aa-4ed2-ab12-faa5868df008",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "ede0168e-15c6-4d6e-bfb6-7cba3cf8abe5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 35,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//           "samagri": {
//             "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "name": "ఇటుకలు/ BRICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 8,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:39:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:14:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 150
//           }
//         },
//         {
//           "id": "edcad959-ac33-41ad-a7cd-e6dcbbbb0bab",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         },
//         {
//           "id": "e5a3e593-5bf2-4fed-afc8-d88c44a00570",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "0031beaf-9312-467e-853a-b2243ce677f5",
//           "samagri": {
//             "id": "0031beaf-9312-467e-853a-b2243ce677f5",
//             "name": "గోమూత్రం/Cow Urine",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:17:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:26:29.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 231
//           }
//         },
//         {
//           "id": "e1f5c912-bb4d-489a-985b-936e6ee5d785",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "de24904d-8678-4b7d-afba-ed5593fa6aa5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//           "samagri": {
//             "id": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//             "name": "లవంగాలు / cloves",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:08:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 88
//           }
//         },
//         {
//           "id": "dd4d6214-b4cb-42df-be9b-f37f6da9fa7c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//           "samagri": {
//             "id": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//             "name": "పచ్చ కర్పూరం /  GREEN CAMPHOR",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 10,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:22:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 99
//           }
//         },
//         {
//           "id": "d8c5c632-f555-42a1-8832-6b2e6e7ec5b6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//           "samagri": {
//             "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:38:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 115
//           }
//         },
//         {
//           "id": "d7b407d7-edfd-48c5-b89c-9070921ea312",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "d6a260a4-4eaf-4de1-b514-3eb46adb7a27",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "d4b37797-bbd0-4dbd-ae5b-3fb2ce671815",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "d3e7783c-8ea9-4324-ab28-bb14f509c1c4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "d3da642a-5963-489e-9ed3-91d1475e0a77",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "d13f1fbd-813c-49b9-8875-4c3ba428487c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "d0304bb4-679a-4d71-b1f0-f187fbd7105a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "c26e726b-a69a-46f1-ba97-873ec775ddbe",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//           "samagri": {
//             "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 8,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:40:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 33
//           }
//         },
//         {
//           "id": "be81a535-c933-4651-8d66-8faa49447510",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//           "samagri": {
//             "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 18,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:01:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 48
//           }
//         },
//         {
//           "id": "b5ee5b38-541c-49d7-b51c-5801728e5f7d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "aba90d1f-8174-4d98-8264-82cae743b497",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "a94a4c39-53b7-4b15-bd71-36f704467359",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "a7b0fc14-9c5f-4d2e-abc4-08e724121b90",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 15,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "a76e0fce-23da-48ad-89a5-f67b4ad87fda",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//           "samagri": {
//             "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "name": "ఇసుక / SAND",
//             "description": "As Gampallu",
//             "units": "other",
//             "standardQty": "1",
//             "costPrice": 100,
//             "pujariSellingPrice": 125,
//             "customerMrp": 125,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:28:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:16:43.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 151
//           }
//         },
//         {
//           "id": "a1594a5b-33fa-45cf-82b2-dd1cab71af80",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//           "samagri": {
//             "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "name": "దర్భ కట్టలు/Kusha Grass",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T06:06:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 53
//           }
//         },
//         {
//           "id": "9ee8508c-f632-4ad6-8094-d46bbf5c781b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "9b45e8ba-f580-4848-ac43-e93a8da7b2e1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "9586933f-bf85-4e54-bef5-9f07bdc5066a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "f3809443-befe-4c75-a784-4f1cf7527d51",
//           "samagri": {
//             "id": "f3809443-befe-4c75-a784-4f1cf7527d51",
//             "name": "యజ్ఞోపవేతం వడికిన /Sacred thread",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 45,
//             "customerMrp": 45,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:25:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 52
//           }
//         },
//         {
//           "id": "9499587e-130f-4b01-83b9-8900dde47911",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "8ad357a6-83b0-43e0-acf5-b7a6c7a522cd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "8a00ddbb-fd74-43bc-8933-13baad1d599e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "8841250a-604f-479b-b5b2-7fd79b24b1e7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//           "samagri": {
//             "id": "eafd4c6a-3f55-47c4-9f62-f91f3914f64c",
//             "name": "జాజి కయ/Nutmeg",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 22,
//             "pujariSellingPrice": 30,
//             "customerMrp": 30,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:03:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 50
//           }
//         },
//         {
//           "id": "84bcc252-6e73-4352-95fd-d56d8058ab0e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 11,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "828ed7ee-dc57-4c2c-bd5b-cdf42eee0d41",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//           "samagri": {
//             "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "name": "గరిక/  Grass",
//             "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 20,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:31:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:11:34.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 183
//           }
//         },
//         {
//           "id": "7d58bc9d-4c39-4f9b-8741-caca5802b674",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "7b92df8f-d80a-4da1-864d-cd30a216e2ef",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "58f64511-aebf-4e90-b1ca-0c398343d723",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "5765cc8a-9020-4056-93f9-8bff4bf1ed52",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "54e72aaf-614f-4f2e-a149-c1365198250d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "50af142e-3442-48a7-898a-b17b787181bf",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "42552b53-47d7-494b-9212-b2f7f92f2daa",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "39fc0e0b-4089-4198-8782-7a051d969ce4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//           "samagri": {
//             "id": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//             "name": "యాలకులు / Cardamom",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 10,
//             "pujariSellingPrice": 24,
//             "customerMrp": 24,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:07:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 87
//           }
//         },
//         {
//           "id": "2f751660-799a-48e0-8341-fd1e4083cb64",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "2f556b37-f8a9-48a4-88ce-6613c7bb431d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "2dfcdbad-5b3e-490c-9cbd-3a041a232645",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "2dd1b7a7-6957-4677-b610-f3d5a9fdbab8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//           "samagri": {
//             "id": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//             "name": "గోమయం/Cow Dung",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-13T06:33:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:27:00.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 230
//           }
//         },
//         {
//           "id": "1f874d0a-4652-4c54-9b84-df12a12ba39e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "1c9121e1-87e5-4a3d-9ea6-a988591c36e9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//           "samagri": {
//             "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 230,
//             "pujariSellingPrice": 270,
//             "customerMrp": 270,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:31:17.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 23
//           }
//         },
//         {
//           "id": "19a5b6d4-cfbd-45fb-a143-d357422cc379",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "14730a63-5f68-4e15-bd5c-2728ac9ac5a1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//           "samagri": {
//             "id": "a91d7023-d5fe-47ba-bb76-2feb39a41b20",
//             "name": "జాపత్రి/Nutmeg Mace",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 54,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:04:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 51
//           }
//         },
//         {
//           "id": "12943569-d426-4b4c-88ba-df9ac45ea1a0",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "0bb93dc3-1159-4f63-8eae-b9376e84154f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "c0dfeb27-c9b4-4965-9cbe-9175cbff8e5c",
//           "samagri": {
//             "id": "c0dfeb27-c9b4-4965-9cbe-9175cbff8e5c",
//             "name": "Fruits & Flowers / పూలు మరియు పండ్లు  ( Ayushya Homam)",
//             "description": "విడి పూలు - 1/4 kg,  పూల మూరలు - 3, మారేడు దళాలు - 20 g, అరటి పండ్లు - 1 dozen, 4types of fruits each 2\n ",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 560,
//             "pujariSellingPrice": 672,
//             "customerMrp": 672,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-25T06:59:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-17T08:08:30.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 268
//           }
//         },
//         {
//           "id": "075e3a7b-40e0-4b52-8d35-ea3afd9e3cf4",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "06f450db-6405-4948-930d-b642ccba3468",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "02d6a08c-81bc-4828-b229-38f4058b477a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "0106ba7b-d79f-46bc-b78b-92835b72fe4d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 149,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         }
//       ]
//     },
//     {
//       "id": 135,
//       "name": "Navagraha Homam",
//       "description": "The term Navagraha denotes the nine celestial bodies that form the base for astrological calculations. Nava means 'Nine' and Graha means 'energy', 'force' or 'controller' so Navagraha Pooja is a prayer (ritual) performed to energies according to the nine forces.\n\nThese nine devatas govern important aspects in our life that make us experience joy or sadness based on our karma.  The real goal of any navagraha puja or astrology related pujas is to come out of problems that are really bothering us and to ultimately start our journey towards eeshwara so that we won't need such pujas in the future\n\nIf you are advised to perform this homam or any other astrological related shanti, japa etc. and if you are not sure whether you need to, do speak to us.  Our pujaris will analyse and advice you if you really need this puja OR if you can solve your issue in other ways, which only require your investment of time and shraddha.",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": "on_behalf",
//       "mode3": "remote",
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/YT3nFDlrnuw\",\"https://www.youtube.com/embed/r6YyE-Wj9kU\"]",
//       "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//       "updatedAt": "2023-07-24T01:32:24.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-06-07T05:45:46.000Z",
//       "createdById": null,
//       "updatedById": "44",
//       "pujacategorymappings": [
//         {
//           "id": 24,
//           "pujaId": 135,
//           "categoryId": 2,
//           "subCategoryId": 3
//         },
//         {
//           "id": 25,
//           "pujaId": 135,
//           "categoryId": 2,
//           "subCategoryId": 6
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 98,
//           "pujaId": 135,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 23999,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 11000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-20T12:58:36.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-26T05:19:46.000Z",
//           "createdById": null,
//           "updatedById": "44",
//           "serviceFee": 2753,
//           "samagriCost": null,
//           "procedures": [
//             {
//               "id": "5c3b4ea4-4140-4954-bcd3-61fa54c59c51",
//               "modelId": 98,
//               "procedureId": 8,
//               "procedure": {
//                 "id": 8,
//                 "language": "telugu",
//                 "title": "Mandaparadhana",
//                 "description": "Aavahanam of navagraha, ashtadikpalaka, panchalokapalaka, trimurthulu, ishta devata, kula devata, and nakshatra devatalu.",
//                 "specialNotes": "testing 8888"
//               }
//             },
//             {
//               "id": "a82ec0a1-901d-4400-bec6-a962d6896ab4",
//               "modelId": 98,
//               "procedureId": 17,
//               "procedure": {
//                 "id": 17,
//                 "language": "telugu",
//                 "title": "Mandapam",
//                 "description": "A devotee can worship his/ her Ishta devata, to help them achieve their wishes and to eventually move onto Moksha. Since, It is not easy to concentrate on the formless, we invite the devata to descend into a form. A devata is pure consciousness, who does not have a form. Just like water, the devata can be invited to take the form of the vessel, known as an Upadhi. It is like freezing water into an ice-block of desired shape.  Mandapam is a suitable place that is prepared prior to starting the puja where devatas can be invoked and concecrated until the puja ends. ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "716d5e56-83ef-4ed8-80e5-e4f1f74170fc",
//               "modelId": 98,
//               "procedureId": 23,
//               "procedure": {
//                 "id": 23,
//                 "language": "telugu",
//                 "title": "Archana ",
//                 "description": "During Archana, we offer flowers to a deity while chanting mantras or names of the deity. The flowers that we offer have a fragrance. Another name for fragrance is vasana(tendency). Therefore, offering flowers to a deity represents offering all our vasanas to the deity. And, while we let go of our vasanas, we chant the names of the deity, to connect with the divine.\n\nSometimes archana is performed with other dhravya like leaves that are most suitable for that particular devata. For e.g. tulsi, bilva etc. ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "687db513-54c8-4e41-a347-9b06e066f27a",
//               "modelId": 98,
//               "procedureId": 14,
//               "procedure": {
//                 "id": 14,
//                 "language": "telugu",
//                 "title": "Aashirvachanam",
//                 "description": "The guru aashirvachanam is the biggest blessing for the devotee or the family performing the puja.  Guru is eeshwara's form in front of us. His tapasya and veda parayana, nithya gayatri japa etc. mean that his blessings can lead to god's grace.",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "2840b60a-de77-4886-89a4-e97d21fcc4f7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "2689e211-baad-4073-87ff-993e5f349815",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 100,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "26873da3-1482-4847-8d47-f327db01d3e6",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "232bd700-0534-4a1b-8fcc-416d21abe2d7",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//           "samagri": {
//             "id": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//             "name": "నీళ్ల బిందెలు / Water Pots",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:33:20.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 248
//           }
//         },
//         {
//           "id": "1d560177-11e9-4b53-a3c2-61451e02495c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "672d4bf0-7711-41d2-9f23-1c72d79d80fc",
//           "samagri": {
//             "id": "672d4bf0-7711-41d2-9f23-1c72d79d80fc",
//             "name": "సెనగలు /  Chick pea",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:44:36.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 68
//           }
//         },
//         {
//           "id": "1c85d47a-62f9-498a-9aec-60035dbceb1d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "130e0a5e-4866-42ba-93e9-153c38556ead",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "addon",
//           "samagriId": "7526e2c0-07c4-4a31-8482-85153766f5b4",
//           "samagri": {
//             "id": "7526e2c0-07c4-4a31-8482-85153766f5b4",
//             "name": "మర్రి కొమ్మ/ Banyan branch",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 75,
//             "pujariSellingPrice": 85,
//             "customerMrp": 85,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T02:57:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:42:23.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 224
//           }
//         },
//         {
//           "id": "0bf415db-e567-40ac-94bd-e15b0647ef96",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//           "samagri": {
//             "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "name": "చెంబులు/Kalasam",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:26.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:39:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 253
//           }
//         },
//         {
//           "id": "054ff985-8a38-4bcc-8c7e-012685de1520",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "addon",
//           "samagriId": "a6466242-2a96-4098-97e9-022996693e06",
//           "samagri": {
//             "id": "a6466242-2a96-4098-97e9-022996693e06",
//             "name": "మేడి కొమ్మ / Medi Branch ",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 80,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-18T02:55:46.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:45:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 222
//           }
//         },
//         {
//           "id": "053ed3cc-2bfd-4cae-b100-ea9691b09bae",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//           "samagri": {
//             "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "name": "వరిపెళాలు/Rice Fields",
//             "description": null,
//             "units": "gms",
//             "standardQty": "50",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:33:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 58
//           }
//         },
//         {
//           "id": "0308945e-2f92-4bfe-9e34-84115f4910cb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "01185ccc-d74a-44d7-852d-08f95167cba9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "489060d1-f4de-4888-9b68-d3831c57179e",
//           "samagri": {
//             "id": "489060d1-f4de-4888-9b68-d3831c57179e",
//             "name": "వడ్లు / Paddy Seeds",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:50:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 71
//           }
//         },
//         {
//           "id": "002a1430-5578-4d6f-8b87-9a58fd2c2e6a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "18916846-5b89-49d2-a9f3-e0359cbbe70c",
//           "samagri": {
//             "id": "18916846-5b89-49d2-a9f3-e0359cbbe70c",
//             "name": "మూకుళ్ళు / Mukudu",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 30,
//             "customerMrp": 30,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:13:20.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 93
//           }
//         },
//         {
//           "id": "fe2c5c71-8992-43dc-8083-8d80c9b8768d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "26b085af-563e-4118-bcd3-933b1ef6856b",
//           "samagri": {
//             "id": "26b085af-563e-4118-bcd3-933b1ef6856b",
//             "name": "ఉలవలు / Horsegram",
//             "description": null,
//             "units": "gms",
//             "standardQty": "250",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:59:36.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 74
//           }
//         },
//         {
//           "id": "fa570831-eae3-45d3-88a6-18525497d962",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "73525e4a-856a-4fe1-9a8b-ca899577013a",
//           "samagri": {
//             "id": "73525e4a-856a-4fe1-9a8b-ca899577013a",
//             "name": "నీలం రంగు బ్లౌజ్ ముక్క/Blue Color Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:01:05.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:23:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 236
//           }
//         },
//         {
//           "id": "f18c6858-43f3-4b6f-a497-6cd50a225386",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "efb93055-b04e-4b4b-9e7b-5693322cc6f6",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "e65b8c6b-c65c-4e2f-899b-5374ad04dc4e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 40,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//           "samagri": {
//             "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "name": "ఇటుకలు/ BRICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 8,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:39:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:14:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 150
//           }
//         },
//         {
//           "id": "e6002e9a-8cb8-4eff-baf2-776e728806f7",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "e2525210-d347-4124-89a1-cab5968bf1dd",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "dcf8e548-59bd-42c2-85c2-c5f0afc31640",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "dcf6798e-1d3c-437d-b617-6a727439199e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "dcb8c481-c70e-4247-8f5a-d2035ed53ed2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "cb64c2ba-1f34-461e-b0c1-0d719d7701a5",
//           "samagri": {
//             "id": "cb64c2ba-1f34-461e-b0c1-0d719d7701a5",
//             "name": "తెలుపు రంగు బ్లౌజ్ ముక్క/White Color Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T00:54:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:18:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 233
//           }
//         },
//         {
//           "id": "dc311da2-a513-4813-9e66-680c9d290030",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "d651b315-4932-45e7-a475-4bad2ecdfbd3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "cf22917f-899d-4f20-b1e1-5af557d43de9",
//           "samagri": {
//             "id": "cf22917f-899d-4f20-b1e1-5af557d43de9",
//             "name": "పెసలు / Moong Bean",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:45:31.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 69
//           }
//         },
//         {
//           "id": "ce73386a-b83d-4135-8bc0-e94c950bb163",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "cd872324-3f88-4b13-9165-9fc751aafc78",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//           "samagri": {
//             "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 500,
//             "pujariSellingPrice": 600,
//             "customerMrp": 600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:43:18.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 114
//           }
//         },
//         {
//           "id": "cbdc615c-143d-4485-8a55-014e7467a1c4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "ca20d952-ea92-4742-b644-0c1f59ebc95c",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "c985a4d4-5da3-457f-89b6-a7ba7585f5b9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "addon",
//           "samagriId": "31ef9c71-5019-488c-a9e8-311d0c264d71",
//           "samagri": {
//             "id": "31ef9c71-5019-488c-a9e8-311d0c264d71",
//             "name": "జువ్వి కొమ్మ/Juvvi branch",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 70,
//             "pujariSellingPrice": 85,
//             "customerMrp": 85,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T02:55:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:48:48.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 221
//           }
//         },
//         {
//           "id": "c788fab5-d7bf-430a-9e1d-f6060274b92c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//           "samagri": {
//             "id": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//             "name": "గుమ్మకాయడి/ PUMPKIN",
//             "description": "1",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 140,
//             "pujariSellingPrice": 150,
//             "customerMrp": 150,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:50:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:14:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 191
//           }
//         },
//         {
//           "id": "c779f6f1-7366-4daa-8be9-927793b0ad53",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "d3376577-7d65-41e5-aa45-aede75fc6d32",
//           "samagri": {
//             "id": "d3376577-7d65-41e5-aa45-aede75fc6d32",
//             "name": "డిజైన్ క్లాత్/Design Cloth",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 100,
//             "pujariSellingPrice": 125,
//             "customerMrp": 125,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:26:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:25:07.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 239
//           }
//         },
//         {
//           "id": "c256eb62-6805-423a-8ebf-6460735abc5a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "c00c1de1-64b9-43f2-a1f5-b99941c2b155",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "acdf7c21-6c25-4a96-87e8-272d55b63eca",
//           "samagri": {
//             "id": "acdf7c21-6c25-4a96-87e8-272d55b63eca",
//             "name": "కందులు / Toor dal",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 4,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:46:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 70
//           }
//         },
//         {
//           "id": "bf87a278-d1c2-445b-b965-b66852be7526",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "bf3cf46c-81a5-4f1c-a58b-5d8645401a4c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "b208a753-a8d5-407e-9c70-16ef70bbd925",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "aa57f753-2696-4547-b861-469bae0fd11d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//           "samagri": {
//             "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "name": "గరిక/  Grass",
//             "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 20,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:31:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:11:34.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 183
//           }
//         },
//         {
//           "id": "a8ab33f2-190f-4097-b877-311f31ef0352",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "1f329789-c301-4476-ae69-60822c13f389",
//           "samagri": {
//             "id": "1f329789-c301-4476-ae69-60822c13f389",
//             "name": "బూడిద రంగు బ్లౌజ్ ముక్క/Ash Color Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:03:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:23:50.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 238
//           }
//         },
//         {
//           "id": "9b0a04a2-bbbd-4040-8577-be99d204e0b3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "addon",
//           "samagriId": "f777870d-f64a-46ba-8817-210a3f31a8da",
//           "samagri": {
//             "id": "f777870d-f64a-46ba-8817-210a3f31a8da",
//             "name": " రావి కొమ్మ/ Peepal Branch",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T02:56:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:46:31.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 223
//           }
//         },
//         {
//           "id": "99901753-bf8e-4e2f-b191-2a8976d20ef0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "21c610ea-d3bb-48ed-b983-d94ddbf3c9b9",
//           "samagri": {
//             "id": "21c610ea-d3bb-48ed-b983-d94ddbf3c9b9",
//             "name": "పసుపచ్చ రంగు  బ్లౌజ్ ముక్క/Yellow Color Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T00:59:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:21:03.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 234
//           }
//         },
//         {
//           "id": "9550ec5c-270e-4988-82ec-f712efd6d4a8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "addon",
//           "samagriId": "2de30e9f-ed41-44a1-a9fe-242718388946",
//           "samagri": {
//             "id": "2de30e9f-ed41-44a1-a9fe-242718388946",
//             "name": "Fruits & Flowers ( Navagraha Homam)",
//             "description": "వీడి పూలు( 2 Types Mix) - 1 kg ,        పూల మూరలు-(2)  ,   ఆరటి పండ్లు -1Dozen ,      ఫ్రూప్ట్స్ 5 types  5 Each\n\n\n",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 1550,
//             "pujariSellingPrice": 1600,
//             "customerMrp": 1600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T04:43:54.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-09T02:32:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 148
//           }
//         },
//         {
//           "id": "94dd2f18-5097-49a1-b288-9c524ee33d85",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "00416a9a-4864-4758-a471-f915ab3c63e7",
//           "samagri": {
//             "id": "00416a9a-4864-4758-a471-f915ab3c63e7",
//             "name": "ముద్దకర్పూరం / Big Chunk Camphor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 38,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:40:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 113
//           }
//         },
//         {
//           "id": "9040f0c0-05a3-4680-8716-de6f58b7d796",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "8cc84d97-5ab3-4729-9701-3825d5c2e4af",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "8a2bf08f-7176-492d-b9bd-cfcf453cfe23",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "eadf2e11-db74-410e-9f2b-fe2c0abea8dd",
//           "samagri": {
//             "id": "eadf2e11-db74-410e-9f2b-fe2c0abea8dd",
//             "name": "ఆకుపచ్చ రంగు బ్లౌజ్ ముక్క/Green Color Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T00:54:31.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:20:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 232
//           }
//         },
//         {
//           "id": "89afe3b8-e60e-4af0-8a6c-5ac98c6b4aae",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//           "samagri": {
//             "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "name": "ఇసుక / SAND",
//             "description": "As Gampallu",
//             "units": "other",
//             "standardQty": "1",
//             "costPrice": 100,
//             "pujariSellingPrice": 125,
//             "customerMrp": 125,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:28:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:16:43.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 151
//           }
//         },
//         {
//           "id": "89214bd5-bc95-4267-b65b-ffd52c2bb9f5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "85c42b25-5839-455e-8838-5a78a3564385",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "846f0813-aebe-46cf-ab56-0d50be96fb40",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "7043905d-685a-4121-bee3-4c81d4101cfe",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "6ffa3cc7-0af9-4115-b909-2dbaccef75a4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "6ba0efe5-e568-4931-a3e8-b55f66fdd977",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "b76071d0-2635-472c-a09a-3862729f2885",
//           "samagri": {
//             "id": "b76071d0-2635-472c-a09a-3862729f2885",
//             "name": "ఎరుపు రంగు బ్లౌజ్ ముక్క/ Red Color Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T00:59:28.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:17:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 235
//           }
//         },
//         {
//           "id": "6a8bf682-7452-479a-923e-b1c0caaa458e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "66dea59f-7442-4b61-b8d3-721f03071207",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 100,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "63304fdc-ce9a-4766-b1b7-259550f52c38",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//           "samagri": {
//             "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "name": "దర్భ కట్టలు/Kusha Grass",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T06:06:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 53
//           }
//         },
//         {
//           "id": "5e03b443-3992-42f4-b9aa-d21735e21964",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "5cb2536d-72c9-49ec-ab9c-c5cf665301af",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "5b289f0a-ca79-45ed-903c-ff5cca29a40a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "4836d741-21a8-4974-adb2-48db7a4b0bc6",
//           "samagri": {
//             "id": "4836d741-21a8-4974-adb2-48db7a4b0bc6",
//             "name": "బొబ్బర్లు / Black eyed beans",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:57:28.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 72
//           }
//         },
//         {
//           "id": "59d71eeb-8ccf-40b6-84f6-c94d11e8bea6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "fc843717-569b-46f3-8a8c-952669a13bf0",
//           "samagri": {
//             "id": "fc843717-569b-46f3-8a8c-952669a13bf0",
//             "name": "మార్వాడి దారం / Marwadi Thread",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 40,
//             "customerMrp": 40,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 79
//           }
//         },
//         {
//           "id": "561f0677-6f2e-44ca-a3e6-8b897c03ac25",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "52972363-0cd1-4d2d-a656-0f61a794b46b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "4d313536-399c-49f7-bfa4-45bcfb201b65",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//           "samagri": {
//             "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "name": "అరటి పండ్లు / Banana",
//             "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//             "units": "no",
//             "standardQty": "12",
//             "costPrice": 60,
//             "pujariSellingPrice": 70,
//             "customerMrp": 70,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-19T22:36:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:26:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 181
//           }
//         },
//         {
//           "id": "488c3c76-5fa9-4bc4-80e9-cccd01a48568",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//           "samagri": {
//             "id": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//             "name": "నల్ల నువ్వులు/Black Sesame",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T02:08:36.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 42
//           }
//         },
//         {
//           "id": "4183e516-10c1-4160-8ea4-f28d5dac01cd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//           "samagri": {
//             "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:38:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 115
//           }
//         },
//         {
//           "id": "3f7aec45-9741-44dd-9ff8-7e2e1e94ec59",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "3d894fa6-1a0b-4b7b-9e09-af5e22fbca77",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         },
//         {
//           "id": "373fefff-3789-4313-b128-2a57bc1c5239",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "363e301e-8ed4-4a20-bb0a-516ecb004129",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "0aa14bf1-02bc-4b1b-9d2f-6f54a2dc077d",
//           "samagri": {
//             "id": "0aa14bf1-02bc-4b1b-9d2f-6f54a2dc077d",
//             "name": "మినుములు / Black Gram",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:58:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 73
//           }
//         },
//         {
//           "id": "317ca902-ab22-4e2c-ba60-7462a510e7bd",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "2ff70cd2-5b97-4811-b471-980db89f491c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "2f23a767-1b63-4846-97f5-9bf55baa5143",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "2c19bd83-dc66-4c06-9d21-b84033ca883d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "8f20fa74-8221-479d-871e-3d989c54c446",
//           "samagri": {
//             "id": "8f20fa74-8221-479d-871e-3d989c54c446",
//             "name": "కాషాయం రంగు బ్లౌజ్ ముక్క/Amber Color Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:01:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:19:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 237
//           }
//         },
//         {
//           "id": "2acdfa82-47a7-4773-93b1-c40b8615a6ec",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "75c54993-415b-4c9c-b109-22c643a7f56b",
//           "samagri": {
//             "id": "75c54993-415b-4c9c-b109-22c643a7f56b",
//             "name": "గోధుమలు / Wheat",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-09T04:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 67
//           }
//         },
//         {
//           "id": "292ae1ac-2229-460b-8c6e-f48dd22a8051",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 135,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         }
//       ]
//     },
//     {
//       "id": 155,
//       "name": "Punyahavachanam",
//       "description": "Punyaham means auspicious, and vachanam means to recite. In this puja, specific mantras are recited which purify us and our surroundings and bring forth the auspiciousness required before performing a vedic action or any auspicious events. The mantra shakti is embedded into the kalasha jalam and leads to prosperity of the doer and their family tree",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": null,
//       "mode3": null,
//       "mode4": null,
//       "videos": "undefined",
//       "images": "[\"/uploads/2023/07/DSC01273-2.jpg\"]",
//       "updatedAt": "2023-07-16T02:26:27.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-07-08T06:36:56.000Z",
//       "createdById": "47",
//       "updatedById": "47",
//       "pujacategorymappings": [],
//       "pujamodels": [
//         {
//           "id": 103,
//           "pujaId": 155,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 6368,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 3000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-28T03:20:25.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-07-08T06:37:35.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 514,
//           "samagriCost": 1883,
//           "procedures": [
//             {
//               "id": "f2347762-95e5-41fe-9795-187a7b2561bb",
//               "modelId": 103,
//               "procedureId": 46,
//               "procedure": {
//                 "id": 46,
//                 "language": "telugu",
//                 "title": "Ayushya Homam",
//                 "description": "The pradhana ayur devatha is offered havissu along with the other devatas that were invited during the kalasa sthapana and mandaparadhana.   Ayusha suktham and ayur devatha mantram are utilised while performing the homam.  Maha mrutyunjaya, saptha chiranjeevulu, dhanvantari are also considered as prominent during the procedure.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "295042a5-ebcb-4fc9-afa0-d45947e81a20",
//               "modelId": 103,
//               "procedureId": 38,
//               "procedure": {
//                 "id": 38,
//                 "language": "telugu",
//                 "title": "Breaking Coconut",
//                 "description": "By offering a coconut to the devata, we offer ourselves in the form of a coconut. First, the outer layer of a coconut called coir, is removed, because it symbolizes all our desires, likes, and dislikes. Next, only the hard shell remains between us and the devata. This hard shell represents our EGO, which we break at the feet of the devata. The remaining thing is our inner self symbolized by coconut water which spills at the devata's feet. On the coconut's hard shell, there are three hole-like marks. These three holes represent all that is three. This includes the body, mind, and intellect, the three Gunas, the three Kaalas, and the three states of consciousness. Therefore, breaking a coconut symbolizes not just breaking our Ego, but also transcending all the things in the category of three.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "d81f10a5-af77-4543-83e4-81a42a5bdc27",
//               "modelId": 103,
//               "procedureId": 36,
//               "procedure": {
//                 "id": 36,
//                 "language": "telugu",
//                 "title": "Aada bidda - Gadapa Kadagadam",
//                 "description": "Most of the negative energy in the house, if any, is deposited on the frame of the main entrance door. Due to this, before entering the house, we apply yellow turmeric paste, red kumkum dots, and white lines to the door frame. White represents Saraswati, yellow represents Lakshmi, and red represents Gowri. The three mothers protect the family. At the entrance of the house, the lady of the house draws rangolis (geometric shapes conducive to positive energy), inviting goddess Lakshmi in her thoughts and emotion to visit the house.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "65c84968-1e46-4b80-b3a7-2b5578096326",
//               "modelId": 103,
//               "procedureId": 50,
//               "procedure": {
//                 "id": 50,
//                 "language": "telugu",
//                 "title": "Satyanarayana Vratam",
//                 "description": "Full procedure of satyanarayana vratam once again reminding us the power of truth and need of honesty and ethics, in order to make this occasion successful",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "edc782ce-49ef-4703-8aa5-ebc1fd3c3051",
//               "modelId": 103,
//               "procedureId": 16,
//               "procedure": {
//                 "id": 16,
//                 "language": "telugu",
//                 "title": "Ganapati Puja",
//                 "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "f69ab779-e61c-40e7-b99e-ec1727565614",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "f49ce39d-3013-440e-8406-9d9180a4e730",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//           "samagri": {
//             "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "name": "చెంబులు/Kalasam",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:26.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:39:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 253
//           }
//         },
//         {
//           "id": "f469e4cb-0bfd-45f3-b241-23912c89f478",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "389822bd-ca89-469c-bc0a-82694a7c8062",
//           "samagri": {
//             "id": "389822bd-ca89-469c-bc0a-82694a7c8062",
//             "name": "అష్ట గంధం/Ashtagandham",
//             "description": null,
//             "units": "gms",
//             "standardQty": "5",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:28:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 47
//           }
//         },
//         {
//           "id": "edf1d7dc-5805-4fbe-bc8d-665102cfed39",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "ea144d0f-a966-4312-a5ba-a19168ef36f2",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "e7c9de72-3179-4f95-ae32-7a55f3f09975",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 11,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "e68f8982-6ac6-43c8-9990-487fcf94668b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "e2923ed5-4e22-455c-9a96-d52d1d73604b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "e12f675a-b5c3-484b-ba99-a2922d6166c2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "db536a44-d1d3-4cf5-a50c-9ab8cc2bb98a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//           "samagri": {
//             "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 8,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:40:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 33
//           }
//         },
//         {
//           "id": "d3ff54b1-996b-44a2-9f0a-6dac74d464ed",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "cc250fbe-edab-44b2-bcba-ded0d3f0d1ba",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//           "samagri": {
//             "id": "92da20c4-a10e-4013-9364-f5114438d904",
//             "name": "వీడి పూలు / Loose Flowers",
//             "description": null,
//             "units": "gms",
//             "standardQty": "250",
//             "costPrice": 110,
//             "pujariSellingPrice": 120,
//             "customerMrp": 120,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:16:53.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:44:42.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 176
//           }
//         },
//         {
//           "id": "cab57ebf-ba2a-4dec-a73c-483a59e5e5e5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "c2dc3f3d-6000-40e1-a27d-d4b9b43afcfa",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "bb18054e-14ba-47cd-8596-0444a7a8ee4d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "b7dc2e40-6ff8-40aa-be9f-ee37a46551e8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "b3ba4ae3-6867-4758-b130-8880b720b27e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "b28e55e9-df79-463f-8bf9-bde8212d97e5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "b222e90d-82ea-4894-bc03-df5de9a599c4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//           "samagri": {
//             "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "name": "బెల్లం / Jaggery",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:23:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T01:22:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 143
//           }
//         },
//         {
//           "id": "a12e4f9e-4ab4-4cc8-8596-d2e3e1d3ab42",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "9fdb2aa5-dea6-4154-ae08-3b9bc938fbd6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "9d578285-b0eb-466b-8226-f93a149a5e1b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "9a55950a-b132-4ebe-990a-04bf31594783",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "98938340-44a7-4856-bd5d-ef0b5865030e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "9699e07f-63ad-492d-a639-47ba82c6b36f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "91de6a3c-4e64-43c1-afc8-a224be0394c9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "a844072d-3461-43c9-ae33-96c0668987af",
//           "samagri": {
//             "id": "a844072d-3461-43c9-ae33-96c0668987af",
//             "name": "ఇత్తడి గ్లాసు / Brass Glass (250ml)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 50,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:26:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 18
//           }
//         },
//         {
//           "id": "874f9244-2d18-46b7-ac4e-e0e23bca7fc8",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "7ee8ce8f-7c93-4d16-b334-052d33b166c4",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "7e2d96b9-a758-4647-a18a-46b8306c42e0",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "7c92f312-781b-4ffc-bec7-45dd605429fd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "726cf263-fafc-4b2c-b04e-38dc5d926a0c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "6e42068f-ce91-4c6b-b443-a32692c0be7c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "6d1d80bd-ed84-4138-b83e-e7a147b0eddc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "6a9fac8e-746f-4d87-a868-309f2a88ebdc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "65950ed9-7431-4ea8-98f9-115fa3649a27",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//           "samagri": {
//             "id": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//             "name": "గోమయం/Cow Dung",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-13T06:33:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:27:00.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 230
//           }
//         },
//         {
//           "id": "58597e49-01d2-40ba-8a16-9cc4a733a9d7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "55b44cb9-84df-4b7b-a683-76ca9b052d7e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "4e408bed-3051-4bfe-935e-5b72ac286e9c",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "4cebd13d-85f8-4a76-bdd5-db24ed961ea5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "f1e08e9d-b717-4135-bc74-db8376126649",
//           "samagri": {
//             "id": "f1e08e9d-b717-4135-bc74-db8376126649",
//             "name": "ఎండు కొబ్బరి ముక్కలు (Premium) / Dry coconut flakes (Premium)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 29,
//             "pujariSellingPrice": 38,
//             "customerMrp": 38,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:45:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:53:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 117
//           }
//         },
//         {
//           "id": "458ca3de-661b-4c59-a874-e177fe8cb2cd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "44cf0ae5-1cf1-4cc6-82f1-261672b5266c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//           "samagri": {
//             "id": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//             "name": "పూల దండ/ Flower Garland",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 200,
//             "pujariSellingPrice": 220,
//             "customerMrp": 220,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:31:38.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T06:47:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 228
//           }
//         },
//         {
//           "id": "44bb3afe-00e7-4843-bc0a-d7281dbcd21a",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "41752fac-f1db-43a3-a1c2-84d885346154",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "3b5a54ed-68d5-46b6-814d-e1c1a3ce1e43",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "3a370672-30f5-4874-9f72-965622ce9970",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "0031beaf-9312-467e-853a-b2243ce677f5",
//           "samagri": {
//             "id": "0031beaf-9312-467e-853a-b2243ce677f5",
//             "name": "గోమూత్రం/Cow Urine",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:17:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:26:29.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 231
//           }
//         },
//         {
//           "id": "3431473e-ebbf-41d0-a300-861660e681b9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "332dbc4f-b89f-492d-89d7-e643bde8eace",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "330a3db6-e066-4947-a588-b56a111f1ee5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "315dfcca-332e-49cb-ad4b-ed15da78af5c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "2f5f2f3b-09ce-4dff-abf9-1af88a4d19cf",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "1c0c719f-35c5-4876-addf-091e84ee50fe",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "addon",
//           "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//           "samagri": {
//             "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 210,
//             "pujariSellingPrice": 500,
//             "customerMrp": 500,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:26:10.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 17
//           }
//         },
//         {
//           "id": "1b7476f9-1f3c-4bf3-871f-362c7bb5c70f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "1b57450c-c0dd-44f2-9dd8-b69dae00c21c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "132361b8-8a0c-4aa9-9a4d-576e9c9f7448",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "0c1f9e4a-719f-4e65-9982-583fbe53ebe9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "088cbe60-b156-477a-ac74-8e62f110a689",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "01816e07-e00f-4bba-8b00-8945e757a0ad",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 155,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         }
//       ]
//     },
//     {
//       "id": 167,
//       "name": "Ganesha - For Navaratri Pandals (Corporate Offices / Community / Colony) - EcoFriendly Mud/Clay ",
//       "description": "Step 1: Select the Ganesha Idol of your choice by checking the photos . Remember the IDOL NUMBER\n\nStep 2:  Go to Add Ons in Samagri, and select the Ganesha size that you wish for\n\nStep 3: Customize other samagri items as per the quantities you require\n\nStep 4:  Click on Proceed.  In Special Notes, mention IDOL NUMBER. \n\nIf you are also looking for Pujari for your pandal, specify if you require pujari for 1 day/ 3 days/ 5 days / 9 days  \n\nPujari charges are 1000rs per visit\n\n1 Day Only morning / Only evening - 1000 Rs\n1 Day Morning and Evening - 2000Rs\n\n3 days Only morning / Only evening - 3000Rs\n3 days Morning and Evening - 6000Rs\n\n5 days Only morning / Only evening - 5000Rs\n5 days Morning and Evening - 10000Rs\n\n9 days Only morning / Only evening - 9000Rs\n9 days Morning and Evening - 18000Rs\n\nPlace the booking with your details and our representative will contact you and finalize the booking\n",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": null,
//       "mode3": null,
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/ET38mTYA3rg\",\"https://www.youtube.com/embed/mNX2gbk7hlw\",\"https://www.youtube.com/embed/arrvt-fQZ2s\"]",
//       "images": "[\"/uploads/2023/07/Ganesha-20-inch.png\",\"/uploads/2023/07/Ganesh-2023-Big-Rate-Card.png\",\"/uploads/2023/07/Ganesha-2ft-1.png\",\"/uploads/2023/07/Ganesha-2ft-2.png\",\"/uploads/2023/07/Ganesha-2ft-3.png\",\"/uploads/2023/07/Ganesha-2.5ft.png\",\"/uploads/2023/07/Ganesha-3ft.png\",\"/uploads/2023/07/Ganesha-4-ft.png\",\"/uploads/2023/07/Ganesha-5ft.png\",\"/uploads/2023/07/Ganesha-5.8ft.png\"]",
//       "updatedAt": "2023-07-25T12:47:27.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-07-21T05:59:32.000Z",
//       "createdById": "47",
//       "updatedById": "44",
//       "pujacategorymappings": [],
//       "pujamodels": [
//         {
//           "id": 116,
//           "pujaId": 167,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 1116,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": null,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-28T03:17:11.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-07-21T06:43:25.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 946,
//           "samagriCost": null,
//           "procedures": [
//             {
//               "id": "e42c2cd5-1fc5-463f-966b-3bf6b93a62cf",
//               "modelId": 116,
//               "procedureId": 43,
//               "procedure": {
//                 "id": 43,
//                 "language": "telugu",
//                 "title": "Thora Grandhi Puja",
//                 "description": "Thoranam (raksha) puja",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "8c24d4de-774d-4af5-8f4f-e3603ccea44c",
//               "modelId": 116,
//               "procedureId": 38,
//               "procedure": {
//                 "id": 38,
//                 "language": "telugu",
//                 "title": "Breaking Coconut",
//                 "description": "By offering a coconut to the devata, we offer ourselves in the form of a coconut. First, the outer layer of a coconut called coir, is removed, because it symbolizes all our desires, likes, and dislikes. Next, only the hard shell remains between us and the devata. This hard shell represents our EGO, which we break at the feet of the devata. The remaining thing is our inner self symbolized by coconut water which spills at the devata's feet. On the coconut's hard shell, there are three hole-like marks. These three holes represent all that is three. This includes the body, mind, and intellect, the three Gunas, the three Kaalas, and the three states of consciousness. Therefore, breaking a coconut symbolizes not just breaking our Ego, but also transcending all the things in the category of three.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "c615b4d4-9b85-43b2-8726-6e84281a2904",
//               "modelId": 116,
//               "procedureId": 33,
//               "procedure": {
//                 "id": 33,
//                 "language": "telugu",
//                 "title": "Aksharabhyasam",
//                 "description": "Aksharabhyasam",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "f586b9c9-3f8c-4da2-aa04-c21b23b84090",
//               "modelId": 116,
//               "procedureId": 24,
//               "procedure": {
//                 "id": 24,
//                 "language": "telugu",
//                 "title": "Vrata Kadhalu",
//                 "description": "Vrata Kadhalu remind us and re -nforce the main promise (vrata) that we are undertaking as part of the puja.  The stories are designed with characters from the puranas whose smarana (rememberance) has a positive intent and impact on us. Hence the stories are not changed. ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "83b9ad4c-376b-4f2d-9f96-0386b5723200",
//               "modelId": 116,
//               "procedureId": 22,
//               "procedure": {
//                 "id": 22,
//                 "language": "telugu",
//                 "title": "Abhishekam (Panchamrutalu)",
//                 "description": "Abhishekam can be seen as watering the roots of a tree. Only if the roots of a tree are nourished, it can produce fruits. During an Abhishekam, the results that we desire to see in this world are like the fruits of a tree, and the divine beings who influence these results are like roots. Since, everything in this universe is interconnected, we perform Abhishekam to see specific results in the world.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "b16434a6-acd9-428d-bd80-9bb793e20ff4",
//               "modelId": 116,
//               "procedureId": 19,
//               "procedure": {
//                 "id": 19,
//                 "language": "telugu",
//                 "title": "Pancha loka palaka puja",
//                 "description": "In Sanatana Dharma, different worlds exist together like a multiverse, called lokas.\nFor Example: When we refer to the Vaikuntha Loka, ‘Vai’ means ‘No’ and ‘Kuntha’ means ‘anxiety’. So, for the people living in Vaikuntha there is ‘No-anxiety’. Similarly, Kailasha is called the loka of Lord Shiva, where his devotees reside. \nIn Pancha Loka Palaka Puja, we bow down to the rulers of five lokas: Ganapati Loka, Brahma Loka, Vishnu Loka, Rudra Loka, and Gouri Loka.",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "0484cada-3f0a-42ca-88c0-4324647bdb0e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "f8a796b8-6a86-4e44-9c91-290dd48830b1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "f7c4c8ab-e6e8-4ba5-a467-bb976cf373f8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "f2a35249-faeb-4261-a18d-7a9b1fa5f2dd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "f773cae7-c30d-4b6d-893c-5a336e820c13",
//           "samagri": {
//             "id": "f773cae7-c30d-4b6d-893c-5a336e820c13",
//             "name": "5 types of fruits (Each 3)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 650,
//             "pujariSellingPrice": 700,
//             "customerMrp": 700,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T04:14:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-16T05:20:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 157
//           }
//         },
//         {
//           "id": "f0e054e3-a241-4dc8-92b1-a4744d7183fd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "9ca7a9ba-14d1-48cf-bdb3-b0700ba8783f",
//           "samagri": {
//             "id": "9ca7a9ba-14d1-48cf-bdb3-b0700ba8783f",
//             "name": "అఖండ జ్యోతి వొత్తులు / Akanda Jyothi Wicks",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 40,
//             "customerMrp": 40,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:02:57.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 78
//           }
//         },
//         {
//           "id": "e9512dc0-8b96-48d8-afef-dc38fb7c2511",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "738a029e-4db1-4a3a-8728-af89a6f95fe3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "a3d9cd9c-7e5e-4161-97b8-8ea71843eae4",
//           "samagri": {
//             "id": "a3d9cd9c-7e5e-4161-97b8-8ea71843eae4",
//             "name": "అగ్గి పెట్టిలు (Homelite) /Match Box (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:57:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 40
//           }
//         },
//         {
//           "id": "725c55b4-659e-465c-97cb-9d114f8d509a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "cde9a6ed-e84e-488d-a9f8-d438053592e8",
//           "samagri": {
//             "id": "cde9a6ed-e84e-488d-a9f8-d438053592e8",
//             "name": "5.8 / 5.9 Feet - గణేష్ విగ్రహం / Ganesha  (Pure Clay / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 12500,
//             "pujariSellingPrice": 16000,
//             "customerMrp": 16000,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:40:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-21T07:19:55.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "6e85b332-d172-463e-b094-e335a0a25eb0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "814c760a-3fb4-4f25-b90e-eba9bd0c9a8a",
//           "samagri": {
//             "id": "814c760a-3fb4-4f25-b90e-eba9bd0c9a8a",
//             "name": "3ft / 3.2ft - గణేష్ విగ్రహం / Ganesha (Pure Clay / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 4000,
//             "pujariSellingPrice": 5200,
//             "customerMrp": 5200,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:42:20.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-23T13:19:47.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "6dc157af-7dfe-402a-a061-82afc241c343",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "57903f59-a61a-400f-b0f9-68e6355039f2",
//           "samagri": {
//             "id": "57903f59-a61a-400f-b0f9-68e6355039f2",
//             "name": "పత్తి వస్త్రం Big / Cotton Garland  (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 52,
//             "customerMrp": 52,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:19:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 96
//           }
//         },
//         {
//           "id": "67383d61-11f4-4861-a973-cba9fcf5e9fa",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "94405a53-4cd7-447c-9058-20b0db6e82f1",
//           "samagri": {
//             "id": "94405a53-4cd7-447c-9058-20b0db6e82f1",
//             "name": "2.3 ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2000,
//             "pujariSellingPrice": 2800,
//             "customerMrp": 2800,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:41:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-23T13:21:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "66c37c21-415e-4fcd-abab-1cea29f1a5f6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//           "samagri": {
//             "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 210,
//             "pujariSellingPrice": 500,
//             "customerMrp": 500,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:26:10.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 17
//           }
//         },
//         {
//           "id": "5e579e77-0c33-4f08-aad8-f940cddd132f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "52a0aae3-3997-441f-8369-7bf3bda103ed",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "4b339936-ead9-46b6-a6d6-ba9f4afe8a9b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "f21392b0-2848-47be-a081-1a9f4733cb48",
//           "samagri": {
//             "id": "f21392b0-2848-47be-a081-1a9f4733cb48",
//             "name": "2.5 ft to 2.8ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2600,
//             "pujariSellingPrice": 4500,
//             "customerMrp": 4500,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:42:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-23T13:21:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "4a7362b1-b003-483a-9062-d194bc9112e8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "fc843717-569b-46f3-8a8c-952669a13bf0",
//           "samagri": {
//             "id": "fc843717-569b-46f3-8a8c-952669a13bf0",
//             "name": "మార్వాడి దారం / Marwadi Thread",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 40,
//             "customerMrp": 40,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 79
//           }
//         },
//         {
//           "id": "3ca9d4f3-faa7-4a3b-b467-2103c5bc30e0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "1f195f94-bc18-4aa1-b1e7-b450ccc995f3",
//           "samagri": {
//             "id": "1f195f94-bc18-4aa1-b1e7-b450ccc995f3",
//             "name": "2.2 ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1800,
//             "pujariSellingPrice": 2500,
//             "customerMrp": 2500,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:41:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-23T13:22:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "3420e564-aeb9-4314-8c03-d0e4f88e247d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "2eac7941-1725-4cfd-893e-5eb39d43639a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "271592ce-6710-4262-a7c4-358a5df794ed",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "bd3cee3c-c039-4347-8660-5102c4981e0b",
//           "samagri": {
//             "id": "bd3cee3c-c039-4347-8660-5102c4981e0b",
//             "name": "5 ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 9500,
//             "pujariSellingPrice": 13000,
//             "customerMrp": 13000,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-25T12:41:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-21T07:10:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "22fb12af-429f-4930-8a68-378fec784ac9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 25,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "173fc7a1-7439-4579-8ac3-efec07942e2d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         },
//         {
//           "id": "1639cc87-796b-4267-ba8d-7f46e1ef586e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "0ead9a46-5322-4a69-bb90-9c1591723c0e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//           "samagri": {
//             "id": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//             "name": "పూల దండ/ Flower Garland",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 200,
//             "pujariSellingPrice": 220,
//             "customerMrp": 220,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:31:38.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T06:47:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 228
//           }
//         },
//         {
//           "id": "0adad9ab-87e1-4ba6-973b-1d886f9139f2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "e37b8f17-d7bb-46ac-b2c9-a040e1c4eeb7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "ec517b0b-d4ab-4538-a612-ee302755ab1b",
//           "samagri": {
//             "id": "ec517b0b-d4ab-4538-a612-ee302755ab1b",
//             "name": "4.5 Feet - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 7000,
//             "pujariSellingPrice": 10000,
//             "customerMrp": 10000,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:41:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-21T07:02:42.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "da2f7a9c-2ded-4058-90f3-70429c7a5788",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "c987ebf9-d1c4-4148-b5e5-367aedcc6aeb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "3b26947e-94f8-46e4-b00b-657c841d2ec5",
//           "samagri": {
//             "id": "3b26947e-94f8-46e4-b00b-657c841d2ec5",
//             "name": "ఇత్తడి కళశాలు (1/2 ltr) / Brass Kalasam (Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 170,
//             "pujariSellingPrice": 300,
//             "customerMrp": 300,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:27:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 19
//           }
//         },
//         {
//           "id": "c3a75a09-0157-467a-a7f1-54311f0ab68b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "b537ccac-7d58-4d90-ad0e-b832ef39296a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "4d7e2a56-3966-4fce-8480-11596641d205",
//           "samagri": {
//             "id": "4d7e2a56-3966-4fce-8480-11596641d205",
//             "name": "20 inch - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 900,
//             "pujariSellingPrice": 1500,
//             "customerMrp": 1500,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:40:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-23T13:23:31.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "b305dded-1f4d-49a0-a6e8-e90b99aaa03f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 116,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "b2cf860a-ac80-4785-8eab-1d191fb45fce",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "2db77651-291a-4841-bc75-e4e6451701ff",
//           "samagri": {
//             "id": "2db77651-291a-4841-bc75-e4e6451701ff",
//             "name": "2 ft - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1300,
//             "pujariSellingPrice": 2000,
//             "customerMrp": 2000,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:41:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-23T13:23:00.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "b0b746e7-17b9-4e80-9376-bb9f7c44cb20",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "b01be69e-5160-438d-8a4c-e2ea4c24a3a1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "afa5d558-8f8a-4b3f-a699-3c05f4b16122",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "ae703abc-26be-4de6-9c34-09985e56625f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "18916846-5b89-49d2-a9f3-e0359cbbe70c",
//           "samagri": {
//             "id": "18916846-5b89-49d2-a9f3-e0359cbbe70c",
//             "name": "మూకుళ్ళు / Mukudu",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 30,
//             "customerMrp": 30,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:13:20.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 93
//           }
//         },
//         {
//           "id": "a8fcc55b-11fe-4549-a3f0-92d251e0f4ed",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 25,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "9f54e581-21da-48d3-a669-cfa101a05394",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "8e6ed4b8-c46f-43ee-95eb-49f66d414313",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "0e9012a1-a5f7-445d-829f-69ef9ac5bf71",
//           "samagri": {
//             "id": "0e9012a1-a5f7-445d-829f-69ef9ac5bf71",
//             "name": "4.2 Feet - గణేష్ విగ్రహం / Ganesha  (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 5800,
//             "pujariSellingPrice": 7500,
//             "customerMrp": 7500,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:41:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-21T07:00:59.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "879ad6be-ecbf-4097-ab6c-bdfe62f4cd7d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "83b1d67b-15be-450b-8f40-14464274ffee",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "f1e08e9d-b717-4135-bc74-db8376126649",
//           "samagri": {
//             "id": "f1e08e9d-b717-4135-bc74-db8376126649",
//             "name": "ఎండు కొబ్బరి ముక్కలు (Premium) / Dry coconut flakes (Premium)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 29,
//             "pujariSellingPrice": 38,
//             "customerMrp": 38,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:45:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:53:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 117
//           }
//         },
//         {
//           "id": "7ae5994c-7f71-47b6-893b-746b1f573ec9",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "7a0caf44-1df5-40a7-b680-b2a0da61e1ea",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 40,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//           "samagri": {
//             "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "name": "బెల్లం / Jaggery",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:23:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T01:22:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 143
//           }
//         },
//         {
//           "id": "7780d07d-9478-46b4-a638-6f6c6c51d28c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 167,
//           "samagriType": "addon",
//           "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//           "samagri": {
//             "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 230,
//             "pujariSellingPrice": 270,
//             "customerMrp": 270,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:31:17.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 23
//           }
//         }
//       ]
//     },
//     {
//       "id": 168,
//       "name": "Ganesha Chaturdhi Kit - River Ganesha 10 inch & 21 Patri (Vinayaka Vratam Kit)",
//       "description": "10 inch River Clay Ganesha\n21 Ayurvedic Patri   (Select from Addons.  All proceedings will be towards NGO helping in tribal girl education)\n\n\n",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": null,
//       "mode3": null,
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/ET38mTYA3rg\",\"https://www.youtube.com/embed/mNX2gbk7hlw\",\"https://www.youtube.com/embed/arrvt-fQZ2s\"]",
//       "images": "[\"/uploads/2023/07/Ganesha-Home-2023.png\"]",
//       "updatedAt": "2023-07-25T22:04:21.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-07-25T12:15:35.000Z",
//       "createdById": "44",
//       "updatedById": "44",
//       "pujacategorymappings": [],
//       "pujamodels": [
//         {
//           "id": 117,
//           "pujaId": 168,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 768,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": null,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-28T03:16:44.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-07-25T12:17:18.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 132,
//           "samagriCost": 519,
//           "procedures": [
//             {
//               "id": "578b3cf1-54ce-4020-a7ec-ba90796cb7f1",
//               "modelId": 117,
//               "procedureId": 44,
//               "procedure": {
//                 "id": 44,
//                 "language": "telugu",
//                 "title": "Mantra Pushpam",
//                 "description": "A reminder that the divine force that we seek, pray and worship actually exists within us, and if we are able to follow Sanatana Dharma (the way of life), we can awaken this force and remain at eternal bliss with this presence.  A reminder that the divine and us are 'abhedam' (inseparable) and we are yet to experience this.\n\nThose who experience this enlightnment need not perform pujas anymore and that is our rishis' wish.  They want us to perform pujas, only for us to rise beyond them towards ultimate gyana and moksha",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "f9eac6b5-7220-45ea-98c2-af22de360a1e",
//               "modelId": 117,
//               "procedureId": 36,
//               "procedure": {
//                 "id": 36,
//                 "language": "telugu",
//                 "title": "Aada bidda - Gadapa Kadagadam",
//                 "description": "Most of the negative energy in the house, if any, is deposited on the frame of the main entrance door. Due to this, before entering the house, we apply yellow turmeric paste, red kumkum dots, and white lines to the door frame. White represents Saraswati, yellow represents Lakshmi, and red represents Gowri. The three mothers protect the family. At the entrance of the house, the lady of the house draws rangolis (geometric shapes conducive to positive energy), inviting goddess Lakshmi in her thoughts and emotion to visit the house.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "201070f1-2049-4264-93a5-561855d7aeab",
//               "modelId": 117,
//               "procedureId": 27,
//               "procedure": {
//                 "id": 27,
//                 "language": "telugu",
//                 "title": "Chandi Parayanam",
//                 "description": "Brahmin who has initiated into chandi will do parayanam and japam and give theertham to yajamani\n1 time, 3, 5, 7, 9 times parayanams repeats\n1.5 to 2 hrs for 1st parayanam + 1 hr for every next iteration\nAfter parayanam, prasad, generally fruits is given ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "bf9d25d4-75b7-4977-8cc7-7cdbff6550e6",
//               "modelId": 117,
//               "procedureId": 21,
//               "procedure": {
//                 "id": 21,
//                 "language": "telugu",
//                 "title": "Satyanarayana Puja",
//                 "description": "Satyanarayana Puja",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "514bad60-e63e-44ea-8aa6-9f72711d5f56",
//               "modelId": 117,
//               "procedureId": 16,
//               "procedure": {
//                 "id": 16,
//                 "language": "telugu",
//                 "title": "Ganapati Puja",
//                 "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "112283f9-109a-41e3-9773-ce386106c0a8",
//               "modelId": 117,
//               "procedureId": 19,
//               "procedure": {
//                 "id": 19,
//                 "language": "telugu",
//                 "title": "Pancha loka palaka puja",
//                 "description": "In Sanatana Dharma, different worlds exist together like a multiverse, called lokas.\nFor Example: When we refer to the Vaikuntha Loka, ‘Vai’ means ‘No’ and ‘Kuntha’ means ‘anxiety’. So, for the people living in Vaikuntha there is ‘No-anxiety’. Similarly, Kailasha is called the loka of Lord Shiva, where his devotees reside. \nIn Pancha Loka Palaka Puja, we bow down to the rulers of five lokas: Ganapati Loka, Brahma Loka, Vishnu Loka, Rudra Loka, and Gouri Loka.",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "f7f037e9-7c89-41dd-a5d4-d00b0488c7de",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "d43f3f12-c5c1-400e-badf-a390c43e7261",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "afaf1cae-ce7b-406b-a9f3-2a1a2e36a2d0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "c0785734-ce6b-4868-9e57-e34bd7b32f44",
//           "samagri": {
//             "id": "c0785734-ce6b-4868-9e57-e34bd7b32f44",
//             "name": "Karishye Cotton Bag (Reusable)",
//             "description": "Sturdy Cotton Bag . Good Quality",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 50,
//             "pujariSellingPrice": 80,
//             "customerMrp": 80,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-25T12:18:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-25T12:18:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "a7b78052-067f-44c8-b053-254b85e48f9a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "a6ae86c0-05b9-4a71-bd8a-5e0a9ba83a88",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 2,
//           "pujaId": 168,
//           "samagriType": "addon",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "9bc75de5-e681-4acd-af94-939130cb7031",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "9bb62db2-9404-43ec-8e1f-566e05cdfa88",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "98d3efc6-29ae-4297-b841-abe560f7044e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "78db3814-7504-4ddf-8fde-0bd5282951ed",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "7713dd59-2dc2-4595-addb-ac2065fb8299",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "addon",
//           "samagriId": "7113792b-a69f-4932-b4b0-86f17a794727",
//           "samagri": {
//             "id": "7113792b-a69f-4932-b4b0-86f17a794727",
//             "name": "Ganesha Pooja - 21 Patri",
//             "description": "21 Ayurvedic Patri for Ganesha Pooja.  All proceedings are directed towards NGOs helping girl education in tribal areas",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 280,
//             "pujariSellingPrice": 300,
//             "customerMrp": 300,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-25T12:20:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-25T12:20:37.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "6973c0ff-9305-47aa-9cfb-4d41c49e0cc5",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "66ccc432-3d28-41b8-ad87-92a17c2fc293",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "5959b553-8b22-49aa-9198-231af5be333c",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "41136472-9763-4201-90cd-67b7017671ab",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "401e6eb8-03f3-4435-8f09-feeaa7c7ebe2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "3d3a87f4-3712-4124-b3a5-3bce19f78fbe",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "375b9443-96cc-4f60-8922-8108392e58de",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "23d2add6-547b-468b-845e-c523d8daf648",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "234484af-9d2d-4f83-aa56-dce94587e865",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "e91f55b6-95ae-45d3-a141-bbf37f4f6f19",
//           "samagri": {
//             "id": "e91f55b6-95ae-45d3-a141-bbf37f4f6f19",
//             "name": "10 inch - గణేష్ విగ్రహం / Ganesha (Pure Clay Ganesha / మట్టి గణేష )",
//             "description": "10 inch Ganesha, carefully and artistically designed\nDhyaana Aasanam, Abhaya Hastham, Kireetham, Mushikam.  \nOm Shree Ganeshaya Namaha\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 155,
//             "pujariSellingPrice": 250,
//             "customerMrp": 250,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-25T12:07:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-25T12:07:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "1cadd727-c7ae-4527-a4be-51d2b64c1cfb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "18845304-c0dc-4bf7-ad1c-edad56e865ee",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 168,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         }
//       ]
//     },
//     {
//       "id": 156,
//       "name": "Samvathsarikam (యజుర్వేదులు/Yajurvedulu)",
//       "description": "This puja procedure is performed exactly 1 year after the person departs and only performed during the 1st year.  From 2nd year onwards Abdhikam is performed.\nThe ritual lasts for 3 days in total. ",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": null,
//       "mode3": null,
//       "mode4": null,
//       "videos": "undefined",
//       "images": "[\"/uploads/2023/07/Samvatsrikam-2.png\"]",
//       "updatedAt": "2023-07-24T01:35:10.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-07-10T00:13:50.000Z",
//       "createdById": "47",
//       "updatedById": "44",
//       "pujacategorymappings": [],
//       "pujamodels": [
//         {
//           "id": 104,
//           "pujaId": 156,
//           "durationHrs": 2,
//           "noOfPujaris": 3,
//           "modelSellingPrice": 98884,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 70000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-28T03:20:04.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-07-10T00:37:12.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 1500,
//           "samagriCost": 12300,
//           "procedures": [
//             {
//               "id": "26a06f94-679a-43bb-b0f0-80a2f9f1bc09",
//               "modelId": 104,
//               "procedureId": 46,
//               "procedure": {
//                 "id": 46,
//                 "language": "telugu",
//                 "title": "Ayushya Homam",
//                 "description": "The pradhana ayur devatha is offered havissu along with the other devatas that were invited during the kalasa sthapana and mandaparadhana.   Ayusha suktham and ayur devatha mantram are utilised while performing the homam.  Maha mrutyunjaya, saptha chiranjeevulu, dhanvantari are also considered as prominent during the procedure.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "9fd2fa33-cd61-4110-a374-ce6c9142453c",
//               "modelId": 104,
//               "procedureId": 39,
//               "procedure": {
//                 "id": 39,
//                 "language": "telugu",
//                 "title": "Plate of rice with gold ring wrapped with darbha",
//                 "description": "As Darbham grass absorbs radiation from the atmosphere, it is also used to cover all food items during eclipse time, to protect them. Darbham is also an effective conductor of sound vibrations, which makes it suitable to absorb and transfer Vedic chanting vibrations.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "b9af8b9e-763a-426c-97d0-164be33ebd54",
//               "modelId": 104,
//               "procedureId": 37,
//               "procedure": {
//                 "id": 37,
//                 "language": "telugu",
//                 "title": "Ash Gourd break / Gummadikaya kottadam",
//                 "description": "As the vegetable called Ash gourd is very high in life energy, it is broken in front of the door.\nAnother ash gourd (a full one, not damaged) is sometimes hung at the entrance after puja and replaced regularly. This wards off any Nara drishti doshas and keeps positive energies flowing into the house.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "035d9b3f-51c5-42c1-a0c1-f3d6ce697272",
//               "modelId": 104,
//               "procedureId": 33,
//               "procedure": {
//                 "id": 33,
//                 "language": "telugu",
//                 "title": "Aksharabhyasam",
//                 "description": "Aksharabhyasam",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "92656822-3425-4204-ace0-46194255da3d",
//               "modelId": 104,
//               "procedureId": 30,
//               "procedure": {
//                 "id": 30,
//                 "language": "telugu",
//                 "title": "Saraswati Homam",
//                 "description": "Ganapati, navagraha devatas and all other deities such as ashtadikpalakas, panchalokapalas, trimurthulu which are generally invited during mandaparadhana are given havissu (offering in the homam via swahakara and agni devata).\nFinally, along with the recititation of saraswati suktham as well as108 repetitions of saraswati moola mantram, the devi is offered havissu.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "4443e2a6-d7ec-4cf6-9804-837eea974bff",
//               "modelId": 104,
//               "procedureId": 23,
//               "procedure": {
//                 "id": 23,
//                 "language": "telugu",
//                 "title": "Archana ",
//                 "description": "During Archana, we offer flowers to a deity while chanting mantras or names of the deity. The flowers that we offer have a fragrance. Another name for fragrance is vasana(tendency). Therefore, offering flowers to a deity represents offering all our vasanas to the deity. And, while we let go of our vasanas, we chant the names of the deity, to connect with the divine.\n\nSometimes archana is performed with other dhravya like leaves that are most suitable for that particular devata. For e.g. tulsi, bilva etc. ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "82fc1184-43c1-4760-b00f-4e7b146059d7",
//               "modelId": 104,
//               "procedureId": 21,
//               "procedure": {
//                 "id": 21,
//                 "language": "telugu",
//                 "title": "Satyanarayana Puja",
//                 "description": "Satyanarayana Puja",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "d80f7e14-2802-43ed-b747-63f741bdb83f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "f3809443-befe-4c75-a784-4f1cf7527d51",
//           "samagri": {
//             "id": "f3809443-befe-4c75-a784-4f1cf7527d51",
//             "name": "యజ్ఞోపవేతం వడికిన /Sacred thread",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 45,
//             "customerMrp": 45,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:25:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 52
//           }
//         },
//         {
//           "id": "d6797563-8f36-4f52-8192-23126d1031e8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "d3a101c3-134e-48d4-9230-22a748f3445a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 12,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//           "samagri": {
//             "id": "8e9fd7a2-dd6d-4c65-8118-0788fdcfd4b3",
//             "name": "నల్ల నువ్వులు/Black Sesame",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T02:08:36.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 42
//           }
//         },
//         {
//           "id": "d1d86592-3e50-44e6-9a44-e4550ba4d20c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "ce6d7242-1c4b-4092-88c1-44d9572c6007",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//           "samagri": {
//             "id": "32b9a3d2-4e36-437d-a211-9a227276f3c5",
//             "name": "పూల దండ/ Flower Garland",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 200,
//             "pujariSellingPrice": 220,
//             "customerMrp": 220,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:31:38.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T06:47:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 228
//           }
//         },
//         {
//           "id": "b84a9df8-e9e3-4f3a-8651-36099f375472",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//           "samagri": {
//             "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "name": "ఇసుక / SAND",
//             "description": "As Gampallu",
//             "units": "other",
//             "standardQty": "1",
//             "costPrice": 100,
//             "pujariSellingPrice": 125,
//             "customerMrp": 125,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:28:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:16:43.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 151
//           }
//         },
//         {
//           "id": "a7b0acf4-2bb0-48a6-ab7d-b0ab85378c91",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 3,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "a2c510e8-05b5-4b86-933e-c2ba01d2452f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "8cf7c5d6-34d0-4993-ae76-457889e55ab7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "87ee1670-b891-4ac6-b3b2-6a3a41c59f00",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         },
//         {
//           "id": "855c8332-9bf2-43d5-8548-579ff3893378",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "84b053cd-1706-4f7c-b6b2-90976656853e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//           "samagri": {
//             "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "name": "అరటి పండ్లు / Banana",
//             "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//             "units": "no",
//             "standardQty": "12",
//             "costPrice": 60,
//             "pujariSellingPrice": 70,
//             "customerMrp": 70,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-19T22:36:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:26:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 181
//           }
//         },
//         {
//           "id": "83721f8e-4f3c-4806-9270-a4031f74922e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//           "samagri": {
//             "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "name": "దర్భ కట్టలు/Kusha Grass",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T06:06:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 53
//           }
//         },
//         {
//           "id": "7596f4ea-6743-4434-b033-4de453e1f628",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//           "samagri": {
//             "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 230,
//             "pujariSellingPrice": 270,
//             "customerMrp": 270,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:31:17.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 23
//           }
//         },
//         {
//           "id": "6bb277f1-2873-4154-875b-dde20f6f097d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "6896b720-0dec-48ac-ba5e-67309a0bc300",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 12,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "98f97689-5cc6-46c6-a53f-f1f759c11f3c",
//           "samagri": {
//             "id": "98f97689-5cc6-46c6-a53f-f1f759c11f3c",
//             "name": "యవలు / Broad Beans",
//             "description": "Yam is a grass plant. It is more commonly known by the English word Barley. It is a major cereal crop grown in temperate climates worldwide. It was one of the first grains to be cultivated by humans, especially in Eurasia as early as 10,000 years ago.[1] Barley is used as animal feed, as a source of fermentable material for distilled beverages such as beer, and as a component of various health foods. It is used in soups and barley bread",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 4,
//             "pujariSellingPrice": 5,
//             "customerMrp": 5,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:52:18.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-13T01:56:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 123
//           }
//         },
//         {
//           "id": "654316c8-80f4-48f8-816c-eca935e49324",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//           "samagri": {
//             "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 110,
//             "pujariSellingPrice": 150,
//             "customerMrp": 150,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:29:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 25
//           }
//         },
//         {
//           "id": "57df5346-c5cd-4adc-bd15-a8454e127dd8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "d55da975-09bc-4577-868a-b94571edef0d",
//           "samagri": {
//             "id": "d55da975-09bc-4577-868a-b94571edef0d",
//             "name": "9×5 మంచి పంచలు (Poojarigariki)/Dhoti",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 550,
//             "pujariSellingPrice": 715,
//             "customerMrp": 715,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:25:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 22
//           }
//         },
//         {
//           "id": "4fb77da0-7184-4050-8004-c2848fa161be",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 30,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "8afdda99-a5e0-4478-9894-a40f7eb9235c",
//           "samagri": {
//             "id": "8afdda99-a5e0-4478-9894-a40f7eb9235c",
//             "name": " అరటి ఆకులూ / Banana leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 4,
//             "pujariSellingPrice": 5,
//             "customerMrp": 5,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:15:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-10T00:31:06.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 200
//           }
//         },
//         {
//           "id": "46ed0e89-d4a2-437b-bc3d-59912e71e335",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 101,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "421b6bb2-2bfd-4869-b404-fd37569ceed6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "35bc5c78-48ee-44de-a498-2bd1931ec6e4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "2b6e8738-a38b-40e3-8249-863480faf9fc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "23145841-6fb0-45f0-8446-6e4b1c7e712f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "2f6a7019-6552-443d-a4be-6eba4e92d213",
//           "samagri": {
//             "id": "2f6a7019-6552-443d-a4be-6eba4e92d213",
//             "name": "దీపం కంచుళ్ళు / Lampshades (Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-19T22:31:17.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T03:48:47.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 138
//           }
//         },
//         {
//           "id": "17eea05c-a342-4396-94bc-ae472134016b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 75,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "10ec7cb0-6c0e-4967-b9b4-31e05595bb79",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 9,
//           "pujaId": 156,
//           "samagriType": "addon",
//           "samagriId": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//           "samagri": {
//             "id": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//             "name": "రాగికళశాలు (1/2 ltr)/Copper kalasam (Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 183,
//             "pujariSellingPrice": 300,
//             "customerMrp": 300,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:25:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 16
//           }
//         },
//         {
//           "id": "0e8453e4-d65d-484d-9344-8da2d58cb2f5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "0e454b93-d20c-46ae-b8f9-b6cf17e5c3c5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 7,
//           "pujaId": 156,
//           "samagriType": "addon",
//           "samagriId": "bdf78f17-db12-4634-9792-4a9f5320856b",
//           "samagri": {
//             "id": "bdf78f17-db12-4634-9792-4a9f5320856b",
//             "name": "రాగి గ్లాసులు/Copper Glass - 250ml ",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 49,
//             "pujariSellingPrice": 80,
//             "customerMrp": 80,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:28:57.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 21
//           }
//         },
//         {
//           "id": "092ca2f9-6dc3-40ab-acba-6d1e8aff22e8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 60,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//           "samagri": {
//             "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "name": "బెల్లం / Jaggery",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:23:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T01:22:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 143
//           }
//         },
//         {
//           "id": "013ba1db-7ff4-4f08-a4f1-07528e5e5ce4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 150,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "fd76109d-7b82-4a9d-8ddc-504f2eb97328",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "fa9c3c18-d484-4a58-99cd-0640c1ad796d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//           "samagri": {
//             "id": "22009d42-95b5-4776-be81-bcdbc9cb9d70",
//             "name": "విభూది (FROM COW DUNG)/ Holy Ash",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 8,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:40:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 33
//           }
//         },
//         {
//           "id": "f9c0c641-3134-4eef-b14c-c8a0e0c871c6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "88283fdf-e374-4209-9f32-271942f838e9",
//           "samagri": {
//             "id": "88283fdf-e374-4209-9f32-271942f838e9",
//             "name": "అగ్నిహోత్రం సామాగ్రి/Agnihotram ",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T03:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-10T00:33:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 198
//           }
//         },
//         {
//           "id": "e831ab71-fd10-4371-ba10-9756022436f0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "b36854ff-7621-42fe-9f2e-7f0128cdcd99",
//           "samagri": {
//             "id": "b36854ff-7621-42fe-9f2e-7f0128cdcd99",
//             "name": "విస్తరాకులు/ Leaf Plate",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 5,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-04T02:21:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-04T02:19:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 192
//           }
//         },
//         {
//           "id": "e1b9aae6-43ad-40c3-b8b1-fea18cf57e8d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "dc8f4bf7-085e-4d6b-8578-09628b08cf08",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 156,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         }
//       ]
//     },
//     {
//       "id": 147,
//       "name": "Lakshmi Kubera Homam",
//       "description": "The Lakshmi Kubera Homam is usually performed by the devotees to get blessed with luxuries, financial growth, and success with the benevolence of Goddess Lakshmi and lord of wealth Kubera. This homam impacts us to improve and overcome financial hindrances which block the flow of wealth.",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": "remote",
//       "mode3": "on_behalf",
//       "mode4": null,
//       "videos": "undefined",
//       "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//       "updatedAt": "2023-07-24T01:33:01.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-06-16T00:47:03.000Z",
//       "createdById": null,
//       "updatedById": "44",
//       "pujacategorymappings": [
//         {
//           "id": 21,
//           "pujaId": 147,
//           "categoryId": 2,
//           "subCategoryId": 6
//         },
//         {
//           "id": 20,
//           "pujaId": 147,
//           "categoryId": 2,
//           "subCategoryId": 7
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 86,
//           "pujaId": 147,
//           "durationHrs": 3,
//           "noOfPujaris": 2,
//           "modelSellingPrice": 19500,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 10000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-28T03:20:45.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-25T22:17:19.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 1709,
//           "samagriCost": 4816,
//           "procedures": [
//             {
//               "id": "bf6ed962-33e9-49b5-84f9-36e8576c712e",
//               "modelId": 86,
//               "procedureId": 46,
//               "procedure": {
//                 "id": 46,
//                 "language": "telugu",
//                 "title": "Ayushya Homam",
//                 "description": "The pradhana ayur devatha is offered havissu along with the other devatas that were invited during the kalasa sthapana and mandaparadhana.   Ayusha suktham and ayur devatha mantram are utilised while performing the homam.  Maha mrutyunjaya, saptha chiranjeevulu, dhanvantari are also considered as prominent during the procedure.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "72e3a11d-4d99-475d-bff3-a05af94eb7d2",
//               "modelId": 86,
//               "procedureId": 50,
//               "procedure": {
//                 "id": 50,
//                 "language": "telugu",
//                 "title": "Satyanarayana Vratam",
//                 "description": "Full procedure of satyanarayana vratam once again reminding us the power of truth and need of honesty and ethics, in order to make this occasion successful",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "1c67f184-3129-4014-9c56-bc059ade2134",
//               "modelId": 86,
//               "procedureId": 53,
//               "procedure": {
//                 "id": 53,
//                 "language": "telugu",
//                 "title": "Naamakarana",
//                 "description": "A plate of rice is taken. With a gold ring wrapped with darbha, 3 lines are drawn and three different names are written by the father.\n1st line: Maasa namam, 2nd line: Nakshatra namam: 3rd : Vyavaharika namam  . Once this is done, the child is placed in cradle Three names are recited in child's ear along with mantra by pujari. Followed by the pujari, the mother, the father and the grandparents repeat the same and bless the child.\n\nDepending ojn the family traditions, culture of the region etc. the sequence or the steps would vary.  The pujari would accordingly guide the event. \n",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "015d896c-9ab1-47bb-8112-bddad4a0366a",
//               "modelId": 86,
//               "procedureId": 43,
//               "procedure": {
//                 "id": 43,
//                 "language": "telugu",
//                 "title": "Thora Grandhi Puja",
//                 "description": "Thoranam (raksha) puja",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "338f38d3-118f-4b64-9893-1cec0c54fcd8",
//               "modelId": 86,
//               "procedureId": 24,
//               "procedure": {
//                 "id": 24,
//                 "language": "telugu",
//                 "title": "Vrata Kadhalu",
//                 "description": "Vrata Kadhalu remind us and re -nforce the main promise (vrata) that we are undertaking as part of the puja.  The stories are designed with characters from the puranas whose smarana (rememberance) has a positive intent and impact on us. Hence the stories are not changed. ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "bf2f8f9e-31fb-43f7-b58b-ba22d7b9e966",
//               "modelId": 86,
//               "procedureId": 37,
//               "procedure": {
//                 "id": 37,
//                 "language": "telugu",
//                 "title": "Ash Gourd break / Gummadikaya kottadam",
//                 "description": "As the vegetable called Ash gourd is very high in life energy, it is broken in front of the door.\nAnother ash gourd (a full one, not damaged) is sometimes hung at the entrance after puja and replaced regularly. This wards off any Nara drishti doshas and keeps positive energies flowing into the house.",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "fdb17a48-3ddc-40be-bb97-0a3b9bd6c16c",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "fca88882-74a1-41d3-b7a6-6315ace3b69a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "f79a5f77-389a-464a-88e7-99631a53f9e0",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "f2ab6b6f-5257-4487-843e-668a6b46d08f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "efca0718-95af-415c-bf25-6b3949ae2d08",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         },
//         {
//           "id": "e914ed24-f870-4d61-bd1a-351514592c27",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "e6844145-9baa-4997-ab33-60a547c26724",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "e028ec19-5f00-4a4e-907d-0fc866f3c0cd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "d1fff849-8a72-4531-b7b1-1198094b0315",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "d0837f8e-c15e-4b10-be0b-a0792c69d50d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//           "samagri": {
//             "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "name": "బెల్లం / Jaggery",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:23:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T01:22:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 143
//           }
//         },
//         {
//           "id": "cc9f8d1e-e7c3-4165-84e7-db24dd6b158f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//           "samagri": {
//             "id": "c9e0d4f1-4d36-42cc-a64a-cf60213046d7",
//             "name": "యాలకులు / Cardamom",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 10,
//             "pujariSellingPrice": 24,
//             "customerMrp": 24,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:07:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 87
//           }
//         },
//         {
//           "id": "cb831381-ed13-4125-8e02-340f9fac3687",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 11,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "9241ed1f-9ee0-4800-a3ae-78b1269b524e",
//           "samagri": {
//             "id": "9241ed1f-9ee0-4800-a3ae-78b1269b524e",
//             "name": "కమలం పువ్వులు / Lotus flowers",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T03:14:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-10T01:32:10.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 197
//           }
//         },
//         {
//           "id": "c134d189-fbca-40ed-a021-ad28344ac74e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "6fab8ce0-d092-4ed6-bdd5-c7b060e4c37a",
//           "samagri": {
//             "id": "6fab8ce0-d092-4ed6-bdd5-c7b060e4c37a",
//             "name": "పట్టు వస్త్రం / Silk cloth",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 150,
//             "pujariSellingPrice": 200,
//             "customerMrp": 200,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:37:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 112
//           }
//         },
//         {
//           "id": "bd49d877-481a-479c-ac8c-5096363b3030",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 9,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "191c838a-45d3-436f-807e-d7fc0cd0eddb",
//           "samagri": {
//             "id": "191c838a-45d3-436f-807e-d7fc0cd0eddb",
//             "name": "కమల గింజలు/Lotus seeds",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:09:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-10T01:33:11.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 195
//           }
//         },
//         {
//           "id": "bb119b64-61f7-4bdb-9369-e7030b67a604",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "b88c79fd-e06e-4a62-87d2-e8d8e9777061",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//           "samagri": {
//             "id": "e84f6c91-a224-4309-bbd9-db077d13e11e",
//             "name": "లవంగాలు / cloves",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:08:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 88
//           }
//         },
//         {
//           "id": "b6a044c0-4d09-48fa-a2a5-053fcf2c3825",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "addon",
//           "samagriId": "695a3876-5245-431c-992d-9ba25f398e5c",
//           "samagri": {
//             "id": "695a3876-5245-431c-992d-9ba25f398e5c",
//             "name": "దర్భ చాపలు / Darba Mat",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 50,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T01:34:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 75
//           }
//         },
//         {
//           "id": "b2d2f5a2-24bb-479e-8ac5-3b5627486f7f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "80189106-e98e-41b8-8435-beef3b4cf163",
//           "samagri": {
//             "id": "80189106-e98e-41b8-8435-beef3b4cf163",
//             "name": "ఎండుద్రాక్ష/Raisins ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:11:50.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 14
//           }
//         },
//         {
//           "id": "ae93bdd7-2bc7-495a-a516-415646549683",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "a4a51c9a-9c98-4c2d-8831-6bef0a057102",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//           "samagri": {
//             "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:38:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 115
//           }
//         },
//         {
//           "id": "a215a0a3-68e6-47b3-9a42-868cfccb9689",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "4423f1cb-e2eb-459c-bba3-9964b0fb3c6b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "499f3b07-536e-4138-8123-a78958cb2c3a",
//           "samagri": {
//             "id": "499f3b07-536e-4138-8123-a78958cb2c3a",
//             "name": "పట్టికబెల్లం చిప్స్ /Misri Chips",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:54:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 106
//           }
//         },
//         {
//           "id": "40358bcd-295c-42e2-9b85-6fbc56488145",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "30975d1e-7d91-4fd8-a1c2-7d731672e7c3",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "2fd73d1e-37b5-4430-b140-0b02d528395d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//           "samagri": {
//             "id": "405893e6-a33f-4fbf-aea0-a3571d051fc9",
//             "name": "తెల్ల నువ్వులు/White Sesame",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T02:09:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 43
//           }
//         },
//         {
//           "id": "2be05a38-0fe1-4a46-8538-6dd1a197862f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//           "samagri": {
//             "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "name": "చెంబులు/Kalasam",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:26.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:39:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 253
//           }
//         },
//         {
//           "id": "24eb12df-1557-4ff5-94e3-2bcb3067712c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "23ebf5f3-68a2-4081-97f4-a72fa4951298",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "ca478478-dede-4f37-bb5a-1e2c2a023696",
//           "samagri": {
//             "id": "ca478478-dede-4f37-bb5a-1e2c2a023696",
//             "name": "చెరుకు ముక్కలు / Pieces of Sugarcane",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 12,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:36:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T05:03:23.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 186
//           }
//         },
//         {
//           "id": "1f68f05e-8631-4ada-a6b4-78c207e834cb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//           "samagri": {
//             "id": "92da20c4-a10e-4013-9364-f5114438d904",
//             "name": "వీడి పూలు / Loose Flowers",
//             "description": null,
//             "units": "gms",
//             "standardQty": "250",
//             "costPrice": 110,
//             "pujariSellingPrice": 120,
//             "customerMrp": 120,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:16:53.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:44:42.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 176
//           }
//         },
//         {
//           "id": "1f44b436-305a-4b78-9670-af87970204d6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//           "samagri": {
//             "id": "6961b6a3-1585-4afb-946c-55d4a0614c8e",
//             "name": "అటుకులు / Poha",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:28:02.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 101
//           }
//         },
//         {
//           "id": "151930d1-64bc-4a8e-9d9d-17b4a5874186",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "96414c68-fdd7-4a85-9fc1-4bedd5c27b60",
//           "samagri": {
//             "id": "96414c68-fdd7-4a85-9fc1-4bedd5c27b60",
//             "name": "జీడిపప్పు/Cashew",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:59:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 12
//           }
//         },
//         {
//           "id": "141e0d36-dbb2-480e-a10f-ab061f50b891",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "13350cf6-3546-4a0a-8fa4-af8024008bea",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "9372c01d-6536-4327-ad2d-bd9cc6d53e1f",
//           "samagri": {
//             "id": "9372c01d-6536-4327-ad2d-bd9cc6d53e1f",
//             "name": "గంగా జలం / Ganga Water",
//             "description": null,
//             "units": "ml",
//             "standardQty": "50",
//             "costPrice": 6,
//             "pujariSellingPrice": 30,
//             "customerMrp": 30,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:11:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 91
//           }
//         },
//         {
//           "id": "12b82113-b2f3-4e07-9653-3af5ec69d495",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "d5fae081-6678-4cd1-865e-89097def5d16",
//           "samagri": {
//             "id": "d5fae081-6678-4cd1-865e-89097def5d16",
//             "name": "పూల మూరలు/flower buds",
//             "description": "1 మూర",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 35,
//             "pujariSellingPrice": 45,
//             "customerMrp": 45,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T03:13:28.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T06:48:27.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 227
//           }
//         },
//         {
//           "id": "11911396-70b4-4f55-9ac0-ceeeb9625a4a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//           "samagri": {
//             "id": "7d6140d8-ec0f-47a2-b3e6-6ee8c1765709",
//             "name": "పచ్చ కర్పూరం /  GREEN CAMPHOR",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 10,
//             "pujariSellingPrice": 12,
//             "customerMrp": 12,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:22:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 99
//           }
//         },
//         {
//           "id": "0493c7aa-8c44-4b65-8d8b-b0457f9fee1f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "98d0dda3-394a-4399-96dd-663bb9e0e1ad",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "9869b490-f93d-4c5a-b4b4-6c69e99f8658",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "addon",
//           "samagriId": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//           "samagri": {
//             "id": "7204fdc7-76f2-4c8f-9c90-c51267bb9ea8",
//             "name": "రాగికళశాలు (1 ltr)/Copper Kalasam (Big)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 210,
//             "pujariSellingPrice": 500,
//             "customerMrp": 500,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:26:10.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 17
//           }
//         },
//         {
//           "id": "96fe5f0c-79d8-4c9e-b784-d39331993b90",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 75,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "95ace273-502c-45fc-8368-615e55b03779",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "17f5dfe8-ebdb-40ed-b278-b73e8cc35ae4",
//           "samagri": {
//             "id": "17f5dfe8-ebdb-40ed-b278-b73e8cc35ae4",
//             "name": "5 types of fruits (Each 2)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 350,
//             "pujariSellingPrice": 400,
//             "customerMrp": 400,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T04:11:15.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-16T05:21:24.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 155
//           }
//         },
//         {
//           "id": "9363087d-832b-4ead-a881-b3daf4703dd7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "88619606-231a-45d1-b3b4-c098538603c3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "88527666-3cc3-4a1d-8c34-254a77a2e375",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "7c2cd2ea-47d7-4dd6-8ba9-a2f28b0c15eb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 50,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "792e93f3-1bf2-4411-b658-4756aeb8ca44",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//           "samagri": {
//             "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "name": "వరిపెళాలు/Rice Fields",
//             "description": null,
//             "units": "gms",
//             "standardQty": "50",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:33:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 58
//           }
//         },
//         {
//           "id": "7767587c-121e-405e-ba96-812daaf6c50e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "76b35c65-66ce-4fda-8afe-57ccfe4e6dbb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "712d340e-f2be-4fc5-a878-ee28047b27c2",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "6ef2aa06-9a7e-4942-ab51-292129a81367",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "6eedc844-fca7-4c1d-8165-5f993787b57c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "6ca2f3a6-0ed2-4c96-aea6-5320a7217a07",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//           "samagri": {
//             "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 500,
//             "pujariSellingPrice": 600,
//             "customerMrp": 600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:43:18.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 114
//           }
//         },
//         {
//           "id": "69dbe52e-91e9-43e8-be7a-960498a6d497",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//           "samagri": {
//             "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 110,
//             "pujariSellingPrice": 150,
//             "customerMrp": 150,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:29:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 25
//           }
//         },
//         {
//           "id": "64e2ef61-e228-4192-a64d-d2131c7612a8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "62e1623e-6bc8-47d2-adae-451a57b309b2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "6145183b-9cf5-4e18-93ee-08060a27f724",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "546d5154-1cef-44c7-9bdf-e2267c4cbebc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "4fdf6aa9-5e8c-4e46-92f9-531668ab019d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "4d995346-2943-44a8-9a5f-57aa68ae0549",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "4bb692d0-78f0-4082-bc87-666152f1576c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "4b0392d5-174b-40eb-b294-184c61094e1d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 147,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         }
//       ]
//     },
//     {
//       "id": 143,
//       "name": "Shankusthapana  (Bhumi pooja)",
//       "description": "Bhumi pooja is performed to seek the blessings and permission of Goddess Bhoomi or earth and Vastu Purush (the deity of directions), before beginning the construction of a new house / place of business etc. The five elements of nature are also worshipped during the bhumi puja",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": null,
//       "mode3": null,
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/4ijX9N6xEBo\"]",
//       "images": "[\"/uploads/2023/07/Bhoomi-pujas-2.jpg\",\"/uploads/2023/07/Bhoomi-Puja-scaled.jpeg\"]",
//       "updatedAt": "2023-07-20T13:29:45.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-06-15T03:08:11.000Z",
//       "createdById": null,
//       "updatedById": "44",
//       "pujacategorymappings": [
//         {
//           "id": 11,
//           "pujaId": 143,
//           "categoryId": 1,
//           "subCategoryId": 1
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 90,
//           "pujaId": 143,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 6899,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 3000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-28T03:01:03.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-06-25T22:27:42.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 914,
//           "samagriCost": 1933,
//           "procedures": [
//             {
//               "id": "e1cb6f1b-d73d-4936-b658-0f1848eae97c",
//               "modelId": 90,
//               "procedureId": 20,
//               "procedure": {
//                 "id": 20,
//                 "language": "telugu",
//                 "title": "Ashta dikpalaka puja",
//                 "description": "Ashtadikpalakas denote that God is in every direction, and that there is a specific energy and deity in each direction. Each energy has specific qualities and is suitable for different activities. We perform this puja to balance the five elements in these directions.\n\nThe Ashtadikpalakas of various directions are:\nKubera for North,\nYama for South,\nIndra for East,\nVaruna for West,\nIsana for Northeast,\nAgni for Southeast,\nVayu for Northwest,\nNirrti for Southwest.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "881ae0ac-4b2a-43d6-a69c-2b729204d49e",
//               "modelId": 90,
//               "procedureId": 24,
//               "procedure": {
//                 "id": 24,
//                 "language": "telugu",
//                 "title": "Vrata Kadhalu",
//                 "description": "Vrata Kadhalu remind us and re -nforce the main promise (vrata) that we are undertaking as part of the puja.  The stories are designed with characters from the puranas whose smarana (rememberance) has a positive intent and impact on us. Hence the stories are not changed. ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "75d77e1b-7a85-4297-931c-0598fb4c1f28",
//               "modelId": 90,
//               "procedureId": 44,
//               "procedure": {
//                 "id": 44,
//                 "language": "telugu",
//                 "title": "Mantra Pushpam",
//                 "description": "A reminder that the divine force that we seek, pray and worship actually exists within us, and if we are able to follow Sanatana Dharma (the way of life), we can awaken this force and remain at eternal bliss with this presence.  A reminder that the divine and us are 'abhedam' (inseparable) and we are yet to experience this.\n\nThose who experience this enlightnment need not perform pujas anymore and that is our rishis' wish.  They want us to perform pujas, only for us to rise beyond them towards ultimate gyana and moksha",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "ffdb86f3-31fd-4703-8926-81d2b4b2864e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "f3030fa9-274c-4628-a689-f1a1a338e097",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "eb4093e1-206d-4417-8acf-198f547c1ba2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "eb0363f0-b4e4-4edb-a9ec-afb5ca7de7a3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "e080adea-427c-4dda-a25b-9302798a951d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "d8ed8480-c2f3-45c7-be4f-d87fd4d92884",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "d69be70c-59c8-42e1-bb97-0acd7653ea59",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "d536b6af-4172-4fa6-994e-02973c1c3f74",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//           "samagri": {
//             "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "name": "అరటి పండ్లు / Banana",
//             "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//             "units": "no",
//             "standardQty": "12",
//             "costPrice": 60,
//             "pujariSellingPrice": 70,
//             "customerMrp": 70,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-19T22:36:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:26:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 181
//           }
//         },
//         {
//           "id": "d465000a-e29c-44db-ade2-affbc4f3d5ba",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "c5e0500f-8f20-4eff-83ef-253e1e1038b8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "c5d056c3-1e92-415b-ac5a-2f042dda3576",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "b8db253f-acdc-44ce-8f5a-29cd67f3544a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "b2f3f694-a80a-4961-b2c7-3011d924b4b4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "af0ab536-15dc-4d8c-bc8c-e653c9b50d0f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "a2193541-6d45-415a-a873-dfa78ce173f4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "a13c6f75-8312-4f3c-8069-a2b1337cbb5a",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "9da2a3f6-aff6-4085-a7d3-92a04bc29a5d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "946f1915-4fe5-4848-a070-cc2ee7eb5023",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "9349f144-444e-4297-bf51-de4d6986bad4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "8ec0e050-14d2-4349-8b17-691b9f7c8732",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "2aa06610-a444-4381-af0b-090398b9fa43",
//           "samagri": {
//             "id": "2aa06610-a444-4381-af0b-090398b9fa43",
//             "name": "దోపళ్ళు / Doppa",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 18,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:58:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 44
//           }
//         },
//         {
//           "id": "8c8c83fc-4286-4947-b1fb-30050c0251cc",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//           "samagri": {
//             "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 18,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:01:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 48
//           }
//         },
//         {
//           "id": "819f1bd8-e7cb-4e8b-b96a-9ecd89424866",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//           "samagri": {
//             "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 230,
//             "pujariSellingPrice": 270,
//             "customerMrp": 270,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:31:17.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 23
//           }
//         },
//         {
//           "id": "7ecfd689-70c7-4abe-9bf7-0c7736759a53",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "776ea4dc-4a4b-494f-8e0e-2b7f4e3ab060",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "6d85b324-1926-42e3-a111-e8207461658d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "5ddea222-90ea-4fe4-8661-a0c64bd5d181",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "5484201d-d09c-440d-a938-4c814dfd5517",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "53947e0c-ee08-4548-a46c-81fc550bfd95",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "addon",
//           "samagriId": "07dd931d-1a74-40fe-8f3e-b4def95054e6",
//           "samagri": {
//             "id": "07dd931d-1a74-40fe-8f3e-b4def95054e6",
//             "name": "నవరత్నాలు / Navratnas ( Best Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 3650,
//             "pujariSellingPrice": 3950,
//             "customerMrp": 3950,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:56:40.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T03:54:07.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 132
//           }
//         },
//         {
//           "id": "4eef5347-ff88-4cf8-ba7f-e5eab3619d84",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "49cf6827-6473-428f-9761-1c652416040e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "41b26e98-2423-4445-8a56-3dcf880452f7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "350a3c49-e86e-4214-a75f-fe1af85bbf3b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "3462a924-21d4-4b6e-8abb-3e619fceb114",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "341988cb-f421-4d5d-b770-00c496f69e2d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "9372c01d-6536-4327-ad2d-bd9cc6d53e1f",
//           "samagri": {
//             "id": "9372c01d-6536-4327-ad2d-bd9cc6d53e1f",
//             "name": "గంగా జలం / Ganga Water",
//             "description": null,
//             "units": "ml",
//             "standardQty": "50",
//             "costPrice": 6,
//             "pujariSellingPrice": 30,
//             "customerMrp": 30,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:11:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 91
//           }
//         },
//         {
//           "id": "31728f8f-8ed5-45fe-a504-efbf31b5b1e2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "30aa6ab3-2392-454b-8f65-d2a93694c79b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "29d47fee-3e59-4a48-9e39-4f72a62bca6f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "292d03b4-103f-4c7b-b1c6-ec01aef6ff81",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "248271be-8081-4cb6-87c8-2aa16a33eb1f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "1cd04d43-0359-42fe-9b25-1408b000be93",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//           "samagri": {
//             "id": "92da20c4-a10e-4013-9364-f5114438d904",
//             "name": "వీడి పూలు / Loose Flowers",
//             "description": null,
//             "units": "gms",
//             "standardQty": "250",
//             "costPrice": 110,
//             "pujariSellingPrice": 120,
//             "customerMrp": 120,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:16:53.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:44:42.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 176
//           }
//         },
//         {
//           "id": "173fadb4-f17d-4085-9f78-00ae0f723087",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "117fbe14-6189-4fb8-a001-4882cd005b82",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "0a1f9116-111a-496e-b61f-e08d4dc7c17d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "default",
//           "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//           "samagri": {
//             "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "name": "చెంబులు/Kalasam",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:26.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:39:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 253
//           }
//         },
//         {
//           "id": "03aca55c-c601-44c6-ba05-e65497aee60a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 143,
//           "samagriType": "addon",
//           "samagriId": "2236e37c-234f-4d44-a9c0-8cfb98f6bf53",
//           "samagri": {
//             "id": "2236e37c-234f-4d44-a9c0-8cfb98f6bf53",
//             "name": "పంచలోహాలు / Five Metals ( Best Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 8500,
//             "pujariSellingPrice": 8900,
//             "customerMrp": 8900,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T07:01:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T03:53:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 133
//           }
//         }
//       ]
//     },
//     {
//       "id": 10,
//       "name": "Gruhapravesham",
//       "description": "Gruhapravesha puja is designed to make a home’s space / environment conducive for the people living in it and to help them move towards their wellbeing. \nThis puja is a culmination of several branches of scriptures/ shastras, and is intended for balancing out any vaastu doshas, and imbalances in the five basic elements and the eight directions.\n",
//       "language": "telugu",
//       "region": "Hyderabad",
//       "mode1": "at_my_home",
//       "mode2": null,
//       "mode3": null,
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/4ijX9N6xEBo\",\"https://www.youtube.com/embed/w_JnwafQQNw\"]",
//       "images": "[\"/uploads/2023/07/Gruhapravesham-2.jpeg\",\"/uploads/2023/07/Gruhapravesham-1-scaled.jpg\"]",
//       "updatedAt": "2023-07-18T03:19:22.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": null,
//       "createdById": null,
//       "updatedById": "47",
//       "pujacategorymappings": [
//         {
//           "id": 3,
//           "pujaId": 10,
//           "categoryId": 1,
//           "subCategoryId": 1
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 71,
//           "pujaId": 10,
//           "durationHrs": 4,
//           "noOfPujaris": 2,
//           "modelSellingPrice": 24822,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 12000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-28T03:09:00.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-05-24T03:36:26.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 1159,
//           "samagriCost": 7877,
//           "procedures": [
//             {
//               "id": "86086fd6-d5ca-4100-be33-69bf4a5472c0",
//               "modelId": 71,
//               "procedureId": 16,
//               "procedure": {
//                 "id": 16,
//                 "language": "telugu",
//                 "title": "Ganapati Puja",
//                 "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "0bc335c5-84a0-4829-a170-1c04567dfd38",
//               "modelId": 71,
//               "procedureId": 6,
//               "procedure": {
//                 "id": 6,
//                 "language": "telugu",
//                 "title": "Punyaha vachanam (Laghu)",
//                 "description": "Punyaham means auspicious, and vachanam means to recite. In this step, mantras are recited which purify us and our surroundings and bring forth the auspiciousness required before performing a vedic action. This step is given in yajurveda and rigveda, and requires kalasham to perform it. It is performed to purify 8 things which are: Gruha, bhu, bhanda, atma, dravya, jala, sthala, shareera, and padartham.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "2640cf81-3fd9-4b22-b0cb-7f2ab2cc8beb",
//               "modelId": 71,
//               "procedureId": 14,
//               "procedure": {
//                 "id": 14,
//                 "language": "telugu",
//                 "title": "Aashirvachanam",
//                 "description": "The guru aashirvachanam is the biggest blessing for the devotee or the family performing the puja.  Guru is eeshwara's form in front of us. His tapasya and veda parayana, nithya gayatri japa etc. mean that his blessings can lead to god's grace.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "aabd56b4-ea56-4fa7-bf59-7d7b41cf4e78",
//               "modelId": 71,
//               "procedureId": 7,
//               "procedure": {
//                 "id": 7,
//                 "language": "telugu",
//                 "title": "Raksha bandanam ",
//                 "description": "A sacred thread is tied to the individuals performing the puja, while chanting mantras. This signifies their determination to begin the ceremony.",
//                 "specialNotes": "testing 32"
//               }
//             },
//             {
//               "id": "81138eef-38fe-4fc4-84cc-55c7eaef588f",
//               "modelId": 71,
//               "procedureId": 8,
//               "procedure": {
//                 "id": 8,
//                 "language": "telugu",
//                 "title": "Mandaparadhana",
//                 "description": "Aavahanam of navagraha, ashtadikpalaka, panchalokapalaka, trimurthulu, ishta devata, kula devata, and nakshatra devatalu.",
//                 "specialNotes": "testing 8888"
//               }
//             },
//             {
//               "id": "9627b226-9662-4b28-afab-d14509fc6b5d",
//               "modelId": 71,
//               "procedureId": 9,
//               "procedure": {
//                 "id": 9,
//                 "language": "telugu",
//                 "title": "Vaastu Kalasa sthapanam and Puja",
//                 "description": "Vaastu purusha aavahana is performed into the kalasha thus enabling the devotees to pray and seek for the blessings of an amsha of this abstract form that governs the space that we are going to live / work in. Depending on the tradition followed in the yajamani (doer's) house, kalasha prathista is done and sometimes not. ",
//                 "specialNotes": "Few customers opt for very detailed and elaborate vaasthu puja which includes things like: sarvathobhadra mandalam, vaasthu bomma / bimbam etc.. You can discuss with pujari in the call if you are interested in the same."
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "53c6358a-39d0-46e2-a6cd-a760cf522a11",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 100,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "51ecad5b-8fe8-4109-8e75-bca0db8c9689",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 10,
//           "samagriType": "addon",
//           "samagriId": "6c40c524-ce9e-4e76-b9d8-23bb3598b511",
//           "samagri": {
//             "id": "6c40c524-ce9e-4e76-b9d8-23bb3598b511",
//             "name": "కొత్త నీళ్ల బిందేళ్లు/ NEW WATER POTS (Steel)",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 1500,
//             "pujariSellingPrice": 1600,
//             "customerMrp": 1600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:40:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:04:26.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 189
//           }
//         },
//         {
//           "id": "4e00a214-7e8f-442d-b102-4aed26ffd584",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//           "samagri": {
//             "id": "1f94e91f-d4fd-4a3b-9ee4-9debc9cfe22f",
//             "name": "గోమయం/Cow Dung",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-13T06:33:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:27:00.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 230
//           }
//         },
//         {
//           "id": "4dd09820-7b6e-4936-a541-addc97619897",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "214b14e5-18fa-480b-8905-52aa5087ff80",
//           "samagri": {
//             "id": "214b14e5-18fa-480b-8905-52aa5087ff80",
//             "name": "వరిపెళాలు/Rice Fields",
//             "description": null,
//             "units": "gms",
//             "standardQty": "50",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:33:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 58
//           }
//         },
//         {
//           "id": "4cf59da6-6edb-4211-b98f-847406ccfb11",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 15,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "4b996a63-3442-4e10-b9f7-d9c7503305f9",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//           "samagri": {
//             "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "name": "చెంబులు/Kalasam",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:26.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:39:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 253
//           }
//         },
//         {
//           "id": "4b818a6d-f768-4e3f-85f1-d2dfc628616e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//           "samagri": {
//             "id": "aa16ab50-b8ee-4177-827b-f5bc899d227f",
//             "name": "నవధాన్యాలు/Nine Grains Offering for Puja",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 3,
//             "customerMrp": 3,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:27:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 7
//           }
//         },
//         {
//           "id": "4a5d676e-f6c0-405a-a0cc-ac45dc945ebf",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "429f9834-d2bd-4c5f-af9a-02dc1d85696e",
//           "samagri": {
//             "id": "429f9834-d2bd-4c5f-af9a-02dc1d85696e",
//             "name": "Fruits and Flowers (Satyanarayana Vratam) (Premium)",
//             "description": "Bananas 6 No ,  Loose Flowers 1/2 Kg  ,  Poola Morallu(2)   Moora Tulasi Mala (1),   Thulasi dhalam 50g  4 Types of Fruits 2 each",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 815,
//             "pujariSellingPrice": 978,
//             "customerMrp": 978,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-15T02:38:36.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-15T00:00:34.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 265
//           }
//         },
//         {
//           "id": "47762bb2-e84d-468c-ab48-aab82d91e207",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "addon",
//           "samagriId": "1c95dca1-e487-47dc-b510-3c026d51e96f",
//           "samagri": {
//             "id": "1c95dca1-e487-47dc-b510-3c026d51e96f",
//             "name": "పాలు పొంగించే గిన్నె1/2 kg / Doodhwala Patila ",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 550,
//             "pujariSellingPrice": 700,
//             "customerMrp": 700,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T01:34:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-19T04:16:02.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 66
//           }
//         },
//         {
//           "id": "46902cba-1759-40b6-84bb-89bea87a0aff",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "40c13cbe-2b66-413d-ad48-10558011f91b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "3c85c937-91e8-4ec4-ba31-3a55350066f0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//           "samagri": {
//             "id": "89d5bd94-5a38-42f4-9405-38dbf808b1f8",
//             "name": "సమిధలు / Homam Samithu Sticks",
//             "description": null,
//             "units": "bunch",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 60,
//             "customerMrp": 60,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:37:34.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 54
//           }
//         },
//         {
//           "id": "3aef2d97-281d-4fb2-a858-62addf0ae34c",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "3a452a6a-8590-420a-85e2-a597a25eefaf",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "36cdaa47-09ec-4fd2-98a5-b463002cd29d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 35,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//           "samagri": {
//             "id": "b8c86d28-5c2c-421c-b1f9-457f1d6769ec",
//             "name": "ఇటుకలు/ BRICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 8,
//             "pujariSellingPrice": 13,
//             "customerMrp": 13,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:39:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:14:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 150
//           }
//         },
//         {
//           "id": "36378b51-a190-4b5c-a4d8-8ec58a097f2a",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "2bf2075b-4905-4e62-9919-d55b4e61a2ce",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//           "samagri": {
//             "id": "8969a2dd-6463-4ab4-a7a6-54f111eebed8",
//             "name": "హవనం పొడి  / Havan Samagri ( Top Quality)",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 3,
//             "pujariSellingPrice": 4,
//             "customerMrp": 4,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:38:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 115
//           }
//         },
//         {
//           "id": "26b5eb4a-6960-4046-b887-0154250c1d9f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "2559c28b-3507-4c93-a5a3-55098364af9d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "3be47417-fac4-4cc9-bd82-e3ba00efc6b4",
//           "samagri": {
//             "id": "3be47417-fac4-4cc9-bd82-e3ba00efc6b4",
//             "name": "బూడిద గుమ్మడి కాయ/Ash gourd",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 150,
//             "pujariSellingPrice": 160,
//             "customerMrp": 160,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:48:31.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:17:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 190
//           }
//         },
//         {
//           "id": "21191387-5ccb-4520-9d47-22cc86709ba8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "20c30b17-c4fb-42e8-9a9c-e4bfcaa96202",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "1ffefed0-4b46-4a20-b8b0-38b7db4973cb",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 60,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "1a25b1b6-21f1-48fb-a9c1-80f5a4833e41",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "1348ed40-4836-48e5-b726-3249525a01ac",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 16,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "0d934e09-d552-450e-bfc3-92d09ad52ba5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "0031beaf-9312-467e-853a-b2243ce677f5",
//           "samagri": {
//             "id": "0031beaf-9312-467e-853a-b2243ce677f5",
//             "name": "గోమూత్రం/Cow Urine",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:17:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:26:29.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 231
//           }
//         },
//         {
//           "id": "0d76b2f8-8705-44d0-9eca-4bd0ce5b61c2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "644a01ca-da37-415f-8d5b-8b1d4c6c17a0",
//           "samagri": {
//             "id": "644a01ca-da37-415f-8d5b-8b1d4c6c17a0",
//             "name": "ఉట్టి / Utti",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 40,
//             "customerMrp": 40,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:37:09.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 38
//           }
//         },
//         {
//           "id": "0845ff30-911b-41ef-87e5-9ba873035788",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "05bf9806-6c69-46de-bc77-6f1500088926",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "037f772b-d132-4250-bf53-f923c5841b3b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "d392818e-706e-4287-9818-c92b4d24342a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//           "samagri": {
//             "id": "26af9022-beb0-44b2-a019-9b687da8cdc1",
//             "name": " మంచి పంచలు 2mtrs / Mandaparadhana Pancha",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 230,
//             "pujariSellingPrice": 270,
//             "customerMrp": 270,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:31:17.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 23
//           }
//         },
//         {
//           "id": "c83d240c-6ce9-4f1f-abc7-ab73322ea2c2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "a844072d-3461-43c9-ae33-96c0668987af",
//           "samagri": {
//             "id": "a844072d-3461-43c9-ae33-96c0668987af",
//             "name": "ఇత్తడి గ్లాసు / Brass Glass (250ml)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 50,
//             "pujariSellingPrice": 75,
//             "customerMrp": 75,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:26:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 18
//           }
//         },
//         {
//           "id": "c5f1402a-1377-41f1-a122-ccee85701069",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "c583ed1e-7378-4417-9ad1-2fe599568799",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "677f4125-5463-41ed-911f-cdf09decf53e",
//           "samagri": {
//             "id": "677f4125-5463-41ed-911f-cdf09decf53e",
//             "name": "పూర్ణాహుతి/ Purnahuti  (Top Quality)",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 500,
//             "pujariSellingPrice": 600,
//             "customerMrp": 600,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T03:43:18.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 114
//           }
//         },
//         {
//           "id": "c4a82f87-ca16-48d9-b7d1-dc8271671273",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "c1ff3528-f0ac-4c2f-8393-e7cee2365c34",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         },
//         {
//           "id": "bd2bf9f4-3501-406b-920d-5b61c45d589e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 100,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "b90c15f7-d4da-46a7-9d2a-0b3ff0ae6afc",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "b89db2a2-697f-4f6e-ab49-a58a35979f4d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "b31390e4-0e15-4cea-9d66-ac85cd50159e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "b149a625-aa5e-4f14-91aa-29bda9fb0788",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "0239464d-2343-4e47-bcc8-4e843c5110af",
//           "samagri": {
//             "id": "0239464d-2343-4e47-bcc8-4e843c5110af",
//             "name": "గరిక/  Grass",
//             "description": "The blades are a grey-green color and are short, usually 2–15 cm (0.79–5.91 in) long with rough edges. The erect stems can grow 1–30 cm (0.39–11.81 in) tall. The stems are slightly flattened, often tinged purple in colour.",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 20,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:31:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:11:34.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 183
//           }
//         },
//         {
//           "id": "ad314276-b5cc-4ed8-a926-8c645a355259",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "b19dc2b1-0b51-4031-91ed-9a61d63985f2",
//           "samagri": {
//             "id": "b19dc2b1-0b51-4031-91ed-9a61d63985f2",
//             "name": "జాజు / Ocher powder/ Geru Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 8,
//             "customerMrp": 8,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:50:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T03:23:59.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 122
//           }
//         },
//         {
//           "id": "a88cafb1-8ad0-4df5-8eee-31360a87d8db",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "a08ed3a7-1088-4263-924f-603f6fce657d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "9a9d1bfb-3417-4fc0-bf3c-1c2f04117a2a",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "8dddc121-4f77-4aab-9c8a-6013158917d7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 8,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//           "samagri": {
//             "id": "fddf0beb-41f8-404a-9d4a-3cfd4145d63c",
//             "name": "బెల్లం / Jaggery",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:23:52.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T01:22:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 143
//           }
//         },
//         {
//           "id": "8acf4d80-8c1b-4188-80e0-35e0cd43d2b1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "8648c461-7be4-4726-8643-7f79a7007a70",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "824876d0-2f8e-4829-a4aa-672c3a2576f5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "addon",
//           "samagriId": "48a8178f-db8e-42f4-bcef-9d2dcee46d21",
//           "samagri": {
//             "id": "48a8178f-db8e-42f4-bcef-9d2dcee46d21",
//             "name": "సత్యనారాయణ స్వామి మహా-ప్రసాద్ పదార్థాలు  / Satyanarayana Vrata Prasadam",
//             "description": "Godhuma Ravva 1 1/4 kg ,  Kaju 50g ,  Kismiss 50g ,   Badam 50 g\n\nగోధుమ రవ్వ\t1 1/4 kg,     కాజు\t50g,     కిస్మిస్\t50g,    బాదం\t50 g",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 427,
//             "pujariSellingPrice": 450,
//             "customerMrp": 450,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:36:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:59:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 175
//           }
//         },
//         {
//           "id": "7f64ba07-f66f-4dcc-b916-7190b713c536",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 3,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//           "samagri": {
//             "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 18,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:01:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 48
//           }
//         },
//         {
//           "id": "7c1e182c-121f-48c0-b494-3795c7a0dd71",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 24,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "d87297fb-4fd9-4014-b4d8-12642e1975a7",
//           "samagri": {
//             "id": "d87297fb-4fd9-4014-b4d8-12642e1975a7",
//             "name": "నిమ్మకాయలు/LEMON",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 5,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:45:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-01T04:00:03.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 173
//           }
//         },
//         {
//           "id": "7b7ac32d-7c6f-445b-a9f9-fe51c07eef70",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "7553eb56-ee70-4b2a-8b06-89760952bee0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "04902608-1beb-43e6-bb11-f579bf3da0f5",
//           "samagri": {
//             "id": "04902608-1beb-43e6-bb11-f579bf3da0f5",
//             "name": "తెల శైలలు / White Cloth",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 110,
//             "pujariSellingPrice": 130,
//             "customerMrp": 130,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T02:13:06.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 46
//           }
//         },
//         {
//           "id": "733e7f98-ba2a-4f69-9ecc-52ba367517c3",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//           "samagri": {
//             "id": "b70dd3e4-0207-4b02-88d3-346df7d66bc3",
//             "name": "దర్భ కట్టలు/Kusha Grass",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 15,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T06:06:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 53
//           }
//         },
//         {
//           "id": "6eb70219-7ed2-4341-9e59-798218823518",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "62b714dd-f071-4c4c-9e07-1f82f37bf0fd",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "1818135a-6d1f-487d-b65d-e920835036c1",
//           "samagri": {
//             "id": "1818135a-6d1f-487d-b65d-e920835036c1",
//             "name": " కొబ్బరి బొండం / Coconut Bondam",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 50,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T03:06:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-01T04:04:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 174
//           }
//         },
//         {
//           "id": "61d31258-91ad-43a3-80f6-047577d99a10",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "5ef43bfa-8d74-4f72-8a13-19bb90dbc658",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "5d6ea2d6-4d8b-46e5-953b-eb2612114616",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "5d2a7054-fe74-4b2b-9c36-b99d16d39a24",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "58894572-5dea-470c-9121-87bd01af66d5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 10,
//           "samagriType": "addon",
//           "samagriId": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//           "samagri": {
//             "id": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//             "name": "రాగికళశాలు (1/2 ltr)/Copper kalasam (Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 183,
//             "pujariSellingPrice": 300,
//             "customerMrp": 300,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:25:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 16
//           }
//         },
//         {
//           "id": "5789ace8-3018-4dd7-aa20-7f367b8fa998",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "addon",
//           "samagriId": "7c45717e-0684-455f-af8a-4dca8140c21b",
//           "samagri": {
//             "id": "7c45717e-0684-455f-af8a-4dca8140c21b",
//             "name": "FRUITS & FLOWERS/ పూలు పండ్లు (GRUHAPRAVESAM) (Medium)",
//             "description": " పువ్వులు\t1/2 KG,     పూల మూరలు 4,         అరటిపండ్లు 1 1/2 Dozen,       పండ్లు\t4రకాలు 5    each",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 900,
//             "pujariSellingPrice": 1080,
//             "customerMrp": 1080,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-15T02:39:40.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T03:54:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 147
//           }
//         },
//         {
//           "id": "55d9a50c-5da6-4317-9334-0620b839f7f8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//           "samagri": {
//             "id": "9dcf0665-9619-4c65-9dd3-70c1733f8e07",
//             "name": "వరిపిండి/Rice Powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 5,
//             "pujariSellingPrice": 7,
//             "customerMrp": 7,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:34:35.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 59
//           }
//         },
//         {
//           "id": "fe5ca8af-db16-4211-a12b-2386ff90ee7e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//           "samagri": {
//             "id": "58725e04-6286-48fa-bcc9-8ac4f60ce8ff",
//             "name": "అగ్గి పెట్టిలు (Homelite Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 2,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:47:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-18T02:51:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 116
//           }
//         },
//         {
//           "id": "f843c803-3191-4b73-8556-291f7b21e366",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 10,
//           "samagriType": "addon",
//           "samagriId": "e2ea4998-041f-4027-8c31-50a68f5b885f",
//           "samagri": {
//             "id": "e2ea4998-041f-4027-8c31-50a68f5b885f",
//             "name": "అరటి పిల్లకల్లు/ Banana Plant ",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 40,
//             "pujariSellingPrice": 45,
//             "customerMrp": 45,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-12T01:27:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T05:28:24.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 199
//           }
//         },
//         {
//           "id": "f40cd2ca-5efb-4ff5-ad37-16aff052de26",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "addon",
//           "samagriId": "c4b1db1f-6f75-4669-b942-1e3535e2be53",
//           "samagri": {
//             "id": "c4b1db1f-6f75-4669-b942-1e3535e2be53",
//             "name": "FRUITS & FLOWERS/ పూలు పండ్లు (GRUHAPRAVESAM) (Premium)",
//             "description": "పువ్వులు\t1/2 KG,     పూల మూరలు 4,         అరటిపండ్లు 1 1/2 Dozen,       పండ్లు\t4రకాలు 5    each",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 1370,
//             "pujariSellingPrice": 1644,
//             "customerMrp": 1644,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-15T02:39:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-14T23:50:59.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 264
//           }
//         },
//         {
//           "id": "f0d7b540-8aea-4977-921b-f5c9dc6b834a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "e99698f9-2d61-4f70-b4db-c49bb1dab303",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "ddf6471b-2a08-4e89-9dc8-62935a033e90",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//           "samagri": {
//             "id": "f476e767-c95f-4f0c-b142-65bf3bb0374b",
//             "name": "గుమ్మకాయడి/ PUMPKIN",
//             "description": "1",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 140,
//             "pujariSellingPrice": 150,
//             "customerMrp": 150,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-14T02:50:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:14:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 191
//           }
//         },
//         {
//           "id": "d69c1980-2f50-4d91-9adb-ab18204bd218",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         },
//         {
//           "id": "d5235a47-3a3e-46d7-a124-815950a3a1af",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 10,
//           "samagriType": "default",
//           "samagriId": "c62966f6-bee6-453d-9265-6ad0f320d741",
//           "samagri": {
//             "id": "c62966f6-bee6-453d-9265-6ad0f320d741",
//             "name": "ఇసుక / SAND",
//             "description": "As Gampallu",
//             "units": "other",
//             "standardQty": "1",
//             "costPrice": 100,
//             "pujariSellingPrice": 125,
//             "customerMrp": 125,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:28:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-31T02:16:43.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 151
//           }
//         }
//       ]
//     },
//     {
//       "id": 4,
//       "name": "Naamakaranam",
//       "description": "A puja to help name the baby most appropriately, in the presence of vedic mantras and a guru's blessing. This is one of the sixteen vedic samskaras in Sanatana Dharma",
//       "language": "telugu",
//       "region": "Hyderabad",
//       "mode1": "at_my_home",
//       "mode2": "remote",
//       "mode3": null,
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/SsRsg2jC-ec\",\"https://www.youtube.com/embed/CkuMjlP8w9A\",\"https://www.youtube.com/embed/qrdxFxKIYNY\",\"https://www.youtube.com/embed/tQJc70v5O2o\"]",
//       "images": "[\"/uploads/2023/07/default-no-puja-image.jpg\"]",
//       "updatedAt": "2023-07-05T05:19:42.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": null,
//       "createdById": null,
//       "updatedById": "9",
//       "pujacategorymappings": [
//         {
//           "id": 1,
//           "pujaId": 4,
//           "categoryId": 1,
//           "subCategoryId": 1
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 74,
//           "pujaId": 4,
//           "durationHrs": 2,
//           "noOfPujaris": 2,
//           "modelSellingPrice": 5000,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 3000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-07-25T06:02:07.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-05-24T04:40:52.000Z",
//           "createdById": null,
//           "updatedById": "47",
//           "serviceFee": 722,
//           "samagriCost": 209,
//           "procedures": [
//             {
//               "id": "6c5f39b9-f797-4ab7-9c51-631a8f14c7d8",
//               "modelId": 74,
//               "procedureId": 15,
//               "procedure": {
//                 "id": 15,
//                 "language": "telugu",
//                 "title": "Sankalpam",
//                 "description": "Sankalpa is the reason for performing this puja. The puja depends upon the Sankalpa or will of the participants. Each puja is performed for a definite purpose and it is this purpose that the performer and participants must hold in their hearts as they take the Sankalpa. \nThe Sankalpa mantra begins by stating the name of the present year, month, season, phase of the moon, the day, time, and the Nakshatra, as per the Indian Calendar. \nIt then states the name of the deity, whom we are invoking for this puja. And finally, the individual or family who will be performing this puja, and the purpose of the puja.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "fd7d817a-9c7d-457b-9625-1cbc1cc17e20",
//               "modelId": 74,
//               "procedureId": 16,
//               "procedure": {
//                 "id": 16,
//                 "language": "telugu",
//                 "title": "Ganapati Puja",
//                 "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "3ed1a1b3-6cf2-48d3-bcad-17ced758f509",
//               "modelId": 74,
//               "procedureId": 6,
//               "procedure": {
//                 "id": 6,
//                 "language": "telugu",
//                 "title": "Punyaha vachanam (Laghu)",
//                 "description": "Punyaham means auspicious, and vachanam means to recite. In this step, mantras are recited which purify us and our surroundings and bring forth the auspiciousness required before performing a vedic action. This step is given in yajurveda and rigveda, and requires kalasham to perform it. It is performed to purify 8 things which are: Gruha, bhu, bhanda, atma, dravya, jala, sthala, shareera, and padartham.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "95ea2c02-d779-49a3-afe6-34fe6b5713dc",
//               "modelId": 74,
//               "procedureId": 14,
//               "procedure": {
//                 "id": 14,
//                 "language": "telugu",
//                 "title": "Aashirvachanam",
//                 "description": "The guru aashirvachanam is the biggest blessing for the devotee or the family performing the puja.  Guru is eeshwara's form in front of us. His tapasya and veda parayana, nithya gayatri japa etc. mean that his blessings can lead to god's grace.",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "efb6e531-c961-4e68-9dcd-38b9d72b8450",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 4,
//           "samagriType": "addon",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "ec0808a2-a192-4861-80a1-f36d92a16fef",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 4,
//           "samagriType": "addon",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "dcedf57a-2820-4552-9554-d3cca48dac58",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "dc3fa43d-de76-4bef-a58f-229a6d673209",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "d2699100-83d7-45ed-ae65-cc7efcc3e9c6",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 12,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "d0e94381-5e6e-4b57-9d8b-342477135552",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "cde4b702-da1f-424b-88fb-dcbc4180235f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "b59f017a-24e4-4b27-8e9e-6bcc2fcf91e1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "82942519-94e4-4a77-a088-c94c5e9c3cf7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "92da20c4-a10e-4013-9364-f5114438d904",
//           "samagri": {
//             "id": "92da20c4-a10e-4013-9364-f5114438d904",
//             "name": "వీడి పూలు / Loose Flowers",
//             "description": null,
//             "units": "gms",
//             "standardQty": "250",
//             "costPrice": 110,
//             "pujariSellingPrice": 120,
//             "customerMrp": 120,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T05:16:53.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-05T04:44:42.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 176
//           }
//         },
//         {
//           "id": "6acf4acf-a97d-4668-8379-cfaa4a6ad024",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "6a36259b-402c-48a0-b0a5-17f334ef90ab",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "5f0a95c7-0f7f-416f-bf3d-941b18ffea5e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "43e4b66e-341e-4406-b596-fe35d01ef217",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "30a43050-3da6-4386-9fde-8f91c204ceab",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "2cb9973b-8b21-40a6-955f-f37947f7955e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "1cf280f0-f049-4c9c-a38b-f3999e0ee20d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "17a0d692-6905-4f84-84c5-82e73c989740",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 4,
//           "samagriType": "addon",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "12f4d2bb-d711-4916-a94e-742ca077850b",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "0879374e-a83d-4b4a-983d-14b04b0cca50",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 4,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         }
//       ]
//     },
//     {
//       "id": 24,
//       "name": "Satyanarayana Vratam",
//       "description": "Sri Satyanarayana Pooja Vrat is performed with the sankalpam (resolve) to Lord Vishnu in the form of Sri Satya Narayana Swamy, seeking his benevolence to remove all the Obstacles and negative Forces and lead to victory or duccess in all the endeavours. Sri Satyanarayana Vrata is performed with puja offerings and satyanarayana katha is recited. This vrata is also observed after important events like gruhapravesha, the beginning of new ventures, marriage, or after the successful completion of major events.",
//       "language": "telugu",
//       "region": "Hyderabad",
//       "mode1": "at_my_home",
//       "mode2": null,
//       "mode3": null,
//       "mode4": null,
//       "videos": "[\"https://www.youtube.com/embed/w_JnwafQQNw\",\"https://www.youtube.com/embed/uZjCFWCu7eM\",\"https://www.youtube.com/embed/4z4DcaIPVAA\"]",
//       "images": "[\"/uploads/2023/07/satyanarayan_vratam.jpg\"]",
//       "updatedAt": "2023-07-13T07:02:37.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": null,
//       "createdById": null,
//       "updatedById": "9",
//       "pujacategorymappings": [
//         {
//           "id": 5,
//           "pujaId": 24,
//           "categoryId": 1,
//           "subCategoryId": 1
//         },
//         {
//           "id": 32,
//           "pujaId": 24,
//           "categoryId": 2,
//           "subCategoryId": 4
//         },
//         {
//           "id": 33,
//           "pujaId": 24,
//           "categoryId": 2,
//           "subCategoryId": 8
//         }
//       ],
//       "pujamodels": [
//         {
//           "id": 70,
//           "pujaId": 24,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 5999,
//           "advanceAmount": null,
//           "isPopularModel": true,
//           "pujariCost": 3000,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-10-05T08:11:33.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-05-24T01:46:40.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 690,
//           "samagriCost": 1394,
//           "procedures": [
//             {
//               "id": "ec627726-5c53-4f40-bb91-f3fe74f06457",
//               "modelId": 70,
//               "procedureId": 15,
//               "procedure": {
//                 "id": 15,
//                 "language": "telugu",
//                 "title": "Sankalpam",
//                 "description": "Sankalpa is the reason for performing this puja. The puja depends upon the Sankalpa or will of the participants. Each puja is performed for a definite purpose and it is this purpose that the performer and participants must hold in their hearts as they take the Sankalpa. \nThe Sankalpa mantra begins by stating the name of the present year, month, season, phase of the moon, the day, time, and the Nakshatra, as per the Indian Calendar. \nIt then states the name of the deity, whom we are invoking for this puja. And finally, the individual or family who will be performing this puja, and the purpose of the puja.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "78b0b7d6-8bb8-48fd-b1d5-ed173488fc96",
//               "modelId": 70,
//               "procedureId": 16,
//               "procedure": {
//                 "id": 16,
//                 "language": "telugu",
//                 "title": "Ganapati Puja",
//                 "description": "Lord Ganapati, is worshiped before starting anything because: \n\nThe Muladhara Chakra, the first Chakra of our body, is the foundation for our body. As Lord Ganapati is the Lord of the Muladhara Chakra, we pray to Him to help us finish the puja without any hurdles. Even our speech starts from the Muladhara Chakra as a subtle sound. Lord Ganapati is therefore called the Lord of speech, and the personification of Aum, the primordial sound.\n\nThe amsha / part of Ganesha's energy that is invoked at the start of every puja is essential for the siddhi of the mantras and for the thoughts to reach the devatas that are being worshipped\n\nGanesha is the creator and destroyer of obstacles. His prayer in the beginning becomes essential for nirvighna (no hurdle) puja completion",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "5f7555c1-b934-461c-86aa-5e18bdbe1051",
//               "modelId": 70,
//               "procedureId": 17,
//               "procedure": {
//                 "id": 17,
//                 "language": "telugu",
//                 "title": "Mandapam",
//                 "description": "A devotee can worship his/ her Ishta devata, to help them achieve their wishes and to eventually move onto Moksha. Since, It is not easy to concentrate on the formless, we invite the devata to descend into a form. A devata is pure consciousness, who does not have a form. Just like water, the devata can be invited to take the form of the vessel, known as an Upadhi. It is like freezing water into an ice-block of desired shape.  Mandapam is a suitable place that is prepared prior to starting the puja where devatas can be invoked and concecrated until the puja ends. ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "e64a3efd-5f18-4b65-a8a8-457c3b3ca851",
//               "modelId": 70,
//               "procedureId": 18,
//               "procedure": {
//                 "id": 18,
//                 "language": "telugu",
//                 "title": "Kalasa aaradhana",
//                 "description": "Here, water in a pot acquires the qualities of holy rivers, through the chanting of mantras. The 'Kalasa' accumulates all the positive vibrations during the puja and the same is transferred to our bodies when its water is sprinkled on our head and food items at the end of the puja.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "c42f6c66-7156-4d32-a323-f81b29dce936",
//               "modelId": 70,
//               "procedureId": 19,
//               "procedure": {
//                 "id": 19,
//                 "language": "telugu",
//                 "title": "Pancha loka palaka puja",
//                 "description": "In Sanatana Dharma, different worlds exist together like a multiverse, called lokas.\nFor Example: When we refer to the Vaikuntha Loka, ‘Vai’ means ‘No’ and ‘Kuntha’ means ‘anxiety’. So, for the people living in Vaikuntha there is ‘No-anxiety’. Similarly, Kailasha is called the loka of Lord Shiva, where his devotees reside. \nIn Pancha Loka Palaka Puja, we bow down to the rulers of five lokas: Ganapati Loka, Brahma Loka, Vishnu Loka, Rudra Loka, and Gouri Loka.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "314cd5ab-ab88-4bc8-af8a-9dd3d694edbb",
//               "modelId": 70,
//               "procedureId": 20,
//               "procedure": {
//                 "id": 20,
//                 "language": "telugu",
//                 "title": "Ashta dikpalaka puja",
//                 "description": "Ashtadikpalakas denote that God is in every direction, and that there is a specific energy and deity in each direction. Each energy has specific qualities and is suitable for different activities. We perform this puja to balance the five elements in these directions.\n\nThe Ashtadikpalakas of various directions are:\nKubera for North,\nYama for South,\nIndra for East,\nVaruna for West,\nIsana for Northeast,\nAgni for Southeast,\nVayu for Northwest,\nNirrti for Southwest.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "4716beae-48a5-4d45-ab97-82ce82275d48",
//               "modelId": 70,
//               "procedureId": 21,
//               "procedure": {
//                 "id": 21,
//                 "language": "telugu",
//                 "title": "Satyanarayana Puja",
//                 "description": "Satyanarayana Puja",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "c6546fb2-f194-4938-829e-23d81bc871be",
//               "modelId": 70,
//               "procedureId": 22,
//               "procedure": {
//                 "id": 22,
//                 "language": "telugu",
//                 "title": "Abhishekam (Panchamrutalu)",
//                 "description": "Abhishekam can be seen as watering the roots of a tree. Only if the roots of a tree are nourished, it can produce fruits. During an Abhishekam, the results that we desire to see in this world are like the fruits of a tree, and the divine beings who influence these results are like roots. Since, everything in this universe is interconnected, we perform Abhishekam to see specific results in the world.",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "8b9968f2-9883-43d0-b080-cab0ec6ff022",
//               "modelId": 70,
//               "procedureId": 23,
//               "procedure": {
//                 "id": 23,
//                 "language": "telugu",
//                 "title": "Archana ",
//                 "description": "During Archana, we offer flowers to a deity while chanting mantras or names of the deity. The flowers that we offer have a fragrance. Another name for fragrance is vasana(tendency). Therefore, offering flowers to a deity represents offering all our vasanas to the deity. And, while we let go of our vasanas, we chant the names of the deity, to connect with the divine.\n\nSometimes archana is performed with other dhravya like leaves that are most suitable for that particular devata. For e.g. tulsi, bilva etc. ",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "143061e3-3127-4a16-96ec-56a7c1f2edc8",
//               "modelId": 70,
//               "procedureId": 24,
//               "procedure": {
//                 "id": 24,
//                 "language": "telugu",
//                 "title": "Vrata Kadhalu",
//                 "description": "Vrata Kadhalu remind us and re -nforce the main promise (vrata) that we are undertaking as part of the puja.  The stories are designed with characters from the puranas whose smarana (rememberance) has a positive intent and impact on us. Hence the stories are not changed. ",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "8dab2712-5702-40d3-9912-d8d6b9967d4f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//           "samagri": {
//             "id": "7e21c2c4-4aa4-4fcd-be2b-a5e515b8ff3a",
//             "name": "మామిడి కొమ్మలు / Mango Leaves",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 25,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:35:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:17:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 145
//           }
//         },
//         {
//           "id": "8bfae949-82c2-49ac-ae23-ffc25b15703e",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "5d041188-68c4-4353-abef-d2b874717a73",
//           "samagri": {
//             "id": "5d041188-68c4-4353-abef-d2b874717a73",
//             "name": "పూజకు సంబంధించిన   దేవుడి పటములు  ",
//             "description": "\n\n",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:37:07.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-27T00:34:32.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 247
//           }
//         },
//         {
//           "id": "829ebfe1-974e-4843-90ab-e906dac6b79c",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//           "samagri": {
//             "id": "942c900f-2e1f-4dab-bcb4-2ad94d489b3a",
//             "name": "నువ్వుల నూనె/ Sesame oil",
//             "description": null,
//             "units": "ml",
//             "standardQty": "25",
//             "costPrice": 17,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:55:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 41
//           }
//         },
//         {
//           "id": "82919200-7195-48ea-b641-5cc739c11ae5",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//           "samagri": {
//             "id": "5b40e1eb-68bb-493c-b3cf-a98c4d0e208f",
//             "name": "నీళ్ల బిందెలు / Water Pots",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:33:20.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 248
//           }
//         },
//         {
//           "id": "809102d3-c668-4410-9d0a-b02d33426f9f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "95a758e3-2601-4657-add4-687e4543e5db",
//           "samagri": {
//             "id": "95a758e3-2601-4657-add4-687e4543e5db",
//             "name": "చెంచాలు/Spoons",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:33.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:52:58.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 245
//           }
//         },
//         {
//           "id": "7df2db48-db3b-42c0-85fc-aa8c4f1984e7",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 4,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "c3bfa357-80cd-4625-9027-32136699bffc",
//           "samagri": {
//             "id": "c3bfa357-80cd-4625-9027-32136699bffc",
//             "name": "పసుపు కొమ్ములు/Turmeric Sticks ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 8,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:14:39.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 6
//           }
//         },
//         {
//           "id": "7af026bb-da19-4a55-bbdc-47629b195cfa",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "addon",
//           "samagriId": "73cda276-7fe2-4b33-89f6-0704249d0363",
//           "samagri": {
//             "id": "73cda276-7fe2-4b33-89f6-0704249d0363",
//             "name": "ఆవు పెరుగు / Curd (Cow Milk) ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 30,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T05:38:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:38:16.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 178
//           }
//         },
//         {
//           "id": "769c0029-0566-4279-b9d4-1b003e969dc5",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 24,
//           "samagriType": "addon",
//           "samagriId": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//           "samagri": {
//             "id": "a1bdd086-5bb9-4893-8cba-110abe7c4166",
//             "name": "తేనె/Honey",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 6,
//             "customerMrp": 6,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:43:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 35
//           }
//         },
//         {
//           "id": "74dd5bab-6faa-4368-a8ef-98c98df2d598",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//           "samagri": {
//             "id": "3d0a5a6c-1978-4f5f-901f-960b96b1a5fe",
//             "name": "గంట/Bell",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:08.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:24:45.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 254
//           }
//         },
//         {
//           "id": "7200d758-041d-4b45-a313-2ee6ae658843",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//           "samagri": {
//             "id": "801b9779-f5f4-4f04-9ca5-f7b1b5d7afa4",
//             "name": "గిన్నెలు/ Bowls",
//             "description": null,
//             "units": "no",
//             "standardQty": "6",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:47:01.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:37:17.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 250
//           }
//         },
//         {
//           "id": "68ca7505-e88c-4a32-bcf5-afe3126e9369",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//           "samagri": {
//             "id": "66e7434f-58dd-4949-8d94-ce01c3d4bbbb",
//             "name": "గరిటలు/ Ladles",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:27.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 243
//           }
//         },
//         {
//           "id": "68bff3bd-88cd-4f94-96f2-ecf3c007c20a",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 7,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//           "samagri": {
//             "id": "5d6558a3-ac53-49a7-b8ed-3f9f6f0df9e4",
//             "name": "Coconut / కొబ్బరి కాయ",
//             "description": "Coconut",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 33,
//             "customerMrp": 33,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T02:28:29.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T04:59:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 144
//           }
//         },
//         {
//           "id": "5e3b04b8-6a89-4542-98f4-e1f54c52f06f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//           "samagri": {
//             "id": "3312a0af-a3f2-4761-917c-5a8d9196768b",
//             "name": "గంధం/Sandalwood powder",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 36,
//             "pujariSellingPrice": 50,
//             "customerMrp": 50,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:12:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 3
//           }
//         },
//         {
//           "id": "42a1bd78-2dca-4c2b-97f1-6cd979f27271",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//           "samagri": {
//             "id": "9ae83c84-f97c-4f72-b9a3-101a4fe19514",
//             "name": "పీటలు/ Peetalu",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:45.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:08.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 240
//           }
//         },
//         {
//           "id": "40a5aab1-5d12-42c4-9c55-4b7f759916f0",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//           "samagri": {
//             "id": "0118b692-1a60-4250-9461-f0dcd5057ee3",
//             "name": "తాంబాళాలు / Basins",
//             "description": null,
//             "units": "no",
//             "standardQty": "3",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:55.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:36:38.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 242
//           }
//         },
//         {
//           "id": "3daae976-243e-4589-8880-29d2331a3910",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 21,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//           "samagri": {
//             "id": "262d2697-2c9d-4afd-bbcb-1ee42041c6d9",
//             "name": "చిల్లరపైసలు/Rupee Coins",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:32:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 26
//           }
//         },
//         {
//           "id": "3b8b52e2-7bb1-4015-baf1-888e40f392ce",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "addon",
//           "samagriId": "1b070b44-eed3-483d-bdcc-b0d38935fedd",
//           "samagri": {
//             "id": "1b070b44-eed3-483d-bdcc-b0d38935fedd",
//             "name": "Fruits and Flowers (Satyanarayana Vratam) (Medium)",
//             "description": "Bananas 6 No ,  Loose Flowers 1/2 Kg  ,  Poola Morallu(2)   Moora Tulasi Mala (1),   Thulasi dhalam 50g  4 Types of Fruits 2 each",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 600,
//             "pujariSellingPrice": 700,
//             "customerMrp": 700,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-15T02:38:51.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:56:59.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 146
//           }
//         },
//         {
//           "id": "3ac0c988-298c-4312-b212-fd7ace0c8e01",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 6,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//           "samagri": {
//             "id": "d24a5919-fff2-4b3a-8b55-d415115971d8",
//             "name": "ఖర్జూరాలు/Dry Dates / Kharjur / Khajoor",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 12,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T06:25:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 5
//           }
//         },
//         {
//           "id": "34e2505e-2bca-4b84-be75-31739ae18b56",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//           "samagri": {
//             "id": "680009c6-6c26-432b-bc6e-ea75ce7de5fa",
//             "name": "చాపలు/ Mats",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:43:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 244
//           }
//         },
//         {
//           "id": "34d0b338-7ba9-496f-a340-8bb1e61a4733",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//           "samagri": {
//             "id": "5ed0e5f9-247e-4a3b-a3e0-af43aa9d98db",
//             "name": "గ్లాసులు/ Glasses",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:49:59.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:38:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 246
//           }
//         },
//         {
//           "id": "30372b87-b2d9-4f40-9f7c-533f971db4a8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//           "samagri": {
//             "id": "f5287c01-7c1a-4467-acbc-1d49ef9db007",
//             "name": "టవల్స్ - 36*72/36*72  / Towel (For peetam or for pujari)",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 110,
//             "pujariSellingPrice": 150,
//             "customerMrp": 150,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:29:13.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 25
//           }
//         },
//         {
//           "id": "2b4e3efe-f9a8-4322-a8b6-ec1a33b00985",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "addon",
//           "samagriId": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//           "samagri": {
//             "id": "7f14581f-a7bd-436e-b7cc-5f0a8adc797d",
//             "name": "రాగికళశాలు (1/2 ltr)/Copper kalasam (Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 183,
//             "pujariSellingPrice": 300,
//             "customerMrp": 300,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T07:25:25.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 16
//           }
//         },
//         {
//           "id": "27849cd4-a956-4914-8636-999bcf283cb0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 20,
//           "pujaId": 24,
//           "samagriType": "addon",
//           "samagriId": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//           "samagri": {
//             "id": "c3c90a73-8fe0-43f7-a9de-d2d16f1778b6",
//             "name": "ఆవు నెయ్యి/Cow Ghee",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 7,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T05:08:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 36
//           }
//         },
//         {
//           "id": "2014431d-db07-4bf4-a97f-8cdaa06cfe4b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//           "samagri": {
//             "id": "15737b89-b27a-47e7-a155-af866fbcf0fb",
//             "name": "పేపర్లు కొద్దిగా / Papers Little Bit",
//             "description": null,
//             "units": "bundles",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:50:42.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:50:41.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 249
//           }
//         },
//         {
//           "id": "1f05bacc-2e12-457b-a289-f1b43ebddae9",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "145d9bf0-e145-46ff-b694-91184f294443",
//           "samagri": {
//             "id": "145d9bf0-e145-46ff-b694-91184f294443",
//             "name": "నేప్కిన్లు/Napkins",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:43.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:49:22.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 251
//           }
//         },
//         {
//           "id": "1d94c7b0-ef66-4ae9-bcd2-5dab4f0d5fa4",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "b8e79765-6a9f-43f7-8c17-48c393ed1c83",
//           "samagri": {
//             "id": "b8e79765-6a9f-43f7-8c17-48c393ed1c83",
//             "name": "పత్తి వస్త్రం చిన్నది / Cotton Garland  ( Small)",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 30,
//             "pujariSellingPrice": 39,
//             "customerMrp": 39,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T01:21:00.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 95
//           }
//         },
//         {
//           "id": "0f666b27-f893-419e-9f0e-e41d6e8f3701",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//           "samagri": {
//             "id": "dfcf3642-0ad4-4591-827b-1488bd0e3dd9",
//             "name": "దీపపు కుందులు /Lampshades",
//             "description": null,
//             "units": "no",
//             "standardQty": "2",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-13T04:48:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:23:39.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 256
//           }
//         },
//         {
//           "id": "09a193a4-7177-4597-a268-5c6b2a1b6c2d",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//           "samagri": {
//             "id": "d8868a0c-43f3-4d72-9d9c-1761a78a1870",
//             "name": "కుంకుమ /Sindoor /  Kumkum ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 9,
//             "pujariSellingPrice": 11,
//             "customerMrp": 11,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:10:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 2
//           }
//         },
//         {
//           "id": "0737070d-56d1-4942-9594-f0f1bf8f1d6b",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//           "samagri": {
//             "id": "b6800cd7-903b-4cf5-b343-aeb4553befcb",
//             "name": "చెంబులు/Kalasam",
//             "description": null,
//             "units": "no",
//             "standardQty": "4",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:42:26.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:39:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 253
//           }
//         },
//         {
//           "id": "eda0f0b1-2a8b-4f95-840f-a674b54c8f5e",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//           "samagri": {
//             "id": "88bc6a12-14df-4581-9c98-cdc561c078e3",
//             "name": "బ్లౌజ్ / Unstitched Blouse Piece",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 58,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T02:51:30.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 103
//           }
//         },
//         {
//           "id": "e84782fc-8318-4754-a939-d3396e465df1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "776b111a-22f3-4051-84a1-996f5defd7d9",
//           "samagri": {
//             "id": "776b111a-22f3-4051-84a1-996f5defd7d9",
//             "name": "పసుపు /Turmeric  / Haldi",
//             "description": "This was a completely organic Turmeric",
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 7,
//             "pujariSellingPrice": 9,
//             "customerMrp": 9,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:11:12.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 1
//           }
//         },
//         {
//           "id": "dfe66764-dacc-4d76-990d-2d057ef90533",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//           "samagri": {
//             "id": "6a526254-42c1-473b-8c44-4d0b9f955a2f",
//             "name": "హారతి కర్పూరం /Camphor",
//             "description": "This was a camphor",
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:04.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 8
//           }
//         },
//         {
//           "id": "d7295d32-6941-4033-a4dc-221a0c72c8b2",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//           "samagri": {
//             "id": "a5d83758-0791-45d3-bf4f-ecff2ca17b20",
//             "name": "ఎండు కొబ్బరి ముక్కలు/Dry coconut shell half pieces (Medium) ",
//             "description": null,
//             "units": "gms",
//             "standardQty": "100",
//             "costPrice": 18,
//             "pujariSellingPrice": 22,
//             "customerMrp": 22,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-12T08:01:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 48
//           }
//         },
//         {
//           "id": "cd4676f0-d221-4be1-af01-e66db28368c8",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 10,
//           "pujaId": 24,
//           "samagriType": "addon",
//           "samagriId": "be08854a-0802-41aa-a71b-703590374e7e",
//           "samagri": {
//             "id": "be08854a-0802-41aa-a71b-703590374e7e",
//             "name": "పంచదార/Sugar",
//             "description": null,
//             "units": "gms",
//             "standardQty": "25",
//             "costPrice": 1,
//             "pujariSellingPrice": 2,
//             "customerMrp": 2,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:47:44.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 37
//           }
//         },
//         {
//           "id": "ca702289-e8db-4b27-8212-87cd5550bd27",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//           "samagri": {
//             "id": "bd39ffe8-5bdf-4372-9b02-b087cbbbf916",
//             "name": "ఏక హారతి/Eka Harati",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:39:14.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:26:01.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 259
//           }
//         },
//         {
//           "id": "c3cc8419-f9c6-4fd1-8507-8c2ccdcb534f",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//           "samagri": {
//             "id": "26b5cd3f-8cb9-4d16-923e-c8f1ca0741cc",
//             "name": "ఉద్దరిని/Uddarini",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:34:41.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:52.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 258
//           }
//         },
//         {
//           "id": "bb7eeced-da5f-46f7-b445-c917f3ddf71f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//           "samagri": {
//             "id": "a1d3b83f-75aa-4220-a879-e2d54cb9c9df",
//             "name": "అగరుబత్తులు / INCENSE STICKS",
//             "description": null,
//             "units": "no",
//             "standardQty": "10",
//             "costPrice": 4,
//             "pujariSellingPrice": 14,
//             "customerMrp": 14,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-22T06:47:48.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 9
//           }
//         },
//         {
//           "id": "b3a68258-a315-4d3b-afbf-c1ee269534ab",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "836ee333-9374-4fbb-9810-815984a91453",
//           "samagri": {
//             "id": "836ee333-9374-4fbb-9810-815984a91453",
//             "name": "అగర్బత్తి స్టాండ్/ Agarbatti Stand",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:35:32.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:51.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 241
//           }
//         },
//         {
//           "id": "b21fec2f-8ab3-46a6-bc69-6544cafc55e6",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//           "samagri": {
//             "id": "303153e2-6f78-40d0-82f5-dee5ad9ba7d3",
//             "name": "దారం బంతి/ Thread Ball",
//             "description": null,
//             "units": "packets",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 10,
//             "customerMrp": 10,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-20T01:35:16.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 28
//           }
//         },
//         {
//           "id": "a6c7b358-fb06-44a0-88d6-cf0f832fa4f0",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "addon",
//           "samagriId": "48a8178f-db8e-42f4-bcef-9d2dcee46d21",
//           "samagri": {
//             "id": "48a8178f-db8e-42f4-bcef-9d2dcee46d21",
//             "name": "సత్యనారాయణ స్వామి మహా-ప్రసాద్ పదార్థాలు  / Satyanarayana Vrata Prasadam",
//             "description": "Godhuma Ravva 1 1/4 kg ,  Kaju 50g ,  Kismiss 50g ,   Badam 50 g\n\nగోధుమ రవ్వ\t1 1/4 kg,     కాజు\t50g,     కిస్మిస్\t50g,    బాదం\t50 g",
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 427,
//             "pujariSellingPrice": 450,
//             "customerMrp": 450,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-26T04:36:22.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:59:15.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 175
//           }
//         },
//         {
//           "id": "a407245b-5bc8-4b69-a11f-cf41eea6ec97",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 2,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//           "samagri": {
//             "id": "d48c0563-7325-4059-a6ca-9c2eb6d7b405",
//             "name": "బియ్యం/Rice",
//             "description": null,
//             "units": "kgs",
//             "standardQty": "1",
//             "costPrice": 60,
//             "pujariSellingPrice": 65,
//             "customerMrp": 65,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:23:37.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 27
//           }
//         },
//         {
//           "id": "9d0e2e4a-88f6-4fbd-81b1-554ad6fd2a33",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//           "samagri": {
//             "id": "53d9a474-adc3-4de4-bbcd-a7365406faaa",
//             "name": "వక్కలు/Betel Nuts",
//             "description": null,
//             "units": "gms",
//             "standardQty": "10",
//             "costPrice": 11,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-26T00:37:23.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 4
//           }
//         },
//         {
//           "id": "9bfbd50f-4cdc-4da8-b4c7-72f60ce69fe5",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//           "samagri": {
//             "id": "c0a51f21-2075-4a0e-96e8-313a2f328632",
//             "name": "పంచపాత్ర/Panchapatra",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:36:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:41:05.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 257
//           }
//         },
//         {
//           "id": "96f74929-6f76-4c87-9d15-6a9d166999c8",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "405616ee-9b88-4cb9-a58d-119440a8d349",
//           "samagri": {
//             "id": "405616ee-9b88-4cb9-a58d-119440a8d349",
//             "name": "చాకు/knife",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:38:03.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:45:12.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 252
//           }
//         },
//         {
//           "id": "91be3f45-1ab8-48ce-b616-ce67134eadbf",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 24,
//           "samagriType": "addon",
//           "samagriId": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//           "samagri": {
//             "id": "90618485-c2fe-49bb-bf12-33d0986eaca8",
//             "name": "ఆవు పాలు/ Cow Milk ",
//             "description": null,
//             "units": "ml",
//             "standardQty": "250",
//             "costPrice": 18,
//             "pujariSellingPrice": 25,
//             "customerMrp": 25,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-16T04:08:58.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:37:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 177
//           }
//         },
//         {
//           "id": "8ffd535e-e031-4536-9507-3fcec546a8ab",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "1a564751-0ae5-42de-821a-e1e3afe41741",
//           "samagri": {
//             "id": "1a564751-0ae5-42de-821a-e1e3afe41741",
//             "name": "కత్తెర / Scissor",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 150,
//             "pujariSellingPrice": 170,
//             "customerMrp": 170,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-06-21T06:13:28.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-21T06:13:28.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 167
//           }
//         },
//         {
//           "id": "f1b2ad14-5075-4c58-897c-35e8d3a37c8f",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 5,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//           "samagri": {
//             "id": "7551917b-d81a-46b9-8a4f-6349ad0bcbb8",
//             "name": "తమలపాకులు / Betel leaves",
//             "description": "These are leaves user in puja",
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 1,
//             "pujariSellingPrice": 1,
//             "customerMrp": 1,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:30:21.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-05-26T05:08:04.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 169
//           }
//         },
//         {
//           "id": "fd4c211c-8c6a-4441-ab7c-ca0dd90d6468",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//           "samagri": {
//             "id": "c7a8d447-67a4-4627-9554-aecad0ccb8b3",
//             "name": "వత్తులుహ్యాండ్ మేడ్ /Wattles/ Wicks Hand Made",
//             "description": null,
//             "units": "no",
//             "standardQty": "20",
//             "costPrice": 10,
//             "pujariSellingPrice": 15,
//             "customerMrp": 15,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-13T06:26:47.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": null,
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 45
//           }
//         },
//         {
//           "id": "f5e1cff7-9e84-4af9-aad2-45c66db0f846",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 24,
//           "samagriType": "default",
//           "samagriId": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//           "samagri": {
//             "id": "33ac3095-bd73-4ad4-be82-6743a88ad25f",
//             "name": "కొబ్బరికాయ పగలగొట్టే  రాడ్/Coconut Breaking Rod",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 0,
//             "pujariSellingPrice": 0,
//             "customerMrp": 0,
//             "karishyeProvided": "does_not_stock",
//             "updatedAt": "2023-07-12T01:43:19.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-28T00:48:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 255
//           }
//         }
//       ]
//     },
//     {
//       "id": 169,
//       "name": "Birthday function",
//       "description": "To celebrate birthday",
//       "language": "telugu",
//       "region": null,
//       "mode1": "at_my_home",
//       "mode2": "remote",
//       "mode3": "on_behalf",
//       "mode4": null,
//       "videos": "[\"https://youtube.com/embed/B6qgBhMt1T4\",\"https://youtube.com/embed/B6qgBhMt1T4\",\"https://youtube.com/embed/B6qgBhMt1T4\",\"https://youtube.com/embed/B6qgBhMt1T4\"]",
//       "images": "[\"/uploads/2023/07/Satyanarayana-Vratam-Abhishekam-scaled.jpg\",\"/uploads/2023/07/Navagraha-3-scaled.jpg\",\"/uploads/2023/07/Satyanarayana-Vratam-Abhishekam-scaled.jpg\",\"/uploads/2023/07/Navagraha-3-scaled.jpg\",\"/uploads/2023/07/Satyanarayana-Vratam-Abhishekam-scaled.jpg\"]",
//       "updatedAt": "2023-08-28T07:37:37.000Z",
//       "deletedAt": null,
//       "importHash": null,
//       "createdAt": "2023-07-28T01:41:25.000Z",
//       "createdById": "9",
//       "updatedById": "9",
//       "pujacategorymappings": [],
//       "pujamodels": [
//         {
//           "id": 118,
//           "pujaId": 169,
//           "durationHrs": 2,
//           "noOfPujaris": 1,
//           "modelSellingPrice": 673,
//           "advanceAmount": 100,
//           "isPopularModel": true,
//           "pujariCost": null,
//           "indicativeCostPrice": null,
//           "name": null,
//           "updatedAt": "2023-08-10T01:18:59.000Z",
//           "deletedAt": null,
//           "importHash": null,
//           "createdAt": "2023-07-28T01:46:16.000Z",
//           "createdById": null,
//           "updatedById": "9",
//           "serviceFee": 500,
//           "samagriCost": 70,
//           "procedures": [
//             {
//               "id": "ab3ef1d7-9325-4c48-ad1c-965b0fd0581d",
//               "modelId": 118,
//               "procedureId": 54,
//               "procedure": {
//                 "id": 54,
//                 "language": "telugu",
//                 "title": "Bhoomi Puja",
//                 "description": "Bhoomi devi ?prudhvi devata aavahana is done into the kalasha.  Bhu devi is worshipped with bhu suktham .  Milk and water is sprinked on the water.  On the ishyanya corner the yajamani digs some mud. The yajamani's wife, takes this mud and dispatches it in the nairuthi corner of the land.  \n\nKalasham with some special ingredients or shanku is placed in the land at that location, covered with bricks and puja is performed. ",
//                 "specialNotes": "test notes"
//               }
//             },
//             {
//               "id": "b6aa787b-15b1-4630-b802-9fb911769919",
//               "modelId": 118,
//               "procedureId": 55,
//               "procedure": {
//                 "id": 55,
//                 "language": "telugu",
//                 "title": "Ganesha Navaratri Puja",
//                 "description": "Puja on 1st day or 3 days or 5 days or 9 days as required.\nPuja on morning or evening or both as required.\n\nOur pujari will be present as you opted for and take care of the nithya puja, devotees, aashirvachanam etc.\n\n",
//                 "specialNotes": "Please mention if you need any other pujas like ganapati homam etc. We will accordingly arrange for the same"
//               }
//             },
//             {
//               "id": "5ecafe5e-3761-44fb-801a-3897e22c63dc",
//               "modelId": 118,
//               "procedureId": 53,
//               "procedure": {
//                 "id": 53,
//                 "language": "telugu",
//                 "title": "Naamakarana",
//                 "description": "A plate of rice is taken. With a gold ring wrapped with darbha, 3 lines are drawn and three different names are written by the father.\n1st line: Maasa namam, 2nd line: Nakshatra namam: 3rd : Vyavaharika namam  . Once this is done, the child is placed in cradle Three names are recited in child's ear along with mantra by pujari. Followed by the pujari, the mother, the father and the grandparents repeat the same and bless the child.\n\nDepending ojn the family traditions, culture of the region etc. the sequence or the steps would vary.  The pujari would accordingly guide the event. \n",
//                 "specialNotes": null
//               }
//             },
//             {
//               "id": "01163d26-badd-4ea3-bfef-065b7c84bc41",
//               "modelId": 118,
//               "procedureId": 51,
//               "procedure": {
//                 "id": 51,
//                 "language": "telugu",
//                 "title": "Navagraha Homam",
//                 "description": " 1/10th of the japam count has to be offered as tarpanam to the graha. For e.g. if 6000 japam was performed, then 600 times tarpanam needs to be offered. Following the same 1/10th of tarpanam count has to be offered to the graha during homam with mantra, dhravyam . For e.g  if 6000 japam was performed, then 600 times tarpanam needs to be offered and 60 times, homam offering needs to be given .\n\nDepending on which graha balance needs to be restored, that particular grahas are offered havissu with a prominent count.   The count depends on the intensity of the problem to be solved and has a mathematical relationship with the tarpanam and japam that was offered to the graha.\n\nSurya\t(6  multiples    / 108)\nChandra  (10 multiples  / 108)\nKujudu\t(7 multiples   / 108)\nBudhudu\t(17 multiples  / 108)\nGuruvu\t(16 multiples   / 108)\nSukrudu\t (20 multiples   / 108)\nShani\t(19 multiples   / 108)\nRahu\t(18 multiples   / 108)\nKetu\t(7 multiples   / 108)",
//                 "specialNotes": "If you have not performed any tarpana or japam and have been advised to perform navagraha homam directly, please mention the same in your call with our pujari.  He will accordingly guide and plan the puja."
//               }
//             },
//             {
//               "id": "e87ab40c-d12a-410f-a6b0-9ac45990024b",
//               "modelId": 118,
//               "procedureId": 52,
//               "procedure": {
//                 "id": 52,
//                 "language": "telugu",
//                 "title": "Shop / Business Dwara Pooja",
//                 "description": "Along with lightning lamps usually the consort of yajamani applies turmeric and decorates the threshold. The yajamani bows to gadapa/dwara and pays his obeisance to the threshold which is manifest of mahalakshmi then followed by breaking of pumpkin balipradhana (sacrifice /offering)\nThe spilling of navadhanyas which signifies prosperity is done, finally step into the new space where prospectively business is conducted \n",
//                 "specialNotes": null
//               }
//             }
//           ]
//         }
//       ],
//       "pujaSamagriMappings": [
//         {
//           "id": "fc6685d2-8118-4012-9180-d3c81e4b81d1",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 169,
//           "samagriType": "addon",
//           "samagriId": "18a15869-1fd6-4452-80b3-567df95c20ab",
//           "samagri": {
//             "id": "18a15869-1fd6-4452-80b3-567df95c20ab",
//             "name": "అరటి పండ్లు / Banana",
//             "description": "A banana is an elongated, edible fruit – botanically a berry – produced by several kinds of large herbaceous flowering plants in the genus Musa. In some countries, bananas used for cooking may be called \"plantains\", distinguishing them from dessert bananas.",
//             "units": "no",
//             "standardQty": "12",
//             "costPrice": 60,
//             "pujariSellingPrice": 70,
//             "customerMrp": 70,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-19T22:36:56.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-06-10T03:26:36.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 181
//           }
//         },
//         {
//           "id": "f8d1519f-1240-40c2-92e2-73ac70cbc731",
//           "isProvidedBy": "pujari",
//           "noOfStandardQty": 1,
//           "pujaId": 169,
//           "samagriType": "default",
//           "samagriId": "814c760a-3fb4-4f25-b90e-eba9bd0c9a8a",
//           "samagri": {
//             "id": "814c760a-3fb4-4f25-b90e-eba9bd0c9a8a",
//             "name": "3ft / 3.2ft - గణేష్ విగ్రహం / Ganesha (Pure Clay / మట్టి గణేష )",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 4000,
//             "pujariSellingPrice": 5200,
//             "customerMrp": 5200,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-25T12:42:20.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-23T13:19:47.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 0
//           }
//         },
//         {
//           "id": "d988e43b-25f3-4b1d-bbea-322890c7f129",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 169,
//           "samagriType": "addon",
//           "samagriId": "934c0246-56b9-419e-adf1-f76bbd33f01b",
//           "samagri": {
//             "id": "934c0246-56b9-419e-adf1-f76bbd33f01b",
//             "name": "పసుపు దీక్ష వస్త్రాలు / Pasupu Deeksha Vatralu",
//             "description": null,
//             "units": "set",
//             "standardQty": "1",
//             "costPrice": 850,
//             "pujariSellingPrice": 900,
//             "customerMrp": 900,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-20T09:18:49.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-09T21:35:11.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 207
//           }
//         },
//         {
//           "id": "c707e8fe-4939-447c-9ad5-544ff722641d",
//           "isProvidedBy": "user",
//           "noOfStandardQty": 1,
//           "pujaId": 169,
//           "samagriType": "default",
//           "samagriId": "a6466242-2a96-4098-97e9-022996693e06",
//           "samagri": {
//             "id": "a6466242-2a96-4098-97e9-022996693e06",
//             "name": "మేడి కొమ్మ / Medi Branch ",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 80,
//             "pujariSellingPrice": 100,
//             "customerMrp": 100,
//             "karishyeProvided": "stocks",
//             "updatedAt": "2023-07-18T02:55:46.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-08T04:45:33.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 222
//           }
//         },
//         {
//           "id": "b16111bc-dcb5-4515-b291-6ac6ead07568",
//           "isProvidedBy": "karishye",
//           "noOfStandardQty": 1,
//           "pujaId": 169,
//           "samagriType": "default",
//           "samagriId": "72e2551c-e406-4a7b-bb41-0384d9125819",
//           "samagri": null
//         },
//         {
//           "id": "8b0b8c8a-ea66-4d3f-8f01-ea791ad05779",
//           "isProvidedBy": "pujari",
//           "noOfStandardQty": 1,
//           "pujaId": 169,
//           "samagriType": "default",
//           "samagriId": "9241ed1f-9ee0-4800-a3ae-78b1269b524e",
//           "samagri": {
//             "id": "9241ed1f-9ee0-4800-a3ae-78b1269b524e",
//             "name": "కమలం పువ్వులు / Lotus flowers",
//             "description": null,
//             "units": "no",
//             "standardQty": "1",
//             "costPrice": 10,
//             "pujariSellingPrice": 20,
//             "customerMrp": 20,
//             "karishyeProvided": "does_not_stock_but_can_supply",
//             "updatedAt": "2023-07-18T03:14:11.000Z",
//             "deletedAt": null,
//             "importHash": null,
//             "createdAt": "2023-07-10T01:32:10.000Z",
//             "createdById": null,
//             "updatedById": null,
//             "orderId": 197
//           }
//         }
//       ]
//     }
//   ]
// }